export default function getGenderString(gender:string) {
   
  let genderString: string = '';
  switch (gender) {
    case "Male":
      genderString = 'He is '
      break;
    case "Female":
      genderString = 'She is '
      break;
    default:
      genderString = 'They are '
      break;
  }
    return genderString;
  }