import React from "react";
import { Route, Redirect, useLocation, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectAuth } from "../containers/slices/authSlice";
import { selectRegistration } from "../containers/slices/registrationSlice";

type Props = {
  element?: React.ReactElement;
} & RouteProps;

function RouteElement({ element }: Props) {
  const location = useLocation();
  const { hasAuth, requiresMFA, hasMFA } = useSelector(selectAuth);
  const { newProfileCreated } = useSelector(selectRegistration);
  
  const rememberDeviceMFA: boolean = typeof localStorage.getItem('rememberDeviceMFA') === "string" ? JSON.parse(localStorage.getItem('rememberDeviceMFA') as string) : undefined;

  const validMFA = requiresMFA === hasMFA || requiresMFA === rememberDeviceMFA;
  
  const userEnabled = ((hasAuth && validMFA) || (hasAuth && newProfileCreated));

  if (!userEnabled) {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location }
        }}
      />
    );
  }

  return element ?? null;
}

export default function PrivateRoute({ element, ...rest }: Props) {
  return (
    <Route {...rest}>
      <RouteElement element={element} />
    </Route>
  );
}
