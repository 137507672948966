import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import { debounce, uniqBy } from "lodash";
import FacilitiesList from "./FacilitiesList";
import useFacilities from "../../queries/useFacilities";
import { defaultRadiusOption } from "../../utils/providers";
import { findFacility, formatFacility } from "../../utils/findPage";
import { extractSearchParams } from "../../utils/url";
import { Spinner } from "../../components/Spinner";
import { GMap, GMapMarker } from "../../components/Map";
import FacilityFilters from "./FacilityFilters";

interface searchParams {
  zip?: string;
  kind?: string[];
  radius?: string;
}

const itemsPerPage = 25;

function Facilities() {
  const [page, setPage] = useState<number>(0);
  const { search: urlSearch } = useLocation();
  const searchParams: searchParams = extractSearchParams(urlSearch);
  const defaultFilterOptions = {
    zip: searchParams?.zip || "",
    radius: searchParams?.radius || defaultRadiusOption.label,
    kind: [findFacility(searchParams?.kind)?.label] || [],
    page: page
  };

  const [facilitiesFilters, setFacilitiesFilters] = useState(defaultFilterOptions);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [activeItemId, setActiveItemId] = useState<string | null>(null);
  const [mapCenter, setMapCenter] = useState<number[]>([0, 0]);
  const [lastPage, setLastPage] = useState(false);
  const [items, setItems] = useState<any[]>([]);
  const [fromScroll, setFromScroll] = useState(false);

  const facilityListRef: any = useRef();

  const facilities = useFacilities(facilitiesFilters);
  const availableCount = facilities?.data?.meta?.availableCount;
  const pages = Math.ceil(availableCount / itemsPerPage) || 1;

  const onItemAction = debounce((id, location) => {
    setSelectedItemId(id === selectedItemId ? "" : id);
    setMapCenter(location);
  }, 250);

  const onTypeChange = (label) => {
    const index = facilitiesFilters.kind.indexOf(label);
    let updatedArray;
    if (index !== -1) {
      updatedArray = [...facilitiesFilters.kind];
      updatedArray.splice(index, 1);
    } else {
      updatedArray = [...facilitiesFilters.kind, label];
    }
    setPage(0);
    onFieldChange({ kind: updatedArray, page: 0 });
  };

  const clearFilters = (e) => {
    e.preventDefault();
    setPage(0);
    setFacilitiesFilters({ ...defaultFilterOptions, page: 0 });
  };

  const onFieldChange = (newField) => {
    setFacilitiesFilters((oldFields) => ({ ...oldFields, ...newField }));
  };

  const renderLocationsOnMap = ({ map, maps: { LatLng, LatLngBounds, Marker }, ref }) => {
    const bounds = new LatLngBounds();
    items.forEach((fac) => {
      const lat = fac.data.locationData.latitude;
      const lng = fac.data.locationData.longitude;
      bounds.extend(new LatLng(lat, lng));
    });
    map.fitBounds(bounds);
  };

  const nextPage = () => {
    if (page >= pages - 1) return;
    setFromScroll(true);
    onFieldChange({ page: facilitiesFilters.page + 1 });
    setPage((prev) => prev + 1);
  };

  const onScrollHandler = () => {
    if (fromScroll) return;
    const currScrollPos = facilityListRef?.current?.scrollTop;
    const elemHeight = facilityListRef?.current?.offsetHeight;
    const fullScrollHeight = facilityListRef?.current?.scrollHeight;
    const itemsLoading = facilities?.isLoading || facilities?.isRefetching;

    if (currScrollPos + elemHeight < fullScrollHeight || itemsLoading) {
      return;
    }

    if (lastPage) return;
    nextPage();
  };

  useEffect(() => {
    setLastPage(facilities?.data?.meta?.availableCount < items.length);
    if (!facilities?.data?.meta?.availableCount) return;

    if (fromScroll) {
      setItems((prev) => uniqBy([...prev, ...facilities?.data?.data?.places], (item) => item.data.id));
    } else {
      setItems(facilities?.data?.data?.places);
    }

    setFromScroll(false);
  }, [facilities?.dataUpdatedAt, page]);

  useEffect(() => {
    facilityListRef?.current?.addEventListener("scroll", onScrollHandler);
    return () => {
      facilityListRef?.current?.removeEventListener("scroll", onScrollHandler);
    };
  }, [facilityListRef?.current]);

  return (
    <main className="bg-gray-background flex flex-col flex-1 w-full mx-auto overflow-y-auto">
      <div className="justify-center w-full h-full max-w-5xl px-4 pb-8 mx-auto font-effra">
        <div className="my-6 ml-0" />
        <div className="flex flex-col w-full h-auto py-8 mb-8 space-y-6 bg-white rounded shadow-lg">
          <div className="w-full h-full px-4">
            <div className="flex flex-col w-full">
              <h1 className="text-4xl mb-8">Find facilities</h1>
              <FacilityFilters
                facilitiesFilters={facilitiesFilters}
                onFieldChange={onFieldChange}
                onTypeChange={onTypeChange}
                clearFilters={clearFilters}
                errors={facilities.error}
              />
              <div className="flex flex-col-reverse h-full md:flex-row gap-4">
                {facilities?.isLoading || facilities?.isRefetching ? (
                  <div className="w-full max-h-450 h-40vh">
                    <Spinner fullScreen={false} />
                  </div>
                ) : items.length ? (
                  <>
                    <div ref={facilityListRef} className="w-full overflow-auto h-40vh max-h-450">
                      <FacilitiesList
                        activeItemId={activeItemId}
                        formatFacility={formatFacility}
                        facilities={items || []}
                        selectedItemId={selectedItemId}
                        setMapCenter={setMapCenter}
                        onItemHover={onItemAction}
                        setHoveredId={(id) => setSelectedItemId(id)}
                      />
                    </div>
                    <div className="grid w-full h-64 z-0 md:h-auto sticky" style={{ top: 10 }}>
                      <GMap center={mapCenter} renderLocations={renderLocationsOnMap}>
                        {!(facilities?.isLoading || facilities?.isRefetching) &&
                          items.map((fac, i) => {
                            const { id } = fac.data;
                            return (
                              <GMapMarker
                                key={id}
                                data={formatFacility(fac)}
                                active={activeItemId === id}
                                selected={selectedItemId === id}
                                onMouseEnter={() => setActiveItemId(id)}
                                onMouseLeave={() => setActiveItemId(null)}
                                onClick={() =>
                                  onItemAction(id, [fac.data.locationData.latitude, fac.data.locationData.longitude])
                                }
                                lat={fac.data.locationData.latitude}
                                lng={fac.data.locationData.longitude}
                              />
                            );
                          })}
                      </GMap>
                    </div>
                  </>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Facilities;
