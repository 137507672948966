import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store/rootReducer";

export interface ConfigsStateSlice {
  [key: string]: string;
}

const initialState: ConfigsStateSlice = {};

export const configsSlice = createSlice({
  name: "configs",
  initialState,
  reducers: {
    addConfigValue: (state, action) => {
      state[action.payload.name] = action.payload.value;
    }
  }
});

export const selectConfigs = (state: RootState) => state.configs;

export const { addConfigValue } = configsSlice.actions;

export default configsSlice.reducer;
