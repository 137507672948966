import React, { useState } from "react";
import { useSelector } from "react-redux";

import { sendDiscountCardToEmail } from "../../api/pricing";
import { ReactComponent as LeftArrowIcon } from "../../assets/icons/left-arrow.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone.svg";
import { ReactComponent as PriceIcon } from "../../assets/icons/PriceWidget.svg";

// import HeartIcon from "../../styles/healthiestYou/assets/icons/heart.svg";
// import EmptyHeartIcon from "../../styles/healthiestYou/assets/icons/heart-empty.svg";
import PricePrescriptionDisclaimer from "./PricePrescriptionDisclaimer";

import { useProviderDiscountCard } from "../../queries/usePrescriptions";
import { CommunicationSendIcon } from "../../components/Icons";
import { selectAuth } from "../../containers/slices/authSlice";

const PricePrescriptionsDetails = ({ priceDetails, onClose }) => {
  const authSelector = useSelector(selectAuth);
  const profileId = authSelector?.entities?.profile?.id;

  const { Name: tempName, Price, PharmacyName, Street1, City, State, ZipCode, drugName } = priceDetails;
  const Name = tempName?.replace(/\/|-/g, " ");

  const { data } = useProviderDiscountCard(drugName || Name, ZipCode);
  //   const { memberId, group, pcn, bin } = discountCard;

  const discountCard = data?.data?.discountCard;

  const detailsItems = [
    ["Member ID", discountCard?.member_id],
    ["Group", discountCard?.group_number],
    ["PCN", discountCard?.rxpcn],
    ["BIN", discountCard?.rxbin]
  ];

  const [showDisclaimer, setShowDisclaimer] = useState(false);
  //   const [isFavorite, setIsFavorite] = useState((favoriteList || []).some((i) => i.PharmacyId === priceDetails.PharmacyId));

  //   const handleFavorite = useCallback(() => {
  //     const index = favoriteList.findIndex((item) => item.PharmacyId === priceDetails.PharmacyId);
  //     if (index === -1) {
  //       addToFavorite({ favorite: true, meta: priceDetails });
  //       favoriteList.push(priceDetails);
  //       setIsFavorite(true);
  //     } else {
  //       addToFavorite({ favorite: false, meta: priceDetails });
  //       favoriteList.splice(index, 1);
  //       setIsFavorite(false);
  //     }
  //   }, [priceDetails]);

  const emailCard = () => {
    const payload = {
      ProfileId: profileId,
      PharmacyName,
      Address: Street1,
      City,
      State,
      Zip: ZipCode,
      DrugName: Name,
      MemberId: discountCard?.member_id,
      Pcn: discountCard?.rxpcn,
      Group: discountCard?.group_number,
      Bin: discountCard?.rxbin
    };

    sendDiscountCardToEmail(payload);
  };

  return (
    <div className="absolute top-0 w-full h-full z-10 bg-white p-8">
      <button className="flex items-center font-medium leading-tight text-primary-blue font-effra" onClick={onClose}>
        <LeftArrowIcon className="w-8 h-4" title="Back" aria-hidden />
        <p className="m-0">Back</p>
      </button>

      <h1 className="mb-8 mt-6 text-4xl font-medium leading-tight">Prescription details</h1>
      <div className="flex">
        {!showDisclaimer ? <PriceIcon className="w-16 h-16 self-start md:h-auto md:w-auto" aria-hidden /> : null}

        <div className="flex flex-grow justify-between ml-4">
          {showDisclaimer ? (
            <PricePrescriptionDisclaimer onClose={() => setShowDisclaimer(false)} />
          ) : (
            <div className="flex-col min-w-full">
              <div className="flex justify-between">
                <div className="flex-col">
                  <h5 className="text-2xl font-light capitalize mb-0">{Name}</h5>
                  <p className="capitalize text-5xl mb-0">${Price.toFixed(2)}</p>
                </div>
                <div>
                  {/* {!!(addToFavorite && favoriteList) && (
                    <Icon showIcon src={isFavorite ? HeartIcon : EmptyHeartIcon} onClick={handleFavorite} />
                  )} */}
                </div>
              </div>
              <p className="text-xl mt-2 mb-2">Locations</p>
              <p className="mb-0 text-lg">{PharmacyName}</p>
              <div className="flex justify-between mb-4">
                <div className="flex flex-col justify-around">
                  <p className="text-lg mb-0">{Street1}</p>
                  <p className="text-lg mb-0">{`${City} ${State} ${ZipCode}`}</p>
                </div>
              </div>
              <div className="flex flex-col mb-4">
                <p className="text-base text-gray-500">Discount help line</p>
                <a
                  className="text-lg flex items-center font-medium leading-tight text-primary-blue font-effra hover:underline"
                  href="tel:(855) 569-6337"
                >
                  (855) 569-6337 <span className="ml-6 mr-4">Call</span>
                  <PhoneIcon className="w-4 h-4" title="Phone Icon" aria-hidden />
                </a>
              </div>

              <p className="text-xl mt-4 mb-0">Discount details</p>
              <p className="text-sm mt-0 mb-4 mr-2">{`Show your pharmacist at ${PharmacyName} to receive the best price on ${Name}`}</p>

              <div className="flex items-center justify-between h-12 mb-10">
                {detailsItems.map(([title, text]) => (
                  <div className="flex flex-col" key={title}>
                    <p className="text-gray-500 mb-1">{title}</p>
                    <p className="mb-1">{text}</p>
                  </div>
                ))}
                <div className="flex items-center">
                  <button className="mb-0 text-primary-blue hover:opacity-75" onClick={emailCard}>
                    Email details
                  </button>
                  <CommunicationSendIcon className="w-10 h-10" />
                </div>
              </div>
              <p className="mb-12">Please call individual locations to confirm pricing available.</p>
              <button
                className="leading-tight text-lg text-primary-blue font-effra hover:opacity-75"
                onClick={() => setShowDisclaimer(true)}
              >
                Disclaimer
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PricePrescriptionsDetails;
