import React, { createContext, useContext, useCallback } from "react";
import { toast, TypeOptions } from "react-toastify";

export enum ToastType {
  SUCCESS = "success",
  WARNING = "warning",
  INFO = "info",
  DANGER = "error"
}

export interface ShowToastProps {
  type: TypeOptions;
  message: string;
}

interface ToastProviderData {
  showToast: (props: ShowToastProps) => void;
}

const ToastContext = createContext<ToastProviderData>({
  showToast: (props: ShowToastProps) => {}
});

const ToastProvider = ({ children }) => {
  const showToast = (props: ShowToastProps) =>
    toast(props.message, {
      type: props?.type ?? toast.TYPE.DEFAULT
    });

  return <ToastContext.Provider value={{ showToast }}>{children}</ToastContext.Provider>;
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (context === undefined) {
    throw new Error("useToast can only be used inside ToastProvider");
  }
  return context;
};

export default ToastProvider;
