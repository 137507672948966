import React from "react";
import { facilityTypes } from "../../utils/facility";
import { useHistory } from "react-router-dom";

const Facilities = ({ zipCode }) => {
  const history = useHistory();
  const quickFilters = facilityTypes;

  const selectFacility = (kind: any) => {
    history.push(`/facilities?zip=${zipCode}&kind=${kind}`);
  };

  return (
    <div data-testid="facilitiesContainer">
      <div className="w-full max-w-sm" data-testid="providerFindForm">
        <p className="mb-2 text-lg font-effra">Quick Filters</p>
        <div className="flex flex-row flex-wrap">
          {quickFilters.map((facility) => {
            return (
              <button
                key={facility.value}
                data-testid={`facility-${facility.value}`}
                onClick={() => selectFacility(facility.value)}
                type="button"
                className="flex items-center justify-center px-4 py-2 m-1 font-medium border rounded-lg border-primary-blue text-primary-blue"
              >
                {facility.label}
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Facilities;
