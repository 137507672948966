import React from "react";

const FavoriteSection = ({ name, favoriteItems = [], FavoriteItemComponent, onUnfavorite }) => (
  <div className="mt-8">
    <p className="pb-4 text-xl font-medium">{name}</p>
    {favoriteItems.map((element) => (
      <FavoriteItemComponent element={element} onUnfavorite={onUnfavorite} />
    ))}
  </div>
);

export default FavoriteSection;
