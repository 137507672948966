import React from "react";
import { trackEvent } from "../api/piwik/piwikService";
import { PiwikAction, PiwikCategory, PiwikName } from "../@types/common";

import Widget from "./Widget";
import WidgetHeader from "./HeaderBar";
import Link from "./Link";
import { ReactComponent as LivongoIcon } from "../assets/icons/Livongo.svg";
import { ReactComponent as RightArrowIcon } from "../assets/icons/right-arrow.svg";

const LivongoChronicCareWidget = () => {
  const handleOnClick = () => {
    return trackEvent({
      category: PiwikCategory.BUTTONS,
      action: PiwikAction.CLICKED,
      name: PiwikName.LIVONGO_CHRONIC_CARE
    });
  };

  return (
    <Widget>
      <WidgetHeader>
        <h2 className="text-3xl font-medium sm:pl-6 font-effra">Meet Livongo</h2>
      </WidgetHeader>
      <div className="flex flex-row pb-4" data-testid="LivongoChronicCareWidgetContainer">
        <div className="flex flex-col w-10 pt-2 ml-5 sm:ml-10">
          <LivongoIcon className="self-end w-8 h-8" aria-hidden />
        </div>
        <div className="flex flex-col pr-4">
          <p className="pb-8 pl-1 sm:pl-1">
            Livongo helps over 400,000 members manage their blood sugar, blood pressure, and weight with connected
            devices, coaching, and personalized tips.
          </p>
          <Link data-testid="link-to-livongo-l2" className="flex" to="/livongo-registration" onClick={handleOnClick}>
            Check it out <RightArrowIcon className="w-4 h-4 mt-1 ml-2" title="Comment Icon" aria-hidden />
          </Link>
        </div>
      </div>
    </Widget>
  );
};

export default LivongoChronicCareWidget;
