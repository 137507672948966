import { createAsyncThunk } from "@reduxjs/toolkit";

import {
  ResetPasswordRequest,
  SendResetEmailRequest,
  ChangePasswordRequest
} from "../../containers/slices/forgotPasswordSlice";
import hyApi from "../services/hyApiService";

export const sendResetEmail = createAsyncThunk(
  "forgotPassword/sendResetEmail",
  async (data: SendResetEmailRequest, thunkAPI) => {
    const response = await hyApi.post("api/profiles/password-recovery", data);

    return response.data;
  }
);

export const resetPassword = createAsyncThunk(
  "forgotPassword/resetPassword",
  async (data: ResetPasswordRequest, thunkAPI) => {
    const { profileId, ...passwordData } = data;
    const response = await hyApi.put(`api/profiles/${profileId}/password`, passwordData);

    return response.data;
  }
);

export const changePassword = createAsyncThunk(
  "forgotPassword/changePassword",
  async ({ profileId, ...data }: ChangePasswordRequest, thunkAPI) => {
    const response = await hyApi.put(`api/profiles/update/password/${profileId}`, data);
    return response;
  }
);

export const changePasswordExternal = createAsyncThunk(
  "forgotPassword/changePasswordExternal",
  async ({ profileId, password, passwordRecoveryCode }: ChangePasswordRequest, thunkAPI) => {
    const response = await hyApi.put(`api/profiles/${profileId}/password`, {
      profileId,
      password,
      passwordRecoveryCode
    });
    return response;
  }
);

export default resetPassword;
