import moment from "moment-timezone";
import { ConsultType, Consult, ConsultStatus, CommunicationMethod } from "../@types/Consults";
import { WEBSDK_ROUTES } from "../router/routes";

export const scheduledTime = (consult: Consult): string => {
  return consult?.is_on_demand ? "ASAP" : moment(consult?.requested_date).format("hh:mm a");
};

export const isWithinTenMinutes = (consult: Consult): boolean => {
  return moment(consult?.requested_date).utc() <= moment().utc().add(10, "minutes");
};

export const isWithinTwentyFourHours = (consult: Consult): boolean => {
  return moment(consult?.requested_date).utc() <= moment().utc().add(24, "hours");
};

export const canReschedule = (consult: Consult): boolean => {
  return (
    scheduledTime(consult) !== "ASAP" &&
    consult?.consult_type !== ConsultType.Dermatology &&
    !isWithinTenMinutes(consult) &&
    isWithinTwentyFourHours(consult)
  );
};

export const canCancel = (consult: Consult): boolean => {
  return !isWithinTenMinutes(consult) && consult?.status === ConsultStatus.Locked;
};

export const canJoinWaitingRoom = (consult: Consult): boolean => {
  return (
    (isWithinTenMinutes(consult) || scheduledTime(consult) === "ASAP" || consult?.status === "Started") &&
    consult?.consult_type !== ConsultType.Dermatology &&
    consult?.communication_method === CommunicationMethod.Video
  );
};

export const getFlowUrl = (consult: Consult): string => {
  return consult.consult_type !== ConsultType.MentalHealth
    ? WEBSDK_ROUTES.RESCHEDULE_GM_VISIT
    : WEBSDK_ROUTES.RESCHEDULE_MH_VISIT;
};
