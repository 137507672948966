import { useField } from "formik";
import React, { useState } from "react";

import { FormFieldError } from "../../FormFields/FieldError";
import { InputField } from "../../FormFields/InputField";

export const FormikPasswordWithButtonInput = ({ children, showFieldError = false, isRequired, ...props }: any) => {
  const [field, meta] = useField(props);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className="flex flex-col mb-4">
      <label className="mb-2 text-sm font-bold text-dark-gray" htmlFor={props.id || props.name}>
        {props.label}
        {isRequired && <span className="px-1">*</span>}
      </label>
      <InputField field={field} meta={meta} type={showPassword ? "text" : "password"}>
        <button
          type="button"
          onClick={() => setShowPassword(!showPassword)}
          className="w-1/4 text-white rounded-r-lg bg-pale-grey-200 hover:bg-gray-700 focus:outline-none"
        >
          <span>{showPassword ? "Hide" : "Show"}</span>
        </button>
      </InputField>
      {showFieldError && <FormFieldError field={field} meta={meta} />}
    </div>
  );
};
