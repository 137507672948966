import React, { useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router";
import { debounce } from "lodash";

import PrescriptionList from "./PrescriptionList";
import PrescriptionsMap from "./PrescriptionMap";

import { useCoordinatesForZipCode, usePrescriptions } from "../../queries/usePrescriptions";
import { extractSearchParams } from "../../utils/url";
import { Spinner } from "../../components/Spinner";
import PrescriptionsFilter from "./PrescriptionsFilter";

interface searchParams {
  zip?: string;
  term?: string;
}

const PrescriptionsSearch = ({ setDrugDetails }) => {
  const { search: urlSearch } = useLocation();
  const searchParams: searchParams = extractSearchParams(urlSearch);
  const defaultFilterValues = useMemo(
    () => ({ ...searchParams, form: "", dosage: "", quantity: "", isShowBrandDrugs: false, enabled: true }),
    [searchParams]
  );
  const [filters, setFilters] = useState(defaultFilterValues);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [activeItemId, setActiveItemId] = useState<string | null>(null);
  const [mapCenter, setMapCenter] = useState<[number, number]>([0, 0]);

  const { data: cords, isLoading: cordsLoading } = useCoordinatesForZipCode(filters);
  const { data: prices, isLoading, isRefetching, error } = usePrescriptions({
    ...cords,
    ...{ ...filters, term: (filters.term + "").replaceAll(" ", "-") },
    manufacturer: filters.isShowBrandDrugs ? "brand" : "generic"
  });

  const handleMarkerClick = debounce(({ PharmacyId, Latitude, Longitude }) => {
    setSelectedItemId(PharmacyId === selectedItemId ? "" : PharmacyId);
    setMapCenter([Latitude, Longitude]);
  }, 250);

  const setDefaultForms = (drugDetails) => {
    if (!drugDetails?.Forms) return;

    const defaultForm = drugDetails.Forms.find((f) => f.IsDefaultForm);
    const defaultStrength = defaultForm?.Strengths.find((s) => s.IsDefault);
    const defaultPackage = defaultStrength?.PackageDescriptions?.[0].Quantities.find((p) => p.IsDefaultQuantity);

    setFilters((filters) => ({
      ...filters,
      form: defaultForm?.Form,
      dosage: defaultStrength?.Strength,
      quantity: defaultPackage?.Quantity
    }));
  };

  const shouldShowSpinner = isLoading || !prices?.drugPrices?.length || isRefetching;

  useEffect(() => {
    setDefaultForms(prices?.drugDetails);
  }, [prices?.drugDetails]);

  const clearFilters = () => {
    setFilters(defaultFilterValues);
  };

  if (cordsLoading) return <Spinner />;
  return (
    <>
      <h1 className="mt-4 mb-6 ml-0 text-4xl font-medium leading-tight">Price prescriptions</h1>
      <PrescriptionsFilter
        filters={filters}
        onFiltersChange={(data) => setFilters(data)}
        drugDetails={prices?.drugDetails || []}
        clearFilters={clearFilters}
        defaultValues={defaultFilterValues}
        errors={error}
      />

      {shouldShowSpinner && !error && filters.enabled ? (
        <Spinner fullScreen={false} />
      ) : (
        <>
          {error || !filters.enabled ? (
            <p className="text-base leading-tight text-dark-gray mb-6">
              Type in the name of the drug you are trying to price compare. We will show you where you can find your
              prescription at the lowest retail cost. The prices do not reflect your current insurance rates.
            </p>
          ) : (
            <div className="flex flex-col-reverse h-full overflow-hidden relative md:flex-row gap-4 ">
              <PrescriptionList
                drugPrices={prices?.drugPrices}
                activeItem={activeItemId}
                selectedItem={selectedItemId}
                onItemMouseEnter={handleMarkerClick}
                onItemMouseLeave={() => setActiveItemId("")}
                onItemClick={(drugPrice) => setDrugDetails({ ...drugPrice, drugName: prices?.drugDetails?.Name })}
                isBrand={filters.isShowBrandDrugs}
              />
              <PrescriptionsMap
                drugPrices={prices?.drugPrices}
                mapCenter={mapCenter}
                activeItem={activeItemId}
                selectedItem={selectedItemId}
                onItemMouseEnter={({ PharmacyId }) => setActiveItemId(PharmacyId)}
                onItemMouseLeave={() => setActiveItemId("")}
                onItemClick={handleMarkerClick}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default PrescriptionsSearch;
