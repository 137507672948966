import React from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "../containers/slices/authSlice";
import { Redirect } from "react-router-dom";
import { Feature } from "../utils/featureHelper";

interface HasFeatureWrapperProps {
  feature: Feature;
  element: React.ReactElement;
}

function HasFeatureWrapper({ element, feature }: HasFeatureWrapperProps) {
  const {
    entities: {
      group: { features }
    }
  } = useSelector(selectAuth);

  if (features.includes(feature)) return element;

  return <Redirect to="/dashboard" />;
}

export default HasFeatureWrapper;
