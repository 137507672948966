import clsx from "clsx";
import React, { Component } from "react";
import FormCard from "../../components/FormCard";
import { Tabs, useTabState, usePanelState } from "@bumaga/tabs";
import AccountSettings from "./AccountSettings";
import ChangePassword from "./ChangePassword";
import PageTitleComponent from "../../components/PageTitleComponent";

const Tab = ({ children, className, ariaControls }) => {
  const { onClick, isActive } = useTabState();
  return (
    <button
      type="button"
      role="tab"
      aria-controls={ariaControls}
      aria-selected={isActive}
      className={clsx(
        isActive &&
          "bg-primary-20 text-bokara-grey w-auto py-2 px-4 block hover:text-bokara-grey focus:outline-none border-1 border-primary-blue",
        !isActive && "text-bokara-grey w-auto py-2 px-4 block border-1 border-pale-grey-200 hover:text-bokara-grey",
        className
      )}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

const Panel = ({ children, labelledById }) => {
  const isActive = usePanelState();
  return isActive ? (
    <div role="tabpanel" aria-labelledby={labelledById}>
      {children}
    </div>
  ) : null;
};

class AccountInfo extends Component {
  render() {
    return (
      <FormCard title="Account Settings">
        <PageTitleComponent title="Account Settings" />
        <div className="px-10 pt-2">
          <Tabs>
            <div className="flex" role="tablist" aria-label="Account Settings">
              <Tab className="mb-2 rounded-l-lg" ariaControls="accountSettingsPanel">
                <p key="accountInformation" id="accountInformationTitle">
                  Account Settings
                </p>
              </Tab>
              <Tab className="mb-2 rounded-r-lg" ariaControls="changePasswordPanel">
                <p key="changePassword" id="changePasswordTitle">
                  Change Password
                </p>
              </Tab>
            </div>
            <Panel labelledById="accountInformationTitle">
              <AccountSettings />
            </Panel>
            <Panel labelledById="changePasswordTitle">
              <ChangePassword />
            </Panel>
          </Tabs>
        </div>
      </FormCard>
    );
  }
}

export default AccountInfo;
