import { createMobileApiRequest } from "@td/websdk";
import { webSDKConfig } from "../../constants/websdk.constant";
import { get, SDK_TOKEN_COOKIE } from "../../utils/cookies";
import { isTimeMoreThan } from "../../utils/swordMigrationsUtils";
import { createAsyncThunk } from "@reduxjs/toolkit";
import mobileApi from "../services/mobileApiService";

const requestUrl = (swordMigrationDate) =>
  `${process.env.REACT_APP_WEBSDK_URL}${isTimeMoreThan(swordMigrationDate)
    ? `actions/physical_therapy_webview?partner_nm=sword` // you can use here 'telespine' 'hinge' 'sword' for different integrators without any code changes :)
    : "actions/physical_therapy_webview"
  }`;


export const redirectToTelespine = async (swordMigrationDate: string) => {
  const config = {
    url: requestUrl(swordMigrationDate),
    configuration: {
      ...webSDKConfig,
      authorization: { ssoToken: get(SDK_TOKEN_COOKIE) }
    }
  };

  const { actions } = await createMobileApiRequest(config);
  const refferalLink = actions[0].value;

  window.open(refferalLink, "_blank");
};

export const mapiLogin = async (teladocSdkToken: string) => {
  try {
    const data = await mobileApi.post('authentication/token_login', { sso_token: teladocSdkToken }) as any;

    if (
      data?.sdk_error_message === 'Session Invalid' ||
      data?.valid === false ||
      data?.registered === "false"
    ) {
      console.log('Mapi auth session invalid', data);
    }
    return data;
  } catch (error) {
  }
}

export const getConsultationList = createAsyncThunk("mapi/getConsultationList", async (memberId: number) => {

  const response = await mobileApi.get(`members_consultations?member_id=${memberId}`);
  return response.data;
});

export const cancelVisitDirect = createAsyncThunk("mapi/cancelVisit", async (payload: { consultId: number, memberId: number }) => {

  const response = await mobileApi.get(`cancel_consult?consult_id=${payload.consultId}&logged_in_member_id=${payload.memberId}`);
  return response.data;
});