import clsx from "clsx";
import React, { useEffect, useState } from "react";
import { errors as errorsObject } from "../utils/errors";
import { isValidZip } from "../utils/findPage";

interface ZipCodeInputProps {
  header: string;
  value: string;
  onChange: any;
  placeholder: string;
  id: string;
  className: string;
  maxLength?: number;
  errors: string | undefined;
  errorOnEmpty: string;
  errorIdentifier?: string;
}

function ZipCodeInput({
  header,
  value,
  onChange,
  placeholder = "Enter a value",
  id,
  className,
  maxLength = 5,
  errorOnEmpty,
  errors = undefined,
  errorIdentifier = "InvalidZipCode"
}: ZipCodeInputProps) {
  const [errorKey, setErrorKey] = useState<string>("");

  useEffect(() => {
    if (errors && errors.includes(errorIdentifier)) {
      setErrorKey(errorIdentifier);
    }
  }, [errors, errorIdentifier]);

  const internalOnChange = (e) => {
    const temp = e.target.value.toString().replace(/[^0-9]/g, "");

    if (errorOnEmpty && temp.length === 0) setErrorKey(errorOnEmpty);

    if (temp.length !== 0) {
      setErrorKey("");
    }
    if (temp.length === 5 && !isValidZip(temp)) setErrorKey(errorIdentifier);

    onChange({ zip: temp, enabled: isValidZip(temp) });
  };

  const validateIncorrectLength = (val) => {
    if (val.length > 0 && val.length < 5) return setErrorKey(errorIdentifier);
  };

  return (
    <div className={clsx(!errorKey && "mb-2", "xs:w-full sm:mb-1", className)}>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          validateIncorrectLength(value);
        }}
      >
        <label htmlFor={id} className="leading-none text-dark-gray text-base block w-full">
          <span>{header}</span>
        </label>
        <input
          id={id}
          value={value}
          onChange={internalOnChange}
          maxLength={maxLength}
          pattern="[0-9]+"
          onBlur={(e) => validateIncorrectLength(e.target.value)}
          className={clsx(
            "block rounded-lg p-4 text-dark-gray text-base leading-none pr-2 w-full",
            errorKey ? "border-red-500" : "border-pale-grey-50"
          )}
          style={{ borderWidth: 1 }}
          placeholder={placeholder}
        />
        {errorKey && (
          <small className={clsx("flex relative sm:-mb-5 md:-mb-3 text-red-500 h-2")}>{errorsObject[errorKey]}</small>
        )}
      </form>
    </div>
  );
}

export default ZipCodeInput;
