import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { PiwikAction, PiwikCategory, PiwikName } from "../@types/common";
import { changePasswordExternal } from "../api/forgotPassword";
import { trackEvent } from "../api/piwik/piwikService";
import Alert from "../components/ApiErrorAlert";
import Button from "../components/Button";
import { ButtonGroup } from "../components/ButtonGroup";
import { FormikPasswordWithButtonInput } from "../components/Formik/Password/FormikPasswordWithButtonInput";
import { FormikValidatePasswordInput } from "../components/Formik/Password/FormikValidatePasswordInput";
import PageTitleComponent from "../components/PageTitleComponent";
import { useSpinner } from "../components/SpinnerProvider";
import { useAppDispatch } from "../store/store";
import { passwordValidation } from "../utils/validationHelper";
import { clearErrors, selectForgotPassword } from "./slices/forgotPasswordSlice";
import { ReactComponent as HYLogoHoriz } from "../assets/HYLogoHoriz.svg";

const SetPassword = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { showSpinner } = useSpinner();
  const { error } = useSelector(selectForgotPassword);
  const { profileId, passwordRecoveryCode: token } = useParams<{ passwordRecoveryCode: string; profileId: string }>();

  const handleCancel = () => {
    trackEvent({
      category: PiwikCategory.FORM,
      action: PiwikAction.CANCEL,
      name: PiwikName.CHANGE_PASSWORD_EXTERNAL
    });
    history.push("/login");
  };

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  const handleSubmit = async ({ newPassword }) => {
    showSpinner({ show: true });

    trackEvent({
      category: PiwikCategory.FORM,
      action: PiwikAction.SUBMIT,
      name: PiwikName.CHANGE_PASSWORD_EXTERNAL
    });
    try {
      await dispatch(
        changePasswordExternal({
          profileId,
          password: newPassword,
          passwordRecoveryCode: token
        })
      );
      showSpinner({ show: false });
      toast.success("Your password has been changed");
      history.push("/dashboard");
    } catch (e) {
      if (e instanceof Error) {
        showSpinner({ show: false });
        toast.error(e?.message);
      }
      return e;
    }
  };

  return (
    <div
      className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
      data-testid="changePasswordContainer"
    >
      <div className="max-w-md w-full space-y-8">
        <div className="m-6">
          <PageTitleComponent title="Set Password" />
          <HYLogoHoriz className="mx-auto w-auto h-16" />
          <h2 className="mt-8 text-3xl font-medium text-gray-900">Reset Password</h2>
          <Formik
            initialValues={{
              newPassword: "",
              verifyNewPassword: ""
            }}
            enableReinitialize={true}
            validationSchema={Yup.object({
              newPassword: passwordValidation,
              verifyNewPassword: passwordValidation.oneOf([Yup.ref("newPassword"), ""], "Passwords must match")
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              handleSubmit(values).finally(() => setSubmitting(false));
            }}
          >
            {({ isSubmitting, isValid }) => (
              <Form className="bg-white shadow-md rounded mt-6 px-8 pt-6 pb-8 mb-4">
                {error && <Alert errors={error} />}
                <div className="max-w-sm">
                  <FormikValidatePasswordInput
                    label="New Password"
                    isRequired={true}
                    placeholder="Please enter password"
                    data-testid="new-password"
                    name="newPassword"
                  />
                  <FormikPasswordWithButtonInput
                    label="Verify New Password"
                    isRequired={true}
                    placeholder="Please enter password again"
                    data-testid="verify-new-password"
                    name="verifyNewPassword"
                    showFieldError={true}
                  />
                </div>
                <div className="w-full max-w-sm">
                  <ButtonGroup>
                    <Button disabled={isSubmitting} buttonType="secondary" onClick={handleCancel}>
                      Cancel
                    </Button>
                    <Button disabled={isSubmitting || !isValid} buttonType="primary" type="submit">
                      Confirm
                    </Button>
                  </ButtonGroup>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
