import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import moment from "moment";
import React, { useEffect } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import postLogin from "../../api/auth";
import { registeIneligibleMember } from "../../api/registration";
import { ReactComponent as HYlogo } from "../../assets/HYLogoHoriz.svg";
import Alert from "../../components/ApiErrorAlert";
import Button from "../../components/Button";
import { ButtonGroup } from "../../components/ButtonGroup";
import { FormikDatePicker } from "../../components/DatePicker/FormikDatePicker";
import { FormFieldError } from "../../components/FormFields/FieldError";
import { FormikCheckbox } from "../../components/Formik/FormikCheckbox";
import { FormikMaskedInput } from "../../components/Formik/FormikMaskedInput";
import { FormikSelect } from "../../components/Formik/FormikSelect";
import { FormikTextInput } from "../../components/Formik/FormikTextInput";
import { FormikPasswordWithButtonInput } from "../../components/Formik/Password/FormikPasswordWithButtonInput";
import { FormikValidatePasswordInput } from "../../components/Formik/Password/FormikValidatePasswordInput";
import { useSpinner } from "../../components/SpinnerProvider";
import { useAppDispatch } from "../../store/store";
import { genderOptions } from "../../utils/constants";
import { stateList } from "../../utils/stateList";
import {
  onlyAlphaCharacters,
  passwordValidation,
  phoneNumberMask,
  phoneValidation,
  zipcodeMask,
  zipValidation
} from "../../utils/validationHelper";
import { selectAuth, setProfileUpdated } from "../slices/authSlice";
import {
  cancelLookup,
  clearAccountInfo,
  clearErrors,
  selectRegistration,
  setPayPerVisitData,
  setNewProfileCreated
} from "../slices/registrationSlice";
import PageTitleComponent from "../../components/PageTitleComponent";

const RegisterPayPerVisit = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = React.useState(false);
  const [captcha, setCaptcha] = React.useState(false);
  const history = useHistory();
  const { hasAuth } = useSelector(selectAuth);
  const dispatch = useAppDispatch();
  const { showSpinner } = useSpinner();
  const { password, email, loading, profileCreated, payPerVisitData, apiError, phone } = useSelector(
    selectRegistration
  );

  useEffect(() => {
    showSpinner({
      show: loading
    });
  }, [loading]);

  useEffect(() => {
    const canRegister = !loading && !apiError && email && payPerVisitData && !profileCreated;

    if (canRegister) {
      dispatch(registeIneligibleMember());
    }
  }, [payPerVisitData]);

  useEffect(() => {
    if (!loading && email && password && profileCreated) {
      dispatch(postLogin({ email, password }));
      dispatch(cancelLookup());
      dispatch(clearErrors());
    }
  }, [profileCreated]);

  useEffect(() => {
    if (hasAuth && !apiError) {
      dispatch(setNewProfileCreated(true));
      history.push("/dashboard");

      toast.success("Account successfully created");
      dispatch(clearErrors());
      dispatch(setProfileUpdated(false));
    }
  }, [hasAuth, apiError]);

  const handleCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    history.push("/register");
  };

  const onChange = (e) => {
    setCaptcha(!!e);
  };

  useEffect(() => {
    dispatch(clearErrors());
    dispatch(clearAccountInfo());
  }, []);

  return (
    <>
      <div
        className="flex justify-center w-full max-w-md min-h-screen mx-auto font-effra"
        data-testid="registerContainer"
      >
        <div className={"flex-col mx-auto w-full"}>
          <PageTitleComponent title="Register Pay Per Visit" />
          <HYlogo className="inline-block h-16 mx-4 mt-10 fill-current" title="HY Logo" />
          <h1
            className="flex items-center justify-between mx-4 my-5 text-2xl font-light text-grey-darkest"
            data-testid="titleText"
          >
            Register pay per visit
          </h1>
          <div className="flex-1">
            <Formik
              initialValues={{
                first: "",
                last: "",
                email: "",
                dob: "",
                address1: "",
                address2: "",
                city: "",
                state: "",
                zip: "",
                phone: "",
                gender: "",
                newPassword: "",
                verifyNewPassword: "",
                acceptTerms: false,
                acceptNotice: false
              }}
              validationSchema={Yup.object({
                first: onlyAlphaCharacters.required("Required"),
                last: onlyAlphaCharacters.required("Required"),
                email: Yup.string().email("Invalid email").required("Required"),
                dob: Yup.date().max(new Date(), "Date cannot be in the future").required("Required"),
                address1: Yup.string().required("Required"),
                address2: Yup.string(),
                city: Yup.string().required("Required"),
                state: Yup.string().required("Required"),
                zip: zipValidation,
                phone: phoneValidation,
                gender: Yup.string().required("Required"),
                newPassword: passwordValidation,
                verifyNewPassword: passwordValidation.oneOf([Yup.ref("newPassword"), ""], "Passwords must match"),
                acceptTerms: Yup.boolean().oneOf([true], 'Must accept "Terms of Service"'),
                acceptNotice: Yup.boolean().oneOf([true], 'Must accept "Notice of Privacy Practices"')
              })}
              onSubmit={(values) => {
                values.dob = moment(values.dob).format("MM/DD/YYYY");
                dispatch(setPayPerVisitData({ ...values }));
              }}
            >
              {({ touched, errors, values, isSubmitting }) => (
                <Form className="max-w-sm mx-4">
                  {apiError && <Alert errors={apiError} />}

                  <div className="mb-4" data-testid="first-container">
                    <p
                      aria-hidden
                      className="mr-4 -mb-5 text-sm text-right font-effra text-gray-default sm:mr-4 md:mr-4 lg:mr-0"
                    >
                      * Required
                    </p>
                    <FormikTextInput
                      label="First"
                      isRequired={true}
                      type="text"
                      placeholder="Please enter first name"
                      data-testid="first"
                      name="first"
                    />
                  </div>
                  <div className="mb-4">
                    <FormikTextInput
                      label="Last"
                      isRequired={true}
                      type="text"
                      placeholder="Please enter last name"
                      data-testid="last"
                      name="last"
                    />
                  </div>
                  <div className="mb-4">
                    <FormikTextInput
                      label="Email address"
                      isRequired={true}
                      type="email"
                      placeholder="Please enter email"
                      data-testid="email"
                      name="email"
                    />
                  </div>
                  <div className="mb-4">
                    <FormikDatePicker name="dob" data-testid="dob" isRequired={true} label="Date of birth" />
                  </div>
                  <div className="mb-4">
                    <FormikTextInput
                      label="Address"
                      isRequired={true}
                      type="text"
                      placeholder="Please enter address"
                      data-testid="address1"
                      name="address1"
                    />
                  </div>
                  <div className="mb-4">
                    <FormikTextInput
                      label="Address line 2"
                      type="text"
                      placeholder="Please enter address line 2"
                      data-testid="address2"
                      name="address2"
                    />
                  </div>
                  <div className="mb-4">
                    <FormikTextInput
                      isRequired={true}
                      label="City"
                      type="text"
                      placeholder="Please enter city"
                      data-testid="city"
                      name="city"
                    />
                  </div>
                  <div className="flex flex-row">
                    <div className="mb-4 mr-5">
                      <FormikSelect
                        data-testid="state-select"
                        type="select"
                        isRequired={true}
                        placeholder="Select one"
                        label="State"
                        name="state"
                        options={
                          stateList &&
                          stateList.map((state, index) => ({
                            label: state.name,
                            value: state.abbreviation
                          }))
                        }
                      />
                    </div>
                    <div className="mb-4">
                      <Field name="zip" type="text">
                        {({ field, form, meta }) => (
                          <>
                            <FormikMaskedInput
                              meta={meta}
                              isRequired={true}
                              label="Zip Code"
                              mask={zipcodeMask}
                              field={field}
                              {...form}
                            />
                            <FormFieldError field={field} meta={meta} />
                          </>
                        )}
                      </Field>
                    </div>
                  </div>

                  <div className="mb-4">
                    <Field name="phone" type="text">
                      {({ field, form, meta }) => (
                        <>
                          <FormikMaskedInput
                            meta={meta}
                            isRequired={true}
                            label="Phone Number"
                            mask={phoneNumberMask}
                            field={field}
                            {...form}
                          />
                          <FormFieldError field={field} meta={meta} />
                        </>
                      )}
                    </Field>
                  </div>

                  <div className="mb-4">
                    <FormikSelect
                      type="select"
                      isRequired={true}
                      placeholder="Select one"
                      data-testid="gender"
                      label="Gender"
                      name="gender"
                      options={genderOptions}
                    />
                  </div>
                  <FormikValidatePasswordInput
                    label="New Password"
                    isRequired={true}
                    type={showPassword ? "text" : "password"}
                    placeholder="Please enter password"
                    data-testid="new-password"
                    name="newPassword"
                  />

                  <FormikPasswordWithButtonInput
                    label="Verify New Password"
                    isRequired={true}
                    type={showVerifyPassword ? "text" : "password"}
                    placeholder="Please enter password again"
                    data-testid="verify-new-password"
                    name="verifyNewPassword"
                    showFieldError={true}
                  />

                  <div className="flex flex-col mb-4">
                    <div className="flex">
                      <FormikCheckbox name="acceptTerms">
                        Accept
                      </FormikCheckbox>
                      <p
                        className={clsx(
                          touched.acceptTerms && errors.acceptTerms && "italic text-red-500",
                          "text-primary-blue mx-1"
                        )}
                      >
                        <a href="https://teladochealth.com/terms-of-service/" target="_blank" rel="noopener noreferrer">
                          "Terms of Service"
                        </a>
                      </p>
                    </div>
                    {touched.acceptTerms && errors.acceptTerms ? (
                      <p className="text-xs italic text-red-500">{errors.acceptTerms}</p>
                    ) : null}
                  </div>
                  <div className="flex flex-col mb-4">
                    <div className="flex">
                      <FormikCheckbox name="acceptNotice">
                        Accept
                      </FormikCheckbox>
                      <p
                        className={clsx(
                          touched.acceptNotice && errors.acceptNotice && "italic text-red-500",
                          "text-primary-blue mx-1"
                        )}
                      >
                        <a
                          href="https://www.teladoc.com/notice-of-privacy-practices/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          "Notice of Privacy Practices"
                        </a>
                      </p>
                    </div>
                    {touched.acceptNotice && errors.acceptNotice ? (
                      <p className="text-xs italic text-red-500">{errors.acceptNotice}</p>
                    ) : null}
                  </div>

                  <p>
                    If the phone number is a mobile number, we will send you alerts and updates by text message. These
                    can be turned off in your account settings. Standard text messaging data and data rates from your
                    carrier may apply.
                  </p>
                  <div className="flex justify-center my-4">
                    <ReCAPTCHA sitekey="6Ld2fEAUAAAAAM9rWhHrJUM-xIETLGgIvz_itlRN" onChange={onChange} />
                  </div>
                  <ButtonGroup>
                    <Button buttonType="secondary" onClick={handleCancel} name="cancel">
                      Cancel
                    </Button>
                    <Button
                      data-testid="next-button"
                      disabled={!captcha}
                      buttonType="primary"
                      type="submit"
                      name="next"
                    >
                      Next
                    </Button>
                  </ButtonGroup>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegisterPayPerVisit;
