export enum Feature {
  telehealth = "Telehealth",
  widgetManagement = "WidgetManagement",
  wellness = "Wellness",
  rx = "RX",
  providers = "Providers",
  facilities = "Facilities",
  pricing = "Pricing",
  plans = "Plans",
  beatTheDeductible = "BeatTheDeductible",
  bestDoctors = "BestDoctors",
  backCare = "Backcare",
  behavioralHealth = "BehavioralHealth",
  betterHelp = "BetterHelp",
  consultsForMissing = "ConsultsForMissing",
  openDependents = "OpenDependents",
  covid19Assessment = "Covid19Assessment"
}

export enum LivongoChronicCareProducts {
  diabetes = "Diabetes",
  diabetesWp = "diabetesWp",
  prediabetes = "Prediabetes",
  preDiabetesWp = "preDiabetesWp",
  cardiovascularWp = "cardiovascularWp",
  chronicKidneyDisease = "ChronicKidneyDisease",
  hypertension = "Hypertension",
  weightManagement = "WeightManagement",
  myStrength = "MyStrength",
  myStrengthComplete = "MyStrengthComplete"
}

export enum LivongoChronicCareBenefits {
  manageBloodSugar = "manageBloodSugar",
  manageBloodPressure = "manageBloodPressure",
  manageWeight = "manageWeight",
  chronicKidneyDisease = "ChronicKidneyDisease",
  diabetes = "Diabetes"
}

export enum LivongoMyStrengthProducts {
  myStrength = "MyStrength",
  myStrengthComplete = "MyStrengthComplete"
}

export enum TeladocFeature {
  generalMedical = "GeneralMedical",
  behavioralHealth = "BehavioralHealth",
  dermatology = "Dermatology",
  nutrition = "Nutrition"
}

export const hasFeature = (group, feature: Feature) => {
  return group?.features?.indexOf(feature) > -1;
};

export const hasTeladocFeature = (group, feature: TeladocFeature) => {
  return getGroupTeladocFeatures(group)?.indexOf(feature) > -1;
};
export const getGroupTeladocFeatures = (group): TeladocFeature[] => {
  return group?.prices?.map((price: any) => price.type);
};

export const hasLivongoChronicCareProducts = (group: { prices: any[] }): boolean => {
  const livongoChronicCareProducts: LivongoChronicCareProducts[] = [];
  for (let key in LivongoChronicCareProducts) {
    livongoChronicCareProducts.push(LivongoChronicCareProducts[key]);
  }

  return !!group?.prices?.some((price: any) => livongoChronicCareProducts.includes(price.type));
};
export const hasLivongoChronicCareProduct = (group, product: LivongoChronicCareProducts): boolean => {
  return !!group?.prices?.find((price: any) => price.type === product);
};

export const hasLivongoMyStrengthProducts = (group: { prices: any[] }): boolean => {
  const livongoMyStrengthProducts: LivongoMyStrengthProducts[] = [];
  for (let key in LivongoMyStrengthProducts) {
    livongoMyStrengthProducts.push(LivongoMyStrengthProducts[key]);
  }
  return !!group?.prices?.some((price: any) => livongoMyStrengthProducts.includes(price.type));
};

export const hasLivongoMyStrengthProduct = (group: { prices: any[] }, product: LivongoMyStrengthProducts): boolean => {
  return !!group?.prices?.find((price: any) => price.type === product);
};
