import { Gender } from "../@types/common";

export const genderOptions: { value: Gender; label: string }[] = [
  {
    value: Gender.MALE,
    label: "Male"
  },
  {
    value: Gender.FEMALE,
    label: "Female"
  },
  {
    value: Gender.OTHER,
    label: "Other"
  }
];
