import axios from "axios";

import history from "../../utils/history";
import { get, JWT_TOKEN_COOKIE, SSO_TOKEN_COOKIE } from "../../utils/cookies";
import { ErrorMessages } from "../../@types/common";

const BASE_URL = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Accept": "application/json",
    "Content-Type": "application/json"
  },
  timeout: 30000
});

export const setAxiosInterceptors = (store) => {
  axiosInstance.interceptors.request.use((config) => {
    const authCookie = get(JWT_TOKEN_COOKIE);
    const ssoTokenCookie = get(SSO_TOKEN_COOKIE);

    if (ssoTokenCookie) {
      config.headers["Application-Authorization"] = ssoTokenCookie;
    }

    if (authCookie) {
      config.headers.Authorization = authCookie;
    }

    return config;
  });

  axiosInstance.interceptors.response.use(
    (res) => res,
    (error) => {
      if (!error || !error.response) {
        return Promise.reject({ message: ErrorMessages.NETWORK_REQUEST_FAILED });
      }

      const state = store.getState();
      const { status } = error.response;
      if ((status === 401 || status === 403) && !state.auth.hasSso) {
        return Promise.reject({ message: ErrorMessages.UNAUTHORIZED });
      } else {
        const meta = error?.response?.data?.meta;
        let message = ErrorMessages.UNKNOWN;

        // deal with 200 invalid session error

        if (meta && meta.errors && Array.isArray(meta.errors) && meta.errors.length > 0) {
          message = meta.errors[0];
        } else if (meta && meta.errorMessages && Array.isArray(meta.errorMessages) && meta.errorMessages.length > 0) {
          message = meta.errorMessages[0].reason;
        } else if (Array.isArray(error?.response?.data?.error_messages) && error?.response?.data?.error_messages.length > 0) {
          return error?.response
        }

        return Promise.reject({ message: message });
      }
    }
  );
};

export default axiosInstance;
