import React, { RefObject, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

import { ReactComponent as RAVIcon } from "../assets/icons/briefcase.svg";
import { ReactComponent as FindIcon } from "../assets/icons/FindWidget.svg";
import { ReactComponent as PriceIcon } from "../assets/icons/PriceWidget.svg";
import { ReactComponent as MessageCenterIcon } from "../assets/icons/envelope.svg";
import WidgetHeader from "../components/HeaderBar";
import ProductBanner from "../components/ProductBanner";
import RAV from "../components/RAV/RAV";
import Widget from "../components/Widget";
import { useAppDispatch } from "../store/store";
import { selectAuth } from "./slices/authSlice";
import { RouteFlowMapping, SdkPaths, selectWebSdk, showSdkFlow } from "./slices/websdkSlice";
import { trackEvent } from "../api/piwik/piwikService";
import { PiwikAction, PiwikCategory, PiwikName } from "../@types/common";
import { ReactComponent as EMSIcon } from "../assets/icons/EMS.svg";
import NeckAndBackcareWidget from "../components/NeckAndBackcareWidget";
import SwordWidget from "../components/SwordWidget";
import {
  Feature,
  hasFeature,
  hasLivongoChronicCareProducts,
  hasLivongoMyStrengthProducts
} from "../utils/featureHelper";
import Visits from "../components/UpcomingVisits/Visits";
import EMSWidget from "../components/EMSWidget";
import PriceWidget from "../components/PriceWidget";
import Find from "../components/Find/Find";
import BeatTheDeductibleWidget from "../components/BeatTheDeductibleWidget";
import LivongoChronicCareWidget from "../components/LivongoChronicCareWidget";
import { ReactComponent as RightArrowIcon } from "../assets/icons/right-arrow.svg";
import LivongoMyStrengthWidget from "../components/LivongoMyStrengthWidget";
import PageTitleComponent from "../components/PageTitleComponent";
import Covid19AssessmentWidget from "../components/Covid19AssessmentWidget";
import HealthcareBenefitBanner from "../components/HealthCareBenefitBanner";
import { isTimeMoreThan } from "../utils/swordMigrationsUtils";
import { selectConfigs } from "./slices/configsSlice";

const Dashboard = () => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const ref: RefObject<HTMLDivElement> = useRef(null);
  const history = useHistory();
  const websdk = useSelector(selectWebSdk);
  const { swordMigrationDate } = useSelector(selectConfigs);
  const {
    entities: { group, profile }
  } = useSelector(selectAuth);

  const hasProviders = hasFeature(group, Feature.providers);
  const hasFacilities = hasFeature(group, Feature.facilities);
  const showFindFeature = hasProviders || hasFacilities;

  const handleOnClickMessages = async () => {
    trackEvent({
      category: PiwikCategory.BUTTONS,
      action: PiwikAction.CLICKED,
      name: PiwikName.MESSAGES
    });
  };

  useEffect(() => {
    const flowToOpen = (params as any)?.sdkFlow ? RouteFlowMapping[(params as any)?.sdkFlow] : undefined;
    if (flowToOpen && SdkPaths[flowToOpen]) {
      dispatch(
        showSdkFlow({ path: SdkPaths[flowToOpen], extraActions: { onClose: () => history.push("/dashboard") } })
      );
    }
  }, [dispatch, history, (params as any)?.sdkFlow]);

  return (
    <div className="w-full max-w-screen-xl mx-auto bg-off-white" ref={ref}>
      <PageTitleComponent title={(params as any)?.sdkFlow || "Dashboard"} />
      {group?.code?.indexOf("HYUHCSR") === -1 && (
        <div className="px-4 my-5 sm:px-6 md:my-0 md:px-8 md:pt-6 sm:mt-6 md:mt-0 max-h-1/4">
          <ProductBanner />
        </div>
      )}
      {group?.code?.includes("HYUHG") && (
        <div className="px-4 my-5 sm:px-6 md:my-0 md:px-8 md:pt-6 sm:mt-6 md:mt-0 max-h-1/4">
          <HealthcareBenefitBanner
            className="italic rounded-md shadow-md h-auto p-1 px-4 align-middle bg-white"
            isBigBanner
          />
        </div>
      )}
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 768: 2 }}>
        <Masonry columnsCount={2} gutter={"0.7rem"} className="gap-10 p-4 sm:p-6 md:gap-6 md:p-8">
          {hasFeature(group, Feature.telehealth) && (
            <Widget>
              <WidgetHeader>
                <RAVIcon className="w-10 h-10 md:h-auto md:w-auto" aria-hidden/>
                <h2 className="text-3xl font-medium font-effra">Request a visit</h2>
              </WidgetHeader>
              <div className="p-4">
                <RAV />
              </div>
            </Widget>
          )}
          {hasFeature(group, Feature.telehealth) && (
            <Widget>
              <WidgetHeader>
                <h2 className="px-4 text-2xl font-medium font-effra">Visits</h2>
              </WidgetHeader>
              <div className="px-4 pb-4">
                <Visits />
              </div>
            </Widget>
          )}
          {hasLivongoMyStrengthProducts(group) && (
            <Widget>
              <WidgetHeader>
                <h2 className="text-3xl font-medium sm:h2l-6 font-effra">Mental Health</h2>
              </WidgetHeader>
              <LivongoMyStrengthWidget />
            </Widget>
          )}
          {hasFeature(group, Feature.pricing) && hasFeature(group, Feature.rx) && (
            <Widget>
              <WidgetHeader>
                <PriceIcon className="w-10 h-10 md:h-auto md:w-auto" aria-hidden />
                <h2 className="text-3xl font-medium">Price</h2>
              </WidgetHeader>
              <div className="p-4">
                <PriceWidget />
              </div>
            </Widget>
          )}

          {hasLivongoChronicCareProducts(group) && <LivongoChronicCareWidget />}
     

          <Widget>
            <WidgetHeader>
              <MessageCenterIcon className="w-10 h-10 md:h-auto md:w-auto" aria-hidden/>
              <h2 className="text-3xl font-medium font-effra">Inbox</h2>
            </WidgetHeader>
            <div className="p-4">
              <p className="pb-8 pl-1 ml-12 sm:ml-12 sm:pl-1 md:ml-20 md:pl-1">
                {websdk.unviewedMessages ? `You have ${websdk.unviewedMessages} unread messages` : "View your messages"}
              </p>
              <div className="w-1/2 pl-1 ml-12 sm:ml-12 sm:pl-1 md:ml-20 md:pl-1">
                <Link to="/inbox" className="flex" onClick={handleOnClickMessages}>
                  View Messages <RightArrowIcon className="w-4 h-4 mt-1 ml-2" title="Right Arrow Icon" aria-hidden />
                </Link>
              </div>
            </div>
          </Widget>
          {hasFeature(group, Feature.backCare) &&
            (isTimeMoreThan(swordMigrationDate) ? <SwordWidget /> : <NeckAndBackcareWidget />)}
          {hasFeature(group, Feature.bestDoctors) && (
            <Widget>
              <WidgetHeader>
                <EMSIcon className="w-10 h-10 md:h-auto md:w-auto" aria-hidden />
                <h2 className="text-3xl font-medium">Expert medical services</h2>
              </WidgetHeader>
              <EMSWidget />
            </Widget>
          )}
          {showFindFeature && (
            <Widget>
              <WidgetHeader>
                <FindIcon className="w-10 h-10 md:h-auto md:w-auto" aria-hidden />
                <h2 className="text-3xl font-medium">Find</h2>
              </WidgetHeader>
              <Find zipCode={profile.zip} hasProviders={hasProviders} hasFacilities={hasFacilities} />
            </Widget>
          )}

          {hasFeature(group, Feature.beatTheDeductible) && <BeatTheDeductibleWidget />}
          {hasFeature(group, Feature.covid19Assessment) && <Covid19AssessmentWidget />}
        </Masonry>
      </ResponsiveMasonry>
    </div>
  );
};

export default Dashboard;
