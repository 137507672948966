import { CSSProperties } from "react";

export type Nullable<T> = T | null;

export type Style = { [key: string]: CSSProperties };

export enum PricingType {
  PROCEDURE = "procedure",
  PRESCRIPTIONS = "prescriptions"
}

export enum Gender {
  MALE = "Male",
  FEMALE = "Female",
  ALL = "All",
  OTHER = "Other"
}

export enum PiwikCategory {
  BUTTONS = "Buttons",
  HEADER_LINKS = "Header Links",
  FORM = "Form"
}

export enum PiwikAction {
  CLICKED = "Clicked",
  SUBMIT = "Submit",
  CANCEL = "Cancel"
}

export enum PiwikName {
  RAV = "Request a visit",
  MY_FAMILY = "My Family",
  MEDICAL_INFO = "Medical Info",
  MESSAGES = "Messages",
  SELF_ASSESMENT = "Self-assessment",
  JOIN_WAITING_ROOM = "Join Waiting Room",
  APPLE_STORE = "Apple Store",
  GOOGLE_PLAY = "Google Play",
  CHANGE_PASSWORD = "Change Password",
  LOGOUT = "Logout",
  LOGIN = "Login",
  REGISTER = "Register Now",
  ACCOUNT_SETTINGS = "Account Settings",
  DASH = "DASHBOARD",
  CHANGE_PASSWORD_EXTERNAL = "Change Password External",
  EXPERT_MEDICAL_SERVICES = "Expert Medical Services",
  TECHNICAL_SUPPORT = "Technical Support",
  SUPPORT = "Support",
  QUESTIONS_OR_FEEDBACK = "Questions or Feedback",
  BEAT_THE_DEDUCTIBLE = "Beat The Deductible",
  NECK_AND_BACKCARE = "Neck and backcare",
  FAVORITES = "Favorites",
  LIVONGO_CHRONIC_CARE = "Livongo Chronic Care",
  LIVONGO_MYSTRENGTH = "Livongo MyStrength",
  COVID_19_ASSESSMENT = "COVID-19 Assessment"
}

export type PiwikEvent = {
  CATEGORY: PiwikCategory;
  ACTION: PiwikAction;
  NAME: PiwikName;
  VALUE?: number | null;
};

export enum ErrorMessages {
  UNAUTHORIZED = "UNAUTHORIZED",
  NETWORK_REQUEST_FAILED = "NETWORK REQUEST FAILED",
  UNKNOWN = "AN UNKNOWN ERROR OCCURRED"
}
