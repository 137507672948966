import React, { useEffect, useCallback } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { selectAuth } from "./slices/authSlice";
import { useSelector } from "react-redux";
import PageTitleComponent from "../components/PageTitleComponent";

function Logout() {
  const { signOut } = useAuth();
  const location = useLocation();
  const { hasAuth } = useSelector(selectAuth);

  const redirectLogout = useCallback(() => {
    return <Redirect to="/login" from={location.pathname} />;
  }, [location.pathname]);

  useEffect(() => {
    // wait for auth to go away before nav to login
    if (!hasAuth) {
      redirectLogout();
    }
  }, [hasAuth, redirectLogout]);

  useEffect(() => {
    signOut();
  }, [signOut]);

  return (
    <div className="max-w-screen-xl mx-auto bg-off-white">
      <PageTitleComponent title="Logout" />
      <div className="px-6 pt-6 pb-8 md:px-8">You have been logged out</div>
    </div>
  );
}

export default Logout;
