import clsx from "clsx";
import React, { useEffect, useRef } from "react";

const DrugPriceListItem = ({ drugPrice, active, selected, onMouseEnter, onMouseLeave, onClick, isBrand }) => {
  const { Name, Price, PharmacyName, Distance, Street1, City, State, ZipCode } = drugPrice;
  const el = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (selected && el.current) {
      el.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [selected]);
  return (
    <div
      role="button"
      tabIndex={0}
      ref={el}
      onKeyDown={() => onClick(drugPrice)}
      className={clsx("p-2 border-b-2", (active || selected) && "bg-pale-grey-10")}
      onMouseEnter={() => onMouseEnter(drugPrice)}
      onMouseLeave={() => onMouseLeave(drugPrice)}
      onClick={() => onClick(drugPrice)}
    >
      <div className="flex justify-between">
        <div className="font-bold text-blue capitalize">{Name}</div>
        <div>{`$${Price.toFixed(2)}`}</div>
      </div>
      <div className="flex justify-between">
        <div>{PharmacyName}</div>
        <div className="text-pale-grey-200">{`${Distance.toFixed(2)} mi`}</div>
      </div>
      <div className="flex items-start justify-between">
        <div className="flex flex-col">
          <p>{Street1}</p>
          <p>{`${City}, ${State} ${ZipCode}`}</p>
        </div>
      </div>
    </div>
  );
};

const DrugPriceList = ({
  drugPrices,
  activeItem,
  selectedItem,
  onItemMouseEnter,
  onItemMouseLeave,
  onItemClick,
  isBrand
}) => (
  <div className="w-full overflow-x-hidden bg-white full-scrollbar max-h-450 h-40vh md:h-full">
    {drugPrices.map((drugPrice) => (
      <DrugPriceListItem
        key={drugPrice.PharmacyId}
        drugPrice={drugPrice}
        active={drugPrice.PharmacyId === activeItem}
        selected={drugPrice.PharmacyId === selectedItem}
        onMouseEnter={onItemMouseEnter}
        onMouseLeave={onItemMouseLeave}
        onClick={onItemClick}
        isBrand={isBrand}
      />
    ))}
  </div>
);

export default DrugPriceList;
