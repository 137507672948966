import React from "react";
import FavoriteButton from "./FavoriteButton";
import FavoritePhone from "./FavoritePhone";

const FavoriteItemWrapper = ({ element, onUnfavorite, children }) => {
  const removeFromFavorites = () => {
    onUnfavorite(element.id);
  };
  return (
    <div className="mb-4 pb-4 border-b-2 favorite-item-border">
      <div className="flex flex-row">
        <div className="self-center mr-4 favorite-avatar-section">{children}</div>
        <div className="flex flex-col justify-center">
          <p className="text-primary-blue font-bold">{element.name.split(" null").join("")}</p>
          {element.meta?.address ? <p className="font-medium">{element.meta.address}</p> : null}
          {element.meta?.phoneNumber ? <FavoritePhone phone={element.meta.phoneNumber} /> : null}
        </div>
        <FavoriteButton onUnfavorite={removeFromFavorites} />
      </div>
    </div>
  );
};

export default FavoriteItemWrapper;
