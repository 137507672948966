import React, { useEffect, useState } from "react";
import Button from "../components/Button";
import moment from "moment";
import { selectAuth, updateMembership } from "./slices/authSlice";
import { useSelector } from "react-redux";
import { trackEvent } from "../api/piwik/piwikService";
import { PiwikAction, PiwikCategory, PiwikName } from "../@types/common";
import {
  AddDependentFormFields,
  AddDependentRequest,
  DependentType,
  selectRegistration,
  clearErrors
} from "./slices/registrationSlice";
import { useAppDispatch } from "../store/store";
import { addDependent } from "../api/registration";
import ucFirst from "../utils/ucFirst";
import getGenderString from "../utils/getGenderString";
import AddDependentModal from "./Modals/AddDependent";
import { Modal } from "../components/Modal";
import { Feature, hasFeature } from "../utils/featureHelper";
import PageTitleComponent from "../components/PageTitleComponent";

const getFamilyFromMembership = (membership) => {
  return [membership.primary, membership.spouse, ...(membership.dependents || [])].filter((member) => !!member);
};

const MyFamily = () => {
  const {
    entities: { profile, membership, group }
  } = useSelector(selectAuth);

  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [submittedPayload, setSubmittedPayload] = useState<AddDependentFormFields | null>(null);
  const dispatch = useAppDispatch();
  const { dependentCreated, apiError } = useSelector(selectRegistration);

  const familyMembers = getFamilyFromMembership(membership);
  const isPrimary = profile.familyMemberId === membership.id;

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    if (dependentCreated && !apiError && submittedPayload) {
      setShowModal(false);
      let spouse = familyMembers.find((member) => member.relationToPrimary === DependentType.SPOUSE);
      const dependents = familyMembers.filter((member) => member.relationToPrimary === "Dependent");

      if (submittedPayload.type === DependentType.SPOUSE) {
        submittedPayload["relationToPrimary"] = DependentType.SPOUSE;
        spouse = submittedPayload;
      } else {
        submittedPayload["relationToPrimary"] = "Dependent";
        dependents.push(submittedPayload);
      }

      dispatch(
        updateMembership({
          spouse,
          dependents
        })
      );
      dispatch(clearErrors());
      setShowSuccessModal(true);
    }
  }, [dependentCreated, apiError, dispatch, familyMembers, submittedPayload]);

  const trackSubmit = () => {
    trackEvent({
      category: PiwikCategory.FORM,
      action: PiwikAction.SUBMIT,
      name: PiwikName.MY_FAMILY
    });
  };

  const submitData = (values: AddDependentFormFields) => {
    trackSubmit();
    setSubmittedPayload(values);
    let payload: Partial<AddDependentRequest> = {};
    payload[values.type] = values.type === "Spouse" ? values : [values];
    payload = {
      EffectiveDate: moment.utc().format(),
      groupId: group.id,
      MemberId: membership.id,
      ...payload
    };
    dispatch(addDependent(payload as AddDependentRequest));
  };

  const onClose = () => {
    setShowModal(false);
    dispatch(clearErrors());
  };


  return (
    <div className="justify-center w-full h-full max-w-3xl mx-auto font-effra">
      <PageTitleComponent title="My Family" />
      <h1 className="my-6 ml-6 text-4xl font-medium leading-tight font-effra">My Family</h1>
      <div className="p-8 m-6 bg-white shadow-lg">
        {familyMembers.map((member, i) => (
          <div
            role="note"
            tabIndex={i + 1}
            aria-label={`${ucFirst(member.first)} ${ucFirst(member.last)}, Date of Birth of is ${moment(member.dob).format("DD MMM YYYY")} and ${getGenderString(member.gender)} ${Math.abs(moment(member.dob).diff(moment(), "years"))} years.`}
            key={member.id || `${member.dob}.${member.first}.${member.last}`}
            className="justify-center w-full px-2 py-3 border-b-1"
          >
            <p className="font-bold">
              {ucFirst(member.first)} {ucFirst(member.last)}
            </p>
            <span>
              {moment(member.dob).format("MM/DD/YYYY")} | {Math.abs(moment(member.dob).diff(moment(), "years"))} years
            </span>
          </div>
        ))}
        {isPrimary && hasFeature(group, Feature.openDependents) && (
          <div className="mt-3">
            <Button onClick={() => setShowModal(true)}>Add a family member</Button>
          </div>
        )}
      </div>
      <AddDependentModal
        error={apiError}
        membership={membership}
        onClose={onClose}
        showModal={showModal}
        submitData={submitData}
      />
      <Modal
        state={{
          close: () => setShowSuccessModal(false),
          isOpen: showSuccessModal
        }}
      >
        <div
          role="dialog"
          data-testid="success-modal"
          className="z-50 flex flex-col self-center w-full h-auto max-h-full mx-auto overflow-y-auto bg-white rounded md:w-3/5"
        >
          <div className="flex flex-row justify-between px-2 py-4 border-gray-200 lg:px-10 md:px-5 md:items-center border-b-1">
            <h1>Success!</h1>
          </div>
          <p className="px-2 py-4 lg:px-10 md:px-5">
            Please allow 24 hours for your family's service to activate. Spouses and dependents 18+ will need to
            register individually.
          </p>
          <div className="flex flex-row justify-end px-2 mt-5 bg-gray-100 lg:px-10 md:px-5 border-t-1">
            <div className="flex justify-end my-4 space-x-2">
              <Button onClick={() => setShowSuccessModal(false)} buttonType="primary">
                OK
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default MyFamily;
