import React from "react";

type Props = {
  messageNumber: number;
};

const MessageIcon = ({ messageNumber }: Props) => {
  return messageNumber > 0 ? (
    <div
      data-testid="message-icon"
      className="flex items-center justify-center px-1 bg-red-600 rounded-full hover:bg-red-500"
    >
      <div className="text-xs text-center text-white">{messageNumber}</div>
    </div>
  ) : null;
};

export default MessageIcon;
