import { useSelector } from "react-redux";
import moment from "moment";
import { selectWebSdk } from "../../containers/slices/websdkSlice";
import { Consult, ConsultType, ConsultStatus } from "../../@types/Consults";

interface filteredConsultations {
  upcoming: Consult[];
  requested: Consult[];
}

// logic defined here: https://confluence.teladoc.net/pages/viewpage.action?pageId=2826735642
// GM or Derm -> upcoming
// (MH or Nut) and (Requested or Proposed) -> requested
// (MH or Nut) and (Started or Locked) -> upcoming
// Started -> upcoming

const useConsultations = (consultations): filteredConsultations => {

  return [...consultations]
    .sort((a, b) => moment(a.requested_date).diff(moment(b.requested_date)))
    .reduce(
      (acc: filteredConsultations, consult: Consult) => {
        const isMentalHealth = consult.consult_type === ConsultType.MentalHealth;
        const isNutrition = consult.consult_type === ConsultType.Nutrition;
        const isRequested = consult.status === ConsultStatus.Requested; // waiting for approval/assigning
        const isProposed = consult.status === ConsultStatus.Proposed;

        if ((isMentalHealth || isNutrition) && (isRequested || isProposed)) {
          acc.requested.push(consult);
        } else {
          acc.upcoming.push(consult);
        }

        return acc;
      },
      { upcoming: [], requested: [] }
    );
};

export default useConsultations;
