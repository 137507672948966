import React from "react";

interface Props {
  children: any;
}

const Widget = ({ children }: Props) => {
  return (
    <div className="w-full h-auto bg-white rounded shadow-lg" data-testid="widgetContainer">
      {children}
    </div>
  );
};

export default Widget;
