import { createAsyncThunk } from "@reduxjs/toolkit";
import { LoginRequest } from "../../containers/slices/authSlice";
import { removeTrackingCookies } from "../../utils/cookies";
import hyApi from "../services/hyApiService";
import { mapiLogin } from "../websdk/mobileApi";

export const postLogin = createAsyncThunk("auth/postLogin", async (data: LoginRequest, thunkAPI) => {
  const response = await hyApi.post("api/auth/login", { ...data, type: "MEMBER", version: 2 });
  return response.data;
});

export const getSdkToken = createAsyncThunk("auth/getSdkToken", async (profileId: string, thunkAPI) => {
  const response = await hyApi.post(`api/profiles/${profileId}/teladoc-sdk-token`);
  return response.data;
});

export const refreshSdkToken = createAsyncThunk("auth/refreshSdkToken", async (thunkAPI) => {
  const response = await hyApi.post(`api/profiles/teladoc-sdk-token`);
  return response.data;
});

export const checkSession = createAsyncThunk("auth/checkSession", async (thunkAPI) => {
  removeTrackingCookies();
  const response = await hyApi.get(`api/auth/check-session`);
  return response.data;
});

export const samlLogin = createAsyncThunk("auth/samlLogin", async (token: string, thunkAPI) => {
  const response = await hyApi.post(`/api/sso/authenticate`, { token });
  return response.data;
});

export const getMapiToken = createAsyncThunk("auth/getMapiToken", async (teladocSdkToken: string, thunkAPI) => {
  const response = await mapiLogin(teladocSdkToken);
  return response.data;
});

export default postLogin;
