import { createAsyncThunk } from "@reduxjs/toolkit";
import { getMessages } from "@td/websdk";
import { callSdk } from "../services/websdkService";

export const getMessage = createAsyncThunk("websdk/getMessage", async (data, thunkAPI) => {
  let response: any = await callSdk(getMessages);
  if (!response) {
    return thunkAPI.rejectWithValue({ value: "Failed to get messages for selected account" });
  }
  return response;
});

export default getMessage;
