import { useField } from "formik";
import React from "react";
import PasswordValidation from "../../PasswordValidation";
import { FormikPasswordWithButtonInput } from "./FormikPasswordWithButtonInput";

export const FormikValidatePasswordInput = ({ ...props }: any) => {
  const [field] = useField(props);
  return (
    <>
      <FormikPasswordWithButtonInput {...field} {...props} />
      {field.value ? <PasswordValidation field={field} /> : null}
    </>
  );
};
