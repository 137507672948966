import React, { useRef } from "react";
import ReactDOM from "react-dom";
import { useClickAway } from "react-use";
import { ReactComponent as Remove } from "../../assets/icons/action/delete/c-remove-large.svg";

interface Props {
  children: any;
  showDialog: boolean;
  dialogConfig: {
    fullWidth: boolean;
  };
  onClose?: () => void;
}

const allowedElements = [".autocomplete-suggestions-web-sdk"];

const Dialog = ({ children, onClose, showDialog, dialogConfig }: Props) => {
  const body = document.getElementsByTagName("body")[0];
  const dialogRef = useRef(null);

  const handleCloseClick = () => {
    onClose && onClose();
  };

  useClickAway(dialogRef, (e) => {
    const target = e.target as Element;
    // Sometimes it happens that those elements are not in DOM and handleCloseClick executes
    if (target && allowedElements.some((el) => target.closest(el))) {
      return;
    }

    handleCloseClick();
  });

  const dialogBody = (
    <div className="fixed inset-0 z-40 bg-black bg-opacity-75">
      <button
        className="absolute top-0 right-0 z-50 w-16 h-16 mt-2 mr-2 text-white bg-gray-400 rounded-full sm:bg-transparent sm:h-24 sm:w-24 sm:text-gray-400"
        onClick={handleCloseClick}
        title="Close Dialog"
      >
        <Remove className="w-16 h-16 fill-current sm:h-24 sm:w-24" title="Close Dialog" aria-hidden />
      </button>
      <div
        ref={dialogRef}
        style={{ width: "calc(100% - 4rem)", height: "calc(100% - 4rem)", maxHeight: "800px", maxWidth: dialogConfig.fullWidth ? "100%" : "513px" }}
        className="px-4 pt-2 pb-4 mt-12 ml-auto mr-auto bg-white rounded shadow-lg"
      >
        <div className="w-full h-8"></div>
        <div style={{ height: "calc(100% - 3rem)", maxHeight: "752px" }} className={"overflow-y-auto w-full"}>
          {children}
        </div>
      </div>
    </div>
  );

  return showDialog ? ReactDOM.createPortal(dialogBody, body) : null;
};

export default Dialog;
