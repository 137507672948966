import React from 'react'
import { Helmet } from 'react-helmet'

function PageTitleComponent({title}) {
    return (
        <Helmet>
            <title>{`HealthiestYou - Member Portal - ${title}`}</title>
        </Helmet>
    )
}

export default PageTitleComponent