import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "./slices/authSlice";
import FormCard from "../components/FormCard";
import Diabetes from "../assets/Livongo/Diabetes.png";
import BloodSugar from "../assets/Livongo/BloodSugar.png";
import WeightManagement from "../assets/Livongo/WeightManagement.png";
import BloodPressure from "../assets/Livongo/BloodPressure.png";
import KidneyDisease from "../assets/Livongo/KidneyDisease.png";

import {
  LivongoChronicCareProducts as products,
  LivongoChronicCareBenefits as benefits,
  hasLivongoChronicCareProduct
} from "../utils/featureHelper";
import { redirectToLivongo } from "../api/livongo";
import { ToastType, useToast } from "../components/ToastProvider";
import { useSpinner } from "../components/SpinnerProvider";
import PageTitleComponent from "../components/PageTitleComponent";

const LivongoRegistration = () => {
  const { showToast } = useToast();
  const { showSpinner } = useSpinner();

  const {
    entities: {
      group,
      familyMember: { telehealthID: memberID }
    }
  } = useSelector(selectAuth);

  const handleClick = async () => {
    showSpinner({ show: true });

    try {
      await redirectToLivongo(memberID);
    } catch (err) {
      showToast({
        type: ToastType.DANGER,
        message: "Unable to redirect you"
      });
    }

    showSpinner({ show: false });
  };

  const benefitsMap = useMemo(() => {
    const chronicCareMap = {};

    for (let key in products) {
      chronicCareMap[key] = hasLivongoChronicCareProduct(group, key as products);
    }

    chronicCareMap[benefits.manageWeight] =
      chronicCareMap[products.weightManagement] ||
      chronicCareMap[products.diabetesWp] ||
      chronicCareMap[products.preDiabetesWp] ||
      chronicCareMap[products.cardiovascularWp];

    chronicCareMap[benefits.manageBloodSugar] =
      chronicCareMap[products.prediabetes] || chronicCareMap[products.preDiabetesWp];

    chronicCareMap[benefits.manageBloodPressure] =
      chronicCareMap[products.diabetesWp] ||
      chronicCareMap[products.preDiabetesWp] ||
      chronicCareMap[products.cardiovascularWp] ||
      chronicCareMap[products.hypertension];

    chronicCareMap[benefits.diabetes] = chronicCareMap[products.diabetes] || chronicCareMap[products.diabetesWp];

    return chronicCareMap;
  }, [group]);

  return (
    <FormCard title={""}>
      <PageTitleComponent title="Livongo Registration" />
      <div className="flex flex-col items-center">
        <p className="px-4 text-2xl font-medium text-center md:px-48 font-effra">
          Livongo, now part of Teladoc, provides our members with tools and support to take charge of their health.
        </p>
        <p className="pt-4 text-2xl font-normal text-center text-pale-grey-200 px-30 font-effra">
          Check out some ways we can help, and see what's available to you.
        </p>
        <div className="flex flex-wrap justify-center w-full pt-12 md:px-12">
          {benefitsMap[benefits.manageBloodSugar] && (
            <div className="flex flex-col w-64 m-4">
              <div className="w-full h-40">
                <img src={BloodSugar} alt="" className="w-full h-full" />
              </div>
              <p className="my-4 text-xl font-medium text-center">Manage your blood sugar</p>
              <p className="text-center text-pale-grey-200 ">
                Achieve lasting weight loss with a smart weight scale, an interactive healthy living course, and tools
                to track and improve your everyday routines. Get personalized guidance, health summaries you can share,
                and unlimited 1:1 coaching.
              </p>
            </div>
          )}
          {benefitsMap[benefits.manageWeight] && (
            <div className="flex flex-col w-64 m-4">
              <div className="w-full h-40">
                <img src={WeightManagement} alt="" className="w-full h-full" />
              </div>
              <p className="my-4 text-xl font-medium text-center">Manage your weight</p>
              <p className="text-center text-pale-grey-200 ">
                Achieve lasting weight loss with a smart weight scale, an interactive healthy living course, and tools
                to track and improve your everyday routines. Get personalized guidance, health summaries you can share,
                and unlimited 1:1 coaching.
              </p>
            </div>
          )}
          {benefitsMap[benefits.chronicKidneyDisease] && (
            <div className="flex flex-col w-64 m-4">
              <div className="w-full h-40">
                <img src={KidneyDisease} alt="" className="w-full h-full" />
              </div>
              <p className="my-4 text-xl font-medium text-center">Manage your kidney health</p>
              <p className="text-center text-pale-grey-200 ">
                Take charge of your kidney health with personalized guidance, a smart weight scale and blood pressure
                monitor, health summaries you can share, and 1:1 coaching.
              </p>
            </div>
          )}
          {benefitsMap[benefits.manageBloodPressure] && (
            <div className="flex flex-col w-64 m-4">
              <div className="w-full h-40">
                <img src={BloodPressure} alt="" className="w-full h-full" />
              </div>
              <p className="h-12 my-4 text-xl font-medium text-center">Manage your blood pressure</p>
              <p className="text-center text-pale-grey-200 ">
                Manage your hypertension and lower your systolic blood pressure with a smart blood pressure monitor,
                personalized guidance, health summaries you can share, and 1:1 coaching.
              </p>
            </div>
          )}
          {benefitsMap[benefits.diabetes] && (
            <div className="flex flex-col w-64 m-4">
              <div className="w-full h-40">
                <img src={Diabetes} alt="" className="w-full h-full" />
              </div>
              <p className="h-12 my-4 text-xl font-medium text-center">Live healthy and prevent diabetes</p>
              <p className="text-center text-pale-grey-200 ">
                Manage your diabetes and reduce your a1c with a smart blood glucose meter, unlimited strips and lancets,
                personalized guidance, health summaries you can share, and 1:1 coaching.
              </p>
            </div>
          )}
        </div>

        <div className="my-6">
          <button
            onClick={handleClick}
            className=" px-4 py-3 text-black border border-solid rounded-lg bg-blue-20 hover:bg-blue-40 border-primary-blue hover:no-underline disabled:bg-disabled disabled:border-disabled disabled:text-white"
          >
            Check my benefits
          </button>
        </div>

        <p className="mx-8 my-4 text-sm font-normal text-center text-pale-grey-200">
          Taking this action will direct you to the Livongo portal. Please be sure to review the Notice of Privacy
          Practice, Terms of Service, and the Non-discrimination/Language Assistance Notice as they are different for
          the Livongo Programs.
        </p>
      </div>
    </FormCard>
  );
};

export default LivongoRegistration;
