import moment from "moment";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { Person } from "../../@types/Registration";
import { cancelLookup } from "../../containers/slices/registrationSlice";
import { useAppDispatch } from "../../store/store";
import Button from "../Button";
import { ButtonGroup } from "../ButtonGroup";

interface Props {
  handlesSelected: (result) => void;
  persons: Person[];
}

export const SelectPerson = ({ persons, handlesSelected }: Props) => {
  const [isSelected, setIsSelected] = useState(false);
  const dispatch = useAppDispatch();
  const history = useHistory();

  const handleOnChange = (e) => {
    const selectedPerson = e.target.value;
    setIsSelected(true);
    handlesSelected(persons.find((person) => person.firstName === selectedPerson));
  };

  return (
    <div className="z-50 flex flex-col w-full p-4 bg-white rounded-lg" role="dialog">
      <h2 className="block p-4 mb-2 text-xl font-thin text-gray-700 border-gray-300 border-b-1">
        Eligible members
      </h2>
      <label className="block p-4 mb-2 font-normal text-gray-700 text-normal">
        We've found {persons.length} matching eligible members. Please let us know who you are:
      </label>
      <ul
        className="w-full px-4 font-normal leading-tight text-gray-700 appearance-none text-normal focus:outline-none"
        data-testid="select"
        defaultValue=""
        onChange={handleOnChange}
      >
        {persons.map((option, index) => {
          const id = `profile_${option.familyMemberId}`;
          if (option.canRegister && option.canRegisterTeladoc && !option.hasProfile) {
            return (
              <li key={option.firstName} value={index} className="pt-4">
                <input type="radio" id={id} name="test" value={option.firstName} />
                <label htmlFor={id} className="ml-4">
                  {option.firstName}
                </label>
              </li>
            );
          }
          if (option.hasProfile) {
            return (
              <li key={index} value={index} className="pt-4 text-gray-500">
                <input disabled type="radio" id={id} name="test" value={option.firstName} />
                <label htmlFor={id} className="ml-4">
                  {option.firstName} (Member already has profile)
                </label>
              </li>
            );
          }
          if (moment(option.dob).toDate() > moment(new Date()).add("years", -18).toDate() && !option.hasProfile) {
            return (
              <li key={index} value={index} className="pt-4 text-gray-500">
                <input disabled type="radio" id={id} name="test" value={option.firstName} />
                <label htmlFor={id} className="ml-4">
                  {option.firstName} (Dependents under the age of 18 are unable to register)
                </label>
              </li>
            );
          }
          return (
            <li key={index} value={index} className="pt-4 text-gray-500">
              <input disabled type="radio" id={id} name="test" value={option.firstName} />
              <label htmlFor={id} className="ml-4">
                {option.firstName} (Profile not active)
              </label>
            </li>
          );
        })}
      </ul>
      <ButtonGroup>
        <Button buttonType="secondary" name="cancelLookup" onClick={() => dispatch(cancelLookup())}>
          Cancel
        </Button>
        <Button
          buttonType="primary"
          disabled={!isSelected}
          type="submit"
          name="next"
          onClick={() => history.push("/register/create-account")}
        >
          Next
        </Button>
      </ButtonGroup>
    </div>
  );
};

export default SelectPerson;
