import clsx from "clsx";
import { FieldInputProps, FieldMetaProps } from "formik";
import React from "react";

import { ReactComponent as Warning } from "../../assets/warning.svg";

interface FormFieldError<V = any> {
  field: FieldInputProps<V> & { id?: string };
  meta: FieldMetaProps<V>;
}

export function FormFieldError({ field, meta }: FormFieldError) {
  const isError = meta.touched && meta.error;
  // id, role, and aria-live added for aria feedback for forms, do not remove/move
  // https://www.w3.org/WAI/WCAG21/quickref/#error-identification
  return (
    <div className={clsx(isError ? "text-danger" : "text-gray-700", "-ml-1 text-base font-effra")}>
      {isError ? (
        <span
          id={`${field?.id || field.name}_error`}
          aria-live="assertive"
          role="alert"
          className="flex items-center w-full"
        >
          <Warning className="m-1 fill-current" aria-label="Alert" />
          {meta.error}
        </span>
      ) : null}
    </div>
  );
}
