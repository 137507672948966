import React from "react";
import { ReactComponent as FavoriteIcon } from "../../assets/icons/action-rate-heart.svg";

export const FavoriteButton = ({ onUnfavorite }) => {
  return (
    <div className="ml-auto self-center cursor-pointer">
      <FavoriteIcon className="w-16 h-16" onClick={onUnfavorite} />
    </div>
  );
};

export default FavoriteButton;
