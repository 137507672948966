import React from "react";
import clsx from "clsx";
import { useTabState } from "@bumaga/tabs";

interface Props {
  children: any;
  onChange?: (type: string) => void;
}

const WidgetTab = ({ children, onChange }: Props) => {
  const { onClick, isActive } = useTabState();
  return (
    <button
      data-testid="tabContainer"
      type="button"
      className={clsx(
        isActive &&
          "text-blue-primary w-auto py-2 font-bold px-4 block hover:text-black focus:outline-none text-black border-b-4 border-primary-blue",
        !isActive && "text-black w-auto py-2 px-4 block hover:text-black",
        "mb-2"
      )}
      onClick={() => {
        onClick();
        if (onChange) {
          onChange(children?.key);
        }
      }}
    >
      {children}
    </button>
  );
};

export default WidgetTab;
