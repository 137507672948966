export interface FavoriteComponentProps {
  element: FavoriteElement;
  onUnfavorite: () => void;
}

export type Avatar = {
  src: string;
  female: boolean;
};

export interface FavoriteElement {
  name: string;
  category: FavoriteCategory;
  id: string;
  avatar: string;
  meta: FavoriteElementMeta | null;
  type: string;
}

export enum FavoriteCirclesCategory {
  RX = "RX",
  PROVIDERS = "Providers",
  RECIPES = "Recipes"
}

export enum RecipeCategory {
  MAIN_DISH = "Main Dishes",
  SOUP = "Soup",
  SIDE_DISH = "Side Dish",
  SALAD = "Salad",
  DESSERT = "Dessert",
  BREAKFAST = "Breakfast",
  BREAD = "Bread",
  APPETIZER = "Appetizer"
}

export enum RxCategory {
  PHARMACY = "Pharmacy",
  PRESCRIPTION = "Medications"
}

export enum ProviderCategory {
  PCP = "PCP",
  DENTISTS = "Dentists",
  PEDIATRICIANS = "Pediatricians",
  OPTOMETRISTS = "Optometrists",
  SPECIALISTS = "Specialists"
}

export enum FacilitiesCategory {
  URGENT_CARE = "Urgent Care",
  VETS = "Vets",
  VETERINARIANS = "Veterinarians",
  HOSPITALS = "Hospitals",
  PHARMACIES = "Pharmacy"
}

export interface FavoriteElementMeta {
  address: string;
  phoneNumber: string;
  lat: string | number;
  lon: string | number;
  npi?: string;
}

export type FavoriteCategory = RxCategory | ProviderCategory | RecipeCategory | FacilitiesCategory;
