import React from "react";
import { ReactComponent as SystemPreferencesIcon } from "../../assets/icons/system-preferences.svg";
import { ReactComponent as CommentIcon } from "../../assets/icons/f-comment.svg";

import FormCard from "../../components/FormCard";
import { Link } from "react-router-dom";
import PageTitleComponent from "../../components/PageTitleComponent";

const Support = () => {
  return (
    <FormCard title="Support">
      <PageTitleComponent title="Support" />
      <div className="flex flex-col h-full p-6 mb-8 space-y-6 font-effra">
        <h2 className="text-2xl font-medium leading-tight">Questions?</h2>
        <p className="font-medium leading-tight">
          Connect with us by submitting a ticket below for technical issues or app related questions. If you need
          medical assistance, please{" "}
          <Link data-testid="link-to-rav" to="/request-a-visit">
            connect to our Telehealth service
          </Link>{" "}
          or contact your primary care provider.
        </p>
        <div className="flex align-middle">
          <Link data-testid="link-to-tech-support" className="text-primary-blue" to="/technical-support">
            Technical support
          </Link>
          <SystemPreferencesIcon className="w-8 h-8 mt-1 ml-2" title="System Preferences Icon" aria-hidden />
        </div>
        <div className="flex">
          <Link data-testid="link-to-questions-or-feedback" className="text-primary-blue" to="/questions-or-feedback">
            Questions or feedback
          </Link>
          <CommentIcon className="w-8 h-8 mt-1 ml-2" title="Comment Icon" aria-hidden />
        </div>
      </div>
    </FormCard>
  );
};

export default Support;
