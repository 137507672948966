import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import moment from "moment";
import clsx from "clsx";

import { ReactComponent as CameraIcon } from "../../assets/icons/camera.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone.svg";
import { ButtonGroup } from "../ButtonGroup";
import Button from "../Button";
import { Modal } from "../Modal";
import { useWebSdk } from "../WebSdkProvider";
import { Consult, CommunicationMethod } from "../../@types/Consults";
import { canReschedule, getFlowUrl, canCancel } from "../../utils/consultsHelper";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "../../containers/slices/authSlice";
import { cancelVisitDirect, getConsultationList } from "../../api/websdk/mobileApi";

interface Props {
  consult: Consult;
}

const storedDateFormat = "YYYY-MM-DD HH:mm:ss Z";
const displayedDateFormat = "MMM D, YYYY @ hh:mm a";

const formatDate = (date) => moment(date, storedDateFormat).format(displayedDateFormat);

const RequestedVisitListItem = ({ consult }: Props) => {
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const showCancelButton = canCancel(consult);

  const icon =
    consult.communication_method === CommunicationMethod.Phone ? (
      <PhoneIcon className="fill-current" title="Phone Icon" />
    ) : (
      <CameraIcon className="fill-current" title="Camera Icon" />
    );

  const handleRescheduleVisit = () => {
    history.push(getFlowUrl(consult), { initialState: consult });
  };

  const {
    entities
  } = useSelector(selectAuth);

  const dispatch = useDispatch();

  const cancelConsultDirect = useCallback(    
    (payload: {consultId: number, memberId: number}) => {
      dispatch(cancelVisitDirect(payload));
      dispatch(getConsultationList(payload.memberId));
    },
    [dispatch]
  );

  
  const cancelConsult = () => {
    setShowDialog(false);
    
    try {      
      const payload = {
        consultId: consult.consult_id,
        memberId: entities.familyMember.telehealthID
      };
      cancelConsultDirect(payload); 

      toast.success("Your visit has been cancelled");
    } catch (e) {
      toast.error("Unable to cancel your visit, please try again.");
    }
  };

  const showCancelModal = () => {
    if (!showCancelButton) {
      toast.error("Sorry! You are unable to cancel a visit if a provider is assigned.");
    } else {
      setShowDialog(true);
    }
  };

  const actionButtons = () => {
    return (
      <div className="flex items-start justify-start w-full -mt-3 sm:mt-1">
        {showCancelButton && (
          <button
            type="button"
            data-testid={"cancel-visit-button"}
            onClick={showCancelModal}
            className={clsx(
              showCancelButton && "hover:no-underline text-disabled",
              "mr-2 text-sm no-underline text-primary-blue text-center "
            )}
          >
            Cancel
          </button>
        )}

        {canReschedule(consult) && (
          <button
            data-testid={"reschedule-visit-button"}
            className="mr-2 text-sm no-underline hover:no-underline text-primary-blue"
            onClick={handleRescheduleVisit}
          >
            Reschedule
          </button>
        )}
        {icon}
      </div>
    );
  };

  return (
    <>
      <div
        data-testid={"requested-visit-item-container"}
        className="flex flex-col items-start px-4 py-2 my-4 border border-solid rounded-lg gap-y-4 sm:flex-row sm:itmes-center border-primary-blue"
        style={{ height: "calc(50% - 1rem)" }}
      >
        <div className="flex flex-col items-start justify-around w-full h-full">
          <p className="text-lg font-medium font-effra text-dark-gray">
            {consult.member?.first_name} {consult.member?.last_name}
          </p>
          <p className="text-xs leading-tight text-dark-gray">
            {consult.consult_type} | Awaiting Confirmation with {consult?.provider?.first_name}{" "}
            {consult?.provider?.last_name}
          </p>
          <div className="hidden sm:flex">{actionButtons()}</div>
        </div>

        <div className="flex flex-col justify-start w-56">
          <p className="self-start mt-0 text-xs font-medium uppercase text-pale-grey-200 sm:mt-2">SELECTED</p>
          {consult?.requested_dates?.map((requestedDate) => (
            <div key={requestedDate} className="text-xs leading-tight text-dark-gray">
              {formatDate(requestedDate)}
            </div>
          ))}
          {consult?.proposed_dates?.map((proposedDate) => (
            <div key={proposedDate} className="text-xs leading-tight text-dark-gray">
              {formatDate(proposedDate)}
            </div>
          ))}
        </div>
        <div className="flex mt-6 sm:w-0 sm:hidden">{actionButtons()}</div>
      </div>

      <Modal
        state={{
          close: () => setShowDialog(false),
          isOpen: showDialog
        }}
      >
        <div
          data-testid={"cancel-visit-modal"}
          className="z-50 flex flex-col self-center h-auto px-10 py-4 mx-auto bg-white rounded w-md"
        >
          <div className="flex flex-col items-center p-2 space-y-2">
            <h1>Cancel Visit?</h1>
            <p>Please Note: You will not be charged for cancelling your visit.</p>
          </div>
          <ButtonGroup>
            <Button buttonType="secondary" onClick={() => setShowDialog(false)}>
              No
            </Button>
            <Button data-testid={"cancel-visit-modal-button"} buttonType="primary" onClick={cancelConsult}>
              Cancel
            </Button>
          </ButtonGroup>
        </div>
      </Modal>
    </>
  );
};

export default RequestedVisitListItem;
