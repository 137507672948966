import React, { useEffect } from "react";
// TODO: refactor and normalize errors via helper function in slice
export const Alert = ({ errors }: { errors: string[] }) => {
  const [title, setTitle] = React.useState<string>(() => "Something went wrong!");
  const [message, setMessage] = React.useState<string>(
    () => "Please verify your information and try again.  For assistance please call 866-703-1259."
  );
  useEffect(() => {
    if (
      errors.indexOf("The email is unavailable.") > -1 ||
      errors[0].indexOf("The email address you entered is already in use") > -1
    ) {
      setTitle("Email already taken");
      setMessage("Check your email and try again.");
    } else {
      setTitle("Something went wrong!");
      setMessage("Please verify your information and try again.  For assistance please call 866-703-1259.");
    }
  }, [errors]);

  return (
    <div className="flex flex-col px-4 py-3 my-6 text-red-700 bg-red-100 border border-red-400 rounded" role="alert">
      <h4 className="font-bold">{title}</h4>
      <p>{message}</p>
    </div>
  );
};

export default Alert;
