import React from "react";
import FormCard from "./FormCard";
import PageTitleComponent from "./PageTitleComponent";

const HealthCareBenefitAlert = () => {
  return (
    <FormCard title="UnitedHealthCare Benefit Alert">
      <PageTitleComponent title="UnitedHealthCare Benefit Alert" />
      <div className="px-8">
        <p>If you are under age 18 and seeking virtual behavioral health services, you can either contact:</p>
        <div className="p-8">
          <ul className="list-disc">
            <li>
              <a href="https://www.bendhealth.com/" target="_blank" rel="noopener noreferrer">
                Bend Health
              </a>
              <span>
                , a virtual behavioral health provider network (You will be prompted to register and provide your active
                UnitedHealthcare ID Card information), or
              </span>
            </li>
            <li>
              <a href="https://www.myallsaversconnect.com/tpa-ap-web/" target="_blank" rel="noopener noreferrer">
                UnitedHealthcare provider directory
              </a>
              <span>, where you can find a virtual behavioral health network provider.</span>
            </li>
          </ul>
        </div>
        <p>
          For additional questions related to virtual behavioral health services for dependents under age 18, please
          contact the member services number on the back of your UHC ID Card.
        </p>
      </div>
    </FormCard>
  );
};

export default HealthCareBenefitAlert;
