interface providersData {
  label: string | number;
  value: string | number;
}

interface specialty {
  label: string;
  value: string;
}

export const specialties: specialty[] = [
  {
    label: "Allergy/Immunology",
    value: "allergy"
  },
  {
    label: "Cancel",
    value: ""
  },
  {
    label: "Cardiology",
    value: "cardiology"
  },
  {
    label: "Counseling",
    value: "counselor"
  },
  {
    label: "Dermatologist",
    value: "dermatology"
  },
  {
    label: "Gastroenterology",
    value: "gastroenterology"
  },
  {
    label: "Neurology",
    value: "neurology"
  },
  {
    label: "No Preference",
    value: "no-preference"
  },
  {
    label: "Obstetrics & Gynecology",
    value: "obstetrics and gynecology"
  },
  {
    label: "Eye Doctors",
    value: "ophthalmology"
  },
  {
    label: "Optometry",
    value: "optometry"
  },
  {
    label: "Orthopedic Surgery",
    value: "orthopedic"
  },
  {
    label: "Other",
    value: "other"
  },
  {
    label: "Otolaryngology",
    value: "otolaryngology"
  },
  {
    label: "Pediatricians",
    value: "family medicine"
  },
  {
    label: "Podiatry",
    value: "podiatrist"
  },
  {
    label: "Primary Care Physician",
    value: "general"
  },
  {
    label: "Psychiatry",
    value: "psychiatry"
  },
  {
    label: "Psychology",
    value: "psychologist"
  },
  {
    label: "Dentists",
    value: "dentistry"
  }
];

export const genderOptions: providersData[] = [
  {
    value: "Male",
    label: "Male"
  },
  {
    value: "Female",
    label: "Female"
  },
  {
    value: "All",
    label: "No preference"
  }
];

export const radiusOptions: providersData[] = [
  {
    value: 5,
    label: "5"
  },
  {
    value: 10,
    label: "10"
  },
  {
    value: 25,
    label: "25"
  },
  {
    value: 50,
    label: "50"
  },
  {
    value: 100,
    label: "100"
  },
  {
    value: 250,
    label: "250"
  }
];

export const zipRegex = /\b\d{5}\b/;

export const defaultGenderOption: providersData = {
  value: "All",
  label: "No preference"
};

export const defaultRadiusOption: providersData = {
  value: 10,
  label: "10"
};
