import React from "react";
import { ErrorMessages } from "../@types/common";

export const Alert = ({ error }: { error: string }) => {
  const errorMessage = !error || error === ErrorMessages.NETWORK_REQUEST_FAILED ? "Invalid login" : error;

  return (
    <div className="flex flex-col px-4 py-3 my-4 text-red-700 bg-red-100 border border-red-400 rounded" role="alert">
      <p className="block sm:inline">{errorMessage}</p>
    </div>
  );
};
