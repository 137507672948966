import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

import { Nullable } from "../../@types/common";
import { Person } from "../../@types/Registration";
import postLogin, { getMapiToken } from "../../api/auth";
import { getSdkToken, refreshSdkToken, samlLogin } from "../../api/auth";
import { sendMFACode, verifyMFACode } from "../../api/MFA";
import editProfile from "../../api/profiles/editProfile";
import { RootState } from "../../store/rootReducer";
import {
  JWT_TOKEN_COOKIE,
  MAPI_TOKEN_COOKIE,
  removeCookies,
  SDK_TOKEN_COOKIE,
  setJwtTokenCookie,
  setMapiTokenCookie,
  setSdkTokenCookie
} from "../../utils/cookies";

export interface LoginRequest {
  password?: string;
  email?: string;
  jwt?: string | null;
  teladocSdkToken?: string | null;
  callback?: Nullable<any>;
}

export interface UserProfile {
  first: Nullable<string>;
  last: Nullable<string>;
  email: Nullable<string>;
  phone: Nullable<string>;
  address1: Nullable<string>;
  address2: Nullable<string>;
  city: Nullable<string>;
  state: Nullable<string>;
  zip: Nullable<string>;
  language: Nullable<string>;
  id: string;
}

export interface LoginState {
  loading: {
    login: boolean;
    profile: boolean;
    sdk: boolean;
    saml: boolean;
    mapi: boolean;
  };
  email: string;
  rememberEmail: boolean;
  sessionExpired: boolean;
  meta: any;
  entities: any;
  error: Nullable<string | undefined>;
  profileError: boolean;
  profileUpdated: boolean;
  reopenFlow: boolean;
  selectedPerson?: Person;
  jwt?: Nullable<string>;
  teladocSdkToken?: Nullable<string>;
  mapiToken?: Nullable<string>;
  isRegistered?: boolean;
  hasSso: boolean;
  hasSdk: boolean;
  hasMapi: boolean;
  hasAuth: boolean;
  expireCountdown: number;
  requiresMFA: boolean;
  hasMFA: boolean;
  MFAPreferredDeliveryId: Nullable<string>;
  MFAPreferredDeliveryMethod: Nullable<string>;
}

const initialState: LoginState = {
  loading: {
    login: false,
    profile: false,
    sdk: false,
    saml: false,
    mapi: false
  },
  rememberEmail: false,
  sessionExpired: false,
  expireCountdown: 10,
  email: "",
  meta: {},
  entities: {},
  error: null,
  profileError: false,
  profileUpdated: false,
  reopenFlow: false,
  jwt: null,
  selectedPerson: undefined,
  teladocSdkToken: null,
  mapiToken: null,
  isRegistered: undefined,
  hasSso: false,
  hasSdk: false,
  hasMapi: false,
  hasAuth: false,
  requiresMFA: false,
  hasMFA: false,
  MFAPreferredDeliveryId: null,
  MFAPreferredDeliveryMethod: null
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setExpireCountdown: (state, action) => {
      state.expireCountdown = action.payload;
    },
    setSessionExpired: (state) => {
      state.sessionExpired = true;
    },
    clearErrors: (state) => {
      state.error = null;
      state.profileUpdated = false;
      state.profileError = false;
      state.sessionExpired = false;
    },
    enableSSO: (state, action) => {
      state.error = null;
      state.loading.login = true;
      state.hasSso = action.payload;
      state.sessionExpired = false;
    },
    removeEntities: () => initialState,
    updateMembership: (state, action) => {
      state.entities.membership = {
        ...state.entities.membership,
        ...action.payload
      };
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
    setAuth: (state, action) => {
      state.hasAuth = action.payload;
    },
    setSdk: (state, action) => {
      state.hasSdk = action.payload;
    },
    setMapi: (state, action) => {
      state.hasMapi = action.payload;
    },
    setSso: (state, action) => {
      state.hasSso = action.payload;
    },
    clearAuth: (state) => initialState,
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setRememberEmail: (state, action) => {
      state.rememberEmail = action.payload;
    },
    setReopenFlow: (state, action) => {
      state.reopenFlow = action.payload;
    },
    setProfileUpdated: (state, action) => {
      state.profileUpdated = action.payload;
    },
    resetLoading: (state) => {
      state.loading = { ...initialState.loading };
    },
    setHasMFA: (state, action) => {
      state.hasMFA = action.payload;
    },
    resetHas2fa: (state) => {
      state.hasMFA = false;
    },
    setMFAPreferredDeliveryId: (state, action) => {
      state.MFAPreferredDeliveryId = action.payload;
    },
    setMFAPreferredDeliveryMethod: (state, action) => {
      state.MFAPreferredDeliveryMethod = action.payload;
    },
    resetSaml: (state) => {
      Cookies.remove(SDK_TOKEN_COOKIE);
      Cookies.remove(MAPI_TOKEN_COOKIE);
      Cookies.remove(JWT_TOKEN_COOKIE);
      state.hasSdk = false;
      state.hasMapi = false;

      state.isRegistered = false;
      state.hasAuth = false;
      state.meta = null;
      state.error = null;
      state.loading.saml = false;
    },
    logout: () => {
      removeCookies();
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(postLogin.pending, (state) => {
      state.loading.login = true;
      state.error = null;
      state.hasAuth = false;

      // we get/set the webSDK token from initial login
      state.loading.sdk = true;
      state.hasSdk = false;
      state.hasMapi = false;
    });

    builder.addCase(postLogin.fulfilled, (state, action) => {
      // set the hy jwt token in the cookie
      setJwtTokenCookie(action.payload.meta.jwt);
      // set the teladoc sdk token in the cookie
      setSdkTokenCookie(action.payload.meta.teladocSdkToken);
    
      // set the hy jwt token in the state
      state.jwt = action.payload.meta.jwt;
      // set the teladoc sdk token in the state

      state.teladocSdkToken = action.payload.meta.teladocSdkToken;
      state.loading.login = false;
      state.hasAuth = true;
      state.sessionExpired = false;
      state.entities = action?.payload?.data;
      state.meta = action.payload.meta;
      state.loading.sdk = false;
      state.hasSdk = true;
      state.requiresMFA = action?.payload?.data?.group.requiresMFA;
    });

    builder.addCase(postLogin.rejected, (state, action) => {
      removeCookies();
      state.loading.login = false;
      state.hasAuth = false;
      state.entities = {};
      state.error = action.error.message === "Rejected" ? "Invalid username or password." : action.error.message;

      state.loading.sdk = false;
      state.hasSdk = false;
      state.hasMapi = false;
    });

    builder.addCase(getSdkToken.pending, (state) => {
      // remove the teladoc sdk token from the cookie
      Cookies.remove(SDK_TOKEN_COOKIE);
      state.teladocSdkToken = null;
      state.loading.sdk = true;
    });

    builder.addCase(getSdkToken.fulfilled, (state, action) => {
      // set the teladoc sdk token in the cookie
      setSdkTokenCookie(action.payload.meta.teladocSdkToken);

      state.meta.teladocSdkToken = action.payload.meta.teladocSdkToken;
      state.teladocSdkToken = action.payload.meta.teladocSdkToken;
      state.loading.sdk = false;
      state.hasSdk = true;
    });

    builder.addCase(getSdkToken.rejected, (state, action) => {
      Cookies.remove(SDK_TOKEN_COOKIE);
      state.teladocSdkToken = null;
      state.error = action.error?.message;
      state.loading.sdk = false;
      state.hasSdk = false;
    });

    //
    builder.addCase(getMapiToken.pending, (state) => {
      // remove the mapi token from the cookie
      Cookies.remove(MAPI_TOKEN_COOKIE);
      state.meta.mapiToken = null;
      state.mapiToken = null;
      state.loading.mapi = true;
    });

    builder.addCase(getMapiToken.fulfilled, (state, action) => {
      // set the mapi token in the cookie
      setMapiTokenCookie(action.payload.authentication.jwt_token);

      state.meta.mapiToken = action.payload.authentication.jwt_token;
      state.mapiToken = action.payload.authentication.jwt_token;
      state.loading.mapi = false;
      state.hasMapi = true;
    });

    builder.addCase(getMapiToken.rejected, (state, action) => {
      Cookies.remove(MAPI_TOKEN_COOKIE);
      state.mapiToken = null;
      state.error = action.error?.message;
      state.loading.mapi = false;
      state.hasMapi = false;
    });
    //

    builder.addCase(refreshSdkToken.pending, (state) => {
      // remove the teladoc sdk token from the cookie
      Cookies.remove(SDK_TOKEN_COOKIE);
      state.teladocSdkToken = null;
    });

    builder.addCase(refreshSdkToken.fulfilled, (state, action) => {
      // set the teladoc sdk token in the cookie
      setSdkTokenCookie(action.payload.meta.teladocSdkToken);

      state.teladocSdkToken = action.payload.meta.teladocSdkToken;
      state.meta.teladocSdkToken = action.payload.meta.teladocSdkToken;
      state.loading.sdk = false;
      state.hasSdk = true;
      state.reopenFlow = true;
    });

    builder.addCase(refreshSdkToken.rejected, (state, action) => {
      Cookies.remove(SDK_TOKEN_COOKIE);
      state.teladocSdkToken = null;
      state.error = action.error?.message;
      state.loading.sdk = false;
      state.hasSdk = false;
      state.reopenFlow = false;
    });

    // builder.addCase(checkSession.pending, (state) => {
    //   console.log("checkSession.pending", state);
    // });

    // builder.addCase(checkSession.fulfilled, (state, action) => {
    //   console.log("checkSession.fulfilled", action.payload);
    // });

    // builder.addCase(checkSession.rejected, (state, action) => {
    //   console.log("checkSession.rejected", action.error);
    // });

    builder.addCase(editProfile.pending, (state) => {
      state.loading.profile = true;
      state.profileUpdated = false;
    });

    builder.addCase(editProfile.fulfilled, (state, action) => {
      state.entities.profile = action.payload.data.profile;
      state.profileError = false;
      state.profileUpdated = true;
      state.loading.profile = false;
    });

    builder.addCase(editProfile.rejected, (state, action) => {
      state.error = action.error.message;
      state.profileError = true;
      state.profileUpdated = false;
      state.loading.profile = false;
    });
    builder.addCase(samlLogin.pending, (state) => {
      Cookies.remove(SDK_TOKEN_COOKIE);
      Cookies.remove(MAPI_TOKEN_COOKIE);
      Cookies.remove(JWT_TOKEN_COOKIE);
      state.hasSdk = false;
      state.selectedPerson = undefined;
      state.hasAuth = false;
      state.loading.saml = true;
      state.error = null;
      state.isRegistered = false;
      state.hasAuth = false;
      state.meta = null;
    });
    builder.addCase(samlLogin.fulfilled, (state, action) => {
      const response = action.payload;
      // set the hy jwt token in the cookie
      if (response.meta.jwt) {
        setJwtTokenCookie(response.meta.jwt ?? "");
        state.jwt = response.meta.jwt;
      }

      if (response.meta.teladocSdkToken) {
        // set the teladoc sdk token in the cookie
        setSdkTokenCookie(response.meta.teladocSdkToken);
      
        // set the teladoc sdk token in the state
        state.teladocSdkToken = response.meta.teladocSdkToken;
      }

      state.hasAuth = response.meta.jwt && response.meta.teladocSdkToken && response.meta.isRegistered;
      state.hasSdk = response.meta.jwt && response.meta.teladocSdkToken && response.meta.isRegistered;
      state.entities.membership = action?.payload?.data.membership;
      state.entities = action?.payload.data;
      state.meta = response.meta;
      state.loading.saml = false;
    });

    builder.addCase(samlLogin.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading.saml = false;
      state.hasAuth = false;
    });

    builder.addCase(sendMFACode.pending, (state, action) => {
      state.MFAPreferredDeliveryId = action.meta.arg.communicationId;
      state.MFAPreferredDeliveryMethod = action.meta.arg.deliveryMethod;
    });
    builder.addCase(verifyMFACode.fulfilled, (state, action) => {

      if (
        action?.payload === undefined ||
        Array.isArray(action?.payload?.data?.error_messages) &&
        action?.payload?.data?.error_messages?.length === 0 ||
        Array.isArray(action?.payload?.error_messages) &&
        action?.payload?.error_messages?.length === 0
      ) {
        state.hasMFA = true;
      }
    })

    builder.addCase(verifyMFACode.rejected, (state, action) => {
      removeCookies();
      state.loading.login = false;
      state.hasAuth = false;

      state.hasMFA = false;
      state.entities = {};
      state.error = action.error.message === "Rejected" ? "Invalid PIN." : action.error.message;
      state.loading.sdk = false;
      state.hasSdk = false;
      state.hasMapi = false;
    });
  }
});

export const selectAuth = (state: RootState) => state.auth;

export const {
  setExpireCountdown,
  clearErrors,
  setEmail,
  setRememberEmail,
  setProfileUpdated,
  enableSSO,
  setAuth,
  setSdk,
  setMapi,
  setSso,
  removeEntities,
  resetLoading,
  resetSaml,
  updateMembership,
  setSessionExpired,
  clearAuth,
  setReopenFlow,
  logout
} = authSlice.actions;

export default authSlice.reducer;
