import axios from "axios";

const BASE_URL = process.env.REACT_APP_WEBSDK_URL;
const API_TOKEN = process.env.REACT_APP_API_TOKEN;

const mobileAppRequestHeader = {
    bundle_identifier: 'com.healthiestyou',
    app_version: "",
    platform: 'websdk',
    is_sdk: true,
    sdk_version: "",
};

const headers = {
    "Accept": "application/json",
    "Content-Type": "application/json",
    'Teladoc-API-Request-Token': API_TOKEN,
    'mobile-app-request': JSON.stringify(mobileAppRequestHeader),
}

const mapiAxiosInstance = axios.create({
    baseURL: BASE_URL,
    headers,
    timeout: 30000
});


export const setMapiAxiosInterceptors = (mapiToken: string) => {
    if (mapiToken) {
        mapiAxiosInstance.defaults.headers.common['Authorization'] = `Bearer ${mapiToken}`;
    }
}

export default mapiAxiosInstance;
