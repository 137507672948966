import clsx from "clsx";
import React, { useEffect } from "react";

import { ReactComponent as Warn } from "../assets/icons/c-warning-2.svg";
import { ReactComponent as Check } from "../assets/icons/n-check.svg";
import { FieldInputProps } from "formik";
import { InputType } from "zlib";

interface Props {
  field?: FieldInputProps<any>;
  password?: string;
  verifyPassword?: string;
}

const oneCapTest = new RegExp(/[A-Z]+/);
const oneLowerTest = new RegExp(/[a-z]+/);
const oneSpecialTest = new RegExp(/[!"#$%&'()* +,./:;<=>?@^_`\\[{|}\]~-]+/);
const oneNumberTest = new RegExp(/[0-9]+/);

const ValidationIcon = ({ validation }: { validation: boolean }) => {
  return validation ? (
    <Check className="inline-block m-1 fill-current" aria-hidden />
  ) : (
    <Warn className="inline-block m-1 fill-current" aria-hidden />
  );
};

const PasswordValidation = ({ field }: Props) => {
  const password = field?.value;
  const [oneCapital, setOneCapital] = React.useState(false);
  const [oneLower, setOneLower] = React.useState(false);
  const [oneSpecial, setOneSpecial] = React.useState(false);
  const [oneNumber, setOneNumber] = React.useState(false);
  const [minLength, setMinLength] = React.useState(false);

  useEffect(() => {
    setMinLength(!!(password && password.length > 8));
    setOneCapital(oneCapTest.test(password));
    setOneLower(oneLowerTest.test(password));
    setOneSpecial(oneSpecialTest.test(password));
    setOneNumber(oneNumberTest.test(password));
  }, [password, oneCapital, oneLower, oneSpecial, oneNumber, minLength]);

  const validateClassname = (valid: boolean) => clsx(valid && "text-success", "text-red-700", "flex items-center py-1");

  // @ts-ignore
  const fieldId = field.id;
  return (
    <div className="flex flex-col" id={`${fieldId || field?.name}_error`} aria-live="assertive" role="alert">
      <span className={validateClassname(oneCapital)}>
        <ValidationIcon validation={oneCapital} />
        One capital letter
      </span>
      <span className={validateClassname(oneSpecial)}>
        <ValidationIcon validation={oneSpecial} />
        One special character (e.g. $#*)
      </span>
      <span className={validateClassname(oneLower)}>
        <ValidationIcon validation={oneLower} />
        One lower case letter
      </span>
      <span className={validateClassname(minLength)}>
        <ValidationIcon validation={minLength} />
        At least 8 characters
      </span>
      <span className={validateClassname(oneNumber)}>
        <ValidationIcon validation={oneNumber} />
        One number
      </span>
    </div>
  );
};

export default PasswordValidation;
