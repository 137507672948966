import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocalStorage } from "react-use";
import * as Yup from "yup";

import { PiwikAction, PiwikCategory, PiwikName } from "../@types/common";
import postLogin from "../api/auth";
import { trackEvent } from "../api/piwik/piwikService";
import { ReactComponent as HYLogoHoriz } from "../assets/HYLogoHoriz.svg";
import { Alert } from "../components/Alert";
import Button from "../components/Button";
import { ButtonGroup } from "../components/ButtonGroup";
import ContactUs from "../components/ContactUs";
import { FormikCheckbox } from "../components/Formik/FormikCheckbox";
import { FormikTextInput } from "../components/Formik/FormikTextInput";
import StyledLink from "../components/Link";
import { useSpinner } from "../components/SpinnerProvider";
import { useAppDispatch } from "../store/store";
import { selectAuth } from "./slices/authSlice";
import { clearCheckSessionPolling } from "./slices/intervalsSlice";
import PageTitleComponent from "../components/PageTitleComponent";
import { CrossIcon } from "../components/Icons";

const Login = () => {
  const [value, setValue, remove] = useLocalStorage<string>("rememberEmail");
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { hasAuth, error, loading } = useSelector(selectAuth);
  const { showSpinner } = useSpinner();
  const [isBannerShown, setIsBannerShown] = useState(true);
  useEffect(() => {
    dispatch(clearCheckSessionPolling());
  }, [dispatch]);

  useEffect(() => {
    showSpinner({
      show: loading.login
    });
  }, [loading.login]);

  useEffect(() => {
    if (hasAuth) {
      history.push("/MFA");
    }
  }, [hasAuth]);

  const addTrackEvent = (name: PiwikName) => {
    trackEvent({
      category: PiwikCategory.BUTTONS,
      action: PiwikAction.CLICKED,
      name
    });
  };

  const handleSubmit = async ({ email, password, ...rest }: any) => {
    rest.rememberEmail ? setValue(email) : remove();
    addTrackEvent(PiwikName.LOGIN);
    const logInResult = await dispatch(postLogin({ email, password }));
    dispatch(logInResult);
  };

  // flex flex-col justify-center w-full h-full max-w-md mx-auto

  return (
    <div
      data-testid="loginContainer"
      className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0"
    >
      {isBannerShown && (
        <div className="pt-3 pb-3 bg-golden-yellow">
          Worried you might have COVID-19?{" "}
          <a
            className="font-bold text-black-mirage underline"
            href="https://www.teladoc.com/coronavirus/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Get important info here
          </a>
          <span style={{ float: "right" }} className=" cursor-pointer" onClick={() => setIsBannerShown(!isBannerShown)}>
            <CrossIcon iconFill="#171717" className="w-4 h-4 text-black mr-2" />
          </span>
        </div>
      )}

      <PageTitleComponent title="Login" />
      {/* This element is to trick the browser into centering the modal contents. */}
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        &#8203;
      </span>
      <Formik
        initialValues={{
          email: value,
          password: "",
          rememberEmail: value && value.length > 1
        }}
        validateOnBlur={false}
        validateOnChange={false}
        enableReinitialize={true}
        validationSchema={Yup.object({
          email: Yup.string().email().required("Required"),
          password: Yup.string().required("Required")
        })}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(true);
          handleSubmit(values).finally(() => setSubmitting(false));
        }}
      >
        {({ isSubmitting, isValidating }) => (
          <Form className="inline-block px-6 pt-6 pb-8 overflow-hidden text-left md:px-8 sm:my-8 sm:align-middle sm:max-w-md sm:w-full">
            <HYLogoHoriz className="w-full p-10" />
            <h1 className="mb-6 font-semibold text-2xl">Login to your account</h1>
            {error && <Alert error={error} />}
            <div className="mb-4">
              <FormikTextInput
                data-testid="emailInput"
                label="Email Address"
                name="email"
                type="email"
                placeholder="Please enter email address"
              />
            </div>
            <div className="mb-2">
              <FormikTextInput
                data-testid="passwordInput"
                label="Password"
                name="password"
                type="password"
                placeholder="Please enter password"
              />
            </div>
            <div className="flex items-center justify-between">
              <FormikCheckbox name="rememberEmail">
                Remember email
              </FormikCheckbox>
              <StyledLink to="/forgot-password">Forgot your password?</StyledLink>
            </div>
            <div className="flex flex-col">
              <ButtonGroup>
                <Button
                  aria-label="Start Registration"
                  buttonType="secondary"
                  disabled={isSubmitting || isValidating}
                  onClick={() => {
                    addTrackEvent(PiwikName.REGISTER);
                    history.push("/register");
                  }}
                >
                  Register Now
                </Button>
                <Button
                  data-testid="loginButton"
                  buttonType="primary"
                  disabled={isSubmitting || isValidating}
                  type="submit"
                  name="next"
                >
                  Login
                </Button>
              </ButtonGroup>
              <ContactUs />
              <div className="pb-3 border-solid border-t-1 border-gray-default" />
              <p className="pb-2 text-xs leading-tight text-justify font-effra text-dark-gray">
                All content of this system and its associated sub-systems are PROPRIETARY INFORMATION and remain the
                sole and exclusive property of this company. This system may be accessed and used by authorized
                personnel only. Authorized users may only perform authorized activities and may not exceed the limits of
                such authorization. Disclosure of information found in this system for any unauthorized use is *STRICTLY
                PROHIBITED*. All activities on this system are subject to monitoring. Intentional misuse of this system
                can result in disciplinary action or criminal prosecution.{" "}
              </p>
              <p className="text-xs leading-tight text-justify font-effra text-dark-gray">
                Your continued use of this portal indicates your agreement to the following policies:{" "}
                <a
                  href="https://www.teladoc.com/notice-of-privacy-practices/"
                  className="text-xs leading-tight"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notice of Privacy Practices
                </a>
                {", "}
                <a
                  href="https://www.teladochealth.com/legal-disclaimer/"
                  className="text-xs leading-tight"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Legal Disclaimer
                </a>{" "}
                and{" "}
                <a
                  href="https://www.teladochealth.com/notice-of-non-discrimination/"
                  className="text-xs leading-tight"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Notice of Non-Discrimination and Language Assistance
                </a>
                .
              </p>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
