import React, { createContext, useCallback, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { logout as logoutFromWebSDK } from "@td/websdk";

import { checkSession } from "./api/auth";
import { logout, selectAuth, setAuth, setSdk, setSso, setMapi } from "./containers/slices/authSlice";
import { logoutSdk } from "./containers/slices/websdkSlice";
import {
  clearCheckSessionPolling,
  clearSdkPolling,
  setCheckSessionPolling,
  clearTokenLoginErrorCount,
  clearEligibilityError,
  selectIntervals
} from "./containers/slices/intervalsSlice";
import { useAppDispatch } from "./store/store";
import { ErrorMessages } from "./@types/common";
import { has, JWT_TOKEN_COOKIE, MAPI_TOKEN_COOKIE, SDK_TOKEN_COOKIE, SSO_TOKEN_COOKIE } from "./utils/cookies";
import { logoutMapi } from "./containers/slices/mapiSlice";

interface AuthProviderData {
  signOut: () => void;
}

const SESSION_CHECK_INTERVAL = 15 * 1000; // 25 seconds

const AuthContext = createContext<AuthProviderData>({
  signOut: () => {}
});

export function AuthProvider({ children }) {
  const dispatch = useAppDispatch();
  const authSelector = useSelector(selectAuth);
  const intervalSelector = useSelector(selectIntervals);
  const history = useHistory();
  const location = useLocation();

  const clearPolling = useCallback(() => {
    dispatch(clearCheckSessionPolling());
  }, [dispatch]);

  const checkCookies = useCallback(() => {
    const hasAuth = has(JWT_TOKEN_COOKIE);
    const hasSdk = has(SDK_TOKEN_COOKIE);
    const hasMapi = has(MAPI_TOKEN_COOKIE);
    const hasSso = has(SSO_TOKEN_COOKIE);

    if (authSelector.hasAuth !== hasAuth) dispatch(setAuth(hasAuth));
    if (authSelector.hasSdk !== hasSdk) dispatch(setSdk(hasSdk));
    if (authSelector.hasMapi !== hasMapi) dispatch(setMapi(hasMapi));
    if (authSelector.hasSso !== hasSso) dispatch(setSso(hasSso));
  }, [authSelector.hasAuth, authSelector.hasSdk, authSelector.hasSso, dispatch]);

  const clearState = useCallback(() => {
    // clears all polling intervals & the sdk state
    logoutFromWebSDK();
    dispatch(logoutSdk());
    dispatch(logoutMapi());
    dispatch(clearSdkPolling());
    dispatch(clearTokenLoginErrorCount());
    dispatch(clearEligibilityError());

    // clears the auth state
    dispatch(logout());
    dispatch(clearCheckSessionPolling());

    // clear the session polling interval
    dispatch(clearCheckSessionPolling());
  }, [dispatch]);

  const signOut = () => {
    clearState();
    history.push("/logout");
  };

  const signIn = useCallback(() => {
    clearState();
    history.push("/login");
  }, [history, clearState]);

  useEffect(() => {
    if (!intervalSelector.sessionInterval && authSelector.hasAuth) {
      const interval = setInterval(() => {
        dispatch(checkSession());
      }, SESSION_CHECK_INTERVAL);
      dispatch(setCheckSessionPolling(interval));
    }
  }, [dispatch, authSelector.hasAuth, intervalSelector.sessionInterval]);

  useEffect(() => {
    if (!authSelector.sessionExpired && authSelector.error === ErrorMessages.UNAUTHORIZED) {
      signIn();
    }
  }, [authSelector.sessionExpired, authSelector.error, signIn]);

  useEffect(() => {
    const { profile, group, familyMember, membership } = authSelector.entities;
    if ((!profile || !group || !familyMember || !membership) && authSelector.hasAuth) {
      signIn();
    }
  }, [authSelector.entities, authSelector.hasAuth, checkCookies, history, signIn]);

  useEffect(() => {
    if (authSelector.sessionExpired) {
      clearState();
      history.push("/session-expired");
    }
  }, [authSelector.sessionExpired, clearState, history]);

  useEffect(() => {
    checkCookies();
  }, [location.pathname, checkCookies]);

  return (
    <AuthContext.Provider
      value={{
        signOut
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useUser can only be used inside AuthProvider");
  }
  return context;
};
