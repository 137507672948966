import React, { useEffect } from "react";
import { useAppDispatch } from "../store/store";
import { getFeatureToggle } from "../api/featureToggles";
import { addConfigValue } from "../containers/slices/configsSlice";

function FeaturesToggleProvider({ children }) {
  const dispatch = useAppDispatch();

  const fetchFeatureToggles = async () => {
    const response: any = await dispatch(getFeatureToggle("swordMigrationDate"));
    if (response.error) {
      console.error(response.error);

      return;
    }
    await dispatch(addConfigValue({ name: response?.payload?.name, value: response?.payload?.startDate }));
  };

  useEffect(() => {
    fetchFeatureToggles();
  }, []);

  return children;
}

export default FeaturesToggleProvider;
