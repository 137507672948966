import { createSlice } from "@reduxjs/toolkit";

import csrTicket from "../../api/csr";
import { RootState } from "../../store/rootReducer";

export interface CSRTicketRequest {
  name: string;
  email: string;
  subject: string;
  comment: string;
  error?: any;
  loading: boolean;
  csrTicketSent: boolean;
}

const initialState: CSRTicketRequest = {
  name: "",
  email: "",
  subject: "",
  comment: "",
  error: null,
  loading: false,
  csrTicketSent: false
};

export const csrTicketSlice = createSlice({
  name: "csrTicket",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
      state.csrTicketSent = false;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(csrTicket.pending, (state, action) => {
      state.csrTicketSent = false;
      state.loading = true;
    });
    builder.addCase(csrTicket.fulfilled, (state, action) => {
      state.csrTicketSent = true;
      state.loading = true;
    });
    builder.addCase(csrTicket.rejected, (state, action) => {
      state.csrTicketSent = false;
      state.error = action.payload;
      state.loading = false;
    });
  }
});
export const selectCsrTicket = (state: RootState) => state.csrTicket;

export const { clearErrors } = csrTicketSlice.actions;

export default csrTicketSlice.reducer;
