import { createAsyncThunk } from "@reduxjs/toolkit";

import { UserProfile } from "../../containers/slices/authSlice";
import hyApi from "../services/hyApiService";

export const editProfile = createAsyncThunk("auth/editProfile", async (user: Partial<UserProfile>) => {
  const { id, ...userData } = user;
  const response = await hyApi.put(`api/profiles/${id}`, userData);

  return response.data;
});

export default editProfile;
