import React from "react";

function HospitalIcon({ className = "" }) {
  return (
    <svg
      className={className}
      width="18px"
      height="17px"
      viewBox="0 0 18 17"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>healthcare / facility / hospital-32</title>
      <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="find-results" transform="translate(-13.000000, -2.000000)" fill="#005C95">
          <g id="healthcare-/-facility-/-hospital-32" transform="translate(14.000000, 1.000000)">
            <path
              d="M5.17647058,1.02941173 L5.11556434,1.03200274 C4.75423119,1.06288297 4.47058822,1.36596434 4.47058822,1.73529409 L4.47043696,5.97043696 L0.235294085,5.97058822 C-0.154553975,5.97058822 -0.470588271,6.28662252 -0.470588271,6.67647058 L-0.470588271,12.3235294 L-0.467997261,12.3844357 C-0.437117032,12.7457688 -0.134035656,13.0294117 0.235294085,13.0294117 L4.47043696,13.0290084 L4.47058822,17.2647059 C4.47058822,17.654554 4.78662252,17.9705883 5.17647058,17.9705883 L10.8235294,17.9705883 L10.8844357,17.9679973 C11.2457688,17.937117 11.5294117,17.6340357 11.5294117,17.2647059 L11.5290084,13.0290084 L15.7647059,13.0294117 C16.154554,13.0294117 16.4705883,12.7133775 16.4705883,12.3235294 L16.4705883,6.67647058 L16.4679973,6.61556434 C16.437117,6.25423119 16.1340357,5.97058822 15.7647059,5.97058822 L11.5290084,5.97043696 L11.5294117,1.73529409 C11.5294117,1.34544602 11.2133775,1.02941173 10.8235294,1.02941173 L5.17647058,1.02941173 Z M10.1172941,2.44115123 L10.1176471,6.67647058 L10.1202381,6.73737681 C10.1511183,7.09870996 10.4541997,7.38235293 10.8235294,7.38235293 L15.0587227,7.38215125 L15.0587227,11.6172941 L10.8235294,11.6176471 L10.7626232,11.6202381 C10.40129,11.6511183 10.1176471,11.9541997 10.1176471,12.3235294 L10.1172941,16.5587227 L5.88215125,16.5587227 L5.88235293,12.3235294 L5.87976192,12.2626232 C5.84888169,11.90129 5.54580032,11.6176471 5.17647058,11.6176471 L0.941151231,11.6172941 L0.941151231,7.38215125 L5.17647058,7.38235293 L5.23737681,7.37976192 C5.59870996,7.34888169 5.88235293,7.04580032 5.88235293,6.67647058 L5.88215125,2.44115123 L10.1172941,2.44115123 Z"
              id="hospital-32"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default HospitalIcon;
