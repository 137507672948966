import React, { useState, useEffect } from "react";
import { ReactComponent as HYLogoHoriz } from "../../assets/HYLogoHoriz.svg";
import StyledLink from "../../components/Link";
import { useHistory } from "react-router";
import PageTitleComponent from "../../components/PageTitleComponent";
import { selectAuth } from "../slices/authSlice";
import { useSelector } from "react-redux";

const SsoError = () => {
  const [countdown, setCountdown] = useState<number>(10);
  const history = useHistory();
  const { error } = useSelector(selectAuth);

  const errorMessage = error ?? "Looks like we had trouble locating your account.";
  setTimeout(() => {
    setCountdown(countdown - 1);
  }, 1000);

  useEffect(() => {
    if (countdown === 0) {
      history.push("/login");
    }
  }, [countdown]);

  return (
    <div className="flex flex-col justify-center w-full h-full max-w-md mx-auto font-effra">
      <div className="flex flex-row items-center">
        <PageTitleComponent title="SSO Error" />
        <HYLogoHoriz className="w-full p-10" />
        <h3 className="py-5">MEMBER PORTAL</h3>
      </div>
      <h6 className="text-red-600">Oh no!</h6>
      <h6 className="pb-5">
        {errorMessage} You will be redirected to the login page in {countdown}
      </h6>
      <div className="w-24">
        <StyledLink to="/login">Sign in</StyledLink>
      </div>

      <p className="pt-10">
        All content of this system and its associated sub-systems are PROPRIETARY INFORMATION and remain the sole and
        exclusive property of this company. This system may be accessed and used by authorized personnel only.
        Authorized users may only perform authorized activities and may not exceed the limits of such authorization.
        Disclosure of information found in this system for any unauthorized use is *STRICTLY PROHIBITED*. All activities
        on this system are subject to monitoring. Intentional misuse of this system can result in disciplinary action or
        criminal prosecution.
      </p>
    </div>
  );
};

export default SsoError;
