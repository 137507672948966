import { Form, Formik, Field } from "formik";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { getPersons, memberLookup, studentLookup } from "../../api/registration";
import { ReactComponent as HYlogo } from "../../assets/HYLogoHoriz.svg";
import Button from "../../components/Button";
import { ButtonGroup } from "../../components/ButtonGroup";
import { FormikDatePicker } from "../../components/DatePicker/FormikDatePicker";
import { FormikSelect } from "../../components/Formik/FormikSelect";
import { FormikTextInput } from "../../components/Formik/FormikTextInput";
import SelectPerson from "../../components/Lookup/SelectPerson";
import Overlay from "../../components/Overlay";
import { useAppDispatch } from "../../store/store";
import { readableError } from "../../utils/regApiErrorHelper";
import { cancelLookup, clearErrors, selectRegistration, setSelectedPerson } from "../slices/registrationSlice";
import ContactUs from "../../components/ContactUs";
import { useSpinner } from "../../components/SpinnerProvider";
import { FormikMaskedInput } from "../../components/Formik/FormikMaskedInput";
import { zipcodeMask } from "../../utils/validationHelper";
import { FormFieldError } from "../../components/FormFields/FieldError";
import PageTitleComponent from "../../components/PageTitleComponent";

const Registration = () => {
  const { membershipId, persons, apiError, loading } = useSelector(selectRegistration);
  const [showFirstDialog, setShowFirstDialog] = useState(false);
  const [showSecondDialog, setShowSecondDialog] = useState(false);
  const [registrationType, setRegistrationType] = useState("");
  const [isStudentPage] = useState<boolean>(window.location.href.toLocaleLowerCase().indexOf("student") > -1);

  const history = useHistory();
  const dispatch = useAppDispatch();
  const { showSpinner } = useSpinner();

  useEffect(() => {
    showSpinner({
      show: loading
    });
  }, [loading]);

  useEffect(() => {
    if (membershipId) {
      dispatch(getPersons({ membershipId }));
    }
  }, [membershipId]);

  const submitForm = async (values: any) => {
    setRegistrationType(values.registrationType);
    const dob = moment(values.dob).format("MM/DD/YYYY").toString();
    if (values.registrationType !== "school") {
      dispatch(
        memberLookup({
          last: values.lastName.toLowerCase(),
          dob,
          zip: values.zip
        })
      );
    } else {
      dispatch(
        studentLookup({
          first: values.firstName.toLowerCase(),
          last: values.lastName.toLowerCase(),
          dob,
          studentId: values.studentId
        })
      );
    }
  };

  const handleSelected = (result) => {
    dispatch(setSelectedPerson(result));
  };

  const handleCancel = (e: { preventDefault: () => void }) => {
    dispatch(cancelLookup());
    e.preventDefault();
    history.push("/login");
  };

  useEffect(() => {
    dispatch(clearErrors());
  }, []);

  useEffect(() => {
    if (apiError && registrationType === "school") {
      setShowSecondDialog(true);
      resetError();
    }
  }, [apiError, registrationType]);

  const resetError = () => {
    if (apiError) dispatch(clearErrors());
  };

  const payPerVisit = () => {
    history.push("/register/pay-per-visit");
  };

  return (
    <>
      <div
        className="flex justify-center w-full max-w-md min-h-screen mx-auto font-effra"
        data-testid="registerContainer"
      >
        <PageTitleComponent title="Register" />
        <Overlay active={Array.isArray(persons)}>
          <SelectPerson handlesSelected={handleSelected} persons={persons} />
        </Overlay>
        <div className={"flex-col mx-auto w-full"}>
          <HYlogo className="inline-block h-16 mt-10 ml-4 fill-current" title="HY Logo" />
          <h1
            className="flex items-center justify-between max-w-sm my-5 ml-4 text-2xl font-light text-grey-darkest"
            data-testid="titleText"
          >
            Register
          </h1>
          <div className="flex-1">
            <Formik
              initialValues={{
                registrationType: isStudentPage ? "school" : "",
                firstName: "",
                lastName: "",
                dob: "",
                zip: "",
                studentId: ""
              }}
              enableReinitialize={true}
              validationSchema={Yup.object({
                registrationType: Yup.string().required("Registration type is required"),
                firstName: Yup.string().when("registrationType", {
                  is: "school",
                  then: Yup.string().required("First name is required"),
                  otherwise: Yup.string()
                }),
                lastName: Yup.string().required("Last name is required"),
                dob: Yup.date()
                  .max(new Date(), "Date cannot be in the future")
                  .typeError("Require valid date")
                  .required("Date of birth is required"),
                zip: Yup.string().when("registrationType", {
                  is: "school",
                  then: Yup.string(),
                  otherwise: Yup.string()
                    .required("Zip code is required")
                    .max(5, "Zip must be 5 digits")
                    .min(5, "Zip must be 5 digits")
                }),
                studentId: Yup.string().when("registrationType", {
                  is: "school",
                  then: Yup.string().required("ID is required"),
                  otherwise: Yup.string()
                })
              })}
              onSubmit={(values, { setSubmitting }) => {
                submitForm(values);
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, isSubmitting, isValid }) => (
                <Form className="max-w-sm mx-4" onChange={resetError}>
                  <div className="mb-4">
                    <p
                      aria-hidden
                      className="mr-4 -mb-5 text-sm text-right font-effra text-davys-grey sm:mr-4 md:mr-4 lg:mr-0"
                    >
                      * Required
                    </p>
                    <FormikSelect
                      type="select"
                      isRequired={true}
                      label="Enrolled as"
                      placeholder="Select one"
                      data-testid="registerType"
                      name="registrationType"
                      options={[
                        { label: "Employee", value: "employer" },
                        { label: "Student", value: "school" },
                        { label: "Other", value: "other" }
                      ]}
                    />
                  </div>

                  {values.registrationType === "school" && (
                    <div className="mb-4">
                      <FormikTextInput
                        type="text"
                        isRequired={true}
                        name="firstName"
                        label="Primary member's first name"
                        placeholder="Please enter first name"
                        data-testid="first"
                      />
                    </div>
                  )}

                  <div className="mb-4">
                    <FormikTextInput
                      type="text"
                      isRequired={true}
                      name="lastName"
                      label="Primary member's last name"
                      placeholder="Please enter last name"
                      data-testid="last"
                    />
                  </div>

                  <div className="mb-4">
                    <FormikDatePicker
                      name="dob"
                      isRequired={true}
                      label="Primary member's date of birth"
                      data-testid="datePicker"
                    />
                  </div>

                  {values.registrationType !== "school" && (
                    <div className="mb-4">
                      <Field name="zip" type="text">
                        {({ field, form, meta }) => (
                          <>
                            <FormikMaskedInput
                              label="Primary member's zip code"
                              meta={meta}
                              isRequired={true}
                              mask={zipcodeMask}
                              field={field}
                              {...form}
                            />
                            <FormFieldError field={field} meta={meta} />
                          </>
                        )}
                      </Field>
                    </div>
                  )}

                  {values.registrationType === "school" && (
                    <div className="mb-4">
                      <FormikTextInput
                        type="text"
                        isRequired={true}
                        placeholder="Please enter ID"
                        data-testid="studentId"
                        label="Student, School, Student resources, Civil ID or SACM ID"
                        name="studentId"
                      />
                    </div>
                  )}
                  {apiError && values.registrationType !== "school" && isValid && (
                    <p className="p-4 text-center text-red-500" data-testid="error">
                      {readableError(apiError, "employer")}
                    </p>
                  )}

                  {apiError && values.registrationType === "school" && isValid && (
                    <div>
                      <p className="p-4 text-center text-red-500" data-testid="error">
                        {readableError(apiError, "school")}
                      </p>
                    </div>
                  )}
                  {values.registrationType === "school" && (
                    <p className="p-4 text-center" data-testid="error">
                      Not a covered student?{" "}
                      <button
                        type="button"
                        className="font-bold text-primary-blue"
                        onClick={() => setShowFirstDialog(true)}
                      >
                        Tap here
                      </button>{" "}
                      to create a pay-per-visit membership
                    </p>
                  )}
                  <ButtonGroup>
                    <Button
                      type="reset"
                      buttonType="secondary"
                      onClick={handleCancel}
                      disabled={isSubmitting}
                      name="cancel"
                    >
                      Cancel
                    </Button>
                    <Button buttonType="primary" disabled={isSubmitting} type="submit" name="next">
                      Next
                    </Button>
                  </ButtonGroup>
                  <ContactUs />
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
      <Overlay active={showFirstDialog}>
        <div className="z-50 flex flex-col self-center h-auto px-10 py-4 mx-auto bg-white rounded w-md" role="dialog">
          <div className="flex flex-col items-center p-2 space-y-2">
            <h2>ATTENTION</h2>
            <p>Clicking continue will create a pay-per-visit membership.</p>
            <p>By registering you acknowledge and accept these terms.</p>
            <p>If you should be covered by a 3rd party, a health plan or </p>
            <p>another individual, please click cancel.</p>
            <p>For questions regarding your account please call 866-703-1259.</p>
          </div>
          <ButtonGroup>
            <Button buttonType="secondary" onClick={() => setShowFirstDialog(false)}>
              Cancel
            </Button>
            <Button buttonType="primary" onClick={payPerVisit}>
              Continue
            </Button>
          </ButtonGroup>
        </div>
      </Overlay>
      <Overlay active={showSecondDialog}>
        <div className="z-50 flex flex-col self-center h-auto px-10 py-4 mx-auto bg-white rounded w-md">
          <div className="flex flex-col items-center p-2 space-y-2">
            <h1>ATTENTION</h1>
            <p>We were unable to locate your account.</p>
            <p>Please verify your information and try again.</p>
            <p>For assistance please call</p>
            <p>866-703-1259</p>
            <p>
              Not a covered student?{" "}
              <button
                className="font-bold text-primary-blue"
                onClick={() => {
                  setShowFirstDialog(true);
                  setShowSecondDialog(false);
                }}
              >
                Tap here
              </button>{" "}
              to create a pay-per-visit
            </p>
            <p className="pb-2">membership.</p>

            <Button buttonType="secondary" onClick={() => setShowSecondDialog(false)}>
              Close
            </Button>
          </div>
        </div>
      </Overlay>
    </>
  );
};

export default Registration;
