import { Item, Section } from "@react-stately/collections";
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import { ReactComponent as HYLogoHoriz } from "../../assets/HYLogoHoriz.svg";
import { MenuButton } from "./Menu";
import { NavItem } from "./NavItem";
import MessageIcon from "../MessageIcon";
import { useMatchMedia } from "../../utils/useMatchMedia";
import { selectAuth } from "../../containers/slices/authSlice";
import { Feature, hasFeature } from "../../utils/featureHelper";
import { feature } from "./FeatureTypes";

interface NavRoute {
  name: string;
  label: string;
  path: string;
  hidden?: boolean;
}
const DROPDOWN_ROUTES: NavRoute[] = [
  { name: "accountSettings", label: "Account Settings", path: "/account-settings" },
  { name: "support", label: "Support", path: "/support" },
  { name: "invoices", label: "Invoices", path: "/invoices" },
  { name: "favorites", label: "Favorites", path: "/favorites" },
  { name: "logout", label: "Logout", path: "/logout" }
];

const NAV_BAR_ROUTES: NavRoute[] = [
  { name: "dashboard", label: "Home", path: "/dashboard" },
  { name: "requestCare", label: "Request Care", path: "/request-a-visit" },
  { name: "medicalInfo", label: "Medical Info", path: "/medical-info" },
  { name: "myFamily", label: "My Family", path: "/my-family" },
  { name: "inbox", label: "Inbox", path: "/inbox" },
];

const ALL_ROUTES: NavRoute[] = NAV_BAR_ROUTES.concat(DROPDOWN_ROUTES);

// @TODO Create separate file with default breakpoints
// default breakpoint could be found in tailwind config file
const SM = "767px";

export const NavBar = ({
  first,
  last,
  unviewedMessages
}: {
  first: string;
  last: string;
  unviewedMessages: number;
}) => {
  const isSMScreen = useMatchMedia(`(max-width: ${SM})`);
  const history = useHistory();
  const location = useLocation();

  const {
    entities: { group }
  } = useSelector(selectAuth);

  const toggleRoutesVisibility = (routes) => {
    return routes.filter((route) => {
      switch (route.path) {
        case "/request-a-visit":
          return hasFeature(group, Feature.telehealth);
        case "/favorites":
          const groupFeatures = Object.fromEntries((group?.features || []).map((key) => [key, true]));
          return !!(groupFeatures[feature.wellness] || groupFeatures[feature.rx] || groupFeatures[feature.providers]);
        default:
          return true;
      }
    });
  };

  const [dropdownItems, setDropdownItems] = useState(() => toggleRoutesVisibility(DROPDOWN_ROUTES));
  const mainRouteItems = toggleRoutesVisibility(NAV_BAR_ROUTES);

  const handleDropDownItemClick = (key: string) => {
    history.push(key);
  };

  useEffect(() => {
    setDropdownItems(toggleRoutesVisibility(isSMScreen ? ALL_ROUTES : DROPDOWN_ROUTES));
  }, [isSMScreen]);

  return (
    <nav
      data-testid="NavBar"
      className="z-30 w-full h-auto bg-white shadow-md"
      aria-label="HealthiestYou navigation bar"
    >
      <div className="max-w-screen-xl p-4 mx-auto bg-white lg:py-0 md:px-8">
        <ul
          className="flex items-center justify-between w-full list-none"
          role="menubar"
          aria-label="HealthiestYou navigation bar"
        >
          <li className="p-1 mr-1" role="menuitem">
            <Link to="/" aria-label="Navigate to the Homepage">
              <HYLogoHoriz aria-hidden="true" title="HY Logo" className="w-40 md:w-48" />
            </Link>
          </li>
          <li className="flex-1 hidden mx-10 space-x-3 justify-evenly md:flex" role="menu">
            {mainRouteItems.map((route, index) => (
              <NavItem key={index} name={route.name} path={route.path} isActive={route.path === location.pathname}>
                {route.name === "messageCenter" ? (
                  <div className="flex items-center space-x-1">
                    <p>Messages</p>
                    <MessageIcon messageNumber={unviewedMessages} />
                  </div>
                ) : (
                  route.label
                )}
              </NavItem>
            ))}
          </li>
          <li className="relative flex" role="menuitem">
            <MenuButton onAction={handleDropDownItemClick} label={`${first} ${last}`}>
              {dropdownItems.map((item) => (
                <Section key={item.path}>
                  <Item key={item.path}>{item.label}</Item>
                </Section>
              ))}
            </MenuButton>
          </li>
        </ul>
      </div>
    </nav>
  );
};
