import { combineReducers } from "@reduxjs/toolkit";

import {
  authReducer,
  csrTicketReducer,
  forgotPasswordReducer,
  registrationReducer,
  webSdkReducer,
  intervalsReducer,
  configsReducer,
  mapiReducer
} from "../containers/slices";

const rootReducer = combineReducers({
  auth: authReducer,
  registration: registrationReducer,
  forgotPassword: forgotPasswordReducer,
  websdk: webSdkReducer,
  csrTicket: csrTicketReducer,
  intervals: intervalsReducer,
  configs: configsReducer,
  mapi: mapiReducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
