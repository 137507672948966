import React, { useState } from "react";
import Input from "../../components/FindPage/Input";
import Select from "../../components/FindPage/Select";
import { ReactComponent as FilterSVG } from "../../assets/icons/controls-setup-preferences.svg";
import { facilityTypes } from "../../utils/facility";
import { radiusOptions } from "../../utils/providers";
import ZipCodeInput from "../../components/ZipCodeInput";

function FacilityFilters({ facilitiesFilters, onFieldChange, onTypeChange, clearFilters, errors }) {
  const [isFilterOpened, setIsFilterOpened] = useState(true);
  return (
    <div className="w-full rounded-lg border-1 md:border-0 border-pale-grey-200 mb-5">
      <div
        onClick={() => setIsFilterOpened((prev) => !prev)}
        className="flex justify-between text-primary-blue px-3 items-center rounded-lg h-12  md:hidden"
      >
        <span className="cursor-pointer">Find and filter</span>
        <FilterSVG />
      </div>
      <form id="containerForm w-full">
        <div className={`w-full bg-off-white rounded-lg p-4 mb-2 ${!isFilterOpened && "hidden"} md:flex`}>
          <div className="w-full flex flex-col">
            <div className="flex flex-wrap md:justify-between gap-3 items-center mb-6 w-full">
              <ZipCodeInput
                value={facilitiesFilters.zip}
                errors={errors?.message}
                errorOnEmpty="RequiredZipCode"
                onChange={(val) => {
                  onFieldChange(val);
                }}
                className="sm:w-32"
                header="Zip"
                id="zip"
                placeholder="Enter zip"
              />
              <Select
                id="radius"
                header="Radius (mi)"
                className="xs:w-full sm:w-24"
                selectedVal={facilitiesFilters.radius}
                options={radiusOptions}
                handleChange={(radius) => {
                  onFieldChange({ radius });
                }}
                handleSubmit={() => {}}
              />
              <div className="flex flex-col sm:flex-row justify-between w-full md:w-auto mr-5">
                {facilityTypes.map(({ value, label }) => (
                  <button
                    key={value}
                    className={`text-dark-gray md:w-1/4 w-full text-base font-medium leading-none ${
                      facilitiesFilters.kind.includes(label) ? "bg-pale-grey-10" : "bg-white"
                    } hover:bg-info-light border-primary-500 border-2 lg:w-auto p-1 h-10 mt-1 mx-2 `}
                    type="button"
                    onClick={() => {
                      onTypeChange(label);
                    }}
                  >
                    {label}
                  </button>
                ))}
              </div>
            </div>
            <div className="flex justify-end float-right mr-5 opacity-75">
              <button
                className="cursor-pointer hover:text-primary-hover text-primary-blue"
                onClick={clearFilters}
                type="button"
              >
                Clear filters
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default FacilityFilters;
