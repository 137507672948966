import React from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { selectAuth } from "../../containers/slices/authSlice";
import Footer from "../Footer";
import { NavBar } from "../Nav/NavBar";
import { selectWebSdk } from "../../containers/slices/websdkSlice";
import clsx from "clsx";
import { selectRegistration } from "../../containers/slices/registrationSlice";

interface LayoutProps {
  children?: React.ReactNode;
}

const Layout = ({ children }: React.PropsWithChildren<LayoutProps>) => {
  const {
    hasAuth,
    requiresMFA,
    hasMFA,
    entities: { profile }
  } = useSelector(selectAuth);

  const {
    newProfileCreated    
  } = useSelector(selectRegistration);

  const { unviewedMessages } = useSelector(selectWebSdk);
  const location = useLocation();

  const rememberDeviceMFA: boolean = typeof localStorage.getItem('rememberDeviceMFA') === "string" ? JSON.parse(localStorage.getItem('rememberDeviceMFA') as string) : undefined;

  const validMFA = requiresMFA === hasMFA || requiresMFA === rememberDeviceMFA;

  return (
    <div className="flex flex-col h-screen">
      {((hasAuth && validMFA) || (hasAuth && newProfileCreated)) && <NavBar {...profile} unviewedMessages={unviewedMessages} />}
      <main
        id="content"
        className={clsx(
          (hasAuth && validMFA) && "bg-gray-background",
          "flex flex-col flex-1 w-full mx-auto overflow-y-auto bg-white"
        )}
      >
        {children}
      </main>
      {"/login" !== location.pathname && <Footer />}
    </div>
  );
};

export default Layout;
