import React, { useEffect, useRef } from "react";
import { ReactComponent as PhoneSVG } from "../../assets/icons/phone.svg";
interface data {
  id: number;
  header: string | null;
  description: string | null;
  address: string;
  phone: string | null;
  distance: number;
  profile: any;
}

interface FindPageListItemInterface {
  data: data;
  onSelect: any;
  // addToFavorite: any;
  favoriteList: any;
  onMouseEnter: any;
  onMouseLeave: any;
  active: boolean;
  selected: boolean;
  IconComponent?: any;
}

const FindPageListItem = ({
  data,
  onSelect,
  // addToFavorite,
  favoriteList = [],
  onMouseEnter,
  onMouseLeave,
  active,
  selected,
  IconComponent = <></>
}: FindPageListItemInterface) => {
  // const [isFavorite, setFavorite] = useState(
  //     !!(favoriteList || []).find(
  //         (provider: any) => data.id === provider.data.id
  //     )
  // );

  const { header, description, address, phone, distance, profile } = data;
  const el: any = useRef();

  useEffect(() => {
    if (active && el.current) {
      el.current.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [active]);

  return (
    <div
      ref={el}
      className={`bbw py-4 px-2 border-b-2 ${active || selected ? "bg-pale-grey-10" : ""}`}
      onMouseEnter={() => onMouseEnter(data.id)}
      onMouseLeave={() => onMouseLeave()}
    >
      <div className="w-full">
        <div className="flex justify-between">
          {profile ? (
            header ? (
              <button className="text-primary-blue font-semibold mb-1 cursor-pointer" onClick={() => onSelect(data)}>
                {header}
              </button>
            ) : (
              <p className="mb-1 ">{header || description}</p>
            )
          ) : (
            <p className="mb-1 ">{header || description}</p>
          )}
          <p className="text-pale-grey-200 whitespace-no-wrap">{`${distance.toFixed(2)} mi`}</p>
        </div>
        {header && profile && <p className="mb-1">{description}</p>}
        <p className="text-primary-blue mb-1">{address}</p>
        <div className="">
          <div>
            {phone ? (
              <>
                <p className="inline text-primary-blue w-auto mr-3">{phone}</p>
                <a className="mr-8 text-lg text-primary-blue" href={`tel:${phone}`}>
                  Call <PhoneSVG className="inline-block w-4 h-4 mb-1 ml-1" />
                </a>
              </>
            ) : null}
          </div>
          {/* {addFavoriteIcon(isFavorite, data, setFavorite)} */}
        </div>
      </div>
    </div>
  );
};

export default FindPageListItem;
