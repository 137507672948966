import React from "react";
import MaskedInput from "react-text-mask";
import clsx from "clsx";
import { mergeProps } from "@react-aria/utils";

// TODO: typing for this
export const FormikMaskedInput = ({ meta, field, ...props }) => {
  const isError = meta.touched && meta.error;
  const newProps = isError ? mergeProps(field, { "aria-describedby": `${field.id || field.name}_error` }) : field;
  return (
    <div className="flex flex-col">
      <label className="mb-2 text-sm font-bold text-dark-gray" htmlFor={field.id || field.name}>
        {props.label}
        {props.isRequired && <span className="px-1" aria-hidden>*</span>}
      </label>
      <MaskedInput
        id={field.id || field.name}
        mask={props.mask}
        keepCharPositions={true}
        guide={true}
        className={clsx(
          isError ? "border-danger focus:border-danger" : "focus:border-primary-blue",
          "w-full px-3 py-3 leading-tight border-pale-grey-200 appearance-none rounded-lg border focus:outline-none focus:border focus:border-solid"
        )}
        {...newProps}
      />
    </div>
  );
};
