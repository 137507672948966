import { createAsyncThunk } from "@reduxjs/toolkit";
import { CSRTicketRequest } from "../../containers/slices/csrSlice";
import hyApi from "../services/hyApiService";

export const csrTicket = createAsyncThunk("csr/ticket", async (ticket: Partial<CSRTicketRequest>) => {
  const response = await hyApi.post(`api/csr/ticket`, ticket);
  return response.data;
});

export default csrTicket;
