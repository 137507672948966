import { createSlice } from "@reduxjs/toolkit";
import { cancelVisitDirect, getConsultationList } from "../../api/websdk/mobileApi";


interface MapiSliceState {
  loading: boolean;
  error: boolean;
  consultations: any[];
  consultsLoading: boolean;

}

const initialState: MapiSliceState = {
  loading: true,
  error: false,
  consultations: [],
  consultsLoading: false,
};

export const mapiSlice = createSlice({
  name: "mapi",
  initialState,
  reducers: {
    removeMapiInfo: () => initialState,
    logoutMapi: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getConsultationList.pending, (state) => {
      state.loading = true;
      state.consultsLoading = true;
    });

    builder.addCase(getConsultationList.fulfilled, (state, action) => {
      state.consultations = action?.payload;
      state.loading = false;

      state.consultsLoading = false;
    });

    builder.addCase(getConsultationList.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
   
    builder.addCase(cancelVisitDirect.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(cancelVisitDirect.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(cancelVisitDirect.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
  }
});

export const selectMapi = (state) => state.mapi;

export const { logoutMapi: logoutMapi } = mapiSlice.actions;

export default mapiSlice.reducer;
