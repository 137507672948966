import { useField } from "formik";
import React, { PropsWithChildren } from "react";
import clsx from "clsx";

interface CheckboxProps {
  name: string;
}

export const FormikCheckbox = ({ children, ...props }: PropsWithChildren<CheckboxProps>) => {
  const [field, meta] = useField({ ...props, type: "checkbox" });
  const isError = meta.touched && meta.error;

  return (
    <label className={clsx(isError && "text-red-500", "flex items-center cursor-pointer select-none")}>
      <input {...field} {...props} type="checkbox" className="max-w-xs mr-2 bg-current" />
      {children}
    </label>
  );
};
