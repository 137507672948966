import { createSlice } from "@reduxjs/toolkit";

import { Nullable } from "../../@types/common";
import resetPassword, { changePassword, sendResetEmail } from "../../api/forgotPassword";
import { changePasswordExternal } from "../../api/forgotPassword";
import { RootState } from "../../store/rootReducer";

export interface ResetPasswordRequest {
  password: string;
  profileId: string;
  passwordRecoveryCode: string;
}
export interface ChangePasswordRequest {
  password: string;
  profileId: string;
  currentPassword?: string;
  passwordRecoveryCode?: string;
}

export interface SendResetEmailRequest {
  email: string;
}

interface ForgotPasswordSliceState {
  loading: boolean;
  error: any;
  sendEmailResponse: Nullable<any>;
  resetPasswordResponse: Nullable<any>;
  changePasswordResponse: Nullable<any>;
}

const initialState: ForgotPasswordSliceState = {
  loading: false,
  sendEmailResponse: null,
  resetPasswordResponse: null,
  changePasswordResponse: null,
  error: null
};

export const forgotPasswordSlice = createSlice({
  name: "forgotPassword",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = null;
      state.changePasswordResponse = null;
      state.sendEmailResponse = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(sendResetEmail.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(sendResetEmail.fulfilled, (state, action) => {
      state.sendEmailResponse = action.payload;
      state.loading = false;
    });

    builder.addCase(sendResetEmail.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    builder.addCase(resetPassword.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.resetPasswordResponse = action.payload;
      state.loading = false;
    });

    builder.addCase(resetPassword.rejected, (state, action) => {
      state.error = action.payload;
      state.loading = false;
    });

    builder.addCase(changePassword.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(changePassword.fulfilled, (state, action) => {
      state.loading = false;
      state.changePasswordResponse = action.payload;
    });

    builder.addCase(changePassword.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });

    builder.addCase(changePasswordExternal.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(changePasswordExternal.fulfilled, (state, action) => {
      state.loading = false;
      state.changePasswordResponse = action.payload;
    });

    builder.addCase(changePasswordExternal.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  }
});

export const selectForgotPassword = (state: RootState) => state.forgotPassword;

export const { clearErrors } = forgotPasswordSlice.actions;

export default forgotPasswordSlice.reducer;
