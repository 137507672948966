import React, { useEffect } from "react";
import UpcomingVisitListItem from "./UpcomingVisitListItem";
import clsx from "clsx";
import { useHistory } from "react-router-dom";
import RequestedVisitListItem from "./RequestedVisitListItem";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "../../containers/slices/authSlice";
import { setMapiAxiosInterceptors } from "../../api/services/mobileApiService";
import { getConsultationList } from "../../api/websdk/mobileApi";

const VisitsList = ({ tabName, consultations }) => {
  const history = useHistory();
  const dispatch = useDispatch();


  const handleOnClick = () => {
    history.push("/request-a-visit");
  };

  const authSelector = useSelector(selectAuth);

  useEffect(() => {
    if (authSelector.hasMapi === true && authSelector.mapiToken) {
      setMapiAxiosInterceptors(authSelector.mapiToken);
      dispatch(getConsultationList(authSelector.entities.familyMember.telehealthID));
    }

  }, [dispatch, authSelector.hasMapi]);


  return (
    <div role="tabpanel" id={`${tabName}-tab`} aria-labelledby={tabName}>
      {consultations?.length ? (
        <div
        tabIndex={0}
          className={clsx(
            consultations.length < 2 && "h-auto",
            consultations.length === 2 && "overflow-hidden pb-0 mb-0 h-auto scrollbar scrollbar--transparent",
            consultations.length > 2 && tabName === "Upcoming"
              ? "overflow-y-auto h-28rem lg:h-17rem"
              : "h-28rem  md:h-20rem",
            "overflow-x-hidden w-auto pr-2 scrollbar"
          )}
          data-testid={"visits-list-container"}
        >
          {consultations.map((consult: any) =>
            tabName === "Upcoming" ? (
              <UpcomingVisitListItem key={consult.consult_id} consult={consult} />
            ) : (
              <RequestedVisitListItem key={consult.consult_id} consult={consult} />
            )
          )}
        </div>
      ) : (
        <div
          data-testid={"no-visits-list-container"}
          className="flex flex-row items-center h-auto py-10 m-4 border border-solid rounded-lg border-primary-blue"
        >
          <div className="px-5">You have no upcoming visits</div>
          <button
            type="button"
            data-testid={"rav-button"}
            className="cursor-pointer text-primary-blue"
            onClick={handleOnClick}
          >
            Request a visit
          </button>
        </div>
      )}
    </div>
  );
};

export default VisitsList;
