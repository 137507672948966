import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  fetchPrescriptionsByName,
  fetchPrescriptionDetails,
  getCoordinatesForZipCode,
  fetchProvideDiscountCard
} from "../api/pricing";
import { convertObjectToQueryString } from "../utils/url";

interface Coordinates {
  latitude: number;
  longitude: number;
}

interface DrugPrice {
  PharmacyId: string;
  Street1: string;
  Street2: string;
  City: string;
  Latitude: number;
  Longitude: number;
  MailOrderPharmacy: false;
  PharmacyName: string;
  PhoneNumber: number;
  FaxNumber: number;
  State: string;
  ZipCode: string;
  Distance: number;
  DetailId: string;
  Name: string;
  Price: number;
}
interface drugDetails {
  Name: string;
  Forms: [
    {
      IsDefaultForm: boolean;
      Form: string;
      Strengths: [
        {
          IsDefault: boolean;
          Strength: string;
          PackageDescriptions: [
            {
              Value: number;
              Quantities: [
                {
                  IsDefaultQuantity: boolean;
                  Quantity: number;
                }
              ];
            }
          ];
        }
      ];
    }
  ];
}
interface PrescriptionsResult {
  drugPrices: DrugPrice[];
  drugDetails: drugDetails | null;
}

interface DrugByName {
  drugId: number;
  name: string;
}

interface Prescriptions {
  data: {
    drugDetails: {
      Name: string;
      Forms: [
        {
          IsDefaultForm: boolean;
          Form: string;
          Strengths: [
            {
              IsDefault: boolean;
              Strength: string;
              PackageDescriptions: [
                {
                  Value: number;
                  Quantities: [
                    {
                      IsDefaultQuantity: boolean;
                      Quantity: number;
                    }
                  ];
                }
              ];
            }
          ];
        }
      ];
    }[];
    defaultDrugPrices: DrugPrice[];
  };
  meta: {
    page: number;
    total: number;
    limit: number;
  };
}

export function usePrescriptions(params) {
  const fieldsForRemove = ["isShowBrandDrugs", "id"];
  const enabled = !!params.latitude && !!params.longitude && params?.zip.length === 5 && params.enabled;
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([key, value]) => {
      if (fieldsForRemove.includes(key)) return false;
      if (value === 0) return true;
      return !!value;
    })
  );

  const searchParams = convertObjectToQueryString(filteredParams);

  return useQuery<PrescriptionsResult, Error>(
    ["prescription_details", searchParams],
    async () => {
      const data = await fetchPrescriptionDetails(searchParams);
      const defaultDrugPrices = data?.data?.defaultDrugPrices;
      const drugDetails = data?.data?.drugDetails?.[0];

      const drugPriceSet = new Set();
      const filteredDrugPrices = defaultDrugPrices?.filter(({ PharmacyId }) => {
        if (drugPriceSet.has(PharmacyId)) return false;
        drugPriceSet.add(PharmacyId);
        return true;
      });

      if (drugDetails?.length === 0) {
        toast.error("Nothing was found.");
      }

      return { drugPrices: filteredDrugPrices, drugDetails };
    },
    {
      enabled,
      initialData: {
        drugDetails: null,
        drugPrices: []
      },
      onError: () => {
        toast.error("Failed to download details");
      }
    }
  );
}

export function usePrescriptionDetails(cpt = "", zip = "") {
  const params = new URLSearchParams({ zip, cpt });

  return useQuery(["prescription_details", params.toString()], () => fetchPrescriptionDetails(params), {
    onError: () => {
      toast.error("Failed to download details");
    }
  });
}

export function usePrescriptionsByName(name: string, enabled = true) {
  const nameWithDashes = name.replaceAll(" ", "-");

  return useQuery<DrugByName[], Error>(
    ["procedure", nameWithDashes],
    async () => {
      const result = await fetchPrescriptionsByName(nameWithDashes);
      return result?.data?.drugs || [];
    },
    {
      initialData: [],
      enabled: !!nameWithDashes && enabled,
      onError: () => {
        toast.error("Failed to download prescriptions");
      }
    }
  );
}

export const useCoordinatesForZipCode = (filters) => {
  const code = filters.zip;
  const enabled = code.length === 5 && filters.enabled;
  return useQuery<Coordinates, Error>(["coordinates_for_code", code], () => getCoordinatesForZipCode(code), {
    initialData: { latitude: 0, longitude: 0 },
    enabled
  });
};

export const useProviderDiscountCard = (name, zip) => {
  const nameWithDashes = name.replace(/ /g, "-");
  const params = new URLSearchParams({ name: nameWithDashes, location: zip });

  return useQuery(["provider_discount_card", params.toString()], () => fetchProvideDiscountCard(params));
};
