import React from "react";
import clsx from "clsx";

export default function ({ className = "" }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" className={clsx(className)} width="40" height="40" viewBox="0 0 40 40">
      <path
        fill="#005c95"
        fillRule="evenodd"
        d="M27.747 13.097l-2.82 14.1c-.101.51-.7.738-1.115.427l-4.599-3.449-2.201 2.631c-.41.49-1.196.224-1.245-.392l-.002-.061v-4.766l-3.247-2.434c-.412-.308-.36-.926.064-1.173l.097-.047 14.113-5.645.027-.011c.09-.032.18-.046.266-.042l.014.001c.027 0 .054.004.08.008.006.003.012.004.019.005.027.005.053.012.08.02l.008.004c.024.007.047.017.07.027l.031.016.051.03.029.019c.109.077.196.185.247.31l.005.016c.025.062.04.13.045.2v.096c-.003.037-.008.074-.017.11zm-1.76 1.605l-8.25 6.6 6.026 4.52 2.224-11.12zm-8.81 7.944v1.764l.906-1.084-.907-.68zm5.72-7.28l-8.527 3.41 2.2 1.651 6.327-5.06z"
      />
      <div id="edge-translate-notifier-container" className="edge-translate-notifier-center" />
    </svg>
  );
}
