import { createSlice } from "@reduxjs/toolkit";
import { Nullable } from "../../@types/common";
import { RootState } from "../../store/rootReducer";

export interface IntervalsStateSlice {
  sdkTokenInterval: Nullable<NodeJS.Timeout>;
  pollingDashboardInterval: Nullable<NodeJS.Timeout>;
  sessionInterval: Nullable<NodeJS.Timeout>;
  tokenLoginErrorCount: number;
  hasEligibilityError: Boolean;
  resetDashboard: Nullable<NodeJS.Timeout>;
}

const initialState: IntervalsStateSlice = {
  sdkTokenInterval: null,
  pollingDashboardInterval: null,
  sessionInterval: null,
  tokenLoginErrorCount: 0,
  hasEligibilityError: false,
  resetDashboard: null
};

export const intervalsSlice = createSlice({
  name: "intervals",
  initialState,
  reducers: {
    setCheckSessionPolling: (state, action) => {
      state.sessionInterval = action.payload;
    },
    setDashboardPolling: (state, action) => {
      state.pollingDashboardInterval = action.payload;
    },
    setSdkTokenPolling: (state, action) => {
      state.sdkTokenInterval = action.payload;
    },
    setResetDashboard: (state, action) => {
      state.resetDashboard = action.payload;
    },
    clearSdkPolling: (state) => {
      if (state.pollingDashboardInterval) {
        clearInterval(state.pollingDashboardInterval as ReturnType<typeof setInterval>);
      }

      if (state.sdkTokenInterval) {
        clearInterval(state.sdkTokenInterval as ReturnType<typeof setInterval>);
      }
      state.sdkTokenInterval = null;
      state.pollingDashboardInterval = null;
    },
    clearCheckSessionPolling: (state) => {
      if (state.sessionInterval) {
        clearInterval(state.sessionInterval as ReturnType<typeof setInterval>);
      }
      state.sessionInterval = null;
    },
    incrementTokenLoginErrorCount: (state) => {
      state.tokenLoginErrorCount = state.tokenLoginErrorCount + 1;
    },
    clearTokenLoginErrorCount: (state) => {
      state.tokenLoginErrorCount = 0;
    },
    clearDashboardPolling: (state) => {
      if (state.pollingDashboardInterval) {
        clearInterval(state.pollingDashboardInterval as ReturnType<typeof setInterval>);
      }
      state.pollingDashboardInterval = null;
    },
    setEligibilityError: (state) => {
      state.hasEligibilityError = true;
    },
    clearEligibilityError: (state) => {
      state.hasEligibilityError = false;
    }
  }
});

export const selectIntervals = (state: RootState) => state.intervals;

export const {
  clearCheckSessionPolling,
  setCheckSessionPolling,
  setDashboardPolling,
  setResetDashboard,
  setSdkTokenPolling,
  clearSdkPolling,
  incrementTokenLoginErrorCount,
  clearTokenLoginErrorCount,
  clearDashboardPolling,
  setEligibilityError,
  clearEligibilityError
} = intervalsSlice.actions;

export default intervalsSlice.reducer;
