import React from "react";
import clsx from "clsx";

const ButtonType = {
  primary: "bg-blue-20 hover:bg-blue-40 disabled:border-disabled disabled:text-white text-black",
  secondary: "text-primary-blue hover:bg-primary-blue bg-white hover:text-white",
  basic: "disabled:opacity-50 bg-blue-70 hover:bg-blue-40 text-white",
  danger: "bg-red-300 text-white font-bold"
};

const ButtonSize = {
  sm: "py-2 px-4 text-xs",
  lg: "py-3 px-6 text-lg"
};

type ButtonProps = {
  buttonType?: keyof typeof ButtonType;
};

function Button({
  children,
  className,
  type = "button",
  buttonType = "primary",
  ...rest
}: React.ButtonHTMLAttributes<HTMLButtonElement> & ButtonProps) {
  const btnDefault =
    "border-primary-blue border-solid border disabled:bg-disabled disabled:text-white rounded-lg py-2 my-2 px-4 md:my-0 lg:my-0";
  return (
    <button className={clsx(ButtonType[buttonType], btnDefault)} type={type} {...rest}>
      {children}
    </button>
  );
}

export default Button;
