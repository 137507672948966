import React from "react";
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone.svg";

interface FavoritePhoneProps {
  phone: string;
}

const FavoritePhone = ({ phone }: FavoritePhoneProps) => (
  <a href={`tel:${phone}`} className="hover:no-underline text-black mt-4 flex leading-tight">
    {phone} <span className="ml-6 mr-4 text-primary-blue">Call</span>
    <PhoneIcon className="w-4 h-4" title="Phone Icon" aria-hidden />
  </a>
);

export default FavoritePhone;
