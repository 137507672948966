import React, { useEffect } from "react";
import { usePopularProcedures } from "../../queries/useProcedures";
import { useSpinner } from "../../components/SpinnerProvider";

const PopularList = ({ onClick }) => {
  const { showSpinner } = useSpinner();
  const { data, isLoading } = usePopularProcedures();

  useEffect(() => {
    showSpinner({
      show: isLoading
    });
  }, [isLoading]);

  const items = data?.data?.popular || [];
  return (
    <div className="font-bold text-lg">
      <h4>Most popular cash procedures</h4>
      <ul className="mt-2">
        {items.map((item) => (
          <li key={`price-procedure-${item.code}`} className="my-2">
            <button className="price-procedure text-primary-blue hover:opacity-75" onClick={() => onClick(item)}>
              {item.name} - {item.code}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PopularList;
