import React, { useRef } from "react";
import { useClickAway } from "react-use";
import clsx from "clsx";
import { find } from "lodash";

interface option {
  label: string | number;
  value: string | number;
}
interface Props {
  header: string;
  id: string;
  className?: string;
  options: option[];
  placeHolder?: string;
  selectedVal: string | number;
  onChange: (searchTerm: string | number) => void;
  onSubmit?: any;
  selectAllOnClick?: boolean;
}

const Autocomplete = ({
  header,
  id,
  className = "",
  options,
  placeHolder = "Enter a value",
  onChange,
  onSubmit = () => {},
  selectedVal,
  selectAllOnClick = false
}: Props) => {
  const [selectionMade, setSelectionMade] = React.useState<boolean>(true);
  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => {
    setSelectionMade(true);
  });

  const makeSelection = (selection: option) => {
    setSelectionMade(true);
    onSubmit(selection.label);

    onChange(selection.label);
  };

  const updateSearchTerm = (value: string) => {
    handleChange(value);
    if (find(options, (o) => (o.label as string).toLowerCase() === value.toLowerCase())) {
      setSelectionMade(true);
      onSubmit(value);
    }
  };

  const handleChange = (value) => {
    onChange(value);
    setSelectionMade(false);
  };

  const filteredOptions = options
    ?.filter((option) => option?.label?.toString().toLowerCase().includes(selectedVal.toString().toLowerCase()))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  const isListShown = (selectAllOnClick || selectedVal) && filteredOptions && !selectionMade;

  return (
    <div className={clsx("relative w-full ", className)} ref={wrapperRef}>
      <div className={className}>
        <label htmlFor={id} className="leading-none text-dark-gray text-base block">
          <span>{header}</span>
        </label>
        <input
          id={id}
          className="block rounded-lg border-pale-grey-50 p-4 text-dark-gray text-base leading-none pr-2 w-full capitalize"
          style={{ borderWidth: 1 }}
          placeholder={placeHolder}
          name={id}
          value={selectedVal}
          autoComplete="off"
          onFocus={(e) => {
            if (selectAllOnClick) e.target.select();

            setSelectionMade(false);
          }}
          onChange={(e) => updateSearchTerm(e.target.value)}
        />
      </div>
      {isListShown && (
        <div
          className={clsx(
            filteredOptions?.length == 0 && "hidden",
            isListShown && "h-48",
            filteredOptions?.length < 1 && !selectedVal && "h-0",
            "absolute w-full z-20"
          )}
        >
          <ul className="absolute w-full h-auto max-h-full overflow-y-auto bg-white shadow-lg border-pale-grey-200 left-2 border rounded-lg rounded-t-none overflow-x-hidden full-scrollbar">
            {filteredOptions.map((option) => (
              <li
                title={option.value as string}
                data-testid={`item-${option.value}`}
                className="block z-20 w-full p-1 pl-8 pr-4 font-medium bg-white autocomplete-border-b cursor-pointer border-pale-grey-200 hover:bg-pale-grey-10"
                key={option.value}
                onClick={() => makeSelection(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Autocomplete;
