import clsx from "clsx";
import { useField, useFormikContext } from "formik";
import React from 'react'
import DatePicker from "react-datepicker";
import MaskedInput from "react-text-mask";
import { FormFieldError } from "../FormFields/FieldError";
import { dateMask } from "../../utils/validationHelper";

export const FormikDatePicker = ({ label, isRequired, ...props }: any) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(props);
  const isError = meta.touched && meta.error;

  return (
    <>
      <div className="flex items-center mb-2 text-sm font-bold">
        <label htmlFor={props.id || props.name} id={`${props.id || props.name}_label`}>
          {label}
          {isRequired && <span className="px-1" aria-hidden>*</span>}
        </label>
      </div>
      <DatePicker
        id={props.id || props.name}
        className={clsx(
          isError && "border-red-500",
          "w-full px-3 py-2 leading-tight border rounded-lg shadow appearance-none focus:outline-none focus:shadow-outline"
        )}
        {...field}
        {...props}
        
        ariaLabelledBy={`${props.id || props.name}_label`}
        selected={(field.value && new Date(field.value)) || null}
        customInput={<MaskedInput
          style={{ borderColor: isError && "#f56565" }}
          aria-required={isRequired}
          required={isRequired}
          id={props.id || props.name}
          mask={dateMask}
          keepCharPositions={true}
          guide={true}
        />}
        onChange={(val) => {
          setFieldValue(field.name, val);
        }}
      />
      <FormFieldError field={field} meta={meta} />
    </>
  );
};
