import {
  FavoriteElement,
  ProviderCategory,
  RecipeCategory,
  RxCategory,
  FavoriteCategory,
  FacilitiesCategory
} from "../FavoriteTypes";

export const parseByCategories = (circles: any[] = [], categoryList: FavoriteCategory[] = []): FavoriteElement[] => {
  let elements: FavoriteElement[] = [];
  circles
    .filter((category) => categoryList.indexOf(category.name) > -1)
    .forEach((category) => {
      const categoryElements = parseElements(category.name, category.elements);
      elements = [...elements, ...categoryElements];
    });
  return elements;
};

export const parseElements = (categoryName, elements): FavoriteElement[] => {
  let items: FavoriteElement[] = [];
  elements.forEach((element) => {
    items.push({
      name: element.name,
      category: categoryName,
      id: element.id,
      avatar: element.avatar,
      meta: JSON.parse(element.meta),
      type: element.type
    });
  });
  return items;
};

export const formatPhone = (phoneNumber?: string): string => {
  const digitsOnly = ("" + phoneNumber).replace(/\+1|\D/g, "");
  const matches = digitsOnly.match(/^(\d{3})(\d{3})(\d{4})$/);
  return matches ? matches.slice(1).join("-") : "";
};

export const parseProviders = (circles: any[]): FavoriteElement[] => {
  return parseByCategories(circles, Object.values(ProviderCategory));
};

export const parseRecipes = (circles: any[]): FavoriteElement[] => {
  return parseByCategories(circles, Object.values(RecipeCategory));
};

export const parsePrescriptions = (circles: any[]): FavoriteElement[] => {
  return parseElements(RxCategory.PRESCRIPTION, circles);
};

export const parseFacilities = (circles: any[], memberPharmacies: any[]): FavoriteElement[] => {
  const facilities = parseByCategories(circles, Object.values(FacilitiesCategory));
  let pharmacies: FavoriteElement[] = [];
  memberPharmacies.forEach((p) => {
    const address = p.workAddress
      ? `${p.workAddress.addressLine1} ${p.workAddress.city}, ${p.workAddress.state} ${p.workAddress.postalCode}`
      : "";
    const phoneNumber = p.workNumber ? `${p.workNumber.areaCode}${p.workNumber.number}` : "";

    pharmacies.push({
      name: p.pharmacyName,
      category: RxCategory.PHARMACY,
      id: p.ncpdpId,
      avatar: "",
      meta: {
        phoneNumber: formatPhone(phoneNumber),
        address,
        lat: "",
        lon: ""
      },
      type: ""
    });
  });
  return [...facilities, ...pharmacies];
};
