import clsx from "clsx";
import React from "react";

import { ReactComponent as HamburgerSVG } from "../../assets/Hamburger.svg";
import { ReactComponent as ArrowSVG } from "../../assets/icons-arrow-01-chevron-down.svg";
import { ReactComponent as MemberSVG } from "../../assets/icons/circle-10.svg";

export function MainMenuButton({ label, isOpen }: { label: string; isOpen: boolean }) {
  return (
    <span className="flex items-center px-0 space-x-0 text-black border-none rounded cursor-pointer md:border md:border-blue-500 hover:text-blue-500 md:px-2 md:space-x-2 group" aria-hidden="true">
      <span className="hidden lg:block">
        <MemberSVG
          aria-hidden="true"
          className="block fill-current group-hover:text-primary-hover text-primary-blue-500"
          title="User Login Logo"
        />
      </span>
      <span className="block p-2 lg:hidden">
        <HamburgerSVG
          aria-hidden="true"
          className="block w-4 h-4 fill-current group-hover:text-primary-hover"
          title="Hamburger Menu"
        />
      </span>
      <span className="hidden p-2 lg:block group-hover:text-primary-hover">{label}</span>
      <span className="hidden p-2 lg:block">
        <ArrowSVG
          aria-hidden="true"
          className={clsx(
            "transition duration-200 transform",
            isOpen && "rotate-180",
            "block w-3 h-3 fill-current group-hover:text-primary-hover"
          )}
          title="Menu Toggle Button"
        />
      </span>
    </span>
  );
}
