import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { getFavorites, deleteFromFavorites, getPharmacies } from "../api/favorites";
import { useAppDispatch } from "../store/store";
import { selectAuth } from "./slices/authSlice";
import { Provider, Facility, Prescription, Recipe } from "../components/Favorites";
import {
  parsePrescriptions,
  parseProviders,
  parseRecipes,
  parseFacilities
} from "../components/Favorites/Helpers/Parser";
import { ReactComponent as FavoriteIcon } from "../assets/icons/favorite.svg";
import FormCard from "../components/FormCard";
import FavoritesSection from "../components/Favorites/FavoriteSection";
import { FavoriteCirclesCategory, RxCategory } from "../components/Favorites/FavoriteTypes";
import { feature } from "../components/Nav/FeatureTypes";
import { useSpinner } from "../components/SpinnerProvider";
import PageTitleComponent from "../components/PageTitleComponent";
import { toast } from "react-toastify";

const Favorites = () => {
  const dispatch = useAppDispatch();
  const { showSpinner } = useSpinner();
  const [providers, setProviders] = useState<any>([]);
  const [facilities, setFacilities] = useState<any>([]);
  const [prescriptions, setPrescriptions] = useState<any>([]);
  const [recipes, setRecipes] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const {
    entities: { profile, familyMember, group }
  } = useSelector(selectAuth);
  const groupFeatures = Object.fromEntries((group?.features || []).map((key, val) => [key, true]));

  useEffect(() => {
    savingFavoritesResponse();
  }, []);

  useEffect(() => {
    showSpinner({
      show: loading
    });
  }, [loading]);

  const savingFavoritesResponse = async () => {
    setLoading(true);
    const response: any = await dispatch(getFavorites(profile));
    if (response.error) {
      setLoading(false);
      toast.error("We are sorry there was an error loading your favorites. Please try again.");

      return;
    }
    let memberPharmaciesResponse;
    if (familyMember) {
      memberPharmaciesResponse = await dispatch(getPharmacies(familyMember));
    }
    (response?.payload?.data?.circles || []).forEach((item) => {
      switch (item.name) {
        case FavoriteCirclesCategory.PROVIDERS:
          formatProviders(item.circles || []);
          formatFacilities(item.circles || [], memberPharmaciesResponse?.payload?.pharmacies || []);
          break;
        case FavoriteCirclesCategory.RECIPES:
          formatRecipes(item.circles || []);
          break;
        case FavoriteCirclesCategory.RX:
          (item.circles || [])
            .filter((rxItem) => rxItem.name === RxCategory.PRESCRIPTION)
            .forEach((rxItem) => {
              formatPrescriptions(rxItem.elements || []);
            });
          break;
        default:
          console.warn(`unknown favorites name: ${item.name}`);
      }
    });
    setLoading(false);
  };

  const formatProviders = (providersCircles) => {
    setProviders(parseProviders(providersCircles));
  };

  const formatRecipes = (recipesCircles) => {
    setRecipes(parseRecipes(recipesCircles));
  };

  const formatPrescriptions = (prescriptionsCircles) => {
    setPrescriptions(parsePrescriptions(prescriptionsCircles));
  };

  const formatFacilities = (facilitiesCircles, memberPharmacies) => {
    setFacilities(parseFacilities(facilitiesCircles, memberPharmacies));
  };

  const isFavoritesSaved = useMemo(
    () => !!(providers.length || facilities.length || prescriptions.length || recipes.length),
    [providers, facilities, prescriptions, recipes]
  );

  const removeFromFavorites = async (id) => {
    const response: any = await dispatch(
      deleteFromFavorites({
        user: profile,
        circleId: id
      })
    );
    if (response.error) {
      toast.error("We are sorry there was an error loading your favorites. Please try again.");
      return;
    }
    savingFavoritesResponse();
  };

  return (
    <FormCard data-testid="myFavoritesContainer" title="My Favorites">
      <PageTitleComponent title="My Favorites" />
      <div className="flex w-full h-auto pr-8 font-effra">
        <div className="px-6 space-x-4 ">
          <FavoriteIcon className="w-16 h-16" aria-hidden />
        </div>
        <div className="w-full pb-10">
          <p className="flex flex-col justify-center h-16 font-medium leading-tight">
            {!isFavoritesSaved
              ? `You do not have any favorites saved. Select the heart icon of the provider, facility, recipes or 
prescription that you want to add to favorites!`
              : `Here you can gain quick access to your favorites and manage them, as well.`}
          </p>
          {isFavoritesSaved ? (
            <>
              {facilities.length ? (
                <FavoritesSection
                  name="Facilities"
                  favoriteItems={facilities}
                  FavoriteItemComponent={Facility}
                  onUnfavorite={removeFromFavorites}
                />
              ) : null}
              {groupFeatures[feature.providers] && providers.length ? (
                <FavoritesSection
                  name="Providers"
                  favoriteItems={providers}
                  FavoriteItemComponent={Provider}
                  onUnfavorite={removeFromFavorites}
                />
              ) : null}
              {groupFeatures[feature.rx] && prescriptions.length ? (
                <FavoritesSection
                  name="Prescriptions"
                  favoriteItems={prescriptions}
                  FavoriteItemComponent={Prescription}
                  onUnfavorite={removeFromFavorites}
                />
              ) : null}
              {groupFeatures[feature.wellness] && recipes.length ? (
                <FavoritesSection
                  name="Recipes"
                  favoriteItems={recipes}
                  FavoriteItemComponent={Recipe}
                  onUnfavorite={removeFromFavorites}
                />
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </FormCard>
  );
};

export default Favorites;
