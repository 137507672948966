import React, { useState } from "react";
import clsx from "clsx";
import { ReactComponent as DownArrow } from "../../assets/icons/down-arrow.svg";

export interface option {
  label: string | number;
  value: string | number;
}
interface Props {
  header: string;
  id: string;
  className: string;
  options: option[];
  selectedVal: string | number;
  placeHolder?: string;
  handleChange?: any;
  shouldFilter?: boolean;
  shouldSort?: boolean;
  handleSubmit?: (e: option) => void;
}

const sortOptions = (a, b) => {
  try {
    const numA = Number(a.label);
    const numB = Number(b.label);
    return numA > numB ? 1 : -1;
  } catch (e) {
    return a.label > b.label ? 1 : -1;
  }
};

const Select = ({
  header,
  id,
  className,
  options,
  placeHolder = "",
  handleChange,
  handleSubmit = () => {},
  selectedVal,
  shouldFilter = true,
  shouldSort = true
}: Props) => {
  const [focused, setFocused] = useState(false);

  const handleBlur = () => {
    setTimeout(() => {
      setFocused(false);
    }, 250);
  };

  const makeSelection = (e, selection: option) => {
    e.preventDefault();

    handleSubmit(selection);
    setFocused(false);
    handleChange(selection.label);
  };

  const filteredOptions = shouldFilter ? options?.filter((option) => option?.label) : options;
  const sortedOptions = shouldSort ? filteredOptions.sort(sortOptions) : filteredOptions;

  return (
    <div className={clsx("relative w-full ", className)}>
      <label htmlFor={id} className="leading-none text-dark-gray text-base block">
        <span>{header}</span>
      </label>
      <div>
        <input
          id={id}
          autoComplete="off"
          className={`block rounded-lg border border-pale-grey-50 p-4 text-dark-gray text-base leading-none pr-2 w-full capitalize ${
            focused ? "rounded-b-none" : ""
          }`}
          placeholder={placeHolder}
          name={id}
          value={selectedVal}
          onFocus={() => setFocused(true)}
          onBlur={handleBlur}
        />
        <div className="absolute inset-y-0 right-0 flex items-center px-0 text-gray-700 pointer-events-none z-2">
          <DownArrow
            className={`fill-current h-full mt-4 transform ${focused ? "rotate-180" : "rotate-0"}`}
            title="Settings Logo"
          />
        </div>
      </div>
      {!!sortedOptions.length && focused && (
        <div
          className={
            "absolute w-full max-h-200px border border-1 bg-white  border-pale-grey-200 rounded-lg rounded-t-none z-10 overflow-auto"
          }
        >
          <ul className="h-auto max-h-200 overflow-auto pr-3">
            {sortedOptions.map((option, index) => (
              <li
                data-testid={`item-${option.value}`}
                className={clsx(
                  "flex items-center p-2 bg-transparent cursor-pointer h-full min-h-40px border-pale-grey-200 hover:bg-pale-grey-10 hover:rounded-l-lg capitalize",
                  index !== options.length - 1 && "border-b"
                )}
                key={option.value}
                onClick={(e) => makeSelection(e, option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Select;
