import React from "react";
import { ReactComponent as ArrowLeftSVG } from "../../assets/icons/left-arrow-without-borders.svg";
import { ReactComponent as AvatarLargeSVG } from "../../assets/icons/avatar-large.svg";
import { ReactComponent as PhoneSVG } from "../../assets/icons/phone.svg";

function ProviderDetails({ data, onBackButton }) {
  const {
    header,
    description,
    address,
    phone,
    distance,
    educations,
    profile: { gender, imageUrl }
  } = data;

  return (
    <div className="">
      <div className="w-auto h-full px-4">
        <h1 className="text-4xl leading-12 text-dark-gray mb-8">Provider details</h1>
        <button
          type="button"
          className="text-left font-medium py-4 pr-2 pl-4 mb-2 text-primary-blue flex flex-row items-center gap-2"
          onClick={onBackButton}
        >
          <ArrowLeftSVG /> Back
        </button>
        <div className="flex">
          {imageUrl ? (
            <img src="" alt="Image Preview" className="image-web-sdk" />
          ) : (
            <div className="">
              <AvatarLargeSVG />
            </div>
          )}

          <div className="ml-8 flex-grow">
            <div className="flex justify-between mb-10 ">
              <div className="">
                <p className="mb-2 mt-2 text-2xl font-medium leading-tight text-dark-gray capitalize">{header}</p>

                <div className="max-w-lg">
                  <p className="block text-lg leading-none  capitalize">{description}</p>
                </div>
              </div>
              {/* {addFavoriteIcon(isFavorite, providerData, setFavorite)} */}
            </div>

            <div className="mb-10">
              <p className="mb-2 text-2xl font-medium ">Locations</p>

              <div className="flex justify-between">
                <p className="text-lg text-primary-blue block capitalize">{address}</p>
                <p className="text-lg w-16 text-pale-grey-200">{`${distance.toFixed(2)} mi`}</p>
              </div>
              {phone ? (
                <div className="ml-2">
                  <p className="inline text-primary-blue text-lg w-auto mr-3">{phone}</p>
                  <a className="mr-8 text-lg text-primary-blue" href={`tel:${phone}`}>
                    Call <PhoneSVG className="inline-block w-4 h-4 mb-1 ml-1" />
                  </a>
                </div>
              ) : null}
            </div>

            {(gender || educations) && (
              <div className="mb-10">
                <p className="text-2xl font-medium ">About</p>
                <div className="">
                  {educations ? (
                    <div className="mb-2">
                      <p className="text-pale-grey-200 leading-tight text-lg mb-1">School</p>
                      <p className="block text-lg leading-none ml-2 capitalize">{educations}</p>
                    </div>
                  ) : null}
                  {gender ? (
                    <div className="mb-2">
                      <p className="text-pale-grey-200 leading-tight text-lg mb-1">Gender</p>
                      <p className="block text-lg leading-none capitalize">{gender}</p>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <p className="provider-details-disclaimer-web-sdk">
        Disclaimer: Please call individual providers to confirm they accept your health insurance. For a complete list
        of providers, please contact your health insurer. Provider information is provided by BetterDoctor.
      </p>
    </div>
  );
}

export default ProviderDetails;
