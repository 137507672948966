import { useQuery } from "react-query";
import { toast } from "react-toastify";

import { fetchPopularProcedures, fetchProcedureDetails, fetchProcedureByName } from "../api/pricing";

interface Popular {
  data: {
    popular: {
      name: string;
      code: string;
      image: string;
    }[];
  };
}

interface ProcedureDetails {
  data: {
    cash: {
      payer: string;
      low: number;
      medium: number;
      high: number;
      average: number;
    }[];
    insurance: {
      payer: string;
      type: string;
      low: number;
      medium: number;
      high: number;
      average: number;
    }[];
  };
}

const isCorrectZipCode = (code) => code.length === 5;

export function usePopularProcedures() {
  return useQuery<Popular, Error>("popular_procedures", fetchPopularProcedures);
}

export function useProcedureDetails(cpt = "", zip = "", searchEnabled) {
  const enabled = zip.length === 5 && !!cpt && searchEnabled;
  const params = new URLSearchParams({ zip, cpt });

  return useQuery<ProcedureDetails, Error>(
    ["procedure_details", params.toString()],
    () => fetchProcedureDetails(params),
    {
      enabled,
      onError: () => {
        toast.error("Failed to download details");
      }
    }
  );
}

export function useProceduresByName(name, enabled = true) {
  return useQuery(["procedure", name], () => fetchProcedureByName(name), {
    initialData: [],
    enabled: !!name && enabled,
    onError: () => {
      toast.error("Failed to download procedures");
    }
  });
}
