export interface Error {
  meta: {
    validationErrors: any[];
    errorMessages: {
      reason: any;
    }[];
  };
}

export const readableError = (err: string, registrationType: string) => {
  let errorMessage = "An error occurred. Please try again later";

  switch (err) {
    case "MemberNotAdult":
      errorMessage =
        "We are unable to provide service to primary members under the age of 18. Please call 866-703-1259 for assistance.";
      break;
    case "MultipleMembersFound":
      errorMessage = "We found more than 1 matching record. Please call (866) 703-1259 for assistance.";
      break;
    case "MemberNotActive":
    case "MemberNoEffectiveDate":
      errorMessage =
        "We located your account, but it is not currently active. Please contact your group administrator to verify your benefit start date.";
      break;
    case "MemberTerminated":
      errorMessage = "Your account is not active. Please contact your benefits administrator for more information.";
      break;
    case "MemberInactiveGroup":
    case "GroupTerminated":
    case "GroupNoStartDate":
      errorMessage =
        "We located your account, but it is not currently active. Please contact your group administrator to verify your benefits.";
      break;
    case "MemberNotFound":
    case "FamilyMemberNotFound":
    default:
      if (registrationType === "payPerVisit") {
        errorMessage = "We were unable to register you. For assistance please call 866-703-1259.";
      } else {
        errorMessage =
          "We were unable to locate your account. Please verify your information and try again.  For assistance please call 866-703-1259.";
      }
  }

  return errorMessage;
};
