export const WEBSDK_ROUTES = {
  RAV: "/request-a-visit",
  MEDICAL_HISTORY: "/medical-info",
  MESSAGE_CENTER: "/message-center",
  INVOICES: "/invoices",
  BACK_CARE: "/back-care",
  PRICE_PRESCRIPTIONS: "/pricing/procedures",
  PRICE_PROCEDURES: "/pricing/prescriptions",
  FIND_FACILITIES: "/find/facilities",
  FIND_PROVIDERS: "/find/providers",
  JOIN_WAITING_ROOM: "/waiting-room",
  RESCHEDULE_GM_VISIT: "/reschedule-gm-visit",
  RESCHEDULE_MH_VISIT: "/reschedule-mh-visit",
  INBOX: "/inbox",
};

export const websdkRoutesArray = Object.values(WEBSDK_ROUTES);
