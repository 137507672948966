import React from "react";
import { Link } from "react-router-dom";

const HealthcareBenefitBanner = ({ className, isBigBanner = false }) => {
  return (
    <div className={className}>
      Participants/Dependents under age 18 seeking virtual behavioral health services, click{" "}
      <Link to="/uhcmentalhealth" aria-label="More information about Participants/Dependents under age 18">
        here
      </Link>
      {isBigBanner ? " for more information." : "."}
    </div>
  );
};

export default HealthcareBenefitBanner;
