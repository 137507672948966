import { createAsyncThunk } from "@reduxjs/toolkit";
import { getConsultations, cancelConsultation } from "@td/websdk";
import { callSdk } from "../services/websdkService";
import { WebSdkErrorMessage } from "../../components/WebSdkProvider";
import hyApi from "../services/hyApiService";

export const getConsultationSDKList = createAsyncThunk("websdk/getConsultationList", async (data, thunkAPI) => {
  let response: any = await callSdk(getConsultations);

  if (!Array.isArray(response)) {
    return thunkAPI.rejectWithValue(response);
  }
  return response;
});

export const cancelVisit = createAsyncThunk("websdk/cancelVisit", async (consultId: number) => {
  return callSdk(cancelConsultation, { consultId });
});

export const consultationLocked = createAsyncThunk("websdk/consultationLocked/", async (consultId: number) =>
  hyApi.post("api/consultation/locked", { consultId }).then(({ data }) => data)
);

