import React from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "../containers/slices/authSlice";

const footerLinkItemsUnauthenticated = [
  {
    label: "Notice of Privacy Practices",
    link: "https://www.teladoc.com/notice-of-privacy-practices/"
  },
  {
    label: "Notice of Non-Discrimination and Language Assistance",
    link: "https://teladochealth.com/notice-of-non-discrimination/"
  },
  {
    label: "Legal Disclaimer",
    link: "https://www.teladochealth.com/legal-disclaimer/"
  }
];

const footerLinkItemsAuthenticated = [
  {
    label: "Terms of Service",
    link: "https://teladochealth.com/terms-of-service/"
  },
  {
    label: "Notice of Non-Discrimination and Language Assistance",
    link: "https://teladochealth.com/notice-of-non-discrimination/"
  },
  {
    label: "Notice of Privacy Practices",
    link: "https://www.teladoc.com/notice-of-privacy-practices/"
  },
  {
    label: "Informed Consent Agreement",
    link: "https://www.teladoc.com/informed-consent-agreement/"
  }
];

const Footer = () => {
  const { hasAuth } = useSelector(selectAuth);
  const footerLinkItems = hasAuth ? footerLinkItemsAuthenticated : footerLinkItemsUnauthenticated;
  const lastLinksIndex = footerLinkItems.length - 1;

  return (
    <footer className="text-sm shadow-md bg-light-grey font-effra" data-testid="footerText">
      <div className="flex flex-col justify-center h-32 max-w-screen-xl py-4 pl-4 mx-auto sm:pl-8 md:pl-8 sm:h-32 lg:h-24">
        <div className="flex flex-col sm:flex sm:flex-col lg:flex lg:flex-row">
          {footerLinkItems.map((footerLinkItem, index) => (
            <div key={footerLinkItem.label}>
              <a
                href={footerLinkItem.link}
                className="pr-2 text-sm leading-tight lg:pl-2"
                target="_blank"
                rel="noopener noreferrer"
              >
                {footerLinkItem.label}
              </a>
              {index !== lastLinksIndex ? <span className="sr-only lg:not-sr-only"> | </span> : ""}
            </div>
          ))}
        </div>
        <div className="pt-4">
          <p className="text-pale-gray-50">© 2002-{new Date().getFullYear()} Teladoc, All rights reserved</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
