import React from "react";
import { Tabs, useTabState, usePanelState } from "@bumaga/tabs";
import clsx from "clsx";
import VisitsList from "./VisitsList";
import useConsultations from "./useConsultations.hook";
import { useSelector } from "react-redux";
import { selectMapi } from "../../containers/slices/mapiSlice";

const Tab = ({ children }) => {
  const { onClick, isActive } = useTabState();

  return (
    <button
      type="button"
      className={clsx(
        isActive && "text-blue-primary font-bold focus:outline-none  border-b-4 border-primary-blue",
        "mb-2 text-black w-auto py-1 px-2 block hover:text-black md:py-2 md:px-6"
      )}
      onClick={onClick}
      role="tab"
      aria-label={`${children?.props?.children} visit`}
      aria-selected={isActive}
      aria-controls={`${children?.key}-tab`}
      id={`${children?.key}-tab`}
    >
      {children}
    </button>
    
  );
};

const Panel = ({ children }) => {
  const isActive = usePanelState();
  return isActive ? children : <></>;
};


const Visits = () => {
  const { consultations } = useSelector(selectMapi);

  const { upcoming, requested } = useConsultations(consultations);

  return (
    <div data-testid="upcomingVisitsContainer" className="h-auto">
      <Tabs>
        <div className="flex justify-end space-x-1" role="tablist" aria-label="Visits">
          <Tab>
            <span key="upcoming">Upcoming ({upcoming.length})</span>
          </Tab>
          <Tab>
            <span key="requested">Requested ({requested.length})</span>
          </Tab>
        </div>
        <Panel>
          <VisitsList consultations={upcoming} tabName={"Upcoming"} />
        </Panel>
        <Panel>
          <VisitsList consultations={requested} tabName={"Requested"} />
        </Panel>
      </Tabs>
    </div>
  );
};

export default Visits;