import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { debounce } from "lodash";
import GMap from "../../components/Map/GMap";
import GMapMarker from "../../components/Map/GMapMarker";
import ProvidersList from "./ProvidersList";
import ProviderDetails from "./ProviderDetails";
import { useDoctors } from "../../queries/useProviders";
import { formatProvider, providersFiltersDefault, providersFiltersInterface } from "../../utils/findPage";
import { extractSearchParams } from "../../utils/url";
import ProvidersFilters from "./ProvidersFilters";
import { Spinner } from "../../components/Spinner";

interface searchParams {
  zip?: string;
  specialty?: string;
}

function ProvidersPage() {
  const { search: urlSearch } = useLocation();
  const searchParams: searchParams = extractSearchParams(urlSearch);
  const defaultFilterOptions = providersFiltersDefault(searchParams);
  const [providersFilters, setProvidersFilters] = useState<providersFiltersInterface>(defaultFilterOptions);

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [activeItemId, setActiveItemId] = useState<string | null>(null);
  const [mapCenter, setMapCenter] = useState<number[]>([0, 0]);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [items, setItems] = useState<any>([]);

  const doctors = useDoctors(providersFilters);

  const onItemAction = debounce((id, location) => {
    setSelectedItemId(id === selectedItemId ? "" : id);
    setMapCenter(location);
  }, 250);

  const clearFilters = (e) => {
    e.preventDefault();
    setProvidersFilters(defaultFilterOptions);
  };

  const onFieldChange = (newField) => {
    setProvidersFilters((oldFields) => ({ ...oldFields, ...newField }));
  };

  const renderLocationsOnMap = ({ map, maps: { LatLng, LatLngBounds, Marker } }) => {
    const bounds = new LatLngBounds();
    doctors?.data?.data?.doctors.forEach((provider) => {
      const lat = provider.practices[0].lat;
      const lng = provider.practices[0].lon;
      bounds.extend(new LatLng(lat, lng));
    });
    map.fitBounds(bounds);
  };

  useEffect(() => {
    const data = doctors?.data?.data?.doctors || [];

    setItems(data);
  }, [doctors?.dataUpdatedAt]);

  return (
    <main className="bg-gray-background flex flex-col flex-1 w-full mx-auto overflow-y-auto">
      <div className="justify-center w-full h-full max-w-5xl px-4 pb-8 mx-auto font-effra">
        <div className="my-6 ml-0" />
        <div className="flex flex-col w-full h-auto py-8 mb-8 space-y-6 bg-white rounded shadow-lg">
          <div className="w-full h-full px-4">
            {!selectedProvider ? (
              <div className=" flex flex-col w-full">
                <h1 className="text-4xl mb-8">Find providers</h1>
                <ProvidersFilters
                  clearFilters={clearFilters}
                  onFieldChange={onFieldChange}
                  providersFilters={providersFilters}
                  errors={doctors.isError ? doctors.error : null}
                />

                <div className="flex flex-col-reverse h-full md:flex-row gap-4">
                  {doctors?.isLoading || doctors?.isRefetching ? (
                    <div className="w-full max-h-450 h-40vh">
                      <Spinner fullScreen={false} />
                    </div>
                  ) : items.length ? (
                    <>
                      <div className="w-full overflow-auto max-h-450 h-40vh">
                        <ProvidersList
                          activeItemId={activeItemId}
                          onProviderSelect={(data) => setSelectedProvider(data)}
                          formatProvider={formatProvider}
                          providers={items || []}
                          selectedItemId={selectedItemId}
                          onItemHover={onItemAction}
                          setHoveredId={(id) => setSelectedItemId(id)}
                        />
                      </div>
                      <div className="grid w-full h-64 z-0 md:h-auto sticky" style={{ top: 10 }}>
                        <GMap center={mapCenter} renderLocations={renderLocationsOnMap}>
                          {items.map((provider, i) => {
                            const { id } = provider;
                            return (
                              <GMapMarker
                                key={id}
                                data={formatProvider(provider)}
                                active={activeItemId === id}
                                selected={selectedItemId === id}
                                onMouseEnter={() => setActiveItemId(id)}
                                onMouseLeave={() => setActiveItemId(null)}
                                onClick={() => onItemAction(id, [provider.practices[0].lat, provider.practices[0].lon])}
                                lat={provider.practices[0].lat}
                                lng={provider.practices[0].lon}
                              />
                            );
                          })}
                        </GMap>
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            ) : (
              <ProviderDetails
                onBackButton={() => {
                  setSelectedProvider(null);
                }}
                data={selectedProvider}
              />
            )}
          </div>
        </div>
      </div>
    </main>
  );
}

export default ProvidersPage;
