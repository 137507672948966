import { WebSDK } from "@td/websdk";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { createContext } from "react";
import { useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, useHistory } from "react-router";
import { toast } from "react-toastify";

import { getSdkToken } from "../api/auth";
import { cancelVisit, getConsultationSDKList } from "../api/websdk/consultations";
import getMessage from "../api/websdk/getMessage";
import { selectAuth, setReopenFlow } from "../containers/slices/authSlice";
import { RouteFlowMapping, selectWebSdk, clearSdkFlow, SdkPaths } from "../containers/slices/websdkSlice";
import {
  setDashboardPolling,
  setSdkTokenPolling,
  selectIntervals,
  clearSdkPolling,
  clearTokenLoginErrorCount,
  setResetDashboard
} from "../containers/slices/intervalsSlice";
import { webSDKConfig } from "../utils/webSdkConfiguration";
import Dialog from "./Dialog/Dialog";
import reset from "../api/websdk/reset";
import startSyncDash from "../api/websdk/startSyncDash";

interface WebSdkProviderData {
  cancelVisit: (consultId: number) => void;
}

const WEBSDK_POLLING_INTERVAL = 5 * 60 * 1000; // 5 minutes
const DASHBOARD_POLLING_INTERVAL = 30 * 1000; // 30 seconds
const DASHBORD_POLLING_RESET = 30 * 60 * 1000; // 30 minutes

const WebSdkContext = createContext<WebSdkProviderData>({
  cancelVisit: (consultId: number) => {}
});

export enum WebSdkErrorMessage {
  AUTH_FAILED = "WEBSDK_AUTH_ERROR"
}

export const WebSdkProvider = ({ children }) => {
  const location = useLocation();
  const dispatch = useDispatch();

  // Selector Data
  const { entities, messagesLoading, syncConsults, syncDashboard, consultsLoading } = useSelector(selectWebSdk);
  const { tokenLoginErrorCount, hasEligibilityError, pollingDashboardInterval, sdkTokenInterval } = useSelector(
    selectIntervals
  );
  const { hasSdk, entities: authEntity, sessionExpired, loading, teladocSdkToken, reopenFlow } = useSelector(
    selectAuth
  );

  const { baseUrl, baseIconUrl, apiToken, bundleIdentifier, baseHyUrl, googleApiKey, inboxConfig, locale } = webSDKConfig;
  const { path } = entities;
  const history = useHistory();

  // State
  const [showSdk, setShowSdk] = useState<boolean>(false);

  const onSdkClose = useCallback(() => {
    entities?.extraActions?.onClose?.();
    dispatch(clearSdkFlow());
    setShowSdk(false);
  }, [entities, dispatch]);

  const cancelConsult = useCallback(
    (consultId: number) => {
      dispatch(cancelVisit(consultId));
      dispatch(getConsultationSDKList());
    },
    [dispatch]
  );

  useEffect(() => {
    if (reopenFlow && path) {
      entities?.extraActions?.onClose?.();
      dispatch(clearSdkFlow());

      var pathKey = Object.keys(SdkPaths).find((key) => SdkPaths[key] === path);
      setTimeout(
        () =>
          history.push(
            Object.keys(RouteFlowMapping).find((key) => RouteFlowMapping[key] === pathKey) || "/request-a-visit"
          ),
        1
      );
      dispatch(setReopenFlow(false));
    }
  }, [dispatch, reopenFlow, entities, history, path]);

  // If session is expired, clear the state and inform the user
  // nothing sets session to expired?
  useEffect(() => {
    if (showSdk && sessionExpired) {
      onSdkClose();
      toast.error("Your session has expired. Please login and try again.");
    }
  }, [onSdkClose, sessionExpired, showSdk]);

  const flow = useMemo(() => location.pathname.replace("/", ""), [location.pathname]);

  // Clear sdkFlow if !RouteFlowMapping[flow] is false
  useEffect(() => {
    if (!RouteFlowMapping[flow]) {
      setShowSdk(false);
      dispatch(clearSdkFlow());
    }
  }, [dispatch, flow]);

  //setting interval for fetching sdkToken
  useEffect(() => {
    if (hasSdk && !sdkTokenInterval && authEntity?.profile?.id) {
      const interval = setInterval(() => {
        dispatch(getSdkToken(authEntity.profile.id));
      }, WEBSDK_POLLING_INTERVAL);
      dispatch(setSdkTokenPolling(interval));
    }
  }, [authEntity, dispatch, hasSdk, sdkTokenInterval]);

  // Open SDK Window when the path has changed and the sdk is not open
  useEffect(() => {
    if (hasSdk && Object.values(SdkPaths).includes(path) && !showSdk) {
      setShowSdk(true);
    }
  }, [authEntity, hasSdk, path, showSdk]);

  /*
    thanking of this as a FSM
    GetMessage is state must be MessageLoading true and syncDashboard changed ( true | false )
    getConsults is state must be syncConsults true and consultsLoading true
    dashboard interval sets syncDashboard state to opposite of its current value
    reset interval sets state to messageLoading false and consultsLoading false
   */

  // Get Messages & Consultations from WebSDK on mount & interval
  useEffect(() => {
    const checkingForSDK =
      hasSdk && !pollingDashboardInterval && tokenLoginErrorCount === 0 && !hasEligibilityError && !loading.saml;

    if (checkingForSDK) {
      dispatch(startSyncDash()); // Sync is triggered as soon page loads, then interval kicks in
      const interval = setInterval(() => {
        dispatch(startSyncDash());
      }, DASHBOARD_POLLING_INTERVAL); // 30 seconds
      dispatch(setDashboardPolling(interval));

      // this is a 30 minute interval meant to set everything back due to an offline state giving it a chance to check if things have recovered but the intervals are stuck in a shut down state
      // aka messageloading = true and consultsLoading = true. both will be set to false and the next interval of dashboard polling will trigger the next cycle
      const resetInterval = setInterval(() => {
        dispatch(reset());
      }, DASHBORD_POLLING_RESET); // 30 minutes
      dispatch(setResetDashboard(resetInterval));
    }
  }, [
    dispatch,
    hasSdk,
    pollingDashboardInterval,
    loading.saml,
    messagesLoading,
    tokenLoginErrorCount,
    hasEligibilityError
  ]);

  // syncDashboard is triggered by interval, and is meant to flip back and forth to trigger the use effect
  // messagesLoading is true when GetMessage is pending, it will only be false due to a reset or getMessage success
  useEffect(() => {
    if (!messagesLoading && syncDashboard) dispatch(getMessage());
  }, [syncDashboard, dispatch, getMessage, messagesLoading]);

  // syncConsults should only be true, if messages request was successful. it is meant to trigger the use effect from getMessage success
  // consultsLoading is true when getConsultationList is pending, it will only be false due to a reset or getMessage success
  useEffect(() => {
    if (!consultsLoading && syncConsults) dispatch(getConsultationSDKList());
  }, [syncConsults, dispatch, getConsultationSDKList, consultsLoading]);

  useEffect(() => {
    if (tokenLoginErrorCount > 5) {
      dispatch(clearSdkPolling());
      dispatch(clearTokenLoginErrorCount());
    }
  }, [tokenLoginErrorCount]);

  const appConfigValues = useMemo(
    () => ({
      baseUrl,
      baseIconUrl,
      baseHyUrl,
      apiToken,
      bundleIdentifier,
      initialState: entities.initialState,
      hyJwtToken: teladocSdkToken,
      googleApiKey,
      inboxConfig,
      locale
    }),
    [apiToken, baseHyUrl, baseIconUrl, baseUrl, bundleIdentifier, entities.initialState, googleApiKey, teladocSdkToken]
  );

  const getDialogConfig = () => {
    if (path && path == SdkPaths.Inbox) {
      return { fullWidth: true }
    }
    return { fullWidth: false }    
  };

  return (
    <WebSdkContext.Provider
      value={{
        cancelVisit: cancelConsult
      }}
    >
      {children}
      <Dialog showDialog={showSdk} onClose={onSdkClose} dialogConfig={getDialogConfig()}>
        <WebSDK
          path={webSDKConfig.paths[entities.path]}
          authorization={{ ssoToken: appConfigValues.hyJwtToken }}
          appConfiguration={appConfigValues}
          extendedConfiguration={entities.extendedConfiguration}
          onExit={onSdkClose}
        />
      </Dialog>
    </WebSdkContext.Provider>
  );
};

export const useWebSdk = () => {
  const context = useContext(WebSdkContext);
  if (context === undefined) {
    throw new Error("useWebSdk can only be used inside WebSdkProvider");
  }
  return context;
};

export default WebSdkProvider;