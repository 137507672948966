import React from "react";
import FavoriteItemWrapper from "./FavoriteItemWrapper";
import { FavoriteComponentProps } from "./FavoriteTypes";

const Recipe = ({ element, onUnfavorite }: FavoriteComponentProps) => (
  <FavoriteItemWrapper onUnfavorite={onUnfavorite} element={element}>
    <div style={{ backgroundImage: `url(${element.avatar})` }} className="favorite-avatar-recipe rounded" />
  </FavoriteItemWrapper>
);

export default Recipe;
