import React from "react";

interface Props {
  children: any;
}

const WidgetHeader = ({ children }: Props) => {
  return <div className="flex items-center px-4 py-2 space-x-4">{children}</div>;
};

export default WidgetHeader;
