import { useQuery } from "react-query";
import { toast } from "react-toastify";

import { fetchPlaces } from "../api/pricing";
import { findFacility } from "../utils/findPage";

function useFacilities(params) {
  const enabled = params.zip.length === 5 && params.enabled;
  const kinds = params.kind.map((k) => findFacility(k, "label")?.value).join(",");
  const query = new URLSearchParams({ ...params, kind: kinds });

  return useQuery(["places", query.toString()], () => fetchPlaces(query), {
    enabled,
    onError: () => {
      toast.error("No facility found. Please, update search params.");
    }
  });
}

export default useFacilities;
