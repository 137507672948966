import React from "react";

const FormCard = ({ children, title }) => {
  return (
    <div
      data-testid={`form-card-${title.split(" ").join("_")}`}
      className="justify-center w-full h-full max-w-5xl px-4 pb-8 mx-auto font-effra"
    >
      <h1 className="my-6 ml-0 text-4xl font-medium leading-tight">{title}</h1>
      <div className="flex flex-col w-full h-auto py-8 mb-8 space-y-6 bg-white rounded shadow-lg">{children}</div>
    </div>
  );
};

export default FormCard;
