import React from "react";
import { ReactComponent as FacilityIcon } from "../../assets/icons/facility.svg";
import FavoriteItemWrapper from "./FavoriteItemWrapper";
import { FavoriteComponentProps } from "./FavoriteTypes";

const Facility = ({ element, onUnfavorite }: FavoriteComponentProps) => (
  <FavoriteItemWrapper onUnfavorite={onUnfavorite} element={element}>
    <FacilityIcon className="favorite-avatar" />
  </FavoriteItemWrapper>
);

export default Facility;
