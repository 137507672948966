export const convertObjectToQueryString = (params) => {
  return Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");
};

export const extractSearchParams = (search) => {
  const searchParams = new URLSearchParams(search);
  const params = {};

  for (const [key, value] of searchParams.entries()) {
    params[key] = value;
  }

  return params;
};
