import React from "react";
import FindPageListItem from "../../components/FindPage/FindPageListItem";

function FacilitiesList({
  facilities,
  formatFacility,
  activeItemId,
  selectedItemId,
  setHoveredId,
  setMapCenter,
  onFacilitySelect = () => {},
  onItemHover
}) {
  return facilities?.map((facility) => {
    const { id } = facility.data;
    return (
      <FindPageListItem
        key={id}
        data={formatFacility(facility)}
        active={activeItemId === id}
        selected={selectedItemId === id}
        onSelect={onFacilitySelect}
        onMouseEnter={() =>
          onItemHover(id, [facility?.data?.locationData?.latitude, facility?.data?.locationData?.longitude])
        }
        onMouseLeave={() => setHoveredId(null)}
        // addToFavorite={() => { }}
        favoriteList={[]}
      />
    );
  });
}

export default FacilitiesList;
