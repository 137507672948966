import { useQuery } from "react-query";
import { fetchDoctors } from "../api/pricing";
import { toast } from "react-toastify";
import { findGender, findSpecialty, providersFiltersInterface } from "../utils/findPage";

interface Doctor {
  id: string;
  practices: [
    {
      locationSlug: null;
      withinSearchArea: boolean;
      distance: number;
      lat: number;
      lon: number;
      uid: string;
      npi: string;
      tin: null;
      slug: null;
      name: string;
      website: string;
      email: null;
      description: null;
      acceptsNewPatients: boolean;
      imageUrls: null;
      insuranceUids: null;
      visitAddress: {
        city: string;
        lat: number;
        lon: number;
        state: string;
        stateLong: string;
        street: string;
        zip: string;
      };
      officeHours: null;
      phones: [
        {
          number: string;
          type: string;
        }
      ];
      languages: null;
      media: null;
      totalDoctors: number;
      doctorsPaginationUrl: null;
      doctors: null;
      insurance_uids: string[];
    }
  ];
  isOpenDoctor: boolean;
  educations: [
    {
      school: null;
      graduationYear: null;
      degree: null;
    }
  ];
  profile: {
    firstName: string;
    middleName: string;
    lastName: string;
    slug: string;
    title: string;
    imageUrl: null;
    gender: null;
    languages: null;
    bio: string;
  };
  ratings: null;
  insurances: [];
  specialties: [
    {
      uid: null;
      name: string;
      description: null;
      category: null;
      actor: null;
      actors: null;
    },
    {
      uid: null;
      name: string;
      description: null;
      category: null;
      actor: null;
      actors: null;
    }
  ];
  hospitalAffiliations: null;
  groupAffiliations: null;
  claims: [];
  licenses: [];
  uid: string;
  npi: string;
  inNetwork: boolean;
}

interface Doctors {
  data: {
    doctors: Doctor[];
  };
  meta: {
    limit: number | null;
    page: number;
    prePaginationCount: number | null;
    total: number;
    type: string;
  };
  error: Error;
  errors: Error[];
}

export function useDoctors(filterParams: providersFiltersInterface) {
  let enabled = filterParams.zip.length === 5 && filterParams.enabled;
  const { enabled: _, ...newParams } = filterParams;

  const gender = findGender(filterParams.gender)?.value as string;
  // if specialty is undefined, return general
  const specialty = findSpecialty(filterParams.specialty)?.value || "general";

  if (!gender || !specialty) enabled = false;

  const params = new URLSearchParams({ ...newParams, gender, specialty, version: "2" } as any);

  return useQuery<Doctors, Error>(
    ["doctors", params.toString()],
    async () => {
      const data = await fetchDoctors(params);
      const doctorsData = data?.data?.doctors || [];

      toast.dismiss("PROVIDER_TOAST");

      if (doctorsData.length === 0 && enabled)
        toast.error("No providers found. Please, update search params.", { toastId: "PROVIDER_TOAST" });

      return data;
    },
    {
      enabled,
      onError: (err) => {
        toast.error("Failed to download details", { toastId: "PROVIDER_TOAST" });
      }
    }
  );
}
