import clsx from "clsx";
import { Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import postLogin from "../../api/auth";
import { registerProfile } from "../../api/registration";
import { ReactComponent as HYlogo } from "../../assets/HYLogoHoriz.svg";
import Alert from "../../components/ApiErrorAlert";
import Button from "../../components/Button";
import { ButtonGroup } from "../../components/ButtonGroup";
import { FormFieldError } from "../../components/FormFields/FieldError";
import { FormikCheckbox } from "../../components/Formik/FormikCheckbox";
import { FormikMaskedInput } from "../../components/Formik/FormikMaskedInput";
import { FormikTextInput } from "../../components/Formik/FormikTextInput";
import { useSpinner } from "../../components/SpinnerProvider";
import { useAppDispatch } from "../../store/store";
import { passwordValidation, phoneNumberMask, phoneValidation } from "../../utils/validationHelper";
import { selectAuth, setProfileUpdated } from "../slices/authSlice";
import {
  cancelLookup,
  clearAccountInfo,
  clearErrors,
  selectRegistration,
  setAccountInfo
} from "../slices/registrationSlice";
import PageTitleComponent from "../../components/PageTitleComponent";
import { FormikValidatePasswordInput } from "../../components/Formik/Password/FormikValidatePasswordInput";
import { FormikPasswordWithButtonInput } from "../../components/Formik/Password/FormikPasswordWithButtonInput";
import { toast } from "react-toastify";

const CreateAccount = () => {
  const [showPassword, setShowPassword] = React.useState(false);
  const [showVerifyPassword, setShowVerifyPassword] = React.useState(false);
  const { phone, password, email, loading, profileCreated, apiError } = useSelector(selectRegistration);
  const { hasAuth } = useSelector(selectAuth);
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { showSpinner } = useSpinner();

  useEffect(() => {
    showSpinner({
      show: loading
    });
  }, [loading, showSpinner]);

  useEffect(() => {
    dispatch(clearErrors());
    dispatch(clearAccountInfo());
  }, []);

  useEffect(() => {
    const canRegister = !loading && phone && password && email && !profileCreated && !apiError;
    if (canRegister) {
      dispatch(registerProfile());
    }
  }, [phone, password, email, profileCreated]);

  useEffect(() => {
    if (!loading && email && password && profileCreated) {
      dispatch(postLogin({ email, password }));
      dispatch(cancelLookup());
      dispatch(clearErrors());
    }
  }, [profileCreated, password, loading, email, dispatch]);

  useEffect(() => {
    if (hasAuth && !apiError) {
      history.push("/dashboard");
      toast.success("Account successfully created");
      dispatch(clearErrors());
      dispatch(setProfileUpdated(false));
    }
  }, [hasAuth, apiError]);

  const handleCancel = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(cancelLookup());
    history.push("/login");
  };

  const showHide = (input: string) => {
    if (input === "newPassword") {
      setShowPassword(!showPassword);
    }
    if (input === "verifyNewPassword") {
      setShowVerifyPassword(!showVerifyPassword);
    }
  };

  return (
    <>
      <div
        className="flex justify-center w-full max-w-md min-h-screen mx-auto font-effra"
        data-testid="registerContainer"
      >
        <PageTitleComponent title="Create Account" />
        <div className={"flex-col mx-auto w-full"}>
          <HYlogo className="inline-block h-16 mt-10 ml-4 fill-current" title="HY Logo" />
          <h1
            className="flex items-center justify-between my-5 ml-4 text-2xl font-light text-grey-darkest"
            data-testid="titleText"
          >
            Create Account
          </h1>
          <div className="flex-1">
            <Formik
              initialValues={{
                email: "",
                phone: "",
                newPassword: "",
                verifyNewPassword: "",
                acceptTerms: false,
                acceptNotice: false
              }}
              enableReinitialize={true}
              validationSchema={Yup.object({
                newPassword: passwordValidation,
                verifyNewPassword: passwordValidation.oneOf([Yup.ref("newPassword"), ""], "Passwords must match"),
                phone: phoneValidation,
                email: Yup.string().email("Invalid email").required("Required"),
                acceptTerms: Yup.boolean().oneOf([true], 'Must accept "Terms of Service"'),
                acceptNotice: Yup.boolean().oneOf([true], 'Must accept "Notice of Privacy Practices"')
              })}
              onSubmit={(values, { setSubmitting }) => {
                dispatch(setAccountInfo(values));
                setSubmitting(false);
              }}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form className="max-w-sm mx-4">
                  {apiError && <Alert errors={apiError} />}
                  <p
                    aria-hidden
                    className="mr-4 -mb-5 text-sm text-right font-effra text-gray-default sm:mr-4 md:mr-4 lg:mr-0"
                  >
                    * Required
                  </p>
                  <div className="mb-4">
                    <FormikTextInput
                      label="Email address"
                      isRequired={true}
                      type="email"
                      name="email"
                      placeholder="Please enter email"
                      data-testid="email"
                    />
                  </div>
                  <div className="mb-4">
                    <Field name="phone" type="text">
                      {({ field, form, meta }) => (
                        <>
                          <FormikMaskedInput
                            meta={meta}
                            label="Phone Number"
                            mask={phoneNumberMask}
                            field={field}
                            {...form}
                          />
                          <FormFieldError field={field} meta={meta} />
                        </>
                      )}
                    </Field>
                  </div>
                  <FormikValidatePasswordInput
                    label="New Password"
                    isRequired={true}
                    type={showPassword ? "text" : "password"}
                    placeholder="Please enter password"
                    data-testid="new-password"
                    name="newPassword"
                  />

                  <FormikPasswordWithButtonInput
                    label="Verify New Password"
                    isRequired={true}
                    type={showVerifyPassword ? "text" : "password"}
                    placeholder="Please enter password again"
                    data-testid="verify-new-password"
                    name="verifyNewPassword"
                    showFieldError={true}
                  />
                  <div className="flex flex-col mb-4">
                    <div className="flex">
                      <FormikCheckbox name="acceptTerms">Accept</FormikCheckbox>
                      <p
                        className={clsx(
                          touched.acceptTerms && errors.acceptTerms && "italic text-red-500",
                          "text-primary-blue mx-1"
                        )}
                      >
                        <a href="https://teladochealth.com/terms-of-service/" target="_blank" rel="noopener noreferrer">
                          "Terms of Service"
                        </a>
                      </p>
                    </div>
                    {touched.acceptTerms && errors.acceptTerms ? (
                      <p className="text-xs italic text-red-500">{errors.acceptTerms}</p>
                    ) : null}
                  </div>
                  <div className="flex flex-col mb-4">
                    <div className="flex">
                      <FormikCheckbox name="acceptNotice">Accept</FormikCheckbox>
                      <p
                        className={clsx(
                          touched.acceptNotice && errors.acceptNotice && "italic text-red-500",
                          "text-primary-blue mx-1"
                        )}
                      >
                        <a
                          href="https://www.teladoc.com/notice-of-privacy-practices/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          "Notice of Privacy Practices"
                        </a>
                      </p>
                    </div>
                    {touched.acceptNotice && errors.acceptNotice ? (
                      <p className="text-xs italic text-red-500">{errors.acceptNotice}</p>
                    ) : null}
                  </div>
                  <p>
                    If the phone number is a mobile number, we will send you alerts and updates by text message. These
                    can be turned off in your account settings. Standard text messaging data and data rates from your
                    carrier may apply.
                  </p>
                  <ButtonGroup>
                    <Button disabled={isSubmitting} buttonType="secondary" onClick={handleCancel} name="cancel">
                      Cancel
                    </Button>
                    <Button disabled={isSubmitting || profileCreated} buttonType="primary" type="submit" name="next">
                      Next
                    </Button>
                  </ButtonGroup>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateAccount;
