import React, { useState } from "react";

import PrescriptionsSearch from "./PrescriptionsSearch";
import PricePrescriptionsDetails from "./PricePrescriptionsDetails";

const Prescriptions = () => {
  const [priceDetails, setDrugDetails] = useState(null);

  return (
    <div className="flex flex-col relative h-80vh w-full max-w-4xl mx-auto p-4 m-6 bg-white shadow-lg">
      <PrescriptionsSearch setDrugDetails={setDrugDetails} />
      {priceDetails && <PricePrescriptionsDetails priceDetails={priceDetails} onClose={() => setDrugDetails(null)} />}
    </div>
  );
};

export default Prescriptions;
