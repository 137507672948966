import React from "react";

const Spinner = ({ text = "", fullScreen = true }) => {
  return (
    <div
      className={`fixed ${
        fullScreen ? "h-screen w-screen" : "relative h-full w-full"
      } flex justify-center items-center `}
    >
      <div
        className="relative w-16 h-16 border-3 border-solid border-transparent rounded-full animate-spin"
        style={{ borderTopColor: "#005c95", top: "-10%" }}
      />
      {text && <div className="text-base">{text}</div>}
    </div>
  );
};

export default Spinner;
