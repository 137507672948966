import { Tabs, usePanelState } from "@bumaga/tabs";
import React from "react";
import Facilities from "./Facilities";
import Providers from "./Providers";
import WidgetTab from "../Tabs";

const Panel = ({ children }) => {
  const isActive = usePanelState();
  return isActive ? <>{children}</> : null;
};

const Find = ({ zipCode, hasProviders, hasFacilities }) => {
  return (
    <div data-testid="findContainer" className="pb-8 pl-1 ml-12 sm:ml-12 sm:pl-1 md:ml-20 md:pl-1">
      <Tabs>
        <div className="flex space-x-1">
          {hasProviders && (
            <WidgetTab>
              <span key="providers">Provider</span>
            </WidgetTab>
          )}
          {hasFacilities && (
            <WidgetTab>
              <span key="facilities">Facility</span>
            </WidgetTab>
          )}
        </div>
        {hasProviders && (
          <Panel>
            <Providers zipCode={zipCode} />
          </Panel>
        )}
        {hasFacilities && (
          <Panel>
            <Facilities zipCode={zipCode} />
          </Panel>
        )}
      </Tabs>
    </div>
  );
};

export default Find;
