import React from "react";

import Button from "../Button";
import { useHistory } from "react-router-dom";
import { trackEvent } from "../../api/piwik/piwikService";
import { PiwikAction, PiwikCategory, PiwikName } from "../../@types/common";
import { useSelector } from "react-redux";
import { selectAuth } from "../../containers/slices/authSlice";
import HealthcareBenefitBanner from "../HealthCareBenefitBanner";

const RAV = () => {
  const history = useHistory();
  const handleOnClick = () => {
    trackEvent({
      category: PiwikCategory.BUTTONS,
      action: PiwikAction.CLICKED,
      name: PiwikName.RAV
    });

    history.push("/request-a-visit");
  };

  const {
    entities: { group }
  } = useSelector(selectAuth);

  return (
    <>
      <p data-testid="RAVTitle" className="pb-8 pl-1 ml-12 sm:ml-12 sm:pl-1 md:ml-20 md:pl-1">
        Treatment for common care needs in adults and children
      </p>
      <div className="flex justify-between pl-1 ml-8 md:ml-10">
        <Button onClick={handleOnClick}>Request a visit</Button>
        {group?.code?.includes("HYUHG") && <HealthcareBenefitBanner className="text-sm pl-8 max-w-xs" />}
      </div>
    </>
  );
};

export default RAV;
