import React, { useState } from "react";
import { ReactComponent as FilterSVG } from "../../assets/icons/controls-setup-preferences.svg";
import Autocomplete from "../../components/FindPage/Autocomplete";
import Input from "../../components/FindPage/Input";
import Select from "../../components/FindPage/Select";
import { genderOptions, radiusOptions, specialties } from "../../utils/providers";
import ZipCodeInput from "../../components/ZipCodeInput";

function ProvidersFilters({ providersFilters, onFieldChange, clearFilters, errors }) {
  const [isFilterOpened, setIsFilterOpened] = useState(true);

  return (
    <div className="w-full rounded-lg border-1 md:border-0 border-pale-grey-200 mb-5">
      <div
        onClick={() => setIsFilterOpened((prev) => !prev)}
        className="flex justify-between text-primary-blue px-3 items-center rounded-lg h-12  md:hidden"
      >
        <span className="cursor-pointer">Find and filter</span>
        <FilterSVG />
      </div>
      <form id="containerForm w-full">
        <div className={`w-full bg-off-white rounded-lg p-4 mb-2 ${!isFilterOpened && "hidden"} md:flex`}>
          <div className="w-full flex flex-col">
            <div className="flex flex-wrap gap-3 items-center mb-6 w-full sm:gap-5">
              <Input
                value={providersFilters.last}
                onChange={(e) => {
                  onFieldChange({ last: e.target.value });
                }}
                className="sm:w-40"
                header="Providers last name"
                id="last"
                placeholder="Enter last name"
              />
              <ZipCodeInput
                value={providersFilters.zip}
                errors={errors?.message}
                errorOnEmpty="RequiredZipCode"
                onChange={onFieldChange}
                className="sm:w-32"
                header="Zip"
                id="zip"
                placeholder="Enter zip"
              />
              <Select
                id="radius"
                header="Radius (mi)"
                className="xs:w-full sm:w-24"
                selectedVal={providersFilters.radius}
                options={radiusOptions}
                handleChange={(radius) => {
                  onFieldChange({ radius });
                }}
                handleSubmit={() => {}}
              />
              <Autocomplete
                id="specialty"
                header="Specialty"
                className="xs:w-full sm:w-auto inline"
                options={specialties}
                placeHolder="Enter a value"
                selectedVal={providersFilters.specialty}
                onChange={(specialty) => {
                  onFieldChange({ specialty });
                }}
                onSubmit={() => {}}
                selectAllOnClick
              />

              <Select
                id="gender"
                header="Provider gender"
                className="xs:w-full sm:w-auto"
                selectedVal={providersFilters.gender}
                options={genderOptions}
                placeHolder="Enter a value"
                handleChange={(gender) => {
                  onFieldChange({ gender });
                }}
                handleSubmit={() => {}}
              />
            </div>

            <div className="flex justify-end float-right mr-5  opacity-75">
              <button
                className="text-primary-blue cursor-pointer hover:text-primary-hover"
                onClick={clearFilters}
                type="button"
              >
                Clear filters
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ProvidersFilters;
