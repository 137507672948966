import React, { useEffect, useRef } from "react";
import { ReactComponent as ZoomSVG } from "../assets/icons/zoom.svg";

import { useClickAway } from "react-use";
import clsx from "clsx";
import { CircledCrossIcon } from "./Icons";

interface Props {
  id: string;
  options: {
    key: string;
    value: string;
  }[];
  className?: string;
  inputClassName?: string;
  placeHolder: string;
  initialValue?: string;
  showCrossIcon?: boolean;
  shouldFilter?: boolean;
  clearOnClickAway?: boolean;
  handleChange?: (searchTerm: string) => any;
  handleSubmit?: (e: { key: string; value: string }) => void;
}

const Autocomplete = ({
  id,
  options,
  placeHolder,
  handleChange,
  handleSubmit = () => {},
  initialValue = "",
  className = "",
  showCrossIcon = false,
  shouldFilter = true,
  clearOnClickAway = true,
  inputClassName = ""
}: Props) => {
  const [searchTerm, setSearchTerm] = React.useState<string>(initialValue);
  const [selectionMade, setSelectionMade] = React.useState<boolean>(!!initialValue);
  const [isFocused, setIsFocused] = React.useState<boolean>(false);

  const wrapperRef = useRef(null);

  useClickAway(wrapperRef, () => clearOnClickAway && setSearchTerm(""));

  const makeSelection = (selection: { key: string; value: string }) => {
    setSelectionMade(true);
    handleSubmit(selection);
    setSearchTerm(selection.value);
  };

  const updateSearchTerm = (value: string) => {
    if (handleChange) {
      handleChange(value);
    }

    setSearchTerm(value);
    setSelectionMade(false);
  };

  const clearSearchTerm = () => {
    updateSearchTerm("");
    handleSubmit({ key: "", value: "" });
  };

  const handleKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      handleSubmit(filteredOptions[0] || { value: "" });
    }
  };

  const filteredOptions = shouldFilter
    ? options
        ?.filter((option) => option?.value?.toLowerCase().includes(searchTerm.toLowerCase()))
        .sort((a, b) => (a.value > b.value ? 1 : -1))
    : options;

  const showList = !!searchTerm && !!filteredOptions && !selectionMade && isFocused;

  useEffect(() => {
    if (!initialValue) return;
    setSearchTerm(initialValue);
  }, [initialValue]);

  return (
    <React.Fragment>
      <div className="relative" ref={wrapperRef}>
        <div
          id={`${id}AutocompleteContainer`}
          data-testid="autocompleteContainer"
          className={clsx("relative rounded-lg shadow-sm", className)}
        >
          <input
            id={`${id}Autocomplete`}
            autoComplete="off"
            aria-autocomplete="list"
            aria-expanded={showList}
            aria-controls={`${id}AutocompleteList`}
            aria-activedescendant={`${id}Autocomplete`}
            role="combobox"
            className={clsx(
              "relative block w-full py-3 pl-4 leading-tight text-gray-700 bg-white border rounded-lg appearance-none border-pale-grey-200 focus:border-primary-blue",
              inputClassName
            )}
            style={{ paddingRight: "70px" }}
            type="text"
            placeholder={placeHolder}
            name="search"
            value={searchTerm}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setTimeout(() => setIsFocused(false), 300)}
            onChange={(e) => updateSearchTerm(e.target.value)}
            onKeyDown={handleKeyDown}
            data-testid="autocomplete"
          />
          <div
            className={`absolute inset-y-0 right-0 flex items-center px-0 text-gray-700 ${
              !showCrossIcon && !searchTerm && "pointer-events-none"
            }`}
          >
            {showCrossIcon && searchTerm && (
              //  @TODO change inline style to tailwind className
              <button
                className="hover:pointer"
                style={{ width: "18px" }}
                onClick={clearSearchTerm}
                aria-label="Clear search term"
              >
                <CircledCrossIcon className="fill-current -mr-1" aria-hidden />
              </button>
            )}
            <ZoomSVG className="fill-current pointer-events-none" title="Zoom icon" aria-hidden />
          </div>
        </div>
        <div
          className={clsx(
            filteredOptions?.length == 0 && "hidden",
            filteredOptions?.length > 0 && searchTerm && !selectionMade && "h-64",
            filteredOptions?.length < 1 && !searchTerm && "h-0",
            "absolute w-full z-10"
          )}
        >
          {showList && (
            <ul
              id={`${id}AutocompleteList`}
              aria-label={`${id} options`}
              role="listbox"
              className="absolute w-full h-auto max-h-full overflow-y-auto bg-white shadow-lg border-pale-grey-200 border rounded-lg rounded-t-none top-2 left-2 full-scrollbar"
            >
              {filteredOptions.map((option) => (
                <li
                  role="option"
                  aria-selected="true"
                  data-testid={`item-${option.value}`}
                  className="block w-full py-4 pl-8 pr-4 font-medium bg-white autocomplete-border-b cursor-pointer border-pale-grey-200 hover:bg-pale-grey-10 focus:bg-pale-grey-10"
                  key={option.key}
                  onClick={() => makeSelection(option)}
                >
                  {option.value}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Autocomplete;
