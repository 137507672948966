import React, { useState, useEffect } from "react";
import axios from "axios";

interface MaintenanceData {
  message: string;
}

const MaintenanceMode = ({ children }) => {
  const [data, setData] = useState<MaintenanceData | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        var isProd = process.env.NODE_ENV === "production";
        const url = `https://healthiestyou-maintenance${
          isProd ? "-prod" : ""
        }.s3-us-west-2.amazonaws.com/member-maintenance.json`;
        const result = await axios(url);
        setData(result.data);
      } catch (e) {
        setData(null);
      }
    };

    fetchData();

    const interval = setInterval(() => {
      fetchData();
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      {data && data.message ? (
        <div className="bg-gray-100 flex flex-1 items-center ">
          <div className="rounded-lg shadow-lg p-4 mx-auto mt-8 max-w-md">
            <p className="text-lg text-gray-800 text-center">{data.message}</p>
          </div>
        </div>
      ) : (
        children
      )}
    </>
  );
};

export default MaintenanceMode;
