import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import Button from "../../components/Button";
import { FormikTextInput } from "../../components/Formik/FormikTextInput";
import { useAppDispatch } from "../../store/store";
import { logout, selectAuth } from "../slices/authSlice";
import { ReactComponent as HYLogoHoriz } from "../../assets/HYLogoHoriz.svg";
import LinkButton from "../../components/LinkButton";
import { verifyMFACode, sendMFACode } from "../../api/MFA";
import { FormikCheckbox } from "../../components/Formik/FormikCheckbox";
import { anonymizeEmail, anonymizePhoneNumber } from "../../utils/anonymizeHelper";
import { useLocalStorage } from "react-use";


const VerifyMFACode = () => {
  const dispatch = useAppDispatch();
  const { MFAPreferredDeliveryId, MFAPreferredDeliveryMethod, entities, teladocSdkToken, hasSdk, mapiToken, hasMapi } = useSelector(selectAuth);
  const [isSubmitting, setSubmitting] = useState(false);
  const history = useHistory();
  const [anonymousDeliveryId, setAnonymousDeliveryId] = useState("");
  const [phoneLabel, setPhoneLabel] = useState(undefined);
  const [rememberDeviceMFA, setRememberDeviceMFA, removeRememberDeviceMFA] = useLocalStorage<boolean>("rememberDeviceMFA", false);

  const handleCodeSubmit = async (values, resetForm) => {
    try {
      if (!hasMapi || !mapiToken) {
        throw new Error("Mapi Token required");
      }
      const response = await dispatch(verifyMFACode({
        mapiJwt: mapiToken,
        pin: values.code,
        rememberDevice: values.rememberDevice
      }));
      
      if (response?.payload?.error_messages?.length > 0) {
        toast.error(response?.payload?.error_messages[0], { style: { height: "auto" } });
        resetForm()
      } else {
        if (response.meta.arg.rememberDevice !== undefined) {
          setRememberDeviceMFA(response.meta.arg.rememberDevice)
        }
        history.push("/dashboard");
      }

    } catch (e) {
      toast.error("We are sorry there was an error verifying your account. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const handleResend = () => {
    if (hasMapi && mapiToken) {
      dispatch(sendMFACode({
        deliveryMethod: MFAPreferredDeliveryMethod,
        communicationId: MFAPreferredDeliveryId,
        mapiJwt: mapiToken
      })).then(() => {
        toast.success("The PIN has been resent");
      }).catch((e) => {
        toast.error("We are sorry there was an error trying to resend the PIN. Please try again.");
        setSubmitting(false);
      })
    } else {
      toast.error("We are sorry there was an error trying to resend the PIN. Please try again.");
      setSubmitting(false);
    }
  };

  const handleReturnToSignIn = () => {
    dispatch(logout());
    history.push("/login");
  };

  useEffect(() => {
    if (MFAPreferredDeliveryMethod === "phone" && MFAPreferredDeliveryId) {
      setAnonymousDeliveryId(anonymizePhoneNumber(MFAPreferredDeliveryId));
      if (history.location.state.phoneLabel) {
        setPhoneLabel(history.location.state.phoneLabel)
      }
    } else if (MFAPreferredDeliveryMethod === "email" && MFAPreferredDeliveryId) {
      setAnonymousDeliveryId(anonymizeEmail(MFAPreferredDeliveryId));
    }
  }, [MFAPreferredDeliveryMethod, MFAPreferredDeliveryId]);

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="m-6">
          <HYLogoHoriz className="mx-auto w-auto h-16" />
          <div>
            <h2 className="mt-6 text-3xl font-extrabold text-dark-gray">Two-Factor Authentication Required</h2>
            <p className="pb-2 mt-8 leading-tight text-justify font-effra text-dark-gray">
              The PIN was sent to: <b>{phoneLabel ? phoneLabel : anonymousDeliveryId}</b>
            </p>
            <p className="pb-2 mt-8 leading-tight text-justify font-effra text-dark-gray">
              When you get it, please enter it below. The code will expire in 30 minutes after being sent.
            </p>
            <Formik
              initialValues={{
                code: "",
                rememberDevice: false
              }}
              enableReinitialize={true}
              validationSchema={Yup.object({
                code: Yup.string().required("Required"),
                rememberDevice: Yup.boolean()
              })}
              onSubmit={(values, { resetForm }) => {
                setSubmitting(true);
                handleCodeSubmit(values, resetForm);
              }}
            >
              {({ isSubmitting, isValid }) => (
                <Form className="bg-white rounded mt-6 pt-6 pb-8 mb-4">
                  <div className="max-w-sm">
                    <FormikTextInput
                      label="Code"
                      name="code"
                      type="string"
                      placeholder="XXXXXX"
                    />
                    <p className="pb-2 mt-8 leading-tight text-justify font-effra text-dark-gray">
                      Still waiting for the code? if it has been a few minutes, please click the RESEND PIN link below
                    </p>
                    <FormikCheckbox name="rememberDevice">Remember this device in the future.</FormikCheckbox>
                    <div className="flex my-8 justify-center flex-col gap-4">
                      <Button disabled={isSubmitting || !isValid} type="submit">
                        Submit
                      </Button>
                      <Button type="button" buttonType="secondary" onClick={() => handleResend()}>
                        Resend PIN
                      </Button>
                      <LinkButton to="/login" onClick={() => handleReturnToSignIn()}>Return to sign in</LinkButton>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyMFACode;
