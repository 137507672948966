import { paths } from "@td/websdk";

const WEBSDK_ICON_URL = process.env.REACT_APP_ICON_BASE_URL;
const WEBSDK_URL = process.env.REACT_APP_WEBSDK_URL;
const HY_BASE_URL = process.env.REACT_APP_BASE_URL;

export const webSDKConfig = {
  baseUrl: WEBSDK_URL,
  baseHyUrl: `${HY_BASE_URL}api/`,
  baseIconUrl: WEBSDK_ICON_URL,
  paths: {
    rav: paths.RAV,
    messageCenter: paths.MESSAGE_CENTER,
    waitingRoom: paths.JOIN_WAITING_ROOM,
    medicalInfo: paths.MEDICAL_HISTORY,
    rescheduleGMVisit: paths.RESCHEDULE_GM_VISIT,
    rescheduleMHVisit: paths.RESCHEDULE_MH_VISIT,
    backCare: paths.BACK_CARE,
    invoices: paths.INVOICES,
    findFacilities: paths.FIND_FACILITIES,
    findProviders: paths.FIND_PROVIDERS,
    pricePrescriptions: paths.PRICE_PRESCRIPTIONS,
    priceProcedures: paths.PRICE_PROCEDURES,
    inbox: paths.INBOX
  },
  apiToken: process.env.REACT_APP_API_TOKEN, //"2e53a139e6774bf04626ebe88e1cdae991c59dc8",
  bundleIdentifier: "com.healthiestyou",
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  locale: 'en',
  inboxConfig: {
    pubnubEnabled: true,
    pubnubSubscribeKey: "sub-c-b9ed53f2-3621-11eb-88bb-1ad0e2424f4f",
    browserBaseUrl: "inbox"
  }
};
