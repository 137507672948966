import React from "react";
import clsx from "clsx";

export default function ({ className = "", iconFill = "#005c95" }) {
  return (
    <svg className={clsx(className)} xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g fill="none" fillRule="evenodd">
        <g fill={iconFill}>
          <g>
            <g>
              <g>
                <path
                  d="M50.88 29.12c.52.518.55 1.34.092 1.895l-.091.1L41.996 40l8.885 8.884c.55.551.55 1.445 0 1.997-.52.519-1.342.549-1.896.091l-.1-.091L40 41.996l-8.884 8.885c-.551.55-1.445.55-1.997 0-.519-.52-.549-1.342-.091-1.896l.091-.1L38.003 40l-8.884-8.884c-.55-.551-.55-1.445 0-1.997.52-.519 1.342-.549 1.896-.091l.1.091L40 38.003l8.884-8.884c.551-.55 1.445-.55 1.997 0z"
                  transform="translate(-849 -214) translate(390 186) translate(32) translate(399)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
