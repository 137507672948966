import React from "react";
import { ReactComponent as PhoneIcon } from "../assets/icons/phone.svg";
import { ReactComponent as EMSIcon } from "../assets/icons/EMS.svg";
import PageTitleComponent from "../components/PageTitleComponent";

const ExpertMedicalServices = () => {
  return (
    <div data-testid="expertMedicalServicesContainer" className="justify-center w-full h-full max-w-3xl mx-auto">
      <PageTitleComponent title="Expert Medical Services" />
      <h1 className="my-6 text-4xl font-medium leading-tight">Expert Medical Services</h1>
      <div className="flex flex-row w-full h-auto pr-8 bg-white rounded shadow-lg">
        <div className="px-6 pt-6 space-x-4">
          <EMSIcon className="w-16 h-16" aria-hidden />
        </div>
        <div className="pt-6 pb-10">
          <p className="pb-6 font-medium leading-tight">
            Expert Medical Services provides confidence and peace of mind to members who are making important medical
            decisions.
          </p>

          <p className="pb-4 text-lg font-bold">Expert Medical Opinion</p>
          <p className="pb-6 font-medium leading-tight">
            When you need answers, we can help. Get an Expert Medical Opinion from one of our world-renowned specialists
            so you can have the answers and confidence to make informed decisions about your health. You can get
            personalized guidance for questions like: Is there a better treatment plan? Is my doctor missing something?
            Do I really need surgery?
          </p>
          <p className="pb-4 text-lg font-bold">Find The Right Medical Doctor For You</p>
          <p className="pb-6 leading-tight">
            Our database is made up of the world’s top medical experts. If you need to visit a specialist, we will
            search to see if any of our doctors meet your criteria and practice within a distance that works for you.
          </p>
          <p className="mt-6 text-pale-grey-200">Phone</p>
          <a href="tel:+1-866-904-0910" className="flex items-center font-medium text-black hover:no-underline">
            +1 (866) 904-0910 <span className="ml-6 mr-4 leading-tight text-primary-blue font-effra">Call</span>
            <PhoneIcon className="w-4 h-4" title="Phone Icon" aria-hidden />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ExpertMedicalServices;
