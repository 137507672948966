import { Field, Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { PiwikAction, PiwikCategory, PiwikName } from "../../@types/common";
import csrTicket from "../../api/csr";
import { trackEvent } from "../../api/piwik/piwikService";
import { ReactComponent as LeftArrowIcon } from "../../assets/icons/left-arrow.svg";
import Button from "../../components/Button";
import { ButtonGroup } from "../../components/ButtonGroup";
import FormCard from "../../components/FormCard";
import { FormFieldError } from "../../components/FormFields/FieldError";
import { FormikMaskedInput } from "../../components/Formik/FormikMaskedInput";
import { FormikTextAreaInput } from "../../components/Formik/FormikTextArea";
import { FormikTextInput } from "../../components/Formik/FormikTextInput";
import { useSpinner } from "../../components/SpinnerProvider";
import { useAppDispatch } from "../../store/store";
import { phoneNumberMask, removePhoneCountryCode } from "../../utils/validationHelper";
import { selectAuth } from "../slices/authSlice";
import PageTitleComponent from "../../components/PageTitleComponent";
import { clearErrors, selectCsrTicket } from "../slices/csrSlice";

const phoneRegex = /(?:-*\d){10}/; // Match 10 numbers and allow dash
const validationSchema = Yup.object({
  first: Yup.string().required("Required"),
  last: Yup.string().required("Required"),
  email: Yup.string().email("Invalid email address").required("Required"),
  phone: Yup.string().matches(phoneRegex, "Phone number has to be 10 digits").required("Required"),
  subject: Yup.string().required("Required"),
  comment: Yup.string().required("Required")
});

const Alert = () => {
  return (
    <div className="flex flex-col px-4 py-3 my-6 text-red-700 bg-red-100 border border-red-400 rounded" role="alert">
      <h4 className="font-bold">There was an error </h4>
      <p>There was an error sending your ticket.</p>
    </div>
  );
};

const TechnicalSupport = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const {
    entities: { profile }
  } = useSelector(selectAuth);
  const { error, csrTicketSent } = useSelector(selectCsrTicket);
  const { showSpinner } = useSpinner();

  const trackSubmit = () => {
    trackEvent({
      category: PiwikCategory.FORM,
      action: PiwikAction.SUBMIT,
      name: PiwikName.TECHNICAL_SUPPORT
    });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);
    showSpinner({ show: true });

    const newValues = {
      ...values,
      subject: `Technical Support - ${values.subject}`,
      name: `${values.first} ${values.last}`,
      comment: `PHONE NUMBER: ${values.phone} \n MEMBER PORTAL \n ${values.comment} `
    };

    dispatch(csrTicket(newValues));
    trackSubmit();
    showSpinner({ show: false });
    setSubmitting(false);
  };

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    if (csrTicketSent) {
      dispatch(clearErrors());
      history.push("/dashboard");

      toast.success("Successfully created Technical Support ticket!");
    }
  }, [csrTicketSent, dispatch, history]);

  return (
    <FormCard title={"Technical support"}>
      <PageTitleComponent title="Technical support" />
      <Link to="/support" className="flex items-center ml-4 font-medium leading-tight text-primary-blue font-effra ">
        <LeftArrowIcon className="w-6 h-4" title="Back" aria-hidden />
        <p className="m-0">Back</p>
      </Link>
      <p className="pl-2 ml-4">Have a technical question? Connect with us by submitting a ticket below.</p>

      <Formik
        initialValues={{
          first: profile.first,
          last: profile.last,
          email: profile.email,
          phone: removePhoneCountryCode(profile.phone),
          subject: "",
          comment: ""
        }}
        enableReinitialize={true}
        isInitialValid={false}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, isValid }) => (
          <Form className="px-2 pb-8 mx-4 mb-6">
            {error && <Alert />}
            <h4 className="mr-4 -mt-3 text-sm text-right font-effra text-gray-default sm:mr-4 md:mr-4 lg:mr-0">
              * Required
            </h4>
            <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
              <div>
                <FormikTextInput data-testid="first" isRequired label="First Name" name="first" type="text" />
              </div>
              <div>
                <FormikTextInput data-testid="last" isRequired label="Last Name" name="last" type="text" />
              </div>
              <div>
                <FormikTextInput data-testid="email" isRequired label="Email Address" name="email" type="email" />
              </div>
              <div>
                <Field name="phone" type="text">
                  {({ field, form, meta }) => (
                    <>
                      <FormikMaskedInput
                        meta={meta}
                        data-testid="phone"
                        isRequired
                        label="Phone Number"
                        mask={phoneNumberMask}
                        field={field}
                        {...form}
                      />
                      <FormFieldError field={field} meta={meta} />
                    </>
                  )}
                </Field>
              </div>
              <div>
                <FormikTextInput
                  data-testid="subject"
                  label="Subject"
                  isRequired
                  placeholder="Subject"
                  name="subject"
                  type="text"
                />
              </div>
              <div>
                <FormikTextAreaInput
                  placeholder="Message"
                  data-testid="comment"
                  label="Message"
                  isRequired
                  name="comment"
                  maxLength="180"
                />
                <p className="text-right">{values?.comment?.length}/180</p>
              </div>
            </div>
            <div className="w-full">
              <ButtonGroup>
                <Button data-testid="submit-ticket" disabled={isSubmitting || !isValid} type="submit">
                  Submit ticket
                </Button>
              </ButtonGroup>
            </div>
          </Form>
        )}
      </Formik>
    </FormCard>
  );
};

export default TechnicalSupport;
