import React from "react";
import { Link } from "react-router-dom";

interface Props {
  to: string;
  children: React.ReactNode;
}

const LinkButton = ({ to, children, ...props }: Props & any) => (
  <Link data-testid="linkButton" to={to} className="dropdown-item" {...props}>
    {children}
  </Link>
);

export default LinkButton;
