import { Action, AnyAction, configureStore, getDefaultMiddleware, ThunkAction } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { FLUSH, PAUSE, PERSIST, persistReducer, persistStore, PURGE, REGISTER, REHYDRATE } from "redux-persist";
import storage from "redux-persist/lib/storage";
import autoMergeLevel2 from "redux-persist/lib/stateReconciler/autoMergeLevel2";

import { ErrorMessages } from "../@types/common";
import rootReducer, { RootState } from "./rootReducer";

const persistConfig = {
  key: "memberPortal",
  version: 1,
  storage,
  stateReconciler: autoMergeLevel2,
  blacklist: ["intervals"]
};

const persistedReducer = persistReducer<RootState, AnyAction>(persistConfig, rootReducer);

const unauthorizedMiddleware = (store) => (next) => (action) => {
  const { dispatch } = store;
  let res = next(action);
  if (action.type !== "auth/postLogin/rejected" && res?.error?.message === ErrorMessages.UNAUTHORIZED) {
    dispatch({
      type: "auth/setError",
      payload: ErrorMessages.UNAUTHORIZED
    });
  }
  return res;
};

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      ignoredPaths: ["websdk.entities.extraActions.onClose"],
      ignoredActionPaths: ["payload.extraActions.onClose"]
    }
  }).prepend(unauthorizedMiddleware)
});

export const persistor = persistStore(store);

if (process.env.NODE_ENV === "development" && (module as any).hot) {
  (module as any).hot.accept("./rootReducer", () => {
    const nextRootReducer = require("./rootReducer").default;
    store.replaceReducer(persistReducer<RootState, AnyAction>(persistConfig, nextRootReducer));
  });
}

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export type AppThunk = ThunkAction<void, RootState, unknown, Action<string>>;

export default store;
