import React, { PropsWithChildren } from "react";
import { Link, LinkProps } from "react-router-dom";

interface StyledLinkProps extends LinkProps {
  to: string;
  children: any;
}

function StyledLink({ children, ...rest }: StyledLinkProps) {
  return (
    <Link className="inline-block text-sm align-baseline hover:text-blue-70" {...rest}>
      {children}
    </Link>
  );
}

export default StyledLink;
