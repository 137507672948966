import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { Tabs } from "@bumaga/tabs";
import { selectAuth } from "../containers/slices/authSlice";
import Autocomplete from "./Autocomplete";
import WidgetTab from "./Tabs";
import { PricingType } from "../@types/common";
import { Feature, hasFeature } from "../utils/featureHelper";
import { usePrescriptionsByName } from "../queries/usePrescriptions";
import { useProceduresByName } from "../queries/useProcedures";
import useDebounceValue from "../hooks/useDebounceValue";

interface ProcedureResponse {
  code: string;
  description: string;
  name: string;
}

const transformProcedures = (procedures) =>
  procedures.map((p: ProcedureResponse) => ({ key: p.code, value: `${p.description} - ${p.code}` }));
const transformPrescriptions = (prescriptions) =>
  prescriptions.map(({ name, drugId }) => ({ key: drugId, value: name.replaceAll("/", " ") }));

const PriceWidget = () => {
  const history = useHistory();
  const {
    entities: { group, profile }
  } = useSelector(selectAuth);
  const [pricingType, setPricingType] = useState<string>(PricingType.PROCEDURE);

  const [searchTerm, setSearchTerm] = useState("");
  const debouncedSearch = useDebounceValue(searchTerm);

  const { data: procedures } = useProceduresByName(debouncedSearch, pricingType === PricingType.PROCEDURE);
  const { data: prescriptions } = usePrescriptionsByName(debouncedSearch, pricingType === PricingType.PRESCRIPTIONS);

  const handleChange = async (searchTerm: string) => setSearchTerm(searchTerm);

  const handleSubmit = (e: { key: string; value: string }) => {
    const { value } = e;
    if (pricingType === PricingType.PROCEDURE) {
      const code: string = procedures.find((item: ProcedureResponse) => value.includes(item.code))?.code || value;

      history.push({
        pathname: "/procedures",
        search: `?zip=${profile.zip}&code=${code}&name=${value}`
      });
      return;
    }

    history.push({
      pathname: "/prescriptions",
      search: `?zip=${profile.zip}&term=${value}`
    });
  };

  const options =
    PricingType.PROCEDURE === pricingType ? transformProcedures(procedures) : transformPrescriptions(prescriptions);

  return (
    <div data-testid="pricingContainer" className="pb-8 pl-1 ml-12 mr-12 sm:ml-12 sm:pl-1 md:ml-20 md:pl-1">
      <Tabs>
        <div className="flex space-x-1">
          {!hasFeature(group, Feature.pricing) ? null : (
            <WidgetTab onChange={setPricingType}>
              <span key={PricingType.PROCEDURE}>Procedures</span>
            </WidgetTab>
          )}
          {!hasFeature(group, Feature.rx) ? null : (
            <WidgetTab onChange={setPricingType}>
              <span data-testid="prescriptions-tab" key={PricingType.PRESCRIPTIONS}>
                Prescriptions
              </span>
            </WidgetTab>
          )}
        </div>
        <div className="my-8">
          <Autocomplete
            id={pricingType}
            placeHolder={`Search for ${pricingType}`}
            options={options}
            handleSubmit={handleSubmit}
            handleChange={handleChange}
          />
        </div>
      </Tabs>
    </div>
  );
};

export default PriceWidget;
