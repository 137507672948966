import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import useDebounceValue from "../../hooks/useDebounceValue";
import { useProcedureDetails, useProceduresByName } from "../../queries/useProcedures";
import { useSpinner } from "../../components/SpinnerProvider";

import { ReactComponent as PriceIcon } from "../../assets/icons/PriceWidget.svg";
import Autocomplete from "../../components/Autocomplete";
import ProcedureDetails from "./ProcedureDetails";
import PopularList from "./PopularList";
import { extractSearchParams } from "../../utils/url";
import ZipCodeInput from "../../components/ZipCodeInput";

interface searchParams {
  zip?: string;
  code?: string;
  name?: string;
}

const formatOptionsResults = (options) =>
  options.map((item) => {
    return {
      key: item.code,
      value: `${item.description} - ${item.code}`
    };
  });

const Input = ({ name, value = "", onChange, onEnterDown = onChange, placeholder = "", maxLength = 100 }) => {
  return (
    <input
      id={name}
      value={value}
      maxLength={maxLength}
      onChange={(event) => onChange(event, name)}
      onKeyDown={onEnterDown}
      placeholder={placeholder}
      className="relative block w-32 py-3 pl-4 pr-6 leading-tight text-gray-700 bg-white border rounded-lg appearance-none z-2 border-pale-grey-200 focus:border-primary-blue"
    />
  );
};

const Procedures = () => {
  const { search: urlSearch } = useLocation();
  const searchParams: searchParams = extractSearchParams(urlSearch);

  const { showSpinner } = useSpinner();
  const [zipCode, setZipCode] = useState(searchParams?.zip || "");
  const [code, setCode] = useState(searchParams?.code || "");
  const [search, setSearch] = useState(searchParams?.name || "");
  const [name, setName] = useState("");
  const [enabled, setEnabled] = useState(true);

  const debouncedSearch = useDebounceValue(search, 100);

  const { data: options } = useProceduresByName(debouncedSearch);
  const { data: itemDetails, isLoading, isError, error } = useProcedureDetails(code, zipCode, enabled);

  const handleSubmit = ({ key, value }) => {
    setCode(key);
    setName(value);
  };

  const handleZipCodeChange = (val) => setZipCode(val);
  const handleItemClick = (item) => {
    setSearch(`${item?.name} - ${item?.code}`);
    setCode(item?.code);
    setName(`${item?.name} - ${item?.code}`);
  };

  useEffect(() => {
    showSpinner({
      show: isLoading
    });
  }, [isLoading]);

  return (
    <div className="w-full max-w-4xl mx-auto p-8 m-6 bg-white shadow-lg">
      <h1 className="mb-6 ml-0 text-4xl font-medium leading-tight">Price procedures</h1>
      <div className="flex gap-5">
        <PriceIcon className="w-32 h-32 md:h-auto md:w-auto" aria-hidden />
        <p className="mt-4">
          To find pricing on different procedures, please enter the name of the procedures or the CPT Code above
        </p>
      </div>
      <div className="m-auto sm:ml-20">
        <div className="flex gap-5 mt-4 mb-6 flex-col sm:flex-row">
          <Autocomplete
            id="procedures"
            showCrossIcon
            clearOnClickAway={false}
            initialValue={search}
            className="w-full sm:w-64"
            inputClassName="py-4"
            placeHolder="Enter a value"
            options={formatOptionsResults(options)}
            handleSubmit={handleSubmit}
            handleChange={(val) => setSearch(val)}
          />
          <ZipCodeInput
            value={zipCode}
            errors={error?.message}
            errorOnEmpty="RequiredZipCode"
            onChange={({ zip, enabled }) => {
              handleZipCodeChange(zip);
              setEnabled(enabled);
            }}
            className="sm:w-32"
            header=""
            id="zip"
            placeholder="Enter zip"
          />{" "}
        </div>
        {!!itemDetails?.data && !isError && <ProcedureDetails item={itemDetails?.data} name={name} />}
        {!itemDetails?.data && <PopularList onClick={handleItemClick} />}
      </div>
    </div>
  );
};

export default Procedures;
