import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth, enableSSO } from "../slices/authSlice";
import postLogin from "../../api/auth";
import { removeCookies, setSsoTokenCookie } from "../../utils/cookies";
import PageTitleComponent from "../../components/PageTitleComponent";

const Authenticating = () => {
  const query = new URLSearchParams(useLocation().search);
  const entryPage = query.get("entryPage");
  const history = useHistory();
  const token = query.get("token");
  const { hasAuth, loading, error, hasSso } = useSelector(selectAuth);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(enableSSO(true));
  }, [dispatch]);

  useEffect(() => {
    if (token && hasSso) {
      setSsoTokenCookie(token);
      dispatch(postLogin({}));
    }
  }, [dispatch, hasSso, token]);

  useEffect(() => {
    if (!loading.login && error) {
      removeCookies();
      history.push("/sso-error");
    }
  }, [error, history, loading.login]);

  useEffect(() => {
    if (!loading.login && hasAuth) {
      switch (entryPage) {
        case "VisitDoctor":
          history.push("/request-a-visit");
          break;
        case "FindProviders":
        case "PricePrescriptions":
        case "PriceProcedures":
        case "CheckInsurance":
        case "FindRecipes":
        case "BestDoctors":
        default:
          history.push("/dashboard");
          break;
      }
    }
  }, [hasAuth]);

  return (
    <div>
      <PageTitleComponent title="Authenticating" />
    </div>);
};

export default Authenticating;
