import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { samlLogin } from "../../api/auth";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "../slices/authSlice";
import { selectRegistration, setSelectedPerson } from "../slices/registrationSlice";
import { useSpinner } from "../../components/SpinnerProvider";

const SAMLEntry = () => {
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");
  const dispatch = useDispatch();
  const history = useHistory();
  const { showSpinner } = useSpinner();

  useEffect(() => {
    if (token) {
      dispatch(samlLogin(token));
    }
  }, [dispatch, token]);

  const { hasAuth, loading, error } = useSelector(selectAuth);
  const { selectedPerson } = useSelector(selectRegistration);

  useEffect(() => {
    showSpinner({ show: loading.saml });
  }, [loading.saml, showSpinner]);

  useEffect(() => {
    const routeToCreateAccount = !loading.saml && !hasAuth && !error && selectedPerson;

    if (error) {
      history.push("/sso-error");
    }

    if (!loading.saml && hasAuth) {
      history.push("/dashboard");
    } else if (routeToCreateAccount) {
      history.push("/register/create-account");
    }
  }, [dispatch, error, hasAuth, history, loading.saml, selectedPerson]);

  return <p data-testid="authenticated-string"></p>;
};

export default SAMLEntry;
