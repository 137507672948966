import React from "react";
import Autocomplete from "../Autocomplete";
import { useHistory } from "react-router-dom";
import { specialties } from "../../utils/providers";
interface Specialties {
  value: string;
  label: string;
}

const Providers = ({ zipCode }) => {
  const history = useHistory();

  const quickFilters = [
    { value: "Primary Care Physician", key: "general" },
    { value: "Pediatricians", key: "family-medicine" },
    { value: "OBGYNs", key: "obstetrics-and-gynecology" },
    { value: "Dentists", key: "dentistry" },
    { value: "Eye Doctors", key: "ophthalmology" },
    { value: "Dermatologists", key: "dermatology" }
  ];

  const selectItem = (key: any) => {
    history.push(`/providers?zip=${zipCode}&specialty=${key}`);
  };

  const handleSubmit = (e) => {
    selectItem(e.key);
  };

  return (
    <div data-testid="providersContainer">
      <form className="w-full max-w-sm" onSubmit={handleSubmit} data-testid="providerFindForm">
        <div className="my-8">
          <Autocomplete
            id="specialty"
            placeHolder="Search by specialty"
            options={specialties.map((s) => ({ key: s.value, value: s.label }))}
            handleSubmit={handleSubmit}
          />
        </div>

        <p className="mb-2 text-lg font-effra">Quick Filters</p>
        <div className="flex flex-wrap">
          {quickFilters.map((specialty, index) => (
            <button
              onClick={() => selectItem(specialty.key)}
              key={specialty.key}
              data-testid={`provider-${specialty.key}`}
              type="button"
              className="flex items-center justify-center px-4 py-2 m-1 font-medium border rounded-lg border-primary-blue text-primary-blue"
            >
              {specialty.value}
            </button>
          ))}
        </div>
      </form>
    </div>
  );
};

export default Providers;
