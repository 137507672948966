import hyApi from "../services/hyApiService";

export const fetchProcedureByName = (name) => hyApi.get(`api/procedure/search?name=${name}`).then(({ data }) => data);
export const fetchProcedureDetails = (params) => hyApi.get(`api/procedure/details?${params}`).then(({ data }) => data);

export const fetchPopularProcedures = () =>
  hyApi.get("api/pricing/procedures/cash/popular?type=cash").then(({ data }) => data);

export const fetchPrescriptionsByName = (name) => hyApi.get(`api/utilities/rx?name=${name}`).then(({ data }) => data);
export const fetchPrescriptionDetails = (params) =>
  hyApi.get(`api/rx/details/price?${params}`).then(({ data }) => data);

export const getCoordinatesForZipCode = (code) => {
  return hyApi.get(`api/address?zip=${code}`).then(({ data }) => data.data);
};

export const fetchProvideDiscountCard = (params) =>
  hyApi.get(`/api/rx/discount-card?${params}`).then(({ data }) => data);

export const sendDiscountCardToEmail = (data) =>
  hyApi.post(`/api/profiles/${data.ProfileId}/discount-card/email`, data).then(({ data }) => data);

export const fetchDoctors = (params) => hyApi.get(`/api/doctors?${params}`).then(({ data }) => data);

export const fetchPlaces = (params) => hyApi.get(`/api/location/places?${params}`).then(({ data }) => data);
