import React from "react";
import { ReactComponent as FemaleIcon } from "../../assets/icons/user-female.svg";
import { ReactComponent as MaleIcon } from "../../assets/icons/user-male.svg";
import { getProviderImage } from "./Helpers/AvatarHelper";
import { Avatar, FavoriteComponentProps } from "./FavoriteTypes";
import FavoriteItemWrapper from "./FavoriteItemWrapper";

const ProviderAvatar = ({ url }) => {
  const avatar: Avatar = getProviderImage(url);
  if (avatar.src) {
    return <img src={avatar.src} className="favorite-avatar" />;
  }
  return avatar.female ? <FemaleIcon className="favorite-avatar" /> : <MaleIcon className="favorite-avatar" />;
};

const Provider = ({ element, onUnfavorite }: FavoriteComponentProps) => (
  <FavoriteItemWrapper onUnfavorite={onUnfavorite} element={element}>
    <ProviderAvatar url={element.avatar} />
  </FavoriteItemWrapper>
);

export default Provider;
