import React, { useCallback, useState } from "react";
import { useHistory } from "react-router";
import clsx from "clsx";
import moment from "moment";

import { ReactComponent as CameraIcon } from "../../assets/icons/camera.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/phone.svg";
import { ButtonGroup } from "../ButtonGroup";
import Button from "../Button";
import { Modal } from "../Modal";
import { useWebSdk } from "../WebSdkProvider";
import { Consult, CommunicationMethod } from "../../@types/Consults";
import { scheduledTime, canCancel, canReschedule, canJoinWaitingRoom, getFlowUrl } from "../../utils/consultsHelper";
import { WEBSDK_ROUTES } from "../../router/routes";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectAuth } from "../../containers/slices/authSlice";
import { cancelVisitDirect, getConsultationList } from "../../api/websdk/mobileApi";

interface Props {
  consult: Consult;
}

const RequestedDate = ({ consult }) => (
  <div className="flex flex-col items-center w-24">
    <p className="text-lg font-light uppercase text-dark-gray">{moment(consult.requested_date).format("MMM")}</p>
    <p className="text-3xl font-light text-dark-gray">{moment(consult.requested_date).date()}</p>
    <p className="text-sm font-light text-black">{moment(consult.requested_date).format("yyyy")}</p>
  </div>
);

const ActionItems = ({ showCancelModal, showCancelButton, consult, handleRescheduleVisit }) => (
  <div className="flex ">
    {showCancelButton && (
      <button
        type="button"
        data-testid={"cancel-visit-button"}
        onClick={showCancelModal}
        className={clsx(
          !showCancelButton && "hover:no-underline text-disabled",
          "mr-4 text-sm no-underline text-primary-blue text-center "
        )}
      >
        Cancel
      </button>
    )}

    {canReschedule(consult) && (
      <button
        type="button"
        data-testid={"reschedule-visit-button"}
        className="mr-4 text-sm no-underline hover:no-underline text-primary-blue"
        onClick={handleRescheduleVisit}
      >
        Reschedule
      </button>
    )}
    {consult.communication_method === CommunicationMethod.Phone && (
      <PhoneIcon className="fill-current" title="Phone Icon" data-testid={"phone-icon"} />
    )}
    {consult.communication_method === CommunicationMethod.Video && (
      <CameraIcon className="fill-current" title="Camera Icon" data-testid={"camera-icon"} />
    )}
  </div>
);

const UpcomingVisitListItem = ({ consult }: Props) => {
  const history = useHistory();
  const [showDialog, setShowDialog] = useState(false);
  const showCancelButton = canCancel(consult);

  const handleJoinWaitingRoom = () => {
    history.push(WEBSDK_ROUTES.JOIN_WAITING_ROOM, { initialState: consult });
  };

  const handleRescheduleVisit = () => {
    history.push(getFlowUrl(consult), { initialState: consult });
  };

  const {
    entities
  } = useSelector(selectAuth);

  const dispatch = useDispatch();

  const cancelConsultDirect = useCallback(    
    (payload: {consultId: number, memberId: number}) => {
      dispatch(cancelVisitDirect(payload));
      dispatch(getConsultationList(payload.memberId));
    },
    [dispatch]
  );

  
  const cancelConsult = () => {
    setShowDialog(false);
    try {
      const payload = {
        consultId: consult.consult_id,
        memberId: entities.familyMember.telehealthID
      };
      cancelConsultDirect(payload); 

      toast.success("Your visit has been cancelled");
    } catch (e) {
      toast.error("Unable to cancel your visit, please try again.");
    }
  };

  const showCancelModal = () => {
    if (!showCancelButton) {
      toast.error("Sorry! You are unable to cancel a visit if a provider is assigned.");
    } else {
      setShowDialog(true);
    }
  };

  const joinWaitingRoom = () => {
    return (
      <>
        {canJoinWaitingRoom(consult) && (
          <button
            type="button"
            data-testid={`joinWaitingRoomLink-${consult.consult_id}`}
            className="px-4 py-2 m-2 lg:mx-0 lg:mb-2 text-sm font-medium no-underline whitespace-no-wrap rounded-lg md:my-4 md:max-w-xs text-primary-blue border-1 border-primary-blue"
            onClick={handleJoinWaitingRoom}
          >
            Join waiting room
          </button>
        )}
      </>
    );
  };

  return (
    <>
      <div
        data-testid={"upcoming-visit-item-container"}
        className="flex items-center py-2 my-4 border border-solid rounded-lg border-primary-blue  w-auto"
        style={{ height: "calc(50% - 1rem)" }}
      >
        <RequestedDate consult={consult} />

        <div className="flex flex-col items-start justify-center w-full sm:w-1/2 self-auto flex-1">
          <div>
            <p className="text-base font-bold text-dark-gray">
              {consult.member?.first_name} {consult.member?.last_name}
            </p>
            <p className="text-sm">
              {consult?.provider?.first_name} {consult?.provider?.last_name}
            </p>
            <p className="text-sm">
              {consult.consult_type} | {scheduledTime(consult)}
            </p>
          </div>
          <div className="hidden lg:flex">
            <ActionItems
              showCancelModal={showCancelModal}
              showCancelButton={showCancelButton}
              consult={consult}
              handleRescheduleVisit={handleRescheduleVisit}
            />
          </div>
          <div className="h-auto  lg:hidden">{joinWaitingRoom()}</div>

          <div className="flex lg:hidden my-2">
            <ActionItems
              showCancelModal={showCancelModal}
              showCancelButton={showCancelButton}
              consult={consult}
              handleRescheduleVisit={handleRescheduleVisit}
            />
          </div>
        </div>
        <div className=" w-0 px-2 sm:w-auto hidden lg:flex">{joinWaitingRoom()}</div>
      </div>

      <Modal
        state={{
          close: () => setShowDialog(false),
          isOpen: showDialog
        }}
      >
        <div
          data-testid={"cancel-visit-modal"}
          className="z-50 flex flex-col self-center h-auto px-10 py-4 mx-auto bg-white rounded w-md"
        >
          <div className="flex flex-col items-center p-2 space-y-2">
            <h1>Cancel Visit?</h1>
            <p>Please Note: You will not be charged for cancelling your visit.</p>
          </div>
          <ButtonGroup>
            <Button buttonType="secondary" onClick={() => setShowDialog(false)}>
              No
            </Button>
            <Button data-testid={"cancel-visit-modal-button"} buttonType="primary" onClick={cancelConsult}>
              Cancel Visit
            </Button>
          </ButtonGroup>
        </div>
      </Modal>
    </>
  );
};

export default UpcomingVisitListItem;
