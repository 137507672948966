export enum CommunicationMethod {
  "Video" = "Video",
  "Phone" = "Phone"
}

export enum ConsultType {
  GeneralMedical = "General Medical",
  MentalHealth = "Mental Health",
  Dermatology = "Dermatology",
  Nutrition = "Nutrition"
}

export enum ConsultStatus {
  Requested = "Requested",
  Locked = "Locked",
  Started = "Started",
  Proposed = "Proposed"
}

export interface Consult {
  member?: {
    first_name?: string | undefined;
    last_name?: string | undefined;
  };
  provider?: {
    first_name?: string | undefined;
    last_name?: string | undefined;
  };
  consult_request_reason: string;
  is_negotiable: boolean;
  state: string;
  communication_method: CommunicationMethod;
  consult_id: number;
  consult_type: ConsultType;
  is_on_demand: boolean;
  requested_date: string;
  requested_dates: string[];
  proposed_dates: string[];
  status: ConsultStatus;
  deny_cancel_interval: number;
  deny_reschedule_interval: number;
}
