import React from "react";
import FindPageListItem from "../../components/FindPage/FindPageListItem";

function ProvidersList({
  providers,
  formatProvider,
  activeItemId,
  selectedItemId,
  setHoveredId,
  onProviderSelect,
  onItemHover
}) {
  return providers?.map((provider) => {
    const { id } = provider;
    return (
      <FindPageListItem
        key={id}
        data={formatProvider(provider)}
        active={activeItemId === id}
        selected={selectedItemId === id}
        onSelect={onProviderSelect}
        onMouseEnter={() => onItemHover(id, [provider?.practices[0]?.lat, provider?.practices[0]?.lon])}
        onMouseLeave={() => setHoveredId(null)}
        // addToFavorite={() => { }}
        favoriteList={[]}
      />
    );
  });
}

export default ProvidersList;
