import React from "react";
import { Route, RouteProps } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetSaml } from "../containers/slices/authSlice";
import { clearCheckSessionPolling, clearSdkPolling } from "../containers/slices/intervalsSlice";
import { setSelectedPerson } from "../containers/slices/registrationSlice";

type Props = {
  element?: React.ReactElement;
} & RouteProps;

function RouteElement({ element }: Props) {
  const dispatch = useDispatch();

  dispatch(resetSaml());
  dispatch(clearSdkPolling());
  dispatch(clearCheckSessionPolling());
  dispatch(setSelectedPerson(null));

  return element ?? null;
}

export default function SamlRoute({ element, ...rest }: Props) {
  return (
    <Route {...rest}>
      <RouteElement element={element} />
    </Route>
  );
}
