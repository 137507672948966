import { ReactComponent as Close } from "../../assets/icons/action/delete/x-close.svg";
import { Field, Form, Formik, FormikHelpers, FormikProps } from "formik";
import * as Yup from "yup";
import {
  dateMask,
  dobValidation,
  onlyAlphaCharacters,
  phoneNumberMask,
  phoneValidation,
  removePhoneSpecialChars
} from "../../utils/validationHelper";
import { AddDependentFormFields, DependentType } from "../slices/registrationSlice";
import { FormikSelect } from "../../components/Formik/FormikSelect";
import { FormikTextInput } from "../../components/Formik/FormikTextInput";
import { FormikMaskedInput } from "../../components/Formik/FormikMaskedInput";
import { FormFieldError } from "../../components/FormFields/FieldError";
import Button from "../../components/Button";
import { Modal } from "../../components/Modal";
import React from "react";
import { genderOptions } from "../../utils/constants";

const dependentOptions: { value: DependentType; label: string }[] = [
  {
    value: DependentType.DEPENDENT,
    label: "Dependent"
  },
  {
    value: DependentType.SPOUSE,
    label: "Spouse"
  }
];

function AddDependentModal({ membership, onClose, showModal, error, submitData }) {
  return (
    <Modal
      state={{
        close: () => onClose(),
        isOpen: showModal
      }}
    >
      <div
        role="dialog"
        data-testid="add-member-modal"
        className="overflow-y-auto max-h-full z-50 md:w-2/5 w-full flex flex-col self-center h-auto mx-auto bg-white rounded"
      >
        <div className="lg:px-10 md:px-5 px-2 py-1 flex flex-row md:items-center justify-between border-b-1 border-gray-200">
          <h1>Add a family member</h1>
          <button className="text-primary-500" data-testid="closeModalButton" onClick={() => onClose()}>
            <Close className="fill-current h-12 sm:h-10 xs:m-12 m-0" aria-hidden />
          </button>
        </div>
        {error && <p className="lg:px-10 md:px-5 px-2 mt-4 text-danger">{error}</p>}
        <div className="lg:px-10 md:px-5 px-2">
          <p className="mt-4 font-bold">Please fill out the form below to add a dependent.</p>
          <p aria-hidden className="text-right font-effra text-gray-default">
            * Required
          </p>
        </div>
        <Formik
          initialValues={
            {
              first: "",
              last: "",
              email: "",
              gender: "",
              dob: "",
              type: "",
              phone: ""
            } as AddDependentFormFields
          }
          validationSchema={Yup.object({
            first: onlyAlphaCharacters.required("Required"),
            last: onlyAlphaCharacters.required("Required"),
            email: Yup.string().email("Invalid email address"),
            gender: Yup.string().required("Required"),
            dob: dobValidation,
            type: Yup.string().required("Required"),
            phone: phoneValidation
          })}
          onSubmit={(values, { setSubmitting }: FormikHelpers<AddDependentFormFields>) => {
            setSubmitting(true);
            const newValues = { ...values, phone: removePhoneSpecialChars(values.phone) } as AddDependentFormFields;
            submitData(newValues);
          }}
        >
          {({ isValid, handleSubmit }: FormikProps<AddDependentFormFields>) => (
            <>
              <Form className="lg:px-10 md:px-5 px-2 mt-4">
                <div className="flex grid grid-flow-row md:grid-cols-2 sm:grid-cols-1 md:gap-4 sm:gap-2">
                  <FormikSelect
                    placeholder="Select one"
                    isRequired={true}
                    label="Dependent type"
                    name="type"
                    options={membership.spouse ? [dependentOptions[0]] : dependentOptions}
                  />
                  <div></div>
                  <FormikTextInput isRequired={true} label="First Name" name="first" type="text" />
                  <FormikTextInput isRequired={true} label="Last Name" name="last" type="text" />
                  <Field name="dob" type="text">
                    {({ field, form, meta }) => (
                      <div>
                        <FormikMaskedInput
                          meta={meta}
                          isRequired={true}
                          placeholder="MM/DD/YYYY"
                          label="Date of birth"
                          mask={dateMask}
                          field={field}
                          {...form}
                        />
                        <FormFieldError field={field} meta={meta} />
                      </div>
                    )}
                  </Field>
                  <FormikSelect
                    placeholder="Select one"
                    isRequired={true}
                    label="Gender"
                    name="gender"
                    options={genderOptions}
                  />
                  <Field name="phone" type="tel">
                    {({ field, form, meta }) => (
                      <div>
                        <FormikMaskedInput
                          meta={meta}
                          isRequired={true}
                          label="Phone number"
                          mask={phoneNumberMask}
                          field={field}
                          {...form}
                        />
                        <FormFieldError field={field} meta={meta} />
                      </div>
                    )}
                  </Field>
                  <FormikTextInput label="Email address" name="email" type="email" />
                </div>
              </Form>
              <div className="mt-10 flex flex-row justify-end lg:px-10 md:px-5 px-2 bg-gray-100 border-t-1">
                <div className="flex my-8 flex-row justify-end space-x-2">
                  <Button onClick={onClose} buttonType="secondary">
                    Cancel
                  </Button>
                  <Button onClick={() => handleSubmit()} disabled={!isValid} type="submit" buttonType="primary">
                    Save
                  </Button>
                </div>
              </div>
            </>
          )}
        </Formik>
      </div>
    </Modal>
  );
}

export default AddDependentModal;
