import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import clsx from "clsx";
import { PiwikAction, PiwikCategory, PiwikName } from "../@types/common";
import { trackEvent } from "../api/piwik/piwikService";
import { ReactComponent as RightArrowIcon } from "../assets/icons/right-arrow.svg";
import Widget from "./Widget";
import WidgetHeader from "./HeaderBar";
import ReactTooltip from "react-tooltip";
import { ReactComponent as InfoIcon } from "../assets/icons/c-info-2.svg";
import { ReactComponent as BeatTheDeductibleIcon } from "../assets/icons/BeatTheDeductible.svg";
import { useAppDispatch } from "../store/store";
import { savingsSummary, savingsHistory } from "../api/savings";
import { selectAuth } from "../containers/slices/authSlice";
import { selectWebSdk } from "../containers/slices/websdkSlice";
import { consultationLocked } from "../api/websdk/consultations";

export type Savings = {
  lifetime?: number;
  totalItems?: number;
  mostRecent: number;
  list: any[];
};

const BeatTheDeductibleWidget = () => {
  const { consultations } = useSelector(selectWebSdk);
  const [savings, setSavings] = useState<Savings>({
    lifetime: 0,
    list: [],
    totalItems: 0,
    mostRecent: 0
  });

  const dispatch = useAppDispatch();
  const {
    entities: { profile }
  } = useSelector(selectAuth);

  const handleOnClick = () => {
    trackEvent({
      category: PiwikCategory.BUTTONS,
      action: PiwikAction.CLICKED,
      name: PiwikName.BEAT_THE_DEDUCTIBLE
    });
  };

  useEffect(() => {
    const getSavings = async () => {
      if (!profile?.id) return;

      const { payload } = await dispatch(savingsSummary(profile.id));
      const { payload: historyPayload } = await dispatch(savingsHistory(profile.id));
      const { limit, skip, ...rest } = historyPayload || {};
      setSavings({ mostRecent: payload?.mostRecent, ...rest });
    };

    const updateSavings = async () => {
      const lockConsults = consultations.map((c) => dispatch(consultationLocked(c.consult_id)));
      await Promise.all(lockConsults);
      getSavings();
    };

    updateSavings();
  }, [consultations, dispatch]);

  return (
    <Widget>
      <>
        <WidgetHeader>
          <BeatTheDeductibleIcon className="w-10 h-10 md:h-auto md:w-auto" aria-hidden />
          {/*
          //@ts-ignore */}
          <ReactTooltip
            className="tooltip-custom"
            place={"bottom"}
            effect={"solid"}
            type="light"
            multiline={true}
            border={true}
            borderColor={"#005c95"}
          />
          <p className="text-3xl font-medium">Beat the deductible</p>
          {savings?.mostRecent && !savings?.lifetime ? (
            <InfoIcon
              data-tip="Recent visit savings will update when you save by using HealthiestYou."
              className="fill-current md:h-auto md:w-auto text-primary-blue"
              aria-hidden
            />
          ) : null}
        </WidgetHeader>
        <div className="p-4" data-testid="beatTheDeductibleContainer">
          <p data-testid="lifetime" className="pb-2 pl-1 ml-12 text-xl font-medium sm:ml-12 sm:pl-1 md:ml-20 md:pl-1">
            Life Savings ${savings?.lifetime || 0}
          </p>
          <p
            data-testid="most-recent"
            className="pb-6 pl-1 ml-12 text-xl font-medium sm:ml-12 sm:pl-1 md:ml-20 md:pl-1"
          >
            Recent Visit Savings ${savings?.mostRecent || 0}
          </p>
          <div className="w-1/2 pl-1 ml-12 sm:ml-12 sm:pl-1 md:ml-20 md:pl-1">
            <Link
              className={clsx("flex", !savings && "pointer-events-none")}
              to="/beat-the-deductible"
              onClick={handleOnClick}
            >
              See savings <RightArrowIcon className="w-4 h-4 mt-1 ml-2" title="Right Arrow Icon" aria-hidden />
            </Link>
          </div>
        </div>
      </>
    </Widget>
  );
};

export default BeatTheDeductibleWidget;
