import React from "react";
import StyledLink from "../components/Link";
import PageTitleComponent from "../components/PageTitleComponent";

function SessionExpired() {
  return (
    <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0 bg-off-white">
      <PageTitleComponent title="Session Expired" />
      {/* This element is to trick the browser into centering the modal contents. */}
      <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
        &#8203;
      </span>
      <div className="inline-block overflow-hidden text-left transition-all transform bg-white rounded-lg shadow-xl sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
        <div className="px-4 pt-5 pb-4 bg-white rounded-lg sm:p-8 sm:pb-6">
          <div className="justify-center sm:flex sm:items-center">
            <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <p className="mb-6 text-lg">Your session has expired</p>
              <StyledLink className="text-md" to={"/login"}>
                Click here to be taken to login
              </StyledLink>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SessionExpired;
