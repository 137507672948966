import React, { useState } from "react";
import clsx from "clsx";
import Autocomplete from "../../components/FindPage/Autocomplete";
import Select, { option } from "../../components/FindPage/Select";
import { usePrescriptionsByName } from "../../queries/usePrescriptions";
import Switch from "../../components/Switch/Switch";
import { find } from "lodash";
import ZipCodeInput from "../../components/ZipCodeInput";

const transformToOptions = (drugs) =>
  drugs.map(({ name }) => ({
    value: name.replaceAll(" ", "-"),
    label: name.replace(/\/|-/g, " ")
  }));

const toFilterOptions = (values, key: string): option[] => [
  {
    value: "",
    label: ""
  },
  ...values.map((value) => ({
    value: value[key],
    label: value[key].toString().replaceAll("/", " ")
  }))
];

const buildDetailsFilter = (details, filter) => {
  if (!details)
    return {
      forms: [],
      dosages: [],
      quantity: []
    };
  const forms = details?.Forms || [];
  const dosages = filter?.form ? forms?.find((f) => f.Form === filter.form)?.Strengths : [];
  const quantity = filter?.dosage
    ? dosages?.find((s) => s.Strength === filter.dosage)?.PackageDescriptions[0].Quantities
    : [];

  return {
    forms: toFilterOptions(forms, "Form"),
    dosages: toFilterOptions(dosages || [], "Strength"),
    quantity: toFilterOptions(quantity || [], "Quantity")
  };
};

const PrescriptionsFilter = ({
  filters,
  onFiltersChange,
  drugDetails = [] as any,
  clearFilters,
  defaultValues = {} as any,
  errors
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const [searchTerm, setSearchTerm] = useState(filters.term);

  const { data: prescriptionsByName } = usePrescriptionsByName(searchTerm);

  const options = buildDetailsFilter(drugDetails, filters);

  const onFilterClear = () => {
    clearFilters();
    setSearchTerm(defaultValues.term);
  };

  const transformedOptions = transformToOptions(prescriptionsByName);

  const submitAutocomplete = (term) => {
    const transformedTerm = find(transformedOptions, { label: term })?.value || term;
    onFiltersChange({ ...filters, term: transformedTerm });
  };

  return (
    <div
      className={clsx(
        "flex flex-no-wrap mb-3 border-1 md:border-0  border-solid rounded-md border-pale-grey-200 pt-2",
        !collapsed && "flex-col",
        collapsed && "border-primary-blue flex-row"
      )}
    >
      {collapsed && <span className="text-primary-blue ml-3 md:hidden">Price and filter</span>}
      <button
        onKeyDown={() => setCollapsed(!collapsed)}
        className="text-sm text-primary-blue mr-3 mb-2 ml-auto inline-block md:hidden"
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? "Expand" : "Collapse"}
      </button>
      <div className={clsx("w-full bg-off-white rounded-lg rounded-t-none p-4", collapsed && "hidden", "md:block")}>
        <form className="w-full flex md:flex-row flex-wrap xl:flex-no-wrap gap-2 gap-y-4">
          <Autocomplete
            id="term"
            header="Prescriptions"
            className="xs:w-full sm:w-auto inline capitalize"
            options={transformedOptions}
            selectedVal={searchTerm}
            onChange={(term) => setSearchTerm(term)}
            onSubmit={submitAutocomplete}
            selectAllOnClick
          />
          <ZipCodeInput
            value={filters.zip}
            errors={errors?.message}
            errorOnEmpty="RequiredZipCode"
            onChange={(val) => onFiltersChange({ ...filters, ...val })}
            className="sm:w-32"
            header="Zip"
            id="zip"
            placeholder="Enter zip"
          />

          {!!options.forms && (
            <>
              <Select
                id="form"
                header="Form"
                className="xs:w-full sm:w-32"
                selectedVal={filters.form}
                options={options.forms}
                handleChange={(form) => onFiltersChange({ ...filters, form })}
                handleSubmit={() => {}}
                shouldFilter={false}
                shouldSort={false}
              />
              <Select
                id="dosage"
                header="Dosage"
                className="xs:w-full sm:w-32"
                selectedVal={filters.dosage}
                options={options.dosages}
                handleChange={(dosage) => onFiltersChange({ ...filters, dosage })}
                handleSubmit={() => {}}
                shouldFilter={false}
                shouldSort={false}
              />
              <Select
                id="quantity"
                header="Quantity"
                className="xs:w-full sm:w-32"
                selectedVal={filters.quantity}
                options={options.quantity}
                handleChange={(quantity) => onFiltersChange({ ...filters, quantity })}
                handleSubmit={() => {}}
                shouldFilter={false}
                shouldSort={false}
              />
              <Switch
                id="show-brand-toggle"
                label="Show brand"
                text="When the toggle is off, generic drugs are shown by default"
                className="pt-6"
                checked={filters.isShowBrandDrugs}
                onChange={(e) => {
                  onFiltersChange({ ...filters, isShowBrandDrugs: e.target.checked });
                }}
              />
            </>
          )}
        </form>
        <button
          className="flex justify-end mt-6 ml-auto text-primary-blue hover:opacity-75 cursor-pointer hover:text-primary-hover"
          onClick={onFilterClear}
          type="button"
        >
          Clear filters
        </button>
      </div>
    </div>
  );
};

export default PrescriptionsFilter;
