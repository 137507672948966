import { useField } from "formik";
import React from "react";

import { FormFieldError } from "../FormFields/FieldError";
import { InputField } from "../FormFields/InputField";

type FormikTextInputProps = {
  label: React.ReactElement | string;
  isRequired?: boolean;
  children?: React.ReactElement;
};

export const FormikTextInput = ({ label, isRequired, children, ...props }: FormikTextInputProps & any) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and alse replace ErrorMessage entirely.
  const [field, meta] = useField(props);

  return (
    // <div className="block space-y-2 text-sm text-gray-700">
    <div className="flex flex-col">
      <label className="mb-2 text-sm font-bold text-dark-gray" htmlFor={props.id || props.name}>
        {label}
        {isRequired && <span className="px-1" aria-hidden="true">*</span>}
      </label>
      <InputField field={field} meta={meta} isRequired={isRequired} {...props}>
        {children}
      </InputField>
      <FormFieldError field={field} meta={meta} />
    </div>
  );
};
