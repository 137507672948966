import clsx from "clsx";
import React from "react";

const PrescriptionsMarker = ({ drugPrice, active, selected, onMouseEnter, onMouseLeave, onClick, lat, lng }) => {
  const { PharmacyName, Street1, Distance, City, State, ZipCode } = drugPrice;
  return (
    <div>
      <button
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={onClick}
        className={clsx("absolute rounded-full bg-white w-4 h-4 border-solid border-6 border-red-pin")}
        style={{
          boxShadow: "0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%)",
          top: -20,
          left: -8,
          borderColor: active || selected ? "#5b2f91" : "#d00"
        }}
      >
        <div
          className="absolute box-border border-solid border-transparent border-6 border-t-8"
          style={{
            left: "50%",
            transform: "translateX(-50%) translateY(25%)",
            borderTop: "10px solid rgb(221,0,0)",
            borderColor: active || selected ? "#5b2f91 transparent transparent" : "#d00 transparent transparent"
          }}
        />
      </button>
      {selected && (
        <div
          className="absolute p-4 bg-white border-t-4 border-primary-500 rounded text-sm z-20"
          style={{ top: 5, left: -150, width: 300 }}
        >
          <div className="mb-3 text-primary-blue">{PharmacyName}</div>
          <div className="flex justify-between mb-1 leading-5">
            <div className="flex-1">{Street1}</div>
            <div className="flex-1 text-right">{Distance.toFixed(2)} mi</div>
          </div>
          <div className="g-map-tooltip-section-web-sdk">
            {City}, {State} {ZipCode}
          </div>
        </div>
      )}
    </div>
  );
};

export default PrescriptionsMarker;
