import React from "react";

const closeButtonType = {
  default: "Toastify__close-button--default",
  info: "Toastify__close-button--info",
  success: "Toastify__close-button--success",
  warning: "Toastify__close-button--warning",
  error: "Toastify__close-button--error"
};

type ToastCloseButtonProps = {
  closeToast: () => void;
  type: keyof typeof closeButtonType;
};

export function ToastCloseButton({ closeToast, type }: any) {
  return (
    <button
      className={`Toastify__close-button ${closeButtonType[type]}`}
      type="button"
      aria-label="close"
      onClick={closeToast}
    >
      <svg aria-hidden="true" viewBox="0 0 18 18">
        <path d="M9 .53c4.678 0 8.47 3.792 8.47 8.47 0 4.678-3.792 8.47-8.47 8.47C4.322 17.47.53 13.679.53 9 .53 4.322 4.321.53 9 .53zM9 1.94c-3.898 0-7.059 3.16-7.059 7.059 0 3.898 3.16 7.059 7.059 7.059 3.898 0 7.059-3.16 7.059-7.059 0-3.898-3.16-7.059-7.059-7.059zm3.323 3.736c.26.26.274.671.045.948l-.045.05L9.998 9l2.325 2.325c.275.276.275.723 0 .999-.26.26-.671.274-.948.045l-.05-.045-2.326-2.325-2.323 2.325c-.276.275-.723.275-.999 0-.26-.26-.274-.671-.045-.948l.045-.05L8 8.998 5.677 6.676c-.275-.276-.275-.723 0-.999.26-.26.671-.274.948-.045l.05.045L9 8l2.325-2.323c.276-.275.723-.275.999 0z" />
      </svg>
    </button>
  );
}
