import React, { ReactElement } from "react";
import GoogleMapReact from "google-map-react";

interface GMap {
  renderLocation: any;
  center: number[];
  children: ReactElement;
  defaultZoom: number;
  yesIWantToUseGoogleMapApiInternals: boolean;
  options: any;
}

const defaultOptions = {
  draggable: true,
  maxZoom: 18
};

const GMap = ({
  renderLocations,
  center = [0, 0],
  children,
  defaultZoom = 12,
  yesIWantToUseGoogleMapApiInternals = true,
  options = defaultOptions
}) => (
  <GoogleMapReact
    bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_API_KEY }}
    defaultZoom={defaultZoom}
    onGoogleApiLoaded={renderLocations}
    center={center}
    yesIWantToUseGoogleMapApiInternals={yesIWantToUseGoogleMapApiInternals}
    options={options}
  >
    {children}
  </GoogleMapReact>
);

export default GMap;
