import { mergeProps } from "@react-aria/utils";
import clsx from "clsx";
import { FieldInputProps, FieldMetaProps } from "formik";
import React from "react";

export interface InputFieldProps<V = any> {
  field: FieldInputProps<V> & { id?: string };
  meta: FieldMetaProps<V>;
  children?: React.ReactElement;
  type?: string;
  isRequired?: boolean;
}

export function InputField({ children, meta, isRequired, field, ...props }: InputFieldProps) {
  const isError = meta.touched && meta.error;
  // id and aria-describedby added for aria feedback for forms, do not remove/move
  const newProps = isError ? mergeProps(props, { "aria-describedby": `${field.id || field.name}_error` }) : props;
  return (
    <div className={clsx(children && "flex")}>
      <input
        id={field.id || field.name}
        className={clsx(
          isError ? "border-danger focus:border-danger" : "focus:border-primary-blue",
          children && "rounded-r-none",
          "rounded-lg w-full p-3 border-pale-grey-200 leading-tight appearance-none focus:outline-none border placeholder-pale-grey-50"
        )}
        required={isRequired}
        aria-required={isRequired}
        {...field}
        {...newProps}
      />
      {children}
    </div>
  );
}
