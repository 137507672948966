import Cookies from "js-cookie";

export const JWT_TOKEN_COOKIE = "jwt_token";
export const SSO_TOKEN_COOKIE = "sso_token";
export const SDK_TOKEN_COOKIE = "sdk_token";
export const MAPI_TOKEN_COOKIE = "mapi_token";

const cookieWhitelist = [JWT_TOKEN_COOKIE, SSO_TOKEN_COOKIE, SDK_TOKEN_COOKIE];
export function removeTrackingCookies() {
  const allCookies = document.cookie.split(";");
  allCookies.forEach((cookie) => {
    const cookieName = cookie.split("=")[0];
    if (!cookieWhitelist.includes(cookieName)) {
      Cookies.remove(cookieName);
    }
  });
}

export function has(cookie: string) {
  return !!Cookies.get(cookie);
}

export function get(cookie: string) {
  return Cookies.get(cookie) || "";
}

export function getSsoToken() {
  return Cookies.get(SSO_TOKEN_COOKIE) || "";
}

export function removeCookies() {
  Cookies.remove(JWT_TOKEN_COOKIE);
  Cookies.remove(SDK_TOKEN_COOKIE);
  Cookies.remove(SSO_TOKEN_COOKIE);
  Cookies.remove(MAPI_TOKEN_COOKIE);
}

export function setJwtTokenCookie(jwt: string) {
  Cookies.set(JWT_TOKEN_COOKIE, jwt, { httpOnly: false, signed: false, sameSite: "strict" });
}

export function setSdkTokenCookie(sdkToken: string) {
  Cookies.set(SDK_TOKEN_COOKIE, sdkToken, { httpOnly: false, signed: false, sameSite: "strict" });
}

export function setMapiTokenCookie(mapiToken: string) {
  Cookies.set(MAPI_TOKEN_COOKIE, mapiToken, { httpOnly: false, signed: false, sameSite: "strict" });
}

export function setSsoTokenCookie(ssoToken: string) {
  Cookies.set(SSO_TOKEN_COOKIE, ssoToken, { httpOnly: false, signed: false, sameSite: "strict" });
}

