import React from "react";

import { ReactComponent as GoogleBadge } from "../assets/google.svg";
import { ReactComponent as InfoItalic } from "../assets/info-italic.svg";
import { ReactComponent as IosBadge } from "../assets/iosappstore.svg";
import { trackEvent } from "../api/piwik/piwikService";
import { PiwikAction, PiwikCategory, PiwikName } from "../@types/common";

const pressIsoBadge = () => {
  trackEvent({
    category: PiwikCategory.BUTTONS,
    action: PiwikAction.CLICKED,
    name: PiwikName.APPLE_STORE
  });
  window.open("https://apps.apple.com/us/app/hy/id968130199", "_blank"); //to open new page
};

const pressGoogleBadge = () => {
  trackEvent({
    category: PiwikCategory.BUTTONS,
    action: PiwikAction.CLICKED,
    name: PiwikName.GOOGLE_PLAY
  });
  window.open("https://play.google.com/store/apps/details?id=com.healthiestyou.app&hl=en_US", "_blank"); //to open new page
};

const ProductBanner = () => {
  return (
    <div className="flex flex-row w-full rounded-md shadow-md h-auto px-4 pt-1 pb-4 align-middle border-solid border-b-1 md:p-2 md:items-center bg-blue-20 md:border-1 md:border-primary-blue">
      <div className="self-center block w-auto px-2 mb-0 sm:flex-1 sm:mb-14 md:px-4 md:flex-1 md:mb-12 lg:mb-0 lg:px-4">
        <InfoItalic title="Info" />
      </div>
      <div className="md:flex md:flex-col lg:flex lg:flex-row">
        <span className="flex-grow block h-auto p-2 px-4">
          Please note product and feature availability is currently limited when accessing via this portal. For full
          access please be sure to download the HealthiestYou app.
        </span>
        <div className="flex flex-row items-center w-full px-4 space-x-2 md:space-x-4 md:w-1/2 sm:w-1/4">
          <button onClick={pressIsoBadge} title="Download HY iOS App from AppStore">
            <IosBadge title="iOS Badge" aria-hidden />
          </button>
          <button onClick={pressGoogleBadge} title="Download HY Android App from Google Play Store">
            <GoogleBadge title="Google Badge" aria-hidden />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProductBanner;
