import React from "react";
import { Link } from "react-router-dom";
import { trackEvent } from "../api/piwik/piwikService";
import { PiwikAction, PiwikCategory, PiwikName } from "../@types/common";
import { ReactComponent as RightArrowIcon } from "../assets/icons/right-arrow.svg";

const EMSWidget = () => {
  const handleOnClick = async () => {
    trackEvent({
      category: PiwikCategory.BUTTONS,
      action: PiwikAction.CLICKED,
      name: PiwikName.EXPERT_MEDICAL_SERVICES
    });
  };

  return (
    <div className="p-4" data-testid="EMSWidgetContainer">
      <p className="pb-8 pl-1 ml-12 sm:ml-12 sm:pl-1 md:ml-20 md:pl-1">
        Did you know that you also have access to Expert Medical Services?
      </p>
      <div className="w-1/2 pl-1 ml-12 sm:ml-12 sm:pl-1 md:ml-20 md:pl-1">
        <Link data-testid="EMSWidgetButton" className="flex" to="/expert-medical-services" onClick={handleOnClick}>
          Learn more <RightArrowIcon className="w-4 h-4 mt-1 ml-2" title="Right Arrow Icon" aria-hidden />
        </Link>
      </div>
    </div>
  );
};

export default EMSWidget;
