import { Avatar } from "../FavoriteTypes";

const SUBDOMAINT_REGEX = /^(https?:\/\/)(www\.)?([^.])*/;
const GENERAL_DOCTOR = "general_doctor";
const GENERAL_DOCTOR_FEMALE = "general_doctor_female";
const BETTER_DOCTOR = "betterdoctor";
const ASSET_TO_REPLACE = "asset2";

export const replaceSubdomain = (url: string, subdomain: string): string =>
  url.replace(SUBDOMAINT_REGEX, `$1$2${subdomain}`);

export const getProviderImage = (imageUrl: string): Avatar => {
  let result = {
    src: "",
    female: imageUrl !== null && imageUrl.includes(GENERAL_DOCTOR_FEMALE) && imageUrl.includes(BETTER_DOCTOR)
  };

  if (imageUrl !== null && !imageUrl.includes(GENERAL_DOCTOR)) {
    result.src = replaceSubdomain(imageUrl, ASSET_TO_REPLACE);
  }

  return result;
};
