import clsx from "clsx";
import React from "react";

function Input({ header, value, onChange, placeholder = "Enter a value", id, className, maxLength = 30 }) {
  return (
    <div className={clsx("xs:w-full", className)}>
      <form onSubmit={(e) => e.preventDefault()}>
        <label htmlFor={id} className="leading-none text-dark-gray text-base block w-full">
          <span>{header}</span>
        </label>
        <input
          id={id}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          className="block  rounded-lg border-pale-grey-50 p-4 text-dark-gray text-base leading-none pr-2 w-full"
          style={{ borderWidth: 1 }}
          placeholder={placeholder}
        />
      </form>
    </div>
  );
}

export default Input;
