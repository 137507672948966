import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import * as Yup from "yup";

import { changePassword } from "../../api/forgotPassword";
import Alert from "../../components/ApiErrorAlert";
import Button from "../../components/Button";
import { ButtonGroup } from "../../components/ButtonGroup";
import { FormikPasswordWithButtonInput } from "../../components/Formik/Password/FormikPasswordWithButtonInput";
import { FormikValidatePasswordInput } from "../../components/Formik/Password/FormikValidatePasswordInput";
import { useSpinner } from "../../components/SpinnerProvider";
import { useAppDispatch } from "../../store/store";
import { passwordValidation } from "../../utils/validationHelper";
import { selectAuth } from "../slices/authSlice";
import { clearErrors, selectForgotPassword } from "../slices/forgotPasswordSlice";
import { trackEvent } from "../../api/piwik/piwikService";
import { PiwikAction, PiwikCategory, PiwikName } from "../../@types/common";
import { toast } from "react-toastify";

const ChangePassword = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { showSpinner } = useSpinner();
  const { error, changePasswordResponse } = useSelector(selectForgotPassword);
  const {
    entities: {
      profile: { id }
    }
  } = useSelector(selectAuth);

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  useEffect(() => {
    if (changePasswordResponse) {
      dispatch(clearErrors());
      showSpinner({
        show: false
      });
      history.push("/dashboard");
      toast.success("Your password has been changed");
    }
  }, [changePasswordResponse, dispatch, history, showSpinner]);

  useEffect(() => {
    if (error) {
      showSpinner({
        show: false
      });
      toast.error(error.message);
    }
  }, [error, showSpinner]);

  const handleCancel = () => {
    trackEvent({
      category: PiwikCategory.FORM,
      action: PiwikAction.CANCEL,
      name: PiwikName.CHANGE_PASSWORD
    });
    history.push("/dashboard");
  };

  const handleSubmit = async ({ newPassword, currentPassword }) => {
    showSpinner({ show: true });

    trackEvent({
      category: PiwikCategory.FORM,
      action: PiwikAction.SUBMIT,
      name: PiwikName.CHANGE_PASSWORD
    });
    try {
      const res = await dispatch(changePassword({ profileId: id, password: newPassword, currentPassword }));

      // it exists
      //@ts-ignore
      if (res?.error?.message) {
        //@ts-ignore
        throw new Error(res.error.message);
      }

      dispatch(clearErrors());
      showSpinner({ show: false });
      toast.success("Your password has been changed");
      history.push("/dashboard");
    } catch (e) {
      showSpinner({ show: false });
      toast.warn(error.message);

      return e;
    } finally {
      showSpinner({ show: false });
    }
  };

  return (
    <Formik
      initialValues={{
        currentPassword: "",
        newPassword: "",
        verifyNewPassword: ""
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        currentPassword: Yup.string().required("Required"),
        newPassword: passwordValidation,
        verifyNewPassword: passwordValidation.oneOf([Yup.ref("newPassword"), ""], "Passwords must match")
      })}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        handleSubmit(values).finally(() => setSubmitting(false));
      }}
    >
      {({ isSubmitting, isValid }) => (
        <Form className="pt-6" id="changePasswordPanel">
          {error && <Alert errors={error} />}
          <div className="max-w-sm">
            <FormikPasswordWithButtonInput
              label="Current Password"
              isRequired={true}
              placeholder="Please enter current password"
              data-testid="current-password"
              name="currentPassword"
            />
            <FormikValidatePasswordInput
              label="New Password"
              isRequired={true}
              placeholder="Please enter password"
              data-testid="new-password"
              name="newPassword"
            />
            <FormikPasswordWithButtonInput
              label="Verify New Password"
              isRequired={true}
              placeholder="Please enter password again"
              data-testid="verify-new-password"
              name="verifyNewPassword"
              showFieldError={true}
            />
          </div>
          <div className="w-full max-w-sm">
            <ButtonGroup>
              <Button
                disabled={isSubmitting}
                buttonType="secondary"
                onClick={handleCancel}
                name="cancel"
                aria-label="Cancel, Back to the Dashboard"
              >
                Cancel
              </Button>
              <Button disabled={isSubmitting || !isValid} buttonType="primary" type="submit" name="next">
                Confirm
              </Button>
            </ButtonGroup>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ChangePassword;
