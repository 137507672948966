export enum feature {
  telehealth = "Telehealth",
  widgetManagement = "WidgetManagement",
  wellness = "Wellness",
  rx = "RX",
  providers = "Providers",
  pricing = "Pricing",
  plans = "Plans",
  beatTheDeductible = "BeatTheDeductible",
  bestDoctors = "BestDoctors",
  backCare = "Backcare",
  behavioralHealth = "BehavioralHealth",
  betterHelp = "BetterHelp",
  consultsForMissing = "ConsultsForMissing",
  openDependents = "OpenDependents",
  covid19Assessment = "Covid19Assessment"
}
