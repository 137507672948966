import { createAsyncThunk } from "@reduxjs/toolkit";
import hyApi from "../services/hyApiService";

export const savingsHistory = createAsyncThunk("savings/history/", async (id: string) =>
  hyApi.get(`api/savings/history/${id}`).then(({ data }) => data)
);

export const savingsSummary = createAsyncThunk("savings/summary/", async (id: string) =>
  hyApi.get(`api/savings/summary/${id}`).then(({ data }) => data)
);
