import React from "react";
import { trackEvent } from "../api/piwik/piwikService";
import { PiwikAction, PiwikCategory, PiwikName } from "../@types/common";
import { redirectToTelespine } from "../api/websdk/mobileApi";
import { useSpinner } from "./SpinnerProvider";
import { toast } from "react-toastify";

import Button from "./Button";
import Widget from "./Widget";
import WidgetHeader from "./HeaderBar";
import { ReactComponent as NeckAndBackIcon } from "../assets/icons/NeckAndBack.svg";
import { useSelector } from "react-redux";
import { selectConfigs } from "../containers/slices/configsSlice";

const NeckAndBackcareWidget = () => {
  const { showSpinner } = useSpinner();
  const { swordMigrationDate } = useSelector(selectConfigs);

  const handleOnClick = async () => {
    showSpinner({ show: true });
    trackEvent({
      category: PiwikCategory.BUTTONS,
      action: PiwikAction.CLICKED,
      name: PiwikName.NECK_AND_BACKCARE
    });

    try {
      await redirectToTelespine(swordMigrationDate);
    } catch (err) {
      console.error(err);
      toast.error("Unable to redirect you");
    }

    showSpinner({ show: false });
  };

  return (
    <Widget>
      <WidgetHeader>
        <NeckAndBackIcon className="w-10 h-10 md:h-auto md:w-auto" aria-hidden />
        <div>
          <h2 className="text-3xl font-medium font-effra">Back and joint care</h2>
          <h3 className="text-xl font-medium leading-tight">In partnership with Orbit Telehealth</h3>
        </div>
      </WidgetHeader>
      <div className="flex flex-col" data-testid="NeckAndBackcareWidgetContainer">
        <div className="px-4 pb-4 font-effra">
          <p className="pb-8 pl-1 pt-4 ml-12 sm:ml-12 sm:pl-1 md:ml-20 md:pl-1">
            Overcome your neck, back and joint pain with a personalized therapy plan and unlimited access to a health
            coach.
          </p>
          <div className="w-1/2 pl-1 ml-12 sm:ml-12 sm:pl-1 md:ml-20 md:pl-1">
            <Button data-testid="NeckAndBackcareWidgetButton" onClick={handleOnClick}>
              Get started with Orbit
            </Button>
          </div>
        </div>
      </div>
    </Widget>
  );
};

export default NeckAndBackcareWidget;
