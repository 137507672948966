import { find, includes } from "lodash";
import { facilityTypes } from "./facility";
import { postalCodes } from "./postalCodes";
import { defaultGenderOption, defaultRadiusOption, genderOptions, specialties } from "./providers";

const getProvidersName = ({ firstName, lastName, title }) => {
  return `${firstName} ${lastName} ${title.toUpperCase()}`;
};

const getGenderSpecialties = (specialties, gender) => {
  let strToReturn = gender ? `${gender.replace(/^./, gender[0].toUpperCase())} - ` : "";
  specialties.forEach((specialty, i) => (strToReturn += i === 0 ? specialty.name : `, ${specialty.name}`));
  return strToReturn;
};

const getAddress = ({ street, city, state, zip }) => {
  return `${street}, ${city}, ${state}, ${zip}`;
};

const getFormatedPhoneNumber = ({ phones }) => {
  return phones?.[0]?.number?.replace(/^(\d{3})(\d{3})(\d{4}).*/, "($1) $2-$3");
};

const normalizeOption = (option) => option?.replace(/-/gi, " ")?.trim() || option;

export interface providersFiltersInterface {
  zip: string;
  specialty: string;
  radius: string;
  gender: string;
  last: string;
  enabled: boolean;
}

export const getEducations = (educations) => {
  return educations.map((e) => e.school).join("\n");
};

const findAssertion = (key, value) => key.toString().toLowerCase() === normalizeOption(value).toLowerCase();

export const findSpecialty = (specialty) => {
  return find(specialties, (spec) => findAssertion(spec.label, specialty) || findAssertion(spec.value, specialty));
};

export const findGender = (gender) => {
  return find(genderOptions, (gen) => gen.label.toString().toLowerCase() === normalizeOption(gender).toLowerCase());
};

export const findFacility = (facility, field = "value") => {
  return find(facilityTypes, (fac) => fac[field].toString().toLowerCase() === normalizeOption(facility).toLowerCase());
};

export const providersFiltersDefault = (searchParams): providersFiltersInterface => ({
  zip: searchParams?.zip || "",
  gender: searchParams?.gender || defaultGenderOption.label,
  last: searchParams?.last || "",
  radius: searchParams?.radius || defaultRadiusOption.label,
  specialty: findSpecialty(searchParams?.specialty)?.label || searchParams?.specialty,
  enabled: true
});

export const formatProvider = (provider) => {
  return {
    id: provider.id,
    header: provider.profile.firstName ? getProvidersName(provider.profile) : "",
    description: getGenderSpecialties(provider.specialties, provider.profile.gender),
    address: getAddress(provider.practices[0].visitAddress),
    phone: getFormatedPhoneNumber(provider.practices[0]),
    distance: provider.practices[0].distance,
    educations: getEducations(provider.educations),
    profile: { gender: provider?.profile?.gender, imageUrl: provider?.profile?.imageUrl }
  };
};

export const formatFacility = (facility) => ({
  id: facility.data.id,
  header: facility.data.company,
  description: null,
  address: `${facility.data.address1}, ${facility.data.city}, ${
    facility.data.locationData.state || facility.data.state
  }, ${facility.data.zip}`,
  phone: facility.data.phone,
  distance: facility.distance,
  profile: null
});

const getState = (zip) => find(postalCodes, (key) => includes(key, parseInt(zip)));

export const isValidZip = (zip) => !!getState(zip);
