import React from "react";
import clsx from "clsx";
import { PiwikAction, PiwikCategory, PiwikName } from "../@types/common";
import { trackEvent } from "../api/piwik/piwikService";
import { ReactComponent as LaunchIcon } from "../assets/icons/launch.svg";
import Widget from "./Widget";
import WidgetHeader from "./HeaderBar";
import ReactTooltip from "react-tooltip";
import { ReactComponent as CovidAssessmentIcon } from "../assets/icons/covid-19.svg";
import Button from "./Button";

const Covid19AssessmentWidget = () => {
  const handleOnClick = (redirectUrl: string) => {
    window.open(redirectUrl, "_blank");
  };

  return (
    <Widget>
      <>
        <WidgetHeader>
          <CovidAssessmentIcon className="w-10 h-10 md:h-auto md:w-auto" aria-hidden />
          <p className="text-3xl font-medium">COVID-19 Assessment</p>
        </WidgetHeader>
        <div className="p-4" data-testid="beatTheDeductibleContainer">
          <p className="pb-8 pl-1 ml-12 sm:ml-12 sm:pl-1 md:ml-20 md:pl-1">
            If you think you are infected with COVID-19, Teladoc physicians can answer questions about the disease,
            evaluate your risk and advise you on next steps. Consider taking our self-assessment below
          </p>
          <div className="w-1/2 pt-2 pl-1 ml-12 sm:ml-12 sm:pl-1 md:ml-20 md:pl-1">
            <Button onClick={() => handleOnClick("https://member.teladoc.com/covid_19")}>
              Take the Self-Assessment
            </Button>
          </div>
          <div className="w-1/2 pt-6 pl-1 ml-12 sm:ml-12 sm:pl-1 md:ml-20 md:pl-1">
            <button
              className={clsx("flex")}
              type="button"
              onClick={() => handleOnClick("https://www.teladoc.com/coronavirus/")}
            >
              Learn more <LaunchIcon className="w-4 h-4 mt-1 ml-2" title="Launch Icon" aria-hidden />
            </button>
          </div>
        </div>
      </>
    </Widget>
  );
};

export default Covid19AssessmentWidget;
