import React, { useRef } from "react";
import { DismissButton, useOverlay } from "@react-aria/overlays";
import { FocusScope } from "@react-aria/focus";

interface Props {
  children: any;
  state: any;
}

export const Modal = React.forwardRef(({ children, state }: Props, ref?) => {
  let overlayRef = useRef() as any;
  let { overlayProps } = useOverlay(
    {
      onClose: () => state.close(),
      isOpen: state.isOpen
    },
    overlayRef
  );

  return (
    state.isOpen && (
      <div {...overlayProps} ref={overlayRef} className="fixed inset-0 z-40 grid bg-black bg-opacity-75">
        <FocusScope restoreFocus>
          <DismissButton onDismiss={() => state.close()} />
          {children}
          <DismissButton onDismiss={() => state.close()} />
        </FocusScope>
      </div>
    )
  );
});
