import React, { createContext, useContext, useMemo, useCallback } from "react";
import Overlay from "./Overlay";
import { ReactComponent as Progress } from "../assets/Progress.svg";

export interface ShowSpinnerProps {
  show: boolean;
}

interface SpinnerProviderData {
  showSpinner: (props?: ShowSpinnerProps) => void;
  toggleSpinner: () => void;
  hide: () => void;
}

const SpinnerContext = createContext<SpinnerProviderData>({
  showSpinner: () => {},
  toggleSpinner: () => {},
  hide: () => {}
});

const SpinnerProvider = ({ children }) => {
  const [show, setShow] = React.useState(false);
  const [spinnerProps, setSpinnerProps] = React.useState<ShowSpinnerProps>({
    show: false
  });

  const toggleSpinner = useCallback(() => {
    setSpinnerProps({ show: !spinnerProps.show });
    setShow(!spinnerProps.show);
  }, [spinnerProps.show]);

  const showSpinner = useCallback(
    (props?: ShowSpinnerProps) => {
      setSpinnerProps({ show: props?.show ?? !show });
      setShow(props?.show ?? !show);
    },
    [show]
  );

  const hide = () => setShow(false);

  const contextValue = useMemo(() => {
    return { showSpinner, hide, toggleSpinner };
  }, [showSpinner, toggleSpinner]);

  return (
    <SpinnerContext.Provider value={contextValue}>
      <Overlay active={show}>
        <div className="z-50 flex flex-col items-center justify-center h-auto px-10 py-4 mx-auto bg-white rounded shadow-lg w-md">
          <Progress className="inline-block h-12 my-3 fill-current" title="Progress" />
          <p className="text-medium font-effra text-blackish">Please wait...</p>
        </div>
      </Overlay>
      {children}
    </SpinnerContext.Provider>
  );
};

export const useSpinner = () => {
  const context = useContext(SpinnerContext);
  if (context === undefined) {
    throw new Error("useSpinner can only be used inside SpinnerProvider");
  }
  return context;
};

export default SpinnerProvider;
