import React from "react";
import clsx from "clsx";

export default function ({ className = "" }) {
  return (
    <svg className={clsx(className)} xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
      <g fill="none" fillRule="evenodd">
        <g fill="#727069">
          <g>
            <path
              d="M16 7.53c4.678 0 8.47 3.792 8.47 8.47 0 4.678-3.792 8.47-8.47 8.47-4.678 0-8.47-3.792-8.47-8.47 0-4.678 3.792-8.47 8.47-8.47zm0 1.411c-3.898 0-7.059 3.16-7.059 7.059 0 3.898 3.16 7.059 7.059 7.059 3.898 0 7.059-3.16 7.059-7.059 0-3.898-3.16-7.059-7.059-7.059zm3.323 3.736c.26.26.274.671.045.948l-.045.05L16.998 16l2.325 2.325c.275.276.275.723 0 .999-.26.26-.671.274-.948.045l-.05-.045-2.326-2.325-2.323 2.325c-.276.275-.723.275-.999 0-.26-.26-.274-.671-.045-.948l.045-.05L15 15.998l-2.323-2.323c-.275-.276-.275-.723 0-.999.26-.26.671-.274.948-.045l.05.045L16 15l2.325-2.323c.276-.275.723-.275.999 0z"
              transform="translate(-366 -176) translate(359 169)"
            />
          </g>
        </g>
      </g>
      <div id="edge-translate-notifier-container" className="edge-translate-notifier-center" />
    </svg>
  );
}
