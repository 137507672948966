import { toast, ToastContainer } from "react-toastify";
import { OverlayProvider } from "@react-aria/overlays";
import React from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

import { AuthProvider } from "./AuthProvider";
import { ToastCloseButton } from "./components/toast/ToastCloseButton";
import Layout from "./components/Layout";
import WebSdkProvider from "./components/WebSdkProvider";
import Dashboard from "./containers/Dashboard";
import ForgotPassword from "./containers/ForgotPassword";
import Login from "./containers/Login";
import CreateAccount from "./containers/Register/CreateAccount";
import RegisterPayPerVisit from "./containers/Register/RegisterPayPerVisit";
import Registration from "./containers/Register/Registration";
import Authenticating from "./containers/SSO/Authenticating";
import SsoError from "./containers/SSO/SsoError";
import SpinnerProvider from "./components/SpinnerProvider";
import Logout from "./containers/Logout";
import SessionExpired from "./containers/SessionExpired";
import SetPassword from "./containers/SetPassword";
import ExpertMedicalServices from "./containers/ExpertMedicalServices";
import MyFamily from "./containers/MyFamily";
import Support from "./containers/Support/Support";
import TechnicalSupport from "./containers/Support/Technical";
import QuestionsOrFeedback from "./containers/Support/QuestionsOrFeedback";
import BeatTheDeductible from "./containers/BeatTheDeductible";
import AccountInfo from "./containers/AccountSettings";
import Favorites from "./containers/Favorites";
import LivongoRegistration from "./containers/LivongoRegistration";
import Procedures from "./pages/procedures";
import Prescriptions from "./pages/prescriptions";
import Providers from "./pages/providers";
import Facilities from "./pages/facilities";
import { Feature } from "./utils/featureHelper";
import HasFeatureWrapper from "./components/HasFeatureRoute";
import SamlRoute from "./components/SamlRoute";
import SAMLEntry from "./containers/SAML/SAMLEntry"; /*PLEASE DONT MERGE OUT!!!!!!!!*/
import HealthCareBenefitAlert from "./components/HealthCareBenefitAlert";
import PrivateRoute from "./components/PrivateRoute";
import MaintenanceMode from "./containers/MaintenanceMode";
import FeaturesToggleProvider from "./components/FeaturesToggleProvider";
import SelectMFAChannel from "./containers/MFA/SelectMFAChannel";
import VerifyMFACode from "./containers/MFA/VerifyMFACode";

const queryClient = new QueryClient({ defaultOptions: { queries: { refetchOnWindowFocus: false } } });

const AppRoutes = () => {
  return (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/MFA">
        <SelectMFAChannel />
      </Route>
      <Route exact path="/MFA/verify">
        <VerifyMFACode />
      </Route>
      <Route exact path="/support">
        <Support />
      </Route>
      <Route exact path="/technical-support">
        <TechnicalSupport />
      </Route>
      <Route exact path="/questions-or-feedback">
        <QuestionsOrFeedback />
      </Route>
      <Route exact path="/register">
        <Registration />
      </Route>
      <Route exact path="/register/eligible-members">
        <Registration />
      </Route>
      <Route exact path="/register/create-account">
        <CreateAccount />
      </Route>
      <Route exact path="/register/pay-per-visit">
        <RegisterPayPerVisit />
      </Route>
      <Route path="/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/sso-error">
        <SsoError />
      </Route>
      <Route path="/user/authenticating">
        <Authenticating />
      </Route>
      <Route path="/session-expired">
        <SessionExpired />
      </Route>
      <Route path="/set-password/:profileId/:passwordRecoveryCode">
        <SetPassword />
      </Route>
      <Route path="/uhcmentalhealth">
        <HealthCareBenefitAlert />
      </Route>
      <SamlRoute path="/SAML/SAMLEntry" element={<SAMLEntry />} /> /*PLEASE DONT MERGE OUT!!!!!!!!*/
      <PrivateRoute exact path="/procedures" element={<Procedures />} />
      <PrivateRoute exact path="/prescriptions" element={<Prescriptions />} />
      <PrivateRoute
        path="/providers"
        element={<HasFeatureWrapper feature={Feature.providers} element={<Providers />} />}
      />
      <PrivateRoute
        path="/facilities"
        element={<HasFeatureWrapper feature={Feature.facilities} element={<Facilities />} />}
      />
      <PrivateRoute exact path="/beat-the-deductible" element={<BeatTheDeductible />} />
      <PrivateRoute exact path="/dashboard" element={<Dashboard />} />
      <PrivateRoute path="/my-family" element={<MyFamily />} />
      <PrivateRoute path="/account-settings" element={<AccountInfo />} />
      <PrivateRoute path="/livongo-registration" element={<LivongoRegistration />} />
      <PrivateRoute path="/expert-medical-services" element={<ExpertMedicalServices />} />
      <PrivateRoute path="/favorites" element={<Favorites />} />
      <PrivateRoute path="/logout" element={<Logout />} />
      <PrivateRoute path="/:sdkFlow" element={<Dashboard />} />
      <Route path="*">
        <Redirect to="/login" />
      </Route>
    </Switch>
  );
};

const toastDefaults = {
  position: toast.POSITION.TOP_CENTER,
  hideProgressBar: true,
  pauseOnHover: true,
  draggable: true,
  autoClose: 5000
};

function App() {
  return (
    <OverlayProvider>
      <QueryClientProvider client={queryClient}>
        <div data-testid="AppContainer" className="flex-1">
          <Router>
            <FeaturesToggleProvider>
              <AuthProvider>
                <Layout>
                  <WebSdkProvider>
                    <SpinnerProvider>
                      <ToastContainer closeButton={ToastCloseButton} {...toastDefaults} />
                      <MaintenanceMode>
                        <AppRoutes />
                      </MaintenanceMode>
                    </SpinnerProvider>
                  </WebSdkProvider>
                </Layout>
              </AuthProvider>
            </FeaturesToggleProvider>
          </Router>
        </div>
      </QueryClientProvider>
    </OverlayProvider>
  );
}

export default App;
