import React from "react";
import { CrossIcon } from "../../components/Icons";

const PricePrescriptionDisclaimer = ({ onClose }) => (
  <div className="flex flex-col min-w-full min-h-screen -ml-4">
    <div className="flex justify-between">
      <p className="text-2xl">Disclaimer</p>
      <button onClick={onClose} onKeyPress={onClose}>
        <CrossIcon />
      </button>
    </div>

    <p className="py-4">
      This is not meant to replace your existing health insurance. We will contract with various vendors to provide you
      with such discounts. Members must present our card at the time the discount is requested. Savings are based upon
      the provider&apos;s usual and customary fees. Actual savings will vary depending upon location and specific
      services or products purchased. The discounts contained herein may not be used in conjunction with any other
      discount plan or program. All listed or quoted prices are current prices by participating providers and subject to
      change without notice. Certain providers may offer products or services to the general public at prices lower than
      the discounted prices available through this program. In such event, you will be charged the lowest price.
    </p>
    <p className="py-4">
      Providers are subject to change without notice and benefits may vary in some states. No portion of any
      provider&apos;s fees will be reimbursed or otherwise paid by us. We are not licensed to provide and do not provide
      medical services or items to individuals. Providers contracted by us, or our agents are solely responsible for the
      professional advice and treatment rendered to consumers, and we disclaims any liability with respect to such
      matters. Discounts on professional services are not available where prohibited by law. Benefits and benefit
      providers may change or be discontinued at any time without notice.
    </p>

    <p className="py-4">
      Names of all providers and provider networks are the tradenames, trademarks, or service marks of these companies.
      We may market available discounts from these companies but are not owned or otherwise affiliated with these
      companies.
    </p>
  </div>
);

export default PricePrescriptionDisclaimer;
