import { createAsyncThunk } from "@reduxjs/toolkit";
import hyApi from "../services/hyApiService";
import { Gender } from "../../@types/common";
import * as rdd from "react-device-detect";
import { AppThunk } from "../../store/store";
import { AddDependentRequest } from "../../containers/slices/registrationSlice";

export interface Platform {
  type?: string;
  platform?: string;
  platformRaw?: string;
  version?: string;
  model?: string;
  manufacturer?: string;
}

export interface RegisterIneligibleMemberRequest {
  groupCode: string;
  address1: string;
  address2: string;
  city: string;
  state: string;
  first: string;
  last: string;
  dob: string;
  zip: string;
  gender: Gender;
  phone: string;
  email: string;
  password: string;
  submittedFrom: string;
  submittedFromPlatform: Platform;
}

interface EmailLookupRequest {
  email: string;
}

interface MemberLookupRequest {
  last: string;
  dob: string;
  zip: string;
}

interface StudentLookupRequest {
  first: string;
  last: string;
  dob: string;
  studentId: string;
}

interface PersonsRequest {
  membershipId: string;
}

export const memberLookup = createAsyncThunk("registration/memberLookup", async (data: MemberLookupRequest) => {
  const response = await hyApi.get(`api/memberships/v2/lookup?lastName=${data.last}&dob=${data.dob}&zip=${data.zip}`);

  return response.data;
});

export const getPersons = createAsyncThunk("registration/getPersons", async (data: PersonsRequest) => {
  const response = await hyApi.get(`api/memberships/${data.membershipId}/persons`);

  return response.data;
});

export const studentLookup = createAsyncThunk("registration/studentLookup", async (data: StudentLookupRequest) => {
  const response = await hyApi.get(
    `api/memberships/v2/student-lookup?firstName=${data.first}&lastName=${data.last}&dob=${data.dob}&studentId=${data.studentId}`
  );

  return response.data;
});

export const emailLookup = createAsyncThunk("registration/emailLookup", async ({ email }: EmailLookupRequest) => {
  const response = await hyApi.get(`api/profiles/check-email?email=${email}`);

  return response.data;
});

export const registerProfile = createAsyncThunk("registration/registerProfile", async (data, thunkAPI) => {
  // @ts-ignore
  const { registration, auth } = thunkAPI.getState();
  const { email, membershipId, password, selectedPerson, phone } = registration;
  const payload = {
    email,
    membershipId: membershipId || auth.entities.membershipId._id,
    password,
    phone,
    familyMemberId: selectedPerson.familyMemberId,
    version: 2
  };
  const response = await hyApi.post("api/profiles", payload);
  return response.data;
});

export const addDependent = createAsyncThunk("registration/addDependent", async (payload: AddDependentRequest) => {
  payload.command = "Change";
  payload.changePlanTo = "EMP";
  const response = await hyApi.post("api/changes", payload);

  return response.data;
});

export const registeIneligibleMember = createAsyncThunk(
  "registration/registeIneligibleMember",
  async (data, thunkAPI) => {
    const deviceData = rdd.deviceDetect();
    const submittedFrom = "member-portal";
    const submittedFromPlatform = {
      type: "browser",
      platform: deviceData.osName,
      platformRaw: deviceData.userAgent,
      version: `${deviceData.osName}_${deviceData.osVersion}`,
      model: null,
      manufacturer: null
    };

    // @ts-ignore
    const { registration } = thunkAPI.getState();
    const { email, password, phone, payPerVisitData } = registration;

    const payload: RegisterIneligibleMemberRequest = {
      email,
      password,
      phone,
      groupCode: "HYUHCSRPAID",
      ...payPerVisitData,
      submittedFromPlatform,
      submittedFrom
    };

    const response = await hyApi.post("api/members/ineligible", payload);

    return response.data;
  }
);

export default {
  memberLookup,
  getPersons,
  studentLookup,
  emailLookup,
  registerProfile
};
