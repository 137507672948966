import React from "react";
import { useField } from "formik";
import clsx from "clsx";
import { ReactComponent as ArrowSVG } from "../../assets/icons-arrow-01-chevron-down.svg";
import { FormFieldError } from "../FormFields/FieldError";

export const FormikSelect = ({ label, isRequired, options, disabled, ...props }: any) => {
  const [field, meta] = useField(props);
  const isError = meta.touched && meta.error;
  const optionsWithPlaceholder =
    props.placeholder && options[0]?.label !== props.placeholder
      ? [
          {
            label: props.placeholder,
            value: ""
          },
          ...options
        ]
      : options;

  return (
    <div className="flex flex-col">
      <label className="mb-2 text-sm font-bold text-dark-gray" htmlFor={props.id || props.name}>
        {label}
        {isRequired && <span className="px-1" aria-hidden>*</span>}
      </label>
      <div className="relative flex flex-row">
        <select
          id={props.id || props.name}
          aria-required={isRequired}
          className={clsx(
            isError ? "border-danger focus:border-danger" : "focus:border-primary-blue",
            "focus:outline-none focus:border-solid focus:border-primary-blue",
            "w-full px-3 py-3 leading-tight bg-transparent text-dark-gray border-pale-grey-200 text-base rounded-lg appearance-none border"
          )}
          disabled={disabled}
          {...field}
        >
          {optionsWithPlaceholder.map((option) => (
            <option key={option.label} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <span className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none text-gray-dark">
          <ArrowSVG aria-hidden className="block w-3 h-3 -ml-4 fill-current" id={`${props.id || props.name}-dropdown`}  title="Dropdown Toggle Icon" />
        </span>
      </div>
      <FormFieldError field={field} meta={meta} />
    </div>
  );
};
