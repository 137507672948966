import * as React from "react";
import { Link } from "react-router-dom";
import { PiwikAction, PiwikCategory, PiwikName } from "../../@types/common";
import { trackEvent } from "../../api/piwik/piwikService";

interface NavItemProps {
  path: string;
  name: string;
  icon?: string;
  isActive?: boolean;
  children: any;
}

export const NavItem = ({ path, name, children, isActive }: NavItemProps) => {
  const trackLinkEvent = (name: string) => {
    let piwikName: PiwikName = PiwikName.RAV;
    switch (name) {
      case "/medical-info":
        piwikName = PiwikName.MEDICAL_INFO;
        break;
      case "/request-a-visit":
        piwikName = PiwikName.RAV;
        break;
      case "/dashboard":
        piwikName = PiwikName.DASH;
        break;
      case "/message-center":
        piwikName = PiwikName.MESSAGES;
        break;
      case "/account-settings":
        piwikName = PiwikName.ACCOUNT_SETTINGS;
        break;
      case "/change-password":
        piwikName = PiwikName.CHANGE_PASSWORD;
        break;
      case "/favorites":
        piwikName = PiwikName.FAVORITES;
        break;
      case "/logout":
        piwikName = PiwikName.LOGOUT;
        break;
    }

    trackEvent({
      category: PiwikCategory.HEADER_LINKS,
      action: PiwikAction.CLICKED,
      name: piwikName
    });
  };

  const handleClick = () => {
    trackLinkEvent(path);
  };
  const activeClassNames = "border-primary-500 border-t-solid border-t-5";
  const classNames =
    "px-3 py-6 font-bold text-black transition-all duration-200 border-t-4 border-transparent hover:bg-pale-grey-10 hover:no-underline ";

  return (
    <Link
      role="menuitem"
      onClick={handleClick}
      data-testid={`${name}Link`}
      to={path}
      className={isActive ? classNames + activeClassNames : classNames}
    >
      {children}
    </Link>
  );
};
