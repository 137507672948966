import { webSDKConfig } from "../../constants/websdk.constant";
import { get, SDK_TOKEN_COOKIE } from "../../utils/cookies";
import { WebSdkErrorMessage } from "../../components/WebSdkProvider";

export interface SdkHandlerProps {
  appConfiguration: typeof webSDKConfig;
  authorization: {
    ssoToken: string;
  };
  [key: string]: any;
}

export type SdkHandler = (config: SdkHandlerProps) => Promise<any>;

export async function callSdk(handler: SdkHandler, extraFields = {}) {
  const payload = {
    appConfiguration: webSDKConfig,
    authorization: { ssoToken: get(SDK_TOKEN_COOKIE) },
    ...extraFields
  };
  try {
    return await handler(payload);
  } catch (err) {
    const { message } = err as { message: WebSdkErrorMessage };
    if (message === WebSdkErrorMessage.AUTH_FAILED) {
      try {
        return await handler(payload);
      } catch (ignored) {
        // ignore error if we get it a second time
      }
    }
  }
}
