import React, { useCallback } from "react";
import { GMap, PrescriptionsMarker } from "../../components/Map";

const PrescriptionsMap = ({
  drugPrices,
  mapCenter,
  activeItem,
  selectedItem,
  onItemMouseEnter,
  onItemMouseLeave,
  onItemClick
}) => {
  const renderLocations = useCallback(
    ({ map, maps: { LatLng, LatLngBounds } }) => {
      const bounds = new LatLngBounds();
      drugPrices.forEach(({ Latitude, Longitude }) => {
        const lat = parseFloat(Latitude);
        const lng = parseFloat(Longitude);
        bounds.extend(new LatLng(lat, lng));
      });
      map.fitBounds(bounds);
    },
    [drugPrices]
  );

  return (
    <div className="h-full w-full mb-2 md:mb-0">
      <GMap renderLocations={renderLocations} center={mapCenter}>
        {drugPrices.map((drugPrice) => {
          const { PharmacyId, Latitude, Longitude } = drugPrice;
          return (
            <PrescriptionsMarker
              key={PharmacyId}
              drugPrice={drugPrice}
              active={PharmacyId === activeItem}
              selected={PharmacyId === selectedItem}
              onMouseEnter={() => onItemMouseEnter(drugPrice)}
              onMouseLeave={() => onItemMouseLeave(drugPrice)}
              onClick={() => onItemClick(drugPrice)}
              lat={parseFloat(Latitude)}
              lng={parseFloat(Longitude)}
            />
          );
        })}
      </GMap>
    </div>
  );
};

export default PrescriptionsMap;
