import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Button from "../../components/Button";
import { useAppDispatch } from "../../store/store";
import { logout, selectAuth } from "../slices/authSlice";
import { ReactComponent as HYLogoHoriz } from "../../assets/HYLogoHoriz.svg";
import { clearErrors } from "../slices/forgotPasswordSlice";
import { FormikSelect } from "../../components/Formik/FormikSelect";
import LinkButton from "../../components/LinkButton";
import { getPhones, sendMFACode } from "../../api/MFA";
import { anonymizeEmail, anonymizePhoneNumber } from "../../utils/anonymizeHelper";
import { getMapiToken, getSdkToken } from "../../api/auth";


export enum EnumPhoneType {
  PHONEFAXTYPE_HOME = "HOME",
  PHONEFAXTYPE_MOBILE = "MOBILE",
  PHONEFAXTYPE_WORK = "WORK",
  PHONEFAXTYPE_OTHER = "OTHER"
}
export type MFAChannelPhone = {
  party_phone_fax_id: string;
  area_code: string;
  phone_number: string;
  preffered_flg: string;
  extension: string;
  phone_fax_type_cd: EnumPhoneType;
};

const SelectMFAChannel = () => {
  const dispatch = useAppDispatch();
  const { entities, hasAuth, error, requiresMFA, hasMFA, hasMapi, mapiToken, hasSdk, teladocSdkToken } = useSelector(selectAuth);
  const [isSubmitting, setSubmitting] = useState(false);
  const history = useHistory();
  const [phones, setPhones] = useState<any[]>([]);
  const [emails, setEmails] = useState<any[]>([]);

  const handleSubmit = (values) => {
    const phoneLabel = phones.find((phone) => phone.value == values.deliveryId).label;

    if (hasMapi && mapiToken) {
      dispatch(
        sendMFACode({
          deliveryMethod: "phone",
          communicationId: values.deliveryId,
          mapiJwt: mapiToken
        })
      )
        .then(() => {
          history.push("/MFA/verify", { phoneLabel });
        })
        .catch((e) => {
          toast.error("We are sorry there was an error sending the PIN. Please try again.");
          setSubmitting(false);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      toast.error("We are sorry there was an error sending the PIN. Please try again.");
      setSubmitting(false);
    }
  };

  const getPhonesAndEmails = () => {
    dispatch(getPhones(entities.familyMember?.telehealthID))
      .then((apiRes) => {
        if (!apiRes) {
          toast.error("We are sorry there was an error retrieving your phone numbers. Please try again.");
          return;
        }
        try {
          const phones = (apiRes?.payload as any).phones as MFAChannelPhone[];
          const phonesWithLabels = phones.map((phone) => {
            if (!phone) {
              throw new Error("Phone is null or undefined");
            }
            return { value: phone.party_phone_fax_id, label: anonymizePhoneNumber(phone.area_code + phone.phone_number) };
          });
          setPhones(phonesWithLabels);

          // const emailsWithLabels = apiRes.payload.emails.map((email) => {
          //   if (!email) {
          //     throw new Error("Email is null or undefined");
          //   }
          //   return { value: email, label: anonymizeEmail(email) };
          // });
          // setEmails(emailsWithLabels);
        } catch (error) {
          toast.error("We are sorry there was an error retrieving your phone numbers. Please try again.");
        }
      })
      .catch(() => {
        toast.error("We are sorry there was an error retrieving your phone numbers. Please try again.");
      });
  };

  useEffect(() => {

    if (!hasAuth) history.push("/login");

    if (error) {
      toast.error("Something went wrong. Please try again.");
      dispatch(clearErrors());
    }
    if (hasAuth && !error && requiresMFA && !hasMFA) {
      getPhonesAndEmails();
    }

    const rememberDeviceMFA: boolean = typeof localStorage.getItem('rememberDeviceMFA') === "string" ? JSON.parse(localStorage.getItem('rememberDeviceMFA') as string) : undefined;

    if ((hasAuth && !requiresMFA) || (hasAuth && requiresMFA && rememberDeviceMFA)) {
      history.push("/dashboard");
    }

    if (hasMFA) {
      history.push("/dashboard");
    }

    dispatch(getSdkToken(entities.profile.id)).then(() => {
      if (hasSdk && teladocSdkToken) {
        dispatch(getMapiToken(teladocSdkToken))
      }
    }
    );

  }, [hasAuth, error, requiresMFA, hasMFA, entities]);

  const handleReturnToSignIn = () => {
    dispatch(logout());
    history.push("/login");
  };

  return (
    <div className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md w-full space-y-8">
        <div className="m-6">
          <HYLogoHoriz className="mx-auto w-auto h-16" />
          <div>
            <h2 className="mt-6 text-3xl font-extrabold text-dark-gray">Two-Factor Authentication Required</h2>
            <p className="pb-2 mt-8 leading-tight text-justify font-effra text-dark-gray">
              It looks like you're logging in on a new device or browser. 
              We'll need to authenticate your account using a mobile phone. 
              Please only select a phone number that allows text messages.
            </p>
            <Formik
              initialValues={{
                deliveryId: ""
              }}
              enableReinitialize={true}
              validationSchema={Yup.object({
                deliveryId: Yup.string().required("Required")
              })}
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              {({ isSubmitting }) => (
                <Form className="bg-white rounded mt-6 pt-6 pb-8 mb-4">
                  <div className="max-w-sm">
                    <label htmlFor="deliveryId">Send PIN to</label>
                    <FormikSelect name="deliveryId" placeholder="Choose your phone" options={[...phones, ...emails]} />
                    <div className="flex my-8 justify-center flex-col gap-4">
                      <Button type="submit" disabled={isSubmitting}>
                        Send PIN
                      </Button>
                      <LinkButton to="/login" onClick={() => handleReturnToSignIn()}>
                        Return to sign in
                      </LinkButton>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelectMFAChannel;
