import React, { PropsWithChildren } from "react";

interface Props {
  active: boolean;
}

const Overlay = ({ active, children }: PropsWithChildren<Props>) => {
  return active ? (
    <div className="fixed inset-0 z-40 flex items-center justify-center h-screen bg-black bg-opacity-75">
      <div className="m-auto">{children}</div>
    </div>
  ) : null;
};

export default Overlay;
