import { createAsyncThunk } from "@reduxjs/toolkit";

import { UserProfile } from "../../containers/slices/authSlice";
import hyApi from "../services/hyApiService";

export const getFavorites = createAsyncThunk("favorites/getFavorites/", async (user: Partial<UserProfile>) => {
  const { id } = user;
  const response = await hyApi.get(`api/profiles/${id}/circles`);

  return response.data;
});

export const deleteFromFavorites = createAsyncThunk(
  "favorites/deleteFromFavorites/",
  async ({ user, circleId }: any) => {
    const response = await hyApi.delete(`api/profiles/${user.id}/circles/${circleId}`);

    return response.data;
  }
);

export const getPharmacies = createAsyncThunk("favorites/getPharmacies", async (user: any) => {
  const response = await hyApi.get("api/teladoc/member/Pharmacies", { params: { memberId: user.telehealthID } });

  return response.data;
});
