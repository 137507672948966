import { paths } from "@td/websdk";
import { WEBSDK_ROUTES } from "../router/routes";

const WEBSDK_ICON_URL = process.env.REACT_APP_ICON_BASE_URL;
const WEBSDK_URL = process.env.REACT_APP_WEBSDK_URL;
const HY_BASE_URL = process.env.REACT_APP_BASE_URL;

export const ROUTE_FLOW_MAP = {
  [WEBSDK_ROUTES.RAV]: paths.RAV,
  [WEBSDK_ROUTES.MEDICAL_HISTORY]: paths.MEDICAL_HISTORY,
  [WEBSDK_ROUTES.MESSAGE_CENTER]: paths.MESSAGE_CENTER,
  [WEBSDK_ROUTES.INVOICES]: paths.INVOICES,
  [WEBSDK_ROUTES.BACK_CARE]: paths.BACK_CARE,
  [WEBSDK_ROUTES.PRICE_PRESCRIPTIONS]: paths.PRICE_PRESCRIPTIONS,
  [WEBSDK_ROUTES.PRICE_PROCEDURES]: paths.PRICE_PROCEDURES,
  [WEBSDK_ROUTES.FIND_FACILITIES]: paths.FIND_FACILITIES,
  [WEBSDK_ROUTES.FIND_PROVIDERS]: paths.FIND_PROVIDERS,
  [WEBSDK_ROUTES.JOIN_WAITING_ROOM]: paths.JOIN_WAITING_ROOM,
  [WEBSDK_ROUTES.RESCHEDULE_GM_VISIT]: paths.RESCHEDULE_GM_VISIT,
  [WEBSDK_ROUTES.RESCHEDULE_MH_VISIT]: paths.RESCHEDULE_MH_VISIT,
  [WEBSDK_ROUTES.INBOX]: paths.INBOX,
};

export const webSDKConfig = {
  baseUrl: WEBSDK_URL,
  baseHyUrl: `${HY_BASE_URL}api/`,
  baseHyNoApiUrl: HY_BASE_URL,
  baseIconUrl: WEBSDK_ICON_URL,
  apiToken: process.env.REACT_APP_API_TOKEN,
  bundleIdentifier: process.env.REACT_APP_BUNDLE_INDENTIFIER,
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  paypalClientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  paypalMerchantId: process.env.REACT_APP_PAYPAL_MERCHANT_ID,
  locale: 'en',
  inboxConfig: {
    pubnubEnabled: true,
    pubnubSubscribeKey: "sub-c-b9ed53f2-3621-11eb-88bb-1ad0e2424f4f",
    browserBaseUrl: "inbox"
  }
};
