import "react-toastify/dist/ReactToastify.css";
import "@td/websdk/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import "./tailwind.generated.css";
import "@teladoc/effra/all.css";

import fetchIntercept from "fetch-intercept";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import { setAxiosInterceptors } from "./api/services/hyApiService";
import * as serviceWorker from "./serviceWorker";
import store, { persistor } from "./store/store";
import { refreshSdkToken } from "./api/auth";

setAxiosInterceptors(store);

fetchIntercept.register({
  request: (url, config) => {
    // Modify the url or config here
    return [url, config];
  },
  requestError: (error) => {
    // Called when an error occurred during another 'request' interceptor call
    return Promise.reject(error);
  },
  response: (response) => {
    const responseCopy = response.clone();
    responseCopy
      .json()
      .then((data) => {
        if (data?.sdk_error_message?.toLowerCase() === "session invalid") {
          store.dispatch(refreshSdkToken());
        }
        return data;
      })
      .catch((err) => console.error(err));
    return response;
  },
  responseError: (error) => {
    // Handle an fetch error
    return Promise.reject(error);
  }
});

const render = () => {
  const App = require("./App").default;

  ReactDOM.render(
    <>
    {/*
      //@ts-ignore */}
    <Provider store={store}>
      {/*
      //@ts-ignore */}
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
    </>,
    document.getElementById("root")
  );
};
render();
if (process.env.NODE_ENV === "development" && (module as any).hot) {
  (module as any).hot.accept("./App", render);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
