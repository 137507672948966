import * as Yup from "yup";
import moment from "moment";

let oneCapTest = new RegExp(/[A-Z]+/);
let oneLowerTest = new RegExp(/[a-z]+/);
let oneSpecialTest = new RegExp(/[!"#$%&'()* +,./:;<=>?@^_`\\[{|}\]~-]+/);
let oneNumberTest = new RegExp(/[0-9]+/);
let onlyAlpha = new RegExp(/^[a-zA-Z\s'-]+$/);

export function removePhoneSpecialChars(phone) {
  return phone.replace(/[^\d]/gi, "");
}

export const removePhoneCountryCode = (phone) => phone.replace(/^\+?1/, "");

export const onlyAlphaCharacters = Yup.string().matches(onlyAlpha, `Only special characters allowed are - and '`);

export const validateDob = (dob) => {
  return moment(dob).isValid();
};

export const isPastDate = (date) => {
  const now = moment();
  const tempDate = moment(date);
  return tempDate.isValid() && now.diff(tempDate, "days") >= 0;
};

export const phoneValidation = Yup.string()
  .required("Required")
  .test("length", "Phone number has to be 10 digits", (val) =>
    val ? removePhoneSpecialChars(val).length === 10 : false
  );

export const dobValidation = Yup.string()
  .test("valid date", "Must enter valid date", validateDob)
  .test("no future dob", "Date cannot be in the future", isPastDate)
  .required("Required");

export const passwordValidation = Yup.string()
  .min(8, "Password is too short - should be 8 chars minimum.")
  .required("Required")
  .matches(oneCapTest, "One capital letter")
  .matches(oneLowerTest, "One lower case letter")
  .matches(oneSpecialTest, "One special character (e.g. $#*)")
  .matches(oneNumberTest, "One number");

export const zipValidation = Yup.string()
  .required("Required")
  .matches(/^[0-9]+$/, "Zip can only be numbers")
  .length(5, "Zip must be 5 digits");

export const phoneNumberMask = [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];
export const zipcodeMask = [/\d/, /\d/, /\d/, /\d/, /\d/];
export const dateMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
