import { createAsyncThunk } from "@reduxjs/toolkit";
import hyApi from "../services/hyApiService";
import { MFAChannelPhone } from "../../containers/MFA/SelectMFAChannel";
import { Nullable } from "../../@types/common";

export const getPhones = createAsyncThunk("MFA/getPhones", async (profileId: string, thunkAPI) => {
  const response = await hyApi.get<{ phone_number: string }[]>(`api/mfa/phones?telehealthId=${profileId}`);
  if (!response.data) {
    return {
      phones: [],
      emails: []
    };
  }

  const phones = response.data as MFAChannelPhone[];
  return {
    phones,
    emails: []
  };
});

export const sendMFACode = createAsyncThunk("MFA/sendMFACode", async ({ communicationId, deliveryMethod, mapiJwt }: { communicationId: string | null, deliveryMethod: string | null, mapiJwt: Nullable<string> }, thunkAPI) => {

  if (!communicationId || !deliveryMethod || !mapiJwt) {
    throw new Error("Missing required parameters communicationId or deliveryMethod.");
  }

  const params = {
    delivery_method: deliveryMethod,
    mapi_jwt: mapiJwt,
    communication_id: communicationId
  };
  const response = await hyApi.post(`api/mfa`, params);

  return response.data;
});

export const verifyMFACode = createAsyncThunk("MFA/verifyMFACode", async (params: { mapiJwt: string, pin: string, rememberDevice: boolean }, thunkAPI) => {
  const { mapiJwt, pin, rememberDevice } = params;
  if (!mapiJwt || !pin) {
    throw new Error("Missing required parameters mapi token or pin.");
  }
  try {
    const response = await hyApi.post(`api/mfa/login`, { pin, remember_device: rememberDevice, mapi_jwt: mapiJwt });
    const { status, data } = response;
    if (status !== 200) {
      if (status === 422 && data?.error_messages?.length > 0) {
        return data;
      } else {
        throw new Error("Error verifying account.");
      }
    }
    return data;
  } catch (error) {
    if (error instanceof Error) {
      throw error;
    } else {
      throw new Error("Unknown error verifying account.");
    }
  }
});
