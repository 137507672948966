import { mergeProps } from "@react-aria/utils";
import clsx from "clsx";
import { FieldHelperProps, FieldInputProps, FieldMetaProps, useField } from "formik";
import React from "react";

import { FormFieldError } from "../FormFields/FieldError";

type FormikTextInputProps = {
  label: React.ReactElement | string;
  isRequired?: boolean;
  children?: React.ReactElement;
};

export const FormikTextAreaInput = ({ label, isRequired, children, ...props }: FormikTextInputProps & any) => {
  const [field, meta]: [FieldInputProps<any> & { id?: string }, FieldMetaProps<any>, FieldHelperProps<any>] = useField(
    props
  );
  const isError = meta.touched && meta.error;

  const newProps = isError ? mergeProps(props, { "aria-describedby": `${field.id || field.name}_error` }) : props;
  return (
    <div className="flex flex-col">
      <label className="mb-2 text-sm font-bold text-dark-gray" htmlFor={props.id || props.name}>
        {label}
        {isRequired && <span className="px-1">*</span>}
      </label>
      <div className={clsx(children && "flex")}>
        <textarea
          id={field.id || field.name}
          className={clsx(
            isError ? "border-danger focus:border-danger" : "focus:border-primary-blue",
            children && "rounded-r-none",
            "rounded-lg w-full p-3 border-pale-grey-200 leading-tight appearance-none focus:outline-none border placeholder-pale-grey-50"
          )}
          {...field}
          {...newProps}
        />
        {children}
      </div>
      <FormFieldError field={field} meta={meta} />
    </div>
  );
};
