import React from "react";
import { useSelector } from "react-redux";
import { selectAuth } from "../../src/containers/slices/authSlice";

import { trackEvent } from "../api/piwik/piwikService";
import { PiwikAction, PiwikCategory, PiwikName } from "../@types/common";
import { ReactComponent as LaunchIcon } from "../assets/icons/launch.svg";
import HealthcareBenefitBanner from "./HealthCareBenefitBanner";
import { redirectToLivongo } from "../api/livongo";
import { ToastType, useToast } from "./ToastProvider";

const LivongoMyStrengthWidget = () => {
  const { showToast } = useToast();
  const {
    entities: { group }
  } = useSelector(selectAuth);

  const {
    entities: {
      familyMember: { telehealthID: memberID }
    }
  } = useSelector(selectAuth);

  const handleOnClick = async (memberID) => {
    try {
      await redirectToLivongo(memberID);

      trackEvent({
        category: PiwikCategory.BUTTONS,
        action: PiwikAction.CLICKED,
        name: PiwikName.LIVONGO_MYSTRENGTH
      });
    } catch (err) {
      showToast({
        type: ToastType.DANGER,
        message: "Unable to redirect you"
      });
    }
  };

  return (
    <div className="flex flex-row pt-2 pb-4 ml-5 sm:ml-10" data-testid="LivongoMyStrengthWidgetContainer">
      <div className="flex flex-col pr-4">
        <p className="pb-4">Get matched with the right care</p>
        <p className="pb-8">
          Not sure what support you need? Take our comprehensive assessment and get a personalized plan which may
          include digital programs, coaching or licensed care.
        </p>
        <div className="flex justify-between">
          <button type="button" className="flex text-primary-blue" onClick={() => handleOnClick(memberID)}>
            Start your journey <LaunchIcon className="w-4 h-4 mt-1 ml-2" title="Comment Icon" aria-hidden />
          </button>
          {group?.code?.includes("HYUHG") && <HealthcareBenefitBanner className="text-sm pl-8 max-w-xs" />}
        </div>
      </div>
    </div>
  );
};

export default LivongoMyStrengthWidget;
