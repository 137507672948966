import { createSlice } from "@reduxjs/toolkit";

import { Gender, Nullable } from "../../@types/common";
import { Person } from "../../@types/Registration";
import { samlLogin } from "../../api/auth";
import {
  addDependent,
  getPersons,
  memberLookup,
  registeIneligibleMember,
  RegisterIneligibleMemberRequest,
  registerProfile,
  studentLookup
} from "../../api/registration";

export interface AddDependentFormFields {
  first: string;
  last: string;
  email: string;
  gender: Gender | "";
  dob: string;
  type: string;
  phone: string;
}

export enum DependentType {
  SPOUSE = "Spouse",
  DEPENDENT = "Dependents"
}

export type AddDependentRequest = {
  [key in DependentType]: AddDependentFormFields[] | AddDependentFormFields;
} & {
  command: string;
  changePlanTo: string;
  EffectiveDate: string;
  groupId: string;
  MemberId: string;
};

interface RegistrationSliceState {
  loading: boolean;
  dependentCreated: boolean;
  membershipId: string;
  persons: Nullable<Person[]>;
  features: Nullable<string[]>;
  plan: string;
  planCode: string;
  email: string;
  apiError: Nullable<string>;
  emailAvailable: boolean;
  password: string;
  phone: string;
  selectedPerson: Nullable<Person>;
  profileCreated: boolean;
  newProfileCreated: boolean;
  payPerVisitData: Nullable<Partial<RegisterIneligibleMemberRequest>>;
}

const initialState: RegistrationSliceState = {
  loading: false,
  apiError: null,
  emailAvailable: true,
  membershipId: "",
  plan: "",
  planCode: "",
  persons: null,
  features: null,
  email: "",
  password: "",
  phone: "",
  selectedPerson: null,
  profileCreated: false,
  payPerVisitData: null,
  dependentCreated: false,
  newProfileCreated: false
};

export const registrationSlice = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setSelectedPerson: (state, action) => {
      state.selectedPerson = action.payload;
    },
    cancelLookup: (state) => {
      state.selectedPerson = null;
      state.loading = false;
      state.apiError = null;
      state.membershipId = "";
      state.plan = "";
      state.planCode = "";
      state.persons = null;
      state.features = null;
      state.email = "";
      state.password = "";
      state.phone = "";
      state.payPerVisitData = null;
    },
    clearErrors: (state) => {
      state.apiError = null;
      state.loading = false;
      state.dependentCreated = false;
    },
    clearAccountInfo: (state) => {
      state.apiError = null;
      state.email = "";
      state.password = "";
      state.phone = "";
      state.payPerVisitData = null;
      state.loading = false;
    },
    setAccountInfo: (state, action) => {
      state.apiError = null;
      state.email = action.payload.email;
      state.password = action.payload.newPassword;
      state.phone = action.payload.phone;
    },
    setPayPerVisitData: (state, action) => {
      const { email, phone, newPassword, acceptTerms, ...rest } = action.payload;
      state.apiError = null;
      state.loading = false;
      state.payPerVisitData = rest;
      state.email = email;
      state.password = newPassword;
      state.phone = phone;
      state.profileCreated = false;
    },
    setNewProfileCreated: (state, action) => {
      state.newProfileCreated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(memberLookup.pending, (state) => {
      state.apiError = null;
      state.loading = true;
      state.profileCreated = false;
    });

    builder.addCase(memberLookup.fulfilled, (state, action) => {
      const { membershipId, plan, planCode } = action.payload.data;
      state.loading = false;
      state.membershipId = membershipId;
      state.plan = plan;
      state.apiError = null;
      state.planCode = planCode;
    });

    builder.addCase(memberLookup.rejected, (state, action) => {
      state.apiError = action.error?.message || null;
      state.loading = false;
      state.persons = null;
      state.membershipId = "";
      state.plan = "";
      state.planCode = "";
    });

    builder.addCase(getPersons.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getPersons.fulfilled, (state, action) => {
      const { persons, features } = action.payload.data;
      state.loading = false;
      state.persons = persons;
      state.features = features;
    });

    builder.addCase(getPersons.rejected, (state, action) => {
      state.loading = false;
      state.persons = null;
      state.membershipId = "";
      state.plan = "";
      state.planCode = "";
    });

    builder.addCase(studentLookup.pending, (state) => {
      state.loading = true;
      state.profileCreated = false;
    });

    builder.addCase(studentLookup.fulfilled, (state, action) => {
      const { membershipId, plan, planCode } = action.payload.data;
      state.loading = false;
      state.membershipId = membershipId;
      state.plan = plan;
      state.planCode = planCode;
    });

    builder.addCase(studentLookup.rejected, (state, action) => {
      state.apiError = action.error?.message || null;
      state.loading = false;
      state.persons = null;
      state.membershipId = "";
      state.plan = "";
      state.planCode = "";
      state.planCode = "";
    });

    builder.addCase(registerProfile.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(registerProfile.fulfilled, (state) => {
      state.loading = false;
      state.profileCreated = true;
      state.apiError = null;
    });

    builder.addCase(registerProfile.rejected, (state, action) => {
      state.apiError = action.error?.message || null;
      state.loading = false;
      state.profileCreated = false;
    });

    builder.addCase(registeIneligibleMember.pending, (state) => {
      state.loading = true;
      state.profileCreated = false;
    });

    builder.addCase(registeIneligibleMember.fulfilled, (state) => {
      state.loading = false;
      state.profileCreated = true;
      state.apiError = null;
    });

    builder.addCase(registeIneligibleMember.rejected, (state, action) => {
      state.apiError = JSON.stringify(action.error?.message);
      state.loading = false;
      state.profileCreated = false;
    });
    builder.addCase(addDependent.pending, (state) => {
      state.loading = true;
      state.dependentCreated = false;
    });

    builder.addCase(addDependent.fulfilled, (state, action) => {
      const errors = action.payload.meta?.errors;
      state.loading = false;
      state.dependentCreated = !errors || !errors.length;
      state.apiError = errors && Array.isArray(errors) ? errors[0] : null;
    });

    builder.addCase(addDependent.rejected, (state, action) => {
      state.apiError = JSON.stringify(action.error?.message);
      state.loading = false;
      state.dependentCreated = false;
    });

    builder.addCase(samlLogin.pending, (state, action) => {
      state.selectedPerson = null;
    });

    builder.addCase(samlLogin.fulfilled, (state, action) => {
      const response = action.payload;
      state.loading = false;
      state.apiError = null;
      state.profileCreated = response?.meta?.hasProfile;
      state.selectedPerson = response?.data?.selectedPerson;
      state.email = response?.data?.selectedPerson?.email;
      state.phone = response?.data?.selectedPerson?.phone;
      state.membershipId = response?.data?.membershipId;
    });
  }
});

export const selectRegistration = (state) => state.registration;

export const {
  cancelLookup,
  clearErrors,
  setAccountInfo,
  setSelectedPerson,
  setPayPerVisitData,
  clearAccountInfo,
  setNewProfileCreated
} = registrationSlice.actions;

export default registrationSlice.reducer;
