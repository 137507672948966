import React from "react";
import { ReactComponent as PrescriptionIcon } from "../../assets/icons/price.svg";
import FavoriteItemWrapper from "./FavoriteItemWrapper";
import { FavoriteComponentProps } from "./FavoriteTypes";

const Prescription = ({ element, onUnfavorite }: FavoriteComponentProps) => (
  <FavoriteItemWrapper onUnfavorite={onUnfavorite} element={element}>
    <PrescriptionIcon className="favorite-avatar" />
  </FavoriteItemWrapper>
);

export default Prescription;
