import React from "react";

interface practices {
  distance: number;
  visitAddress: {
    street: string;
    city: string;
    state: string;
    zip: number;
  };
}

interface provider {
  profile: { firstName: string; lastName: string };
  practices: practices[];
}

interface ProviderMarker {
  data: any;
  active: boolean;
  selected: boolean;
  onMouseEnter: any;
  onMouseLeave: any;
  onClick: any;
  lat: number;
  lng: number;
}

const GMapMarker = ({ data, active, selected, onMouseEnter, onMouseLeave, onClick }: ProviderMarker) => {
  const { header, address, distance } = data;

  return (
    <div>
      <div onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={onClick}>
        <div
          className={`absolute rounded-full bg-white cursor-pointer`}
          style={{
            boxShadow: "0 4px 6px -1px rgb(0 0 0 / 10%), 0 2px 4px -1px rgb(0 0 0 / 6%)",
            top: -20,
            left: -8,
            width: 16,
            height: 16,
            border: "6px solid #d00",
            borderColor: active || selected ? "#5b2f91" : "#d00"
          }}
        />
        <div
          className="absolute box-border cursor-pointer"
          style={{
            top: -8.5,
            left: -7,
            border: "7px solid transparent",
            borderTop: "10px solid rgb(221,0,0)",
            borderColor: active || selected ? "#5b2f91 transparent transparent" : "#d00 transparent transparent"
          }}
        />
      </div>
      {selected && (
        <div
          className="absolute p-4 bg-white border-t-4 border-primary-500 rounded text-sm z-20"
          style={{ top: 5, left: -150, width: 300 }}
        >
          <div className="mb-3 text-primary-blue">{header}</div>
          <div className="flex justify-between mb-1 leading-5">
            <div className="flex-1">{address}</div>
            <div className="flex-1 text-right">{distance.toFixed(2)} mi</div>
          </div>
          <div
            className="absolute box-border cursor-pointer"
            style={{
              top: -17,
              left: 145,
              border: "5px solid transparent",
              borderBottom: "10px solid rgb(221,0,0)",
              borderColor: active || selected ? "transparent transparent #5b2f91" : ""
            }}
          />
        </div>
      )}
    </div>
  );
};

export default GMapMarker;
