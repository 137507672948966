import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import FormCard from "../components/FormCard";
import { ReactComponent as BeatTheDeductibleIcon } from "../assets/icons/BeatTheDeductible.svg";
import { ReactComponent as InfoIcon } from "../assets/icons/c-info-2.svg";
import { ReactComponent as LaunchIcon } from "../assets/icons/launch.svg";
import { savingsHistory } from "../api/savings";
import { selectAuth } from "./slices/authSlice";
import { useAppDispatch } from "../store/store";
import moment from "moment";
import PageTitleComponent from "../components/PageTitleComponent";
import Tooltip from "../components/Tooltip";

const BEAT_THE_DEDUCTIBLE_TOOLTIP_TEXT =
  "The costs used to determine your estimated savings are average costs for care obtained from multiple healthcare resources and case studies.  These are not actual savings calculated based on your insurance plan or benefits.";
interface Consult {
  youPaid: number;
  consultationTimestamp: string;
  alternative: string;
  alternativeCost: number;
  savings: number;
}

const BeatTheDeductible = () => {
  const dispatch = useAppDispatch();

  const {
    entities: { profile }
  } = useSelector(selectAuth);

  const [savingsHistoryResponse, setSavingsHistoryResponse] = useState<any>();

  useEffect(() => {
    savingHistoryResponse();
  }, []);

  const savingHistoryResponse = async () => {
    const response = await dispatch(savingsHistory(profile?.id));
    setSavingsHistoryResponse(response.payload);
  };

  return (
    <FormCard data-testid="beatTheDeductibleContainer" title="Beat the Deductible">
      <PageTitleComponent title="Beat the Deductible" />
      <div className="flex w-full h-auto pr-8 font-effra">
        <div className="px-6 space-x-4 ">
          <BeatTheDeductibleIcon className="w-10 h-10 md:h-auto md:w-auto" aria-hidden />
        </div>
        <div className="flex flex-col">
          <p className="flex text-2xl font-medium">
            Lifetime Savings
            <Tooltip
              id={"beatTheDeductibleTooltip"}
              borderColor={"#005c95"}
              text={BEAT_THE_DEDUCTIBLE_TOOLTIP_TEXT}
              showBorder
            >
              <InfoIcon className="self-start fill-current md:h-auto md:w-auto text-primary-blue" aria-hidden />
            </Tooltip>
          </p>
          <p className="-mt-4 text-5xl uppercase" data-testid="lifetime">
            ${savingsHistoryResponse?.lifetime}
          </p>
          {(!savingsHistoryResponse?.list || savingsHistoryResponse?.list?.length < 1) && (
            <p>Recent visit savings will update when you save by using HealthiestYou</p>
          )}
        </div>
      </div>
      <div className="flex flex-col w-auto max-w-2xl pl-4 pr-4 ml-4 font-effra md:pl-24 md:ml-4">
        <ul className="h-auto">
          {savingsHistoryResponse &&
            savingsHistoryResponse?.list?.map((value: Consult, index: number) => (
              <li className="py-2" key={value?.consultationTimestamp ?? index}>
                <p className="pb-2 text-lg font-medium uppercase">
                  {moment(value?.consultationTimestamp).format("MM/DD/yyyy")} |{" "}
                  {moment(value?.consultationTimestamp).format("h:mm a")}
                </p>
                <div className="grid invisible w-0 h-0 grid-cols-3 mb-4 md:visible md:w-auto md:h-auto">
                  <p className="font-medium text-pale-grey-200">{value.alternative} cost</p>
                  <p className="font-medium text-pale-grey-200">You saved</p>
                  <p className="font-medium text-pale-grey-200">You paid</p>
                  <p>${value.alternativeCost}</p>
                  <p>${value.savings}</p>
                  <p>${value.youPaid}</p>
                </div>

                <div className="flex flex-col visible h-auto mb-4 md:invisible md:h-0 md:w-0">
                  <p className="mt-1 -mb-1 font-medium text-pale-grey-200">{value.alternative} cost</p>
                  <p data-testid={`alternative-cost-${index}`}>${value.alternativeCost}</p>
                  <p className="mt-1 -mb-1 font-medium text-pale-grey-200">You saved</p>
                  <p data-testid={`savings-${index}`}>${value.savings}</p>
                  <p className="mt-1 -mb-1 font-medium text-pale-grey-200">You paid</p>
                  <p data-testid={`you-paid-${index}`}>${value.youPaid}</p>
                </div>
                <div className=" border-pale-grey-20 border-b-1"></div>
              </li>
            ))}
        </ul>
        <p className="my-4 text-lg font-bold leading-tight">Helpful Articles</p>
        <ul>
          <li className="py-2">
            <div className="flex">
              <a href="pdf/eightWays.pdf" target="_blank">
                Eight ways to cut your health costs
              </a>
              <LaunchIcon className="self-start ml-2 fill-current md:h-auto md:w-auto text-primary-blue" aria-hidden />
            </div>
          </li>
          <li className="py-2">
            <div className="flex">
              <a href="pdf/savingsAccount.pdf" target="_blank">
                Savings account for health care costs
              </a>
              <LaunchIcon className="self-start ml-2 fill-current md:h-auto md:w-auto text-primary-blue" aria-hidden />
            </div>
          </li>
          <li className="py-2">
            <div className="flex">
              <a href="pdf/understanding.pdf" target="_blank">
                Understanding your healthcare cost
              </a>
              <LaunchIcon className="self-start ml-2 fill-current md:h-auto md:w-auto text-primary-blue" aria-hidden />
            </div>
          </li>
        </ul>
      </div>
    </FormCard>
  );
};

export default BeatTheDeductible;
