import { createMobileApiRequest } from "@td/websdk";
import { webSDKConfig } from "../../utils/webSdkConfiguration";
import { get, SDK_TOKEN_COOKIE } from "../../utils/cookies";

const myStrengthPayload = { service_specialty_code: "MYMENTALHEALTHCOMP" };

const requestUrl = `${process.env.REACT_APP_WEBSDK_URL}/screens/referral_link`;

export const redirectToLivongo = async (memberID) => {
  const getBaseBody = (memberId) => ({
    actor_type: "member",
    logged_in_member_id: memberId,
    actor_id: memberId,
    service_specialty_code: "DIABETES",
    url_request_data: {
      dfd_dashboard: true,
      health_category: "General Medical"
    }
  });

  const config = {
    url: requestUrl,
    configuration: {
      ...webSDKConfig,
      authorization: { ssoToken: get(SDK_TOKEN_COOKIE) }
    },
    data: getBaseBody(memberID)
  };

 const response = await createMobileApiRequest(config);

  window.open(response.redirect_to_url, "_blank");
};

export const redirectToMyStrength = () => redirectToLivongo(myStrengthPayload);
