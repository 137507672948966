import { Field, Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { PiwikAction, PiwikCategory, PiwikName } from "../../@types/common";
import { trackEvent } from "../../api/piwik/piwikService";
import editProfile from "../../api/profiles/editProfile";
import Button from "../../components/Button";
import { ButtonGroup } from "../../components/ButtonGroup";
import { FormFieldError } from "../../components/FormFields/FieldError";
import { FormikMaskedInput } from "../../components/Formik/FormikMaskedInput";
import { FormikSelect } from "../../components/Formik/FormikSelect";
import { FormikTextInput } from "../../components/Formik/FormikTextInput";
import { useSpinner } from "../../components/SpinnerProvider";
import { useAppDispatch } from "../../store/store";
import { genderList, languageList } from "../../utils/selectOptionUtils";
import {
  phoneNumberMask,
  phoneValidation,
  removePhoneCountryCode,
  removePhoneSpecialChars,
} from "../../utils/validationHelper";
import { clearErrors, selectAuth, setProfileUpdated } from "../slices/authSlice";

const Alert = () => {
  return (
    <div className="flex flex-col px-4 py-3 my-6 text-red-700 bg-red-100 border border-red-400 rounded" role="alert">
      <h4 className="font-bold">Profile Update Error</h4>
      <p>There was an error updating your profile.</p>
    </div>
  );
};

const AccountSettings = () => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { profileUpdated, entities, profileError, loading } = useSelector(selectAuth);
  const [languageSelected, setLanguageSelected] = useState("");
  const [otherLanguageSelected, setOtherLanguageSelected] = useState("");
  const { showSpinner } = useSpinner();

  useEffect(() => {
    showSpinner({
      show: loading.profile
    });
  }, [loading.profile]);

  useEffect(() => {
    dispatch(clearErrors());
    if (!languageList.filter((language) => language.value === entities.profile.language).length) {
      setLanguageSelected("Other");
      setOtherLanguageSelected(entities.profile.language);
    } else {
      setLanguageSelected(entities.profile.language);
    }
  }, []);

  useEffect(() => {
    if (profileUpdated) {
      dispatch(clearErrors());
      dispatch(setProfileUpdated(false));
      history.push("/dashboard");

      toast.success("Profile saved");
    }
  }, [profileUpdated, dispatch, history]);

  const trackSubmit = () => {
    trackEvent({
      category: PiwikCategory.FORM,
      action: PiwikAction.SUBMIT,
      name: PiwikName.ACCOUNT_SETTINGS
    });
  };

  const handleSubmit = (values, { setSubmitting }) => {
    setSubmitting(true);

    if (values.language === "Other") {
      values.language = values.otherLanguage;
    }

    const newValues = {
      ...values,
      version: 2,
      phone: removePhoneSpecialChars(values.phone)
    };

    dispatch(editProfile(newValues));
    trackSubmit();
    setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        ...entities.profile,
        phone: removePhoneCountryCode(entities.profile.phone),
        language: languageSelected,
        otherLanguage: otherLanguageSelected,
        gender: entities.profile.gender
      }}
      enableReinitialize={true}
      validationSchema={Yup.object({
        first: Yup.string().required("Required"),
        last: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
        phone: phoneValidation,
        language: Yup.string().required("Required"),
        otherLanguage: Yup.string().max(25, "Too many characters"),
        gender: Yup.string().required("Required")
      })}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue }) => (
        <Form className="pt-6" data-testid="accountSettingContainer" id="accountSettingsPanel">
          {profileError && <Alert />}
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2">
            <div>
              <FormikTextInput label="First Name" name="first" type="text" />
            </div>
            <div>
              <FormikTextInput label="Last Name" name="last" type="text" />
            </div>
            <div>
              <FormikTextInput label="Email Address" name="email" type="email" />
            </div>
            <div>
              <Field name="phone" type="text">
                {({ field, form, meta }) => (
                  <>
                    <FormikMaskedInput
                      meta={meta}
                      label="Phone Number"
                      mask={phoneNumberMask}
                      field={field}
                      {...form}
                    />
                    <FormFieldError field={field} meta={meta} />
                  </>
                )}
              </Field>
            </div>
            <div>
              <Field name="language" type="text">
                {({ field, form, meta }) => (
                  <>
                    <FormikSelect label="Language" name="language" type="select" options={languageList} />
                    {(languageList.indexOf(field.value) > -1 || field.value === "Other") && (
                      <FormikTextInput label="Other" name="otherLanguage" type="text" />
                    )}
                  </>
                )}
              </Field>
            </div>
            <div>
              <FormikSelect label="Gender" name="gender" type="select" options={genderList} />
            </div>
          </div>
          <div className="w-full">
            <ButtonGroup>
              <Button data-testid="save" disabled={isSubmitting || profileUpdated} type="submit">
                Save
              </Button>
            </ButtonGroup>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AccountSettings;
