import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import { Modal } from "../../components/Modal";

import { ReactComponent as InfoIcon } from "../../assets/icons/c-info-2.svg";

const section1Text = `Your actual out-of-pocket costs may vary based on factors specific to your provider and/or your
plan. Some plans base their reimbursement rates on a percentage of "usual, customary, and
reasonable" charges, which is referred to as "UCR" Others use a formula based on the
Medicare fee schedule that is published by the US Department of Health and Human Services. To learn
how your health plan determines out-of-network reimbursement rates and covered services, call the
number listed on the back of your insurance card.`;
const section2Text = `HealthiestYou does not determine, develop, or establish any fee, reimbursement level or UCR for
any procedure or service. Your plan is responsible for setting your actual reimbursement level, or UCR.
Our data reflect benchmark market rates, which are what providers typically charge for a procedure
or service in a particular geographic area. Estimates provided in this app are for informational
purposes only.`;

const Tooltip = ({ message }) => (
  <>
    <InfoIcon
      data-tip={message}
      className="self-start fill-current md:h-auto md:w-auto text-primary-blue"
      aria-hidden
    />
    {/* 
    //@ts-ignore */}
    <ReactTooltip
      className="w-auto max-w-lg tooltip-custom"
      place={"bottom"}
      effect={"solid"}
      type="light"
      multiline={true}
      border={true}
      borderColor={"#005c95"}
    />
  </>
);

const TooltipLabel = ({ text, message }) => (
  <div className="flex align-middle justify-center items-center">
    <p className="text-pale-grey-200 mb-1">{text}</p>
    <Tooltip message={message} />
  </div>
);

const toUsdNumberFormat = (number) =>
  new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" }).format(number);

const ProcedureDetails = ({ item: { cash = [], insurance = [] }, name = "" }: any) => {
  const [showDialog, setShowDialog] = useState(false);

  return (
    <>
      <div className="price-procedure-details-web-sdk">
        <h2 className="text-xl mb-4">{name}</h2>
        <div className="flex flex-col items-start mb-4">
          <TooltipLabel
            text="Avg cash pricing"
            message="These are the low, average and high prices you would expect to pay in your area if you were paying cash for a procedure rather than submitting to your insurance."
          />

          <p className="text-lg">{toUsdNumberFormat(cash[0]?.average)}</p>
        </div>
        <div className="flex flex-col items-start mb-4">
          <TooltipLabel
            text="Avg insurance pricing submitted"
            message="These are the low, average and high amounts submitted by patients to their insurance carriers in your area for this procedure."
          />
          <p className="text-lg">
            {toUsdNumberFormat(insurance.filter((item) => item.type === "submitted")[0]?.average)}
          </p>
        </div>
        <div className="flex flex-col items-start mb-4">
          <TooltipLabel
            text=" Avg insurance pricing paid"
            message="These are the low, average and high claims paid by insurance carriers in your area for this procedure."
          />

          <p className="text-lg">
            {toUsdNumberFormat(insurance.filter((item) => item.type === "allowed")[0]?.average)}
          </p>
        </div>

        <button className="mt-2 mb-6 text-primary-blue hover:underline" onClick={() => setShowDialog(true)}>
          Disclaimer
        </button>
      </div>

      <Modal
        state={{
          close: () => setShowDialog(false),
          isOpen: showDialog
        }}
      >
        <div className="dialog-web-sdk m-auto p-6 pt-4">
          <h4 className="text-2xl font-medium mb-4">Disclaimer</h4>

          <p className="text-web-sdk">{section1Text}</p>
          <p className="text-web-sdk">{section2Text}</p>
          <button className="button-web-sdk mt-8" onClick={() => setShowDialog(false)}>
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default ProcedureDetails;
