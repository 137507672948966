import clsx from "clsx";
import React, { ReactNode, RefObject, useEffect, useRef, useState } from "react";
import { getRect, getBestPosition } from "popover-helper";

type positions =
  | "bottom-center"
  | "bottom-right"
  | "bottom-left"
  | "top-center"
  | "top-right"
  | "top-left"
  | "right-center"
  | "right-bottom"
  | "right-top"
  | "left-center"
  | "left-bottom"
  | "left-top";

type Tooltip = {
  id: string;
  children: ReactNode;
  text: string;
  borderColor?: string;
  positions?: positions[];
  showBorder?: boolean;
};

type position = {
  direction: string;
  type: positions[];
  position: string;
  align: string;
  top: number;
  left: number;
  width: number;
  height: number;
  spacePosition: number;
  spaceAlign: number;
  priority: number;
  change: number;
  space: number;
  index: number;
};

function Tooltip({ children, text, borderColor, id, positions = [], showBorder = false }: Tooltip) {
  const [isShown, setIsShown] = useState(false);
  const [position, setPosition] = useState({} as position);
  const tooltipTextRef: RefObject<HTMLDivElement> = useRef(null);
  const rootPosition = getRect("#root"); // area in which tooltip could be show, in future could be as prop

  const showTooltip = () => setIsShown(true);
  const hideTooltip = () => setIsShown(false);
  const toggleTooltip = () => setIsShown(!isShown);

  useEffect(() => {
    if (tooltipTextRef.current) {
      const iconPosition = getRect(`#${id}Icon`);
      const tooltipPosition = getRect(`#${id}Tooltip`); // these values need to be recalculated every time

      setPosition(getBestPosition(rootPosition, iconPosition, tooltipPosition, positions, position));
      tooltipTextRef.current?.focus();
    }
  }, [isShown, tooltipTextRef]);

  useEffect(() => {
    document.addEventListener("scroll", hideTooltip);

    return () => {
      document.removeEventListener("scroll", hideTooltip);
    };
  }, []);

  return (
    <>
      <button
        id={`${id}Icon`}
        tabIndex={0}
        onMouseEnter={showTooltip}
        onMouseLeave={hideTooltip}
        onClick={toggleTooltip}
        aria-label={`${isShown ? "Close" : "Tooltip. Tooltip is collapsed. Open"} tooltip`}
        aria-haspopup="true"
      >
        {children}
      </button>

      {/* TODO: after update to new version tailwind update style to classNames */}
      {isShown && (
        <div
          id={`${id}Tooltip`}
          className={clsx(
            "absolute flex flex-col items-center tooltip bg-white text-black leading-normal box-border font-effra",
            "font-bold rounded-lg border-2 py-2 px-5 w-48 inline-block pointer-events-none z-10 focus:outline-none",
            `tooltip-${position.position} tooltip-${position.type}`
          )}
          style={
            {
              "--popover-border-color": borderColor,
              "--popover-left": (position.left || 0) + "px",
              "--popover-top": (position.top || 0) + "px",
              "--popover-opacity": isShown ? "0.9" : "0",
              "--popover-border": showBorder ? `2px solid ${borderColor}` : "none"
            } as React.CSSProperties
          }
          ref={tooltipTextRef}
          tabIndex={0}
          aria-label={`Tooltip is expended ${text}`}
          role="tooltip"
        >
          {text}
        </div>
      )}
    </>
  );
}

export default Tooltip;
