import React from "react";

const ContactUs = () => (
  <footer
    className="flex flex-wrap justify-center w-full pb-4 text-sm leading-tight footer font-effra"
    data-testid="footerText"
    id="contactUsFooterText"
  >
    If you have questions, please contact us at
    <a href="tel:+1-866-703-1259" aria-labelledby="contactUsFooterText" className="ml-1">
      +1 (866) 703-1259
    </a>
  </footer>
);

export default ContactUs;
