import { createSlice } from "@reduxjs/toolkit";
import getMessage from "../../api/websdk/getMessage";
import { cancelVisit, getConsultationSDKList } from "../../api/websdk/consultations";
import reset from "../../api/websdk/reset";
import startSyncDash from "../../api/websdk/startSyncDash";

export enum SdkPaths {
  RequestAVisit = "rav",
  MessageCenter = "messageCenter",
  WaitingRoom = "waitingRoom",
  MedicalInfo = "medicalInfo",
  RescheduleGMVisit = "rescheduleGMVisit",
  RescheduleMHVisit = "rescheduleMHVisit",
  BackCare = "backCare",
  Invoices = "invoices",
  FindFacilities = "findFacilities",
  FindProviders = "findProviders",
  PricePrescriptions = "pricePrescriptions",
  PriceProcedures = "priceProcedures",
  Covid19Assessment = "covid19Assessment",
  Inbox = "inbox",
}

export const RouteFlowMapping = {
  "request-a-visit": "RequestAVisit",
  "medical-info": "MedicalInfo",
  "message-center": "MessageCenter",
  "invoices": "Invoices",
  "waiting-room": "WaitingRoom",
  "reschedule-gm-visit": "RescheduleGMVisit",
  "reschedule-mh-visit": "RescheduleMHVisit",
  "back-care": "BackCare",
  "inbox": "Inbox",
  // TODO: check this routes
  // "find-facilities": "FindFacilities",
  // "find-providers": "FindProviders",
  // "price-prescriptions": "PricePrescriptions",
  // "price-procedures": "PriceProcedures",
  // "covid-19-assessment": "Covid19Assessment"
};

interface WebSdkSliceState {
  loading: boolean;
  error: boolean;
  messages: Array<any>;
  messagesLoading: boolean;
  syncDashboard: boolean;
  syncConsults: boolean;
  unviewedMessages: number;
  consultations: any[];
  consultsLoading: boolean;
  entities: {
    path: SdkPaths | undefined;
    extraActions: {
      onClose: () => void;
    };
  };
}

const initialState: WebSdkSliceState = {
  loading: true,
  error: false,
  messages: [],
  messagesLoading: false,
  syncDashboard: false,
  syncConsults: false,
  consultations: [],
  consultsLoading: false,
  unviewedMessages: 0,

  entities: {
    path: undefined,
    extraActions: {
      onClose: () => {}
    }
  }
};

export const websdkSlice = createSlice({
  name: "websdk",
  initialState,
  reducers: {
    removeSdkInfo: () => initialState,
    showSdkFlow: (state, action) => {
      state.entities = { ...state.entities, ...action.payload };
    },
    clearSdkFlow: (state) => {
      state.entities = { ...state.entities, path: undefined };
    },
    logoutSdk: () => {
      return initialState;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(startSyncDash.pending, (state) => {
      state.syncDashboard = true;
    });

    builder.addCase(reset.pending, (state) => {
      state.messagesLoading = false;
      state.consultsLoading = false;
    });

    builder.addCase(getMessage.pending, (state) => {
      state.loading = true;
      state.syncDashboard = false;
      state.messagesLoading = true;
    });

    builder.addCase(getMessage.fulfilled, (state, action) => {
      state.messages =
        action?.payload?.screens && action.payload.screens[0] && action.payload.screens[0]?.data?.threads_list;
      state.unviewedMessages = action?.payload?.response_data?.unviewed_messages;
      state.loading = false;
      state.messagesLoading = false;
      state.syncConsults = true;
    });

    builder.addCase(getMessage.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });

    builder.addCase(getConsultationSDKList.pending, (state) => {
      state.loading = true;
      state.consultsLoading = true;
    });

    builder.addCase(getConsultationSDKList.fulfilled, (state, action) => {
      state.consultations = action?.payload;
      state.loading = false;
      state.syncConsults = false;

      state.consultsLoading = false;
    });

    builder.addCase(getConsultationSDKList.rejected, (state) => {
      state.error = true;
      state.loading = false;
      state.syncConsults = false;
    });

    builder.addCase(cancelVisit.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(cancelVisit.fulfilled, (state) => {
      state.loading = false;
    });

    builder.addCase(cancelVisit.rejected, (state) => {
      state.error = true;
      state.loading = false;
    });
  }
});

export const selectWebSdk = (state) => state.websdk;

export const { showSdkFlow, clearSdkFlow, removeSdkInfo, logoutSdk } = websdkSlice.actions;

export default websdkSlice.reducer;
