import clsx from "clsx";
import React from "react";

function Switch({ id, checked, onChange, label, text = "", className = "" }) {
  return (
    <div className={clsx("flex items-center h-full", className)}>
      <input id={id} checked={checked} onChange={onChange} type="checkbox" className="w-0 h-0 invisible" />
      <label
        className={clsx("table cursor-pointer relative w-8 h-5 rounded-full", checked ? "bg-green-ios" : "bg-disabled")}
        style={{ transition: "background-color .1s", minWidth: "2rem" }}
        htmlFor={id}
      >
        <span
          className="absolute w-4 h-4 rounded-full bg-white duration-100 -translate-x-full"
          style={{
            boxShadow: "0 0 2px 0 rgb(10 10 10 / 29%)",
            top: 2,
            content: "",
            left: checked ? "calc(100% - 2px)" : 2,
            transform: checked && "translateX(-100%)"
          }}
        />
      </label>
      <div>
        <span className="ml-2 text-base leading-none text-dark-gray font-medium">{label}</span>
        <br />
        <small className={"flex relative h-0 ml-2 leading-3"}>{text}</small>
      </div>
    </div>
  );
}

export default Switch;
