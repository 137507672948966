import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { sendResetEmail } from "../api/forgotPassword";
import Button from "../components/Button";
import { FormikTextInput } from "../components/Formik/FormikTextInput";
import { useAppDispatch } from "../store/store";
import { selectAuth } from "./slices/authSlice";
import { ReactComponent as HYLogoHoriz } from "../assets/HYLogoHoriz.svg";
import { clearErrors } from "./slices/forgotPasswordSlice";
import PageTitleComponent from "../components/PageTitleComponent";

const ForgotPassword = () => {
  const { email } = useSelector(selectAuth);
  const dispatch = useAppDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(clearErrors());
  }, [dispatch]);

  const handleSubmit = async (values) => {
    try {
      await dispatch(sendResetEmail(values));
      history.push("/login");
      toast.success("Reset password email has been sent");
    } catch (e) {
      return e;
    }
  };

  return (
    <div
      className="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
      data-testid="forgotPasswordContainer"
    >
      <div className="max-w-md w-full space-y-8">
        <div className="m-6">
          <PageTitleComponent title="Forgot Password" />
          <HYLogoHoriz className="mx-auto w-auto h-16" />
          <h2 className="mt-8 text-3xl font-medium text-gray-900">Forgot your password?</h2>
          <Formik
            initialValues={{
              email
            }}
            enableReinitialize={true}
            validationSchema={Yup.object({
              email: Yup.string().email("Invalid email address").required("Required")
            })}
            onSubmit={(values, { setSubmitting }) => {
              setSubmitting(true);
              handleSubmit(values).finally(() => setSubmitting(false));
            }}
          >
            {({ isSubmitting, isValid }) => (
              <Form className="bg-white shadow-md rounded mt-6 px-8 pt-6 pb-8 mb-4">
                <div className="max-w-sm">
                  <FormikTextInput
                    data-testid="emailInput"
                    label="Email"
                    name="email"
                    type="email"
                    placeholder="johndoe@gmail.com..."
                  />
                  <div className="flex my-8 justify-center">
                    <Button data-testid="resetPasswordButton" disabled={isSubmitting || !isValid} type="submit">
                      Reset password
                    </Button>
                  </div>

                  <div className="text-center">
                    <p>
                      Don't have an account yet? <a>Sign Up</a>{" "}
                    </p>

                    <p>
                      Already have an account? <a>Sign In</a>{" "}
                    </p>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
