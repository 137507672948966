import { useButton } from "@react-aria/button";
import { FocusScope, useFocusRing } from "@react-aria/focus";
import { AriaMenuItemProps, useMenu, useMenuItem, useMenuSection, useMenuTrigger } from "@react-aria/menu";
import { DismissButton, useOverlay } from "@react-aria/overlays";
import { useSeparator } from "@react-aria/separator";
import { mergeProps } from "@react-aria/utils";
import { useMenuTriggerState } from "@react-stately/menu";
import { TreeProps, useTreeState } from "@react-stately/tree";
import clsx from "clsx";
import React, { useRef } from "react";

import { MainMenuButton } from "./MainMenuButton";

function Button({ ...props }) {
  let ref = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
  let { buttonProps, isPressed } = useButton(props, ref);
  let { focusProps, isFocusVisible, isFocused } = useFocusRing();

  let className = clsx(
    props.isDisabled ? "bg-gray-400" : isPressed ? "bg-pale-grey-10" : "hover:bg-off-white",
    "rounded cursor-pointer",

    // Remove the browser default focus ring and add a custom one
    // that only shows up when interacting with a keyboard.
    "focus:outline-none",
    isFocusVisible ? "shadow-outline" : "",
    "transition ease-in-out duration-150"
  );

  return (
    <button {...mergeProps(focusProps, buttonProps)} ref={ref} className={className} aria-label="Account" data-bs-toggle="dropdown" role="button" aria-controls="menubar">
      {props.children}
    </button>
  );
}

export function Popover({ children, state }) {
  let overlayRef = useRef() as any;
  let { overlayProps } = useOverlay(
    {
      onClose: () => state.close(),
      shouldCloseOnBlur: true,
      isOpen: state.isOpen,
      isDismissable: true
    },
    overlayRef
  );

  return (
    <FocusScope restoreFocus>
      <div {...overlayProps} ref={overlayRef} className="z-50 bg-white shadow-lg">
        <DismissButton onDismiss={() => state.close()} />
        {children}
        <DismissButton onDismiss={() => state.close()} />
      </div>
    </FocusScope>
  );
}

export function MenuButton(props) {
  let state = useMenuTriggerState(props);
  let ref = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
  let { menuTriggerProps, menuProps } = useMenuTrigger({}, state, ref);

  const handleKeyUp = (e) => {
    const openKeys = ["Space", "Enter"];
    const pressedButtonCode = e.nativeEvent.code;

    if (openKeys.includes(pressedButtonCode)) {
      state.isOpen ? state.close() : state.open()
    }
  }

  return (
    <>
      <Button
        {...props}
        onKeyUp={handleKeyUp}
        {...menuTriggerProps}
      >
        <MainMenuButton label={props.label} isOpen={state.isOpen}/>
      </Button>
      <span className="absolute top-0 right-0 mt-12 -mr-3 md:mr-0" role="group" aria-label="Menu">
        {state.isOpen && (
          <Popover state={state}>
            <Menu
              {...props}
              aria-label="Menu"
              role="menu"
              domProps={menuProps}
              autoFocus={state.focusStrategy || true}
              onClose={() => state.close()}
            />
          </Popover>
        )}
      </span>
    </>
  );
}

function Menu(props: TreeProps<{}> & AriaMenuItemProps) {
  let state = useTreeState({ ...props, selectionMode: "none" });
  let ref = useRef() as React.MutableRefObject<HTMLUListElement>;
  let { menuProps } = useMenu(props, state, ref);

  return (
    <ul {...menuProps} role="menu" ref={ref} className="rounded-md shadow-xs focus:outline-none" aria-label="Account">
      {[...state.collection].map((item) => (
        <MenuSection key={item.key} section={item} state={state} onAction={props.onAction} onClose={props.onClose} />
      ))}
    </ul>
  );
}

function MenuSection({ section, state, onAction, onClose }) {
  let { itemProps, groupProps } = useMenuSection({
    "heading": section.rendered,
    "aria-label": section["aria-label"]
  });

  let { separatorProps } = useSeparator({
    elementType: "li"
  });
  return (
    <>
      {section.key !== state.collection.getFirstKey() && (
        <li {...separatorProps} className="border-t border-gray-300" role="none" />
      )}
      <li {...itemProps} role="none">
        <ul {...groupProps} role="presentation">
          {[...section.childNodes].map((node) => (
            <MenuItem key={node.key} item={node} state={state} onAction={onAction} onClose={onClose} />
          ))}
        </ul>
      </li>
    </>
  );
}

function MenuItem({ item, state, onAction, onClose }) {
  let ref = React.useRef() as React.MutableRefObject<HTMLLIElement>;
  let { menuItemProps } = useMenuItem(
    {
      key: item.key,
      isDisabled: item.isDisabled,
      onAction,
      onClose
    },
    state,
    ref
  );

  let isFocused = state.selectionManager.focusedKey === item.key;

  let className = clsx(
    "text-gray-900",
    isFocused && "bg-pale-grey-10",
    "cursor-pointer select-none py-6 px-5 w-56 focus:outline-none leading-tight font-medium relative pointer"
  );

  return (
    <li {...menuItemProps} ref={ref} className={className} role="menuitem">
      {isFocused && <span className="absolute top-0 left-0 w-1 h-full bg-primary-500" />}
      {item.rendered}
    </li>
  );
}
